import { Box } from '../../Box'
import { Heading } from '../../Heading'
import { ImageFieldImage, RichTextField } from '@prismicio/types'
import { Text } from '../../Text'
import { Icon, OrbitImage } from '@orbit/ui/core'
import * as styles from './SpeakersCard.css'
import { BreakPoints, theme } from '@orbit/ui/themes'
import { useState, useEffect } from 'react'
import { useWindowSize } from 'react-use'
import { PrismicRichText } from '@prismicio/react'
import Link from 'next/link'

export interface SpeakersCardProps {
    image: ImageFieldImage
    name: string
    jobtitle: string
    text: any
    linkedinLabel?: string
    linkedinLink?: string
    color: string
    size?: 'small' | 'medium' | 'large'
    variant?: 'default' | 'whiteBackground'
    subtitleColor?: string
}

export const SpeakersCard: React.FC<SpeakersCardProps> = ({
    image,
    name,
    jobtitle,
    text,
    linkedinLabel,
    linkedinLink,
    color,
    size,
    variant,
    subtitleColor,
}) => {
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)
    useEffect(() => {
        if (width < BreakPoints.mobile) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])
    return (
        <Box
            flexDirection="column"
            align="center"
            width={'full'}
            boxStyles={{
                height: '100%',
                paddingTop: '8rem',
            }}
        >
            {image && (
                <Box
                    boxStyles={{
                        position: 'absolute',
                        transform: 'translateY(-6.75rem)',
                    }}
                >
                    <OrbitImage
                        containerclass={styles.imageWrapper}
                        src={image.url || ''}
                        alt={image.alt || ''}
                        height={184}
                        width={184}
                    />
                </Box>
            )}

            <Box
                boxStyles={{
                    backgroundColor: color,
                    paddingTop: '5.75rem',
                    height: '100%',
                    width: '100%',
                }}
                flexDirection="column"
            >
                <Box
                    extraClassName={styles.contentWrapper({
                        size: size,
                    })}
                    boxStyles={{
                        paddingBottom:
                            variant === 'whiteBackground' ? '2.5rem' : '5rem',
                    }}
                >
                    <Box flexDirection="column">
                        <Heading
                            my={0}
                            paddingTop={5}
                            paddingBottom={2}
                            type="h3"
                            text={name}
                        />
                        {variant === 'default' ? (
                            <Text
                                my={0}
                                paddingBottom={2}
                                textClass={styles.jobtitleTextStyleDefault}
                            >
                                {jobtitle}
                            </Text>
                        ) : (
                            <Text
                                my={0}
                                style={{ color: subtitleColor }}
                                textClass={styles.jobtitleTextStyleColor}
                            >
                                {jobtitle}
                            </Text>
                        )}
                        {variant === 'default' ? (
                            <Text
                                my={0}
                                paddingBottom={3}
                                style={{ fontWeight: '300' }}
                                size="medium"
                            >
                                {text}
                            </Text>
                        ) : (
                            <PrismicRichText
                                field={text}
                                components={{
                                    paragraph: ({ children }) => (
                                        <Text
                                            style={{ fontWeight: '300' }}
                                            size="medium"
                                        >
                                            {children}
                                        </Text>
                                    ),
                                }}
                            />
                        )}
                    </Box>

                    <Box justify="center" paddingBottom={isMobile ? 36 : 0}>
                        {linkedinLink && (
                            <Link href={linkedinLink}>
                                <a
                                    href={linkedinLink}
                                    className={styles.footerLinksText}
                                    target={'_blank'}
                                    rel="noreferrer"
                                >
                                    <Icon icon={'Linkedin'} />
                                    <Text
                                        style={{
                                            fontWeight: 600,
                                            marginTop: 0,
                                            overflowWrap: 'anywhere',
                                        }}
                                    >
                                        {`@${linkedinLabel}`}
                                    </Text>
                                </a>
                            </Link>
                        )}
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
