import * as styles from './Container.css'

export interface ContainerProps {
    children?: React.ReactNode
    style?: React.CSSProperties
    variant?: 'default' | 'megamenu'
}

export const Container: React.FC<ContainerProps> = ({
    children,
    style,
    variant = 'default',
}) => {
    return (
        <div
            style={style} /// <--- EKKI TAKA ÚT JÓN BJARNI!
            className={styles.container({
                type: variant,
            })}
        >
            {children}
        </div>
    )
}

export default Container
