import {
    parsePrismicBackgroundColor,
    parsePrismicHeadingColor,
    parsePrismicHighlightColor,
    parsePrismicLightBackgroundColor,
} from '@orbit/prismic'
import {
    Box,
    OrbitPriceModule,
    Carousel,
    Container,
    MotionBox,
    HeadingContainer,
} from '@orbit/ui/core'
import { RichTextField, RTNode } from '@prismicio/types'
import { TPriceModuleSlice, TPriceModuleItems } from '../PriceModule.types'
import { cardRevealUp } from '@orbit/utils/framer-motion'
import { BreakPoints, theme } from '@orbit/ui/themes'
import { useState, useEffect, useRef } from 'react'
import { useWindowSize } from 'react-use'
import { PrismicSerializer } from '@orbit/ui/prismic'
import SwiperCore from 'swiper'
import * as styles from '../PriceModule.css'
export const DefaultPriceModule = (slice: TPriceModuleSlice) => {
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)
    const swiperRef = useRef<SwiperCore>()
    const navigationPrevRef = useRef<HTMLDivElement>(null)
    const navigationNextRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (width < BreakPoints.mobile) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    const RichtextToString = (bullets: RichTextField) => {
        const bullet: string[] = []
        bullets.forEach((item: RTNode) => {
            if (item.type === 'paragraph') {
                bullet.push(item.text)
            }
        })
        return bullet
    }

    return (
        <Box
            anchorid={slice.primary.anchor || undefined}
            justify="center"
            boxStyles={{
                background: slice.primary.backgroundfilled
                    ? parsePrismicBackgroundColor(slice.primary.slicecolor)
                    : 'white',
            }}
            paddingTop={isMobile ? 36 : 80}
            paddingBottom={isMobile ? 36 : 80}
        >
            <Container>
                <HeadingContainer
                    tagline={
                        slice.primary.tagline
                            ? slice.primary.tagline
                            : undefined
                    }
                    title={slice.primary.title ? slice.primary.title : ''}
                    color={parsePrismicHeadingColor(slice.primary.slicecolor)}
                    pb="0rem"
                />
                {slice.primary.text && (
                    <Box
                        justify="center"
                        align="center"
                        margin="auto"
                        extraClassName={styles.textBox({
                            oneCard: slice.items.length === 1 ? true : false,
                        })}
                        paddingLeft={16}
                        paddingRight={16}
                    >
                        <PrismicSerializer
                            slicecolor={slice.primary.slicecolor}
                            text={slice.primary.text}
                            textAlign="center"
                        />
                    </Box>
                )}
                <Box flexDirection="row" width="full">
                    <Carousel
                        totalItems={slice.items.length}
                        color={parsePrismicHeadingColor(
                            slice.primary.slicecolor
                        )}
                        variant={'dots'}
                        pageSize={{
                            mobile: 1,
                            tablet: slice.items.length === 1 ? 1 : 2,
                            desktop: slice.items.length === 1 ? 1 : 3,
                        }}
                        itemsToSlide={{
                            mobile: 1,
                            tablet: 1,
                            desktop: 1,
                        }}
                        tabletWidth={1135}
                        mobileWidth={910}
                        partialVisibilityGutter={0}
                    >
                        {Array.isArray(slice.items) &&
                            slice.items.map(
                                (item: TPriceModuleItems, index: number) => (
                                    <MotionBox
                                        width="full"
                                        align="center"
                                        justify="center"
                                        boxStyles={{
                                            height: '100%',
                                        }}
                                        key={
                                            'orbit-price-module-default' + index
                                        }
                                        initial={
                                            isMobile ? 'visible' : 'hidden'
                                        }
                                        viewport={{
                                            once: true,
                                        }}
                                        whileInView="visible"
                                        transition={{
                                            duration: 0.6,
                                            delay: 0.15 * (index + 1),
                                        }}
                                        variants={cardRevealUp}
                                    >
                                        <OrbitPriceModule
                                            backgroundFilled={
                                                slice.primary.backgroundfilled
                                            }
                                            backgroundColor={
                                                slice.primary
                                                    .backgroundfilled &&
                                                item.focused
                                                    ? parsePrismicLightBackgroundColor(
                                                          slice.primary
                                                              .slicecolor
                                                      )
                                                    : slice.primary
                                                          .backgroundfilled &&
                                                      !item.focused
                                                    ? theme.colors.neutral[100]
                                                    : !slice.primary
                                                          .backgroundfilled &&
                                                      item.focused
                                                    ? parsePrismicBackgroundColor(
                                                          slice.primary
                                                              .slicecolor
                                                      )
                                                    : parsePrismicLightBackgroundColor(
                                                          slice.primary
                                                              .slicecolor
                                                      )
                                            }
                                            sliceColor={parsePrismicHeadingColor(
                                                slice.primary.slicecolor
                                            )}
                                            variation={'Default'}
                                            totalItems={slice.items.length}
                                            tagline={item.tagline as string}
                                            price={item.price as string}
                                            text={item.text as string}
                                            included={
                                                item.included &&
                                                RichtextToString(item.included)
                                            }
                                            notIncluded={
                                                item.notincluded &&
                                                RichtextToString(
                                                    item.notincluded
                                                )
                                            }
                                            buttonLabel={
                                                item.buttonlabel as string
                                            }
                                            buttonIcon={item.buttonicon}
                                            buttonLink={item.buttonlink.url}
                                            openNewTab={item.openNewTab}
                                        />
                                    </MotionBox>
                                )
                            )}
                    </Carousel>
                </Box>
                {slice.primary.disclaimer && (
                    <Box
                        boxStyles={{}}
                        extraClassName={styles.textBox({
                            oneCard: slice.items.length === 1 ? true : false,
                        })}
                    >
                        <PrismicSerializer
                            slicecolor={slice.primary.slicecolor}
                            text={slice.primary.disclaimer}
                        />
                    </Box>
                )}
            </Container>
        </Box>
    )
}

export default DefaultPriceModule
