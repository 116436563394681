import { theme } from '@orbit/ui/themes'
import { JSXMapSerializer } from '@prismicio/react'
import React from 'react'
import { Text } from '../../../../orbit-ui/core/src'

export const Paragraph: JSXMapSerializer['paragraph'] = ({
    text = '',
    node,
    key,
    children,
}) => {
    return <Text key={key}>{children || text}</Text>
}

export const Strong: JSXMapSerializer['strong'] = ({
    text = '', // overwrite text type from undefined to string
    node,
    key,
    children,
}) => {
    return (
        <strong style={{ fontWeight: '600' }} key={key}>
            {children}
        </strong>
    )
}
