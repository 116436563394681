import { BreakPoints } from '@orbit/ui/themes'
import { useState, useEffect, ReactNode } from 'react'
import { useWindowSize } from 'react-use'
import { ArrowLink } from '../ArrowLink'
import { Box } from '../Box'
import { Button } from '../Button'
import { HeadingContainer } from '../HeadingContainer'
import { IconType } from '../Icons'
import * as style from './PreFooter.css'
type PreFooterProps = {
    backgroundFilled: boolean
    backgroundColor: string
    dotColor: string
    color: 'primary' | 'secondary' | 'tertiary' | 'accent'
    imageUrl?: string
    imageAlt?: string
    tagline?: string
    title?: string
    buttonIcon?: IconType
    buttonLink?: string
    buttonLabel?: string
    text?: string | ReactNode
    openNewTab: boolean
    buttonVariant?: 'filled' | 'link'
    headingSize?: 'small' | 'xsmall'
    variant?: 'left' | 'center'
    imageshape?: 'Square' | 'Circle'
}
export const PreFooter: React.FC<PreFooterProps> = ({
    backgroundFilled,
    backgroundColor,
    dotColor,
    color,
    imageUrl,
    imageAlt,
    tagline,
    title,
    text,
    buttonLabel,
    buttonIcon,
    buttonLink,
    openNewTab,
    buttonVariant = 'filled',
    headingSize = 'small',
    variant = 'left',
    imageshape = 'Circle',
}) => {
    const { width } = useWindowSize()

    const [deviceType, setDeviceType] = useState<
        'mobile' | 'tablet' | 'desktop'
    >('desktop')

    useEffect(() => {
        if (width < BreakPoints.mobile) {
            return setDeviceType('mobile')
        }
        if (width < BreakPoints.tabletSmall) {
            return setDeviceType('tablet')
        }
        return setDeviceType('desktop')
    }, [width])

    const withImage: boolean = imageUrl ? true : false

    function button() {
        return (
            <Box
                marginTop={deviceType === 'mobile' ? 8 : 24}
                alignSelf={
                    withImage && deviceType !== 'mobile'
                        ? 'flexStart'
                        : 'center'
                }
                paddingRight={!withImage && deviceType !== 'mobile' ? 80 : 0}
            >
                {buttonVariant !== 'link' ? (
                    <Button
                        variant={buttonLabel ? 'filled' : 'icon'}
                        color={color}
                        href={buttonLink}
                        icon={buttonIcon}
                        ariaLabel={buttonLabel ? undefined : buttonIcon}
                        newTab={openNewTab}
                    >
                        {buttonLabel}
                    </Button>
                ) : (
                    <ArrowLink link={buttonLink} linkText={buttonLabel || ''} />
                )}
            </Box>
        )
    }
    return (
        <Box
            align={variant === 'center' ? 'center' : 'flexStart'}
            justify="spaceBetween"
            flexDirection={deviceType === 'mobile' ? 'column' : 'row'}
            boxStyles={{
                backgroundColor: !backgroundFilled ? backgroundColor : 'white',
            }}
            width="full"
        >
            <Box
                alignItems="center"
                justify="spaceBetween"
                width="full"
                flexDirection={
                    withImage
                        ? 'column'
                        : deviceType === 'mobile'
                        ? 'column'
                        : 'row'
                }
                alignSelf="center"
                align={variant === 'center' ? 'center' : 'flexStart'}
            >
                <Box
                    flexDirection="column"
                    align={variant === 'center' ? 'center' : 'flexStart'}
                    boxStyles={{ maxWidth: '35rem' }}
                    py={deviceType === 'mobile' ? 6 : 0}
                >
                    <Box
                        flexDirection="column"
                        align={variant === 'center' ? 'center' : 'flexStart'}
                    >
                        <HeadingContainer
                            variant={variant}
                            tagline={tagline || undefined}
                            title={title || undefined}
                            color={color}
                            size={headingSize}
                            gap="0.5rem"
                            pb="0rem"
                        />
                        <p
                            style={{
                                textAlign:
                                    deviceType !== 'mobile' ? 'left' : 'center',
                                margin: 0,
                            }}
                        >
                            {text}
                        </p>
                    </Box>
                </Box>
                {buttonLink &&
                    (imageshape === 'Circle' || width > BreakPoints.mobile) &&
                    button()}
            </Box>
            {imageUrl && (
                <Box
                    width={'half'}
                    justify="center"
                    paddingLeft={deviceType === 'mobile' ? 0 : 16}
                    align="flexEnd"
                    alignSelf="center"
                    paddingBottom={deviceType === 'mobile' ? 40 : 8}
                    position="relative"
                >
                    <img
                        src={imageUrl || ''}
                        alt={imageAlt || ''}
                        className={
                            imageshape === 'Circle'
                                ? style.imageStyleCircle
                                : style.imageStyleSquare
                        }
                    />
                    {imageshape === 'Circle' && (
                        <Box
                            extraClassName={style.dot}
                            boxStyles={{
                                backgroundColor: dotColor,
                            }}
                        ></Box>
                    )}
                </Box>
            )}
            {buttonLink &&
                imageshape !== 'Circle' &&
                deviceType === 'mobile' &&
                button()}
        </Box>
    )
}

export const SmallCardPreFooter: React.FC<PreFooterProps> = ({
    backgroundFilled,
    backgroundColor,
    dotColor,
    color,
    imageUrl,
    imageAlt,
    tagline,
    title,
    text,
    buttonLabel,
    buttonIcon,
    buttonLink,
    openNewTab,
    buttonVariant = 'filled',
    headingSize = 'small',
    variant = 'left',
    imageshape = 'Circle',
}) => {
    const { width } = useWindowSize()
    const [deviceType, setDeviceType] = useState<
        'mobile' | 'tablet' | 'desktop'
    >('desktop')
    const withImage: boolean = imageUrl ? true : false

    function button() {
        return (
            <Box
                marginTop={deviceType === 'mobile' ? 8 : 24}
                alignSelf={
                    withImage && deviceType !== 'mobile'
                        ? 'flexStart'
                        : 'center'
                }
            >
                {buttonVariant !== 'link' ? (
                    <Button
                        variant={buttonLabel ? 'filled' : 'icon'}
                        color={color}
                        href={buttonLink}
                        icon={buttonIcon}
                        ariaLabel={buttonLabel ? undefined : buttonIcon}
                        newTab={openNewTab}
                    >
                        {buttonLabel}
                    </Button>
                ) : (
                    <ArrowLink link={buttonLink} linkText={buttonLabel || ''} />
                )}
            </Box>
        )
    }
    useEffect(() => {
        if (width < BreakPoints.mobile) {
            return setDeviceType('mobile')
        }
        if (width < BreakPoints.tabletSmall) {
            return setDeviceType('tablet')
        }
        return setDeviceType('desktop')
    }, [width])

    return (
        <Box
            align={variant === 'center' ? 'center' : 'flexStart'}
            alignItems="center"
            justify="spaceBetween"
            flexDirection={
                withImage && width > BreakPoints.mobile ? 'row' : 'column'
            }
            boxStyles={{
                backgroundColor: !backgroundFilled ? backgroundColor : 'white',
            }}
            width="full"
            gap="2.5rem"
        >
            <Box
                alignItems="center"
                justify="spaceBetween"
                width={width > BreakPoints.mobile ? 'half' : 'full'}
                flexDirection={'column'}
                alignSelf="center"
                align={variant === 'center' ? 'center' : 'flexStart'}
            >
                <Box
                    flexDirection="column"
                    align={variant === 'center' ? 'center' : 'flexStart'}
                    boxStyles={{ maxWidth: '35rem' }}
                >
                    <Box
                        flexDirection="column"
                        align={variant === 'center' ? 'center' : 'flexStart'}
                    >
                        <HeadingContainer
                            variant={variant}
                            tagline={tagline || undefined}
                            title={title || undefined}
                            color={color}
                            size={headingSize}
                            gap="0.5rem"
                            pb="0rem"
                        />
                        <p
                            style={{
                                textAlign:
                                    withImage && deviceType !== 'mobile'
                                        ? 'left'
                                        : 'center',
                                margin: 0,
                            }}
                        >
                            {text}
                        </p>
                    </Box>
                </Box>
                {buttonLink &&
                    (imageshape === 'Circle' || width > BreakPoints.mobile) &&
                    button()}
            </Box>
            {imageUrl && (
                <Box
                    width={width > BreakPoints.mobile ? 'half' : 'full'}
                    justify="center"
                    paddingLeft={0}
                    align="flexEnd"
                    alignSelf="center"
                    position="relative"
                >
                    <img
                        src={imageUrl || ''}
                        alt={imageAlt || ''}
                        className={
                            imageshape === 'Circle'
                                ? style.imageStyleCircle
                                : style.imageStyleSquare
                        }
                        style={{
                            width: imageshape === 'Square' ? '100%' : 'unset',
                        }}
                    />
                    {imageshape === 'Circle' && (
                        <Box
                            extraClassName={style.SmallCardDot}
                            boxStyles={{
                                backgroundColor: dotColor,
                            }}
                        ></Box>
                    )}
                </Box>
            )}
            {buttonLink &&
                imageshape !== 'Circle' &&
                deviceType === 'mobile' &&
                button()}
        </Box>
    )
}
