import { parsePrismicDarkColor } from '@orbit/prismic'
import {
    Box,
    Button,
    Container,
    Heading,
    Pagination,
    Select,
    Table,
} from '@orbit/ui/core'
import { PrismicRichText } from '@prismicio/react'
import { useEffect, useState } from 'react'
import useSwr from 'swr'
import { TStaffSearchContext, TStaffSearchSlice } from '../StaffSearch.types'
import * as styles from './StaffSearch.css'

type TStaffResponse = {
    JobTitle: string
    WorkEmail: string
    Name: string
}

const fetcher = (url: string) => fetch(url).then((res) => res.json())

export const DefaultStaffSearch = (
    slice: TStaffSearchSlice,
    context: TStaffSearchContext
) => {
    const [query, setQuery] = useState('')
    const [employees, setEmployees] = useState<TStaffResponse[]>()
    const [canSearch, setCanSearch] = useState(false)
    const [isValid, setIsValid] = useState(false)

    const [currentPage, setCurrentPage] = useState(1)
    const [offset, setOffset] = useState(10)

    const [filteredEmployees, setFilteredEmployees] =
        useState<TStaffResponse[]>()

    const { data } = useSwr<TStaffResponse[]>(
        isValid && canSearch ? `/api/staff?name=${query}` : null,
        fetcher
    )

    useEffect(() => {
        if (query.length > 2) setIsValid(true)
        else setIsValid(false)
    }, [query])

    useEffect(() => {
        if (data) {
            setCanSearch(false)
            setEmployees([...data])

            const fe = data.slice(0, offset)
            setFilteredEmployees(fe)
        }
    }, [data, offset])

    useEffect(() => {
        if (employees && employees.length > 0) {
            const start = (currentPage - 1) * offset
            const end = start + offset

            setFilteredEmployees(employees.slice(start, end))
        }
    }, [currentPage, employees, offset])

    const handleFormSubmission = (e: React.FormEvent) => {
        e.preventDefault()
        setCanSearch(true)
    }

    const translateJobTitle = (title: string) => {
        const split = title.split('/')
        if (split.length <= 1) return title
        return context.lang === 'is' ? split[0] : split[1]
    }

    return (
        <>
            <Container>
                <Box extraClassName={styles.HeadingWrapperStyle}>
                    <Heading
                        type="h5"
                        my={0}
                        padding={0}
                        text={slice.primary.tagline ?? ''}
                    />
                    <Heading
                        type="h1"
                        my={0}
                        paddingBottom={4}
                        text={slice.primary.title ?? ''}
                    />
                    <PrismicRichText
                        field={slice.primary.description}
                        components={{
                            hyperlink: ({ text, node }) => (
                                <a
                                    style={{
                                        color: parsePrismicDarkColor(
                                            slice.primary.slicecolor
                                        ),
                                        fontWeight: 600,
                                        textDecoration: 'none',
                                    }}
                                    href={node.data.url}
                                    target={
                                        node.data.link_type === 'Web'
                                            ? node.data.target
                                            : ''
                                    }
                                    rel="noopener noreferrer"
                                >
                                    {text}
                                </a>
                            ),
                        }}
                    />
                </Box>
            </Container>
            <Box
                extraClassName={styles.BackgroundWrapperStyle({
                    slicecolor: slice.primary.slicecolor,
                })}
            >
                <Container>
                    <Box width="full" flex={1} gap="2.5rem">
                        <Box width="full" flex={4}>
                            <Select
                                variant="filled"
                                placeholder={
                                    context.lang === 'is'
                                        ? 'Velja fjölda'
                                        : 'Number of results'
                                }
                                defaultValue="10"
                                options={['10', '20', '30', '40', '50']}
                                onSelectChange={(opt) =>
                                    setOffset(
                                        !isNaN(parseInt(opt))
                                            ? parseInt(opt)
                                            : 10
                                    )
                                }
                            />
                        </Box>
                        <Box width="full" flex={8}>
                            <form
                                className={styles.FormStyle}
                                onSubmit={(e) => handleFormSubmission(e)}
                            >
                                <Box width="full" position="relative">
                                    <input
                                        placeholder={
                                            context.lang === 'is'
                                                ? 'Leit'
                                                : 'Search'
                                        }
                                        type="text"
                                        value={query}
                                        className={styles.InputStyle}
                                        onChange={(e) =>
                                            setQuery(e.target.value)
                                        }
                                    />
                                    <Box
                                        extraClassName={
                                            styles.SearchButtonWrapper
                                        }
                                    >
                                        <Button
                                            size="small"
                                            variant="icon"
                                            icon="Search"
                                        />
                                    </Box>
                                </Box>
                            </form>
                        </Box>
                    </Box>
                </Container>
                <Container>
                    {employees &&
                        filteredEmployees &&
                        filteredEmployees.length > 0 && (
                            <Box extraClassName={styles.TableWrapper}>
                                <Table
                                    color={slice.primary.slicecolor}
                                    backgroundFilled={true}
                                    boldRow={false}
                                    tableData={{
                                        tableHead: {
                                            items:
                                                context.lang === 'is'
                                                    ? [
                                                          'Nafn',
                                                          'Starfsheiti',
                                                          'Netfang',
                                                      ]
                                                    : [
                                                          'Name',
                                                          'Job title',
                                                          'Email',
                                                      ],
                                        },
                                        tableRows: filteredEmployees.map(
                                            (e) => ({
                                                items: [
                                                    e.Name,
                                                    translateJobTitle(
                                                        e.JobTitle
                                                    ),
                                                    e.WorkEmail.replace(
                                                        '@',
                                                        ` ${
                                                            context.lang ===
                                                            'is'
                                                                ? ' [hjá] '
                                                                : ' [at] '
                                                        } `
                                                    ),
                                                ],
                                            })
                                        ),
                                        withLink: false,
                                    }}
                                />
                                <Pagination
                                    total={employees.length}
                                    currentPage={currentPage}
                                    pageSize={offset}
                                    color={slice.primary.slicecolor}
                                    onPageChange={(page) =>
                                        setCurrentPage(page)
                                    }
                                />
                            </Box>
                        )}
                </Container>
            </Box>
        </>
    )
}
