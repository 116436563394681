import { TButtonSlices } from '../Button.types'
import { Button, Box, Container } from '@orbit/ui/core'
import { parsePrismicHeadingColor } from '@orbit/prismic'
export const FilledButton = (slice: TButtonSlices) => {
    return (
        <Container>
            <Box
                anchorid={slice.primary.anchor || undefined}
                boxStyles={{
                    maxWidth: '53.5rem',
                    margin: 'auto',
                    paddingTop: '2rem',
                    paddingBottom: '2rem',
                }}
            >
                <Button
                    href={slice.primary.link.url}
                    color={parsePrismicHeadingColor(slice.primary.slicecolor)}
                    newTab={slice.primary.openNewTab}
                    variant="filled"
                >
                    {slice.primary.label}
                </Button>
            </Box>
        </Container>
    )
}

export default FilledButton
