import { BreakPoints, theme } from '@orbit/ui/themes'
import { cardRevealUp } from '@orbit/utils/framer-motion'
import { ImageFieldImage, KeyTextField } from '@prismicio/types'
import moment from 'moment'
import { useState, useEffect } from 'react'
import { useWindowSize } from 'react-use'
import { Box, MotionBox } from '../../Box'
import { Heading } from '../../Heading'
import { Icon } from '../../Icons'
import { OrbitImage } from '../../Image'
import { Text } from '../../Text'
import * as style from './EventsCard.css'
import { Pencil } from './IconPencil'

export interface EventsCardProps {
    uid: string
    id: string
    tag: string
    date: string
    heading: string
    image: ImageFieldImage
    link?: string
    tagColor?: 'primary' | 'secondary' | 'tertiary'
    summary: KeyTextField
    size?: 'small' | 'medium' | 'large'
    availability?: 'Full' | 'Available'
    backgroundColor?: string
    textColor?: string
    headingColor?: string
    cardColor?: string
    headingLevel?: 'h3' | 'h4'
    index?: number
}

export const EventsCard: React.FC<EventsCardProps> = ({
    backgroundColor,
    textColor,
    headingColor,
    cardColor,
    id,
    heading,
    link,
    image,
    tag,
    date,
    summary,
    availability,
    size = 'small',
    uid,
    headingLevel = 'h4',
    index = 1,
}) => {
    const [isMobile, setIsMobile] = useState(false)
    const { width } = useWindowSize()

    useEffect(() => {
        if (width < BreakPoints.tabletSmall) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    const imageHeight = 22.5 * 16

    const renderHeader = () => {
        return (
            <Box
                extraClassName={style.dateStyle}
                boxStyles={{
                    color: textColor,
                    paddingBottom:
                        availability === 'Full' && size !== 'small'
                            ? 0
                            : '1.25rem',
                }}
                align="center"
                width="full"
                justify="spaceBetween"
            >
                <Box align="center">
                    {uid !== 'canon-namskeid-byrjendur' && (
                        <>
                            <Icon
                                icon={'CalendarAlt'}
                                size="small"
                                color={textColor}
                            />
                            <Box paddingLeft={8}>
                                {moment(date).format('DD/MM/YYYY')}
                            </Box>
                        </>
                    )}
                </Box>
                <Box
                    extraClassName={style.tagStyle}
                    boxStyles={{
                        color: headingColor,
                        background: backgroundColor,
                    }}
                >
                    {tag === 'Course'
                        ? 'Námskeið'
                        : tag === 'Event'
                        ? 'Viðburður'
                        : 'Vefvarp'}
                </Box>
            </Box>
        )
    }

    const availabilityFull = () => {
        return (
            <Box
                marginTop={24}
                width={'full'}
                justify={'center'}
                align="center"
                boxStyles={{
                    background: theme.colors.neutral[700],
                }}
            >
                <Text
                    textAlign="center"
                    style={{
                        color: theme.colors.neutral[100],
                        fontWeight: '600',
                        lineHeight: '1rem',
                        fontSize: '0.875rem',
                    }}
                >
                    Lokað fyrir skráningu
                </Text>
                <Box paddingLeft={16}>
                    <Pencil />
                </Box>
            </Box>
        )
    }
    return (
        <MotionBox
            width={'full'}
            key={id}
            extraClassName={style.EventsCardWrapper({ size: size })}
            flexDirection={size === 'small' ? 'column' : 'row'}
            padding={5}
            boxStyles={{
                backgroundColor: cardColor,
                width: isMobile ? '100%' : '',
            }}
            initial="hidden"
            viewport={{
                once: true,
            }}
            whileInView="visible"
            transition={{
                duration: 0.6,
                delay: 0.15 * ((index % 3) + 1),
            }}
            variants={cardRevealUp}
        >
            <a
                href={`/vidburdir/${link}`}
                className={style.linkWrapper({ size: size })}
            >
                <Box
                    boxStyles={{
                        position: 'relative',
                        width: isMobile ? '100% !important' : '',
                    }}
                    extraClassName={style.imageWrapper({ size: size })}
                    align="center"
                    flexDirection="column"
                >
                    {size === 'small' && renderHeader()}
                    {isMobile && size !== 'small' && renderHeader()}
                    <Box align="center" width="full" flexDirection="column">
                        <OrbitImage
                            className={
                                isMobile
                                    ? style.imageStyleMobile
                                    : style.imageStyleDesktop({ size: size })
                            }
                            height={imageHeight}
                            width="100%"
                            layout="fill"
                            src={image.url || ''}
                            alt={image.alt || 'Stakur viðburður'}
                        />

                        {size === 'small' &&
                            availability === 'Full' &&
                            availabilityFull()}
                    </Box>
                </Box>

                <Box
                    boxStyles={{
                        textDecoration: 'none',
                    }}
                    flexDirection="column"
                    width={size == 'small' || isMobile ? 'full' : 'half'}
                >
                    {!isMobile && size !== 'small' && renderHeader()}
                    {size !== 'small' &&
                        availability === 'Full' &&
                        availabilityFull()}

                    <Box
                        paddingBottom={4}
                        boxStyles={{ minHeight: isMobile ? 0 : '6rem' }}
                    >
                        <Heading
                            type={headingLevel}
                            text={heading}
                            my={0}
                            style={{
                                marginBottom: size === 'small' ? 0 : 'unset',
                                marginTop:
                                    size === 'small' ? 24 : isMobile ? 24 : 0,
                                fontSize:
                                    size === 'small'
                                        ? '1.5rem'
                                        : isMobile
                                        ? '1.5rem'
                                        : '2rem',
                                lineHeight:
                                    size === 'small'
                                        ? '2rem'
                                        : isMobile
                                        ? '2rem'
                                        : '2.5rem',
                                wordBreak: 'break-word',
                            }}
                        />
                    </Box>
                    <Text my={0} size="medium">
                        {summary}
                    </Text>
                </Box>
            </a>
        </MotionBox>
    )
}
