import { Box, Button, Heading, Text } from '@orbit/ui/core'
import { BreakPoints, theme } from '@orbit/ui/themes'
import Link from 'next/link'
import { useState, useEffect } from 'react'
import { useWindowSize } from 'react-use'
import { TMenuItemSliceDefault } from '../MenuItem.types'
import { MenuLinkStyle, MenuLinkWrapper } from './MenuItem.css'

export const DefaultMenuItem = (slice: TMenuItemSliceDefault) => {
    const flexSize = slice.items.length > 4 ? 2 : 1

    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)
    useEffect(() => {
        if (width < BreakPoints.tabletSmall) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    return (
        <Box
            key={slice.primary.title}
            flexDirection="column"
            flex={flexSize}
            boxStyles={{
                paddingTop: isMobile ? '1.5rem' : '2.5rem',
                paddingBottom: '2rem',
            }}
        >
            <Box wrap="wrap" flexDirection="column">
                {slice.primary.title && (
                    <Heading
                        text={slice.primary.title}
                        type={'h5'}
                        style={{
                            fontSize: '0.75rem',
                            letterSpacing: '0.125rem',
                            width: '100%',
                            margin: '0',
                            marginBottom: isMobile ? '1rem' : '2.5rem',
                            color: theme.colors.neutral[600],
                        }}
                    />
                )}
                <Box
                    wrap={isMobile ? 'nowrap' : 'wrap'}
                    flexDirection={isMobile ? 'column' : 'row'}
                    boxStyles={
                        isMobile
                            ? {
                                  gap: '1.5rem',
                                  paddingLeft: '0.5rem',
                                  paddingRight: '0.5rem',
                              }
                            : { gap: '2.5rem', paddingBottom: '3rem' }
                    }
                >
                    {slice.items.map((item, j: number) => {
                        // TODO ADD ONKEYDOWN TO NAVIGATE

                        return (
                            <Link href={item.link.url} key={j} passHref>
                                <a
                                    className={MenuLinkWrapper}
                                    href={item.link.url}
                                >
                                    {item.linktitle && item.link.url && (
                                        <Box
                                            flexDirection="column"
                                            justify={'spaceBetween'}
                                        >
                                            <Box flexDirection="row">
                                                <Box
                                                    flexDirection="column"
                                                    boxStyles={
                                                        isMobile
                                                            ? {
                                                                  gap: '0rem',
                                                              }
                                                            : {
                                                                  gap: '0.5rem',
                                                              }
                                                    }
                                                >
                                                    <p
                                                        className={
                                                            MenuLinkStyle
                                                        }
                                                    >
                                                        {item.linktitle}
                                                    </p>
                                                    <Text
                                                        my={0}
                                                        style={
                                                            isMobile
                                                                ? {
                                                                      fontSize:
                                                                          '1rem',
                                                                  }
                                                                : {}
                                                        }
                                                        size="small"
                                                    >
                                                        {item.linkdescription}
                                                    </Text>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )}
                                </a>
                            </Link>
                        )
                    })}
                </Box>
                {slice.primary.menulink && slice.primary.menulinklabel && (
                    <Box marginTop={24} marginBottom={isMobile ? 0 : 24}>
                        <Button
                            href={slice.primary.menulink.url}
                            color="primary"
                            variant="text"
                            icon="ArrowRight"
                        >
                            {slice.primary.menulinklabel}
                        </Button>
                    </Box>
                )}
            </Box>
        </Box>
    )
}

export default DefaultMenuItem
