import { Box, Button, Hamburger } from '@orbit/ui/core'
import { BreakPoints } from '@orbit/ui/themes'
import React, { useContext, useEffect, useState } from 'react'
import { useWindowSize } from 'react-use'
import { useRouter } from 'next/router'
import { MegaMenu, VerslunMegaMenu } from '../OrbitMegaMenu'
import * as styles from './Nav.css'
import { AnimatePresence, motion, Variants } from 'framer-motion'
import { MobileMenu } from '../MobileMenu'
import { MegamenuContext } from '@oribt/context'
import { MobilemenuContext } from '../MobileMenu/MobileMenuContext'
import { NavigationProps } from '../Header'
import { SearchInput } from '../Header/SearchInput'

type MenuTitles =
    | 'Lausnir'
    | 'Vörur'
    | 'Ráðgjöf og þjónusta'
    | 'Origo'
    | 'Our solutions'
    | 'About us'

export const Navigation: React.FC<NavigationProps> = ({
    menus,
    buttonLabel,
    buttonLink,
    lang,
}) => {
    const router = useRouter()
    const megamenuContext = useContext(MegamenuContext)
    const mobilemenuContext = useContext(MobilemenuContext)

    const [activeMenu, setActiveMenu] = useState<MenuTitles | null>(null)

    useEffect(() => {
        const routerChangeHandler = () => {
            megamenuContext.setOpenMegamenu('')
            mobilemenuContext.setSelectedMobilemenu('')
            mobilemenuContext.setIsOpen(false)
        }
        router.events.on('routeChangeComplete', routerChangeHandler)

        return () => {
            router.events.off('routeChangeComplete', routerChangeHandler)
        }
    }, [megamenuContext, mobilemenuContext, router])

    useEffect(() => {
        if (window) {
            const path = window.location.pathname.split('/')[1]
            if (path) {
                if (lang === 'is') {
                    switch (path) {
                        case 'lausnir':
                            setActiveMenu('Lausnir')
                            break
                        case 'Vörur':
                            setActiveMenu('Vörur')
                            break
                        case 'radgjof-og-thjonusta':
                            setActiveMenu('Ráðgjöf og þjónusta')
                            break
                        default:
                            setActiveMenu('Origo')
                            break
                    }
                } else {
                    const engPath = window.location.pathname.split('/')[2]
                    switch (engPath) {
                        case 'solutions':
                            setActiveMenu('Our solutions')
                            break
                        case undefined:
                            setActiveMenu(null)
                            break
                        default:
                            setActiveMenu('About us')
                            break
                    }
                }
            }
        }
    }, [lang])

    const [renderMobile, setRenderMobile] = useState<boolean>(false)
    const { width } = useWindowSize()

    const handleMobileMenu = () => {
        mobilemenuContext.setIsOpen(!mobilemenuContext.isOpen)
    }

    useEffect(() => {
        if (width < BreakPoints.medium) {
            return setRenderMobile(true)
        }
        setRenderMobile(false)
    }, [width])

    const megamenuVariants: Variants = {
        open: {
            opacity: 1,
            transition: {
                duration: 0.2,
                ease: 'easeInOut',
            },
        },
        closed: {
            opacity: 0,
            transition: {
                duration: 0.2,
                ease: 'easeInOut',
            },
        },
    }

    const mobileButtonGroupVariants: Variants = {
        open: {
            opacity: 1,
            x: 0,
            zIndex: 1,
            transition: {
                duration: 0.2,
                staggerChildren: 0.1,
            },
        },
        closed: {
            opacity: 0,
            x: 20,
            zIndex: -1,
            transition: {
                duration: 0.2,
                staggerChildren: 0.1,
            },
        },
    }
    return (
        <div style={{ width: '100%' }}>
            {width > BreakPoints.medium ? (
                <nav className={styles.menu}>
                    <Box
                        extraClassName={styles.NavWrapper({
                            lang: lang === 'en-gb' ? 'en' : 'is',
                        })}
                    >
                        {menus?.map((menu, i: number) => {
                            return (
                                <Box
                                    key={i}
                                    boxStyles={{
                                        display: 'grid',
                                        placeContent: 'center',
                                        height: '100%',
                                    }}
                                >
                                    <MenuButton
                                        primary={menu.title}
                                        active={menu.title === activeMenu}
                                        selected={
                                            megamenuContext.menuOpen ===
                                            menu.title
                                        }
                                        onClick={() => {
                                            megamenuContext.setOpenMegamenu(
                                                megamenuContext.menuOpen ===
                                                    menu.title
                                                    ? ''
                                                    : menu.title
                                            )
                                        }}
                                    />
                                    <AnimatePresence>
                                        {menu.title ===
                                            megamenuContext.menuOpen && (
                                            <motion.div
                                                initial="closed"
                                                animate="open"
                                                variants={megamenuVariants}
                                            >
                                                {menu.title === 'Vörur' ? (
                                                    <VerslunMegaMenu
                                                        menu={menu}
                                                        active={
                                                            menu.title ===
                                                            megamenuContext.menuOpen
                                                        }
                                                    />
                                                ) : (
                                                    <MegaMenu
                                                        menu={menu}
                                                        menuId={
                                                            megamenuContext.menuOpen
                                                        }
                                                        active={
                                                            menu.title ===
                                                            megamenuContext.menuOpen
                                                        }
                                                    />
                                                )}
                                            </motion.div>
                                        )}
                                    </AnimatePresence>
                                </Box>
                            )
                        })}
                    </Box>
                </nav>
            ) : (
                <Box extraClassName={styles.MobileNavWrapper}>
                    <AnimatePresence>
                        <motion.div
                            className={styles.MobileButtonGroupWrapper}
                            initial="closed"
                            animate={
                                mobilemenuContext.isOpen ? 'open' : 'closed'
                            }
                            exit="closed"
                            variants={mobileButtonGroupVariants}
                        >
                            <SearchInput lang={lang} />
                            <Button
                                variant="icon"
                                href={lang === 'is' ? '/english' : '/'}
                                color="secondary"
                                size="small"
                                icon={lang === 'is' ? 'EN' : 'IS'}
                            ></Button>
                        </motion.div>
                    </AnimatePresence>
                    <Hamburger
                        open={mobilemenuContext.isOpen}
                        onClick={() => handleMobileMenu()}
                    />
                    <MobileMenu
                        open={mobilemenuContext.isOpen}
                        menus={menus}
                        buttonLabel={buttonLabel}
                        buttonLink={buttonLink}
                    />
                </Box>
            )}
        </div>
    )
}

interface MenuButtonProps {
    primary: string
    active: boolean
    selected: boolean
    onClick: () => void
}

const MenuButton: React.FC<MenuButtonProps> = ({
    primary,
    active,
    selected,
    onClick,
}) => {
    const [shouldAnimate, setShouldAnimate] = useState(false)
    const router = useRouter()
    useEffect(() => {
        if (router.pathname === '/' || router.pathname === '/english')
            setShouldAnimate(true)
    }, [router])

    return (
        <button
            onClick={onClick}
            className={`${styles.menuButton} ${active && 'active'} ${
                selected && 'selected'
            } ${shouldAnimate ? styles.NavItemStyleAnimation : ''}`}
            aria-haspopup="true"
            aria-expanded={selected}
            data-text={primary}
        >
            {primary}
        </button>
    )
}

export default Navigation
