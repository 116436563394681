import React from 'react'
import { DefaultMenuItem, WithBlogOrNews, WithIconMenuItem } from './variations'
import { SliceComponentProps } from '@prismicio/react'
import { TMenuItemSlices } from './MenuItem.types'
import WithBrandIcon from './variations/WithBrandIcon'

const MenuItem: React.FC<{ slice: TMenuItemSlices }> = ({ slice }) => {
    switch (slice.variation) {
        case 'default': {
            return DefaultMenuItem(slice)
        }
        case 'withIcon': {
            return WithIconMenuItem(slice)
        }
        case 'withBlogOrNews': {
            return WithBlogOrNews(slice)
        }
        case 'withBrandIcon': {
            return WithBrandIcon(slice, 1)
        }
        default:
            return null
    }
}

export default MenuItem
