import {
    parsePrismicHeadingColor,
    parsePrismicTextCardBackgroundColor,
} from '@orbit/prismic'
import { Box, Container, Heading, Icon } from '@orbit/ui/core'
import { BreakPoints, theme } from '@orbit/ui/themes'
import { useState, useEffect, useRef } from 'react'
import { useWindowSize } from 'react-use'
import * as styles from '../TextBlock.css'
import { TTextBlockWithTableOfContentsSliceDefault } from '../TextBlock.types'
import { Waypoint } from 'react-waypoint'
import { PrismicSerializer } from '../../PrismicRichTextSerializer'

export const TextBlockWithTableOfContents = (
    slice: TTextBlockWithTableOfContentsSliceDefault
) => {
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [isViewing, setIsViewing] = useState(isMobile ? 0 : -1)
    const [isActive, setIsActive] = useState(isMobile ? 0 : -1)
    const section = useRef<HTMLDivElement>(null)
    const notsection = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (width < BreakPoints.tabletSmall) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    useEffect(() => {
        scrollToSection()
    }, [isViewing])

    const scrollToSection = () => {
        if (section.current) {
            section.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            })
        }
    }

    return (
        <Box
            anchorid={slice.primary.anchor || undefined}
            boxStyles={{ position: 'relative' }}
            flexDirection="row"
            paddingTop={16}
        >
            <Container>
                <Box flexDirection="row" extraClassName={styles.gridStyle}>
                    <Box extraClassName={styles.gridItemStyle} justify="center">
                        <Box
                            extraClassName={styles.stickyBox}
                            flexDirection="column"
                            py={4}
                        >
                            <Box
                                extraClassName={
                                    styles.TablesofContentsContainer
                                }
                                flexDirection="column"
                            >
                                <Box
                                    boxStyles={{}}
                                    extraClassName={styles.tableOfContentsBox}
                                    flexDirection="column"
                                >
                                    {!isMobile
                                        ? slice.items.map(
                                              (item, index: number) => {
                                                  return (
                                                      <Box
                                                          flexDirection="row"
                                                          align="center"
                                                          position="relative"
                                                          key={
                                                              (item.title ||
                                                                  'textblockwithtableofcontents') +
                                                              index
                                                          }
                                                      >
                                                          <Box
                                                              boxStyles={{
                                                                  background:
                                                                      index ===
                                                                      isActive
                                                                          ? theme
                                                                                .colors
                                                                                .primary[500]
                                                                          : 'transparent',
                                                              }}
                                                              extraClassName={
                                                                  styles.activeDot
                                                              }
                                                          />

                                                          <p
                                                              style={{
                                                                  color:
                                                                      index ===
                                                                      isActive
                                                                          ? theme
                                                                                .colors
                                                                                .neutral[900]
                                                                          : theme
                                                                                .colors
                                                                                .neutral[600],
                                                              }}
                                                              className={
                                                                  styles.tableOfContentsText
                                                              }
                                                              onClick={() =>
                                                                  setIsViewing(
                                                                      index
                                                                  )
                                                              }
                                                          >
                                                              {item.title}
                                                          </p>
                                                      </Box>
                                                  )
                                              }
                                          )
                                        : slice.items && (
                                              <div
                                                  className={
                                                      styles.selectBoxContainer
                                                  }
                                                  onClick={() =>
                                                      isOpen === true
                                                          ? setIsOpen(false)
                                                          : setIsOpen(true)
                                                  }
                                              >
                                                  <Box
                                                      boxStyles={{
                                                          background:
                                                              theme.colors
                                                                  .primary[500],
                                                          top: isOpen
                                                              ? '5%'
                                                              : '46%',
                                                      }}
                                                      extraClassName={
                                                          styles.activeDotMobile
                                                      }
                                                      display={'block'}
                                                  />
                                                  <Box
                                                      extraClassName={
                                                          styles.selectBoxSelectedTtem
                                                      }
                                                  >
                                                      {isViewing !== -1
                                                          ? slice.items[
                                                                isViewing
                                                            ]?.title
                                                          : slice.primary.title}
                                                  </Box>
                                                  <div
                                                      className={
                                                          styles.selectBoxArrow
                                                      }
                                                  >
                                                      <span
                                                          className={`${
                                                              isOpen
                                                                  ? styles.selectBoxArrowUp
                                                                  : styles.selectBoxArrowDown
                                                          }`}
                                                      >
                                                          <svg
                                                              width="16"
                                                              height="12"
                                                              viewBox="0 0 8 6"
                                                              fill="none"
                                                              xmlns="http://www.w3.org/2000/svg"
                                                          >
                                                              <path
                                                                  d="M7.78164 1.1133C7.65153 0.989128 7.47552 0.919434 7.29206 0.919434C7.1086 0.919434 6.93259 0.989128 6.80248 1.1133L4.30942 3.47329L1.85109 1.1133C1.72097 0.989128 1.54497 0.919434 1.3615 0.919434C1.17804 0.919434 1.00203 0.989128 0.871919 1.1133C0.80683 1.17527 0.755167 1.249 0.719911 1.33024C0.684655 1.41148 0.666504 1.49862 0.666504 1.58663C0.666504 1.67464 0.684655 1.76177 0.719911 1.84301C0.755167 1.92425 0.80683 1.99799 0.871919 2.05996L3.81636 4.88663C3.88092 4.94911 3.95773 4.99871 4.04235 5.03256C4.12698 5.0664 4.21774 5.08383 4.30942 5.08383C4.40109 5.08383 4.49186 5.0664 4.57649 5.03256C4.66111 4.99871 4.73792 4.94911 4.80247 4.88663L7.78164 2.05996C7.84673 1.99799 7.89839 1.92425 7.93365 1.84301C7.96891 1.76177 7.98706 1.67464 7.98706 1.58663C7.98706 1.49862 7.96891 1.41148 7.93365 1.33024C7.89839 1.249 7.84673 1.17527 7.78164 1.1133Z"
                                                                  fill="#0F1015"
                                                              />
                                                          </svg>
                                                      </span>
                                                  </div>

                                                  <div
                                                      style={{
                                                          display: isOpen
                                                              ? 'block'
                                                              : 'none',
                                                      }}
                                                      className={
                                                          styles.selectBoxItems
                                                      }
                                                  >
                                                      <div
                                                          key={
                                                              slice.primary
                                                                  .title
                                                          }
                                                          onClick={() =>
                                                              setIsViewing(-1)
                                                          }
                                                          className={
                                                              isViewing === -1
                                                                  ? styles.selectedOption
                                                                  : styles.notSelectedOption
                                                          }
                                                          style={{
                                                              position:
                                                                  'relative',
                                                          }}
                                                      >
                                                          {slice.primary.title}
                                                      </div>
                                                      {slice.items.map(
                                                          (
                                                              item,
                                                              index: number
                                                          ) => (
                                                              <div
                                                                  key={
                                                                      item.title
                                                                  }
                                                                  onClick={() =>
                                                                      setIsViewing(
                                                                          index
                                                                      )
                                                                  }
                                                                  className={
                                                                      isViewing ===
                                                                      index
                                                                          ? styles.selectedOption
                                                                          : styles.notSelectedOption
                                                                  }
                                                                  style={{}}
                                                              >
                                                                  {item.title}
                                                              </div>
                                                          )
                                                      )}
                                                  </div>
                                              </div>
                                          )}
                                </Box>
                            </Box>

                            {slice.primary.buttonlink.url && (
                                <Box
                                    width="full"
                                    alignSelf="center"
                                    marginTop={16}
                                    display={isMobile ? 'none' : 'block'}
                                    flexDirection="column"
                                >
                                    <a
                                        className={styles.buttonStyle}
                                        href={
                                            slice.primary.buttonlink.url || ''
                                        }
                                        style={{
                                            background:
                                                parsePrismicTextCardBackgroundColor(
                                                    slice.primary.slicecolor
                                                ),
                                        }}
                                        target={
                                            slice.primary.openNewTab
                                                ? '_blank'
                                                : ''
                                        }
                                        rel="noopener noreferrer"
                                    >
                                        <Box paddingRight={8}>
                                            {slice.primary.buttonlabel}
                                        </Box>
                                        {slice.primary.buttonicon && (
                                            <Icon
                                                icon={slice.primary.buttonicon}
                                                color="black"
                                                size="small"
                                            />
                                        )}
                                    </a>
                                </Box>
                            )}
                        </Box>
                    </Box>

                    <Box
                        margin={'auto'}
                        flexDirection="column"
                        extraClassName={styles.headingContainerBox}
                    >
                        <Box
                            flexDirection={'column'}
                            width={'full'}
                            align={isMobile ? 'center' : 'flexStart'}
                            ref={isViewing === -1 ? section : notsection}
                        >
                            {slice.primary.tagline && (
                                <Heading
                                    my={0}
                                    paddingBottom={3}
                                    type={'h6'}
                                    text={slice.primary.tagline}
                                    color={parsePrismicHeadingColor(
                                        slice.primary.slicecolor
                                    )}
                                />
                            )}
                            {slice.primary.title && (
                                <Heading
                                    my={0}
                                    paddingBottom={3}
                                    type={'h1'}
                                    text={slice.primary.title}
                                />
                            )}
                        </Box>
                        <Box
                            flexDirection={'column'}
                            paddingBottom={24}
                            display="block"
                        >
                            <PrismicSerializer
                                text={slice.primary.content}
                                slicecolor={slice.primary.slicecolor}
                            />
                        </Box>
                        <Box flexDirection="column">
                            {slice.items.map((item, index: number) => {
                                return (
                                    <Box
                                        ref={
                                            isViewing === index
                                                ? section
                                                : notsection
                                        }
                                        extraClassName={styles.scrollTo}
                                    >
                                        <Waypoint
                                            onEnter={() => setIsActive(index)}
                                            topOffset={'40%'}
                                            bottomOffset={'40%'}
                                        >
                                            <Box
                                                flexDirection="column"
                                                key={
                                                    'textblockcontents' + index
                                                }
                                                display="inlineBlock"
                                            >
                                                <Box flexDirection="column">
                                                    <Heading
                                                        text={item.title || ''}
                                                        type={'h3'}
                                                    />
                                                </Box>
                                                <Box
                                                    boxStyles={{
                                                        display: 'block',
                                                    }}
                                                >
                                                    <PrismicSerializer
                                                        slicecolor={
                                                            slice.primary
                                                                .slicecolor
                                                        }
                                                        text={item.content}
                                                    />
                                                </Box>
                                            </Box>
                                        </Waypoint>
                                    </Box>
                                )
                            })}
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

export default TextBlockWithTableOfContents
