import { ChevronDown } from '@orbit/assets'
import { parsePrismicLink } from '@orbit/prismic'
import { Box, Button, Heading } from '@orbit/ui/core'
import { SliceZone } from '@prismicio/react'
import { KeyTextField, LinkField } from '@prismicio/types'
import { useContext } from 'react'
import AnimateHeight from 'react-animate-height'
import { TMenu } from '../Header'
import MenuItem from '../MenuItem'

import { VerslunMegaMenu } from '../OrbitMegaMenu'
import * as styles from './MobileMenu.css'
import { MobilemenuContext } from './MobileMenuContext'
interface MobileMenuProps {
    menus: TMenu[]
    open: boolean
    buttonLabel: KeyTextField
    buttonLink: LinkField
}

export const MobileMenu: React.FC<MobileMenuProps> = ({
    menus,
    open,
    buttonLabel,
    buttonLink,
}) => {
    const mobilemenuContext = useContext(MobilemenuContext)

    return (
        <div className={`${styles.mobileWrapper} ${open && 'active'}`}>
            <Box flexDirection="column" wrap="nowrap">
                {menus.map((menu, i: number) => {
                    return (
                        <Box
                            flexDirection="column"
                            wrap="nowrap"
                            width="full"
                            key={i}
                            extraClassName={styles.menuItem}
                        >
                            <Box
                                flexDirection="row"
                                justify="spaceBetween"
                                align="center"
                                width="full"
                                boxStyles={{ cursor: 'pointer' }}
                                onClick={() =>
                                    mobilemenuContext.setSelectedMobilemenu(
                                        mobilemenuContext.selectedMenu ===
                                            menu.title
                                            ? ''
                                            : menu.title
                                    )
                                }
                            >
                                <Heading
                                    style={{
                                        width: '100%',
                                        fontSize: '1.25rem',
                                        fontWeight: 400,
                                        margin: '0',
                                        paddingTop: '1.5rem',
                                        paddingBottom: '1.5rem',
                                    }}
                                    text={menu.title}
                                    type={'h3'}
                                />
                                <Box
                                    boxStyles={
                                        menu.title ===
                                        mobilemenuContext.selectedMenu
                                            ? {
                                                  transform: 'rotate(180deg)',
                                                  transition:
                                                      'transform: 0.3s linear',
                                              }
                                            : {
                                                  transform: 'rotate(0deg)',
                                                  transition:
                                                      'transform 0.3s linear',
                                              }
                                    }
                                >
                                    <ChevronDown />
                                </Box>
                            </Box>
                            <Box flexDirection="column" width={'full'}>
                                <AnimateHeight
                                    duration={500}
                                    height={
                                        menu.title ===
                                        mobilemenuContext.selectedMenu
                                            ? 'auto'
                                            : 0
                                    }
                                    style={{ width: '100%' }}
                                >
                                    {menu.title === 'Vörur' ? (
                                        <VerslunMegaMenu
                                            mobile={true}
                                            active={
                                                menu.title ===
                                                mobilemenuContext.selectedMenu
                                            }
                                            menu={menu}
                                        />
                                    ) : (
                                        <SliceZone
                                            components={{ menu_item: MenuItem }}
                                            slices={menu.slices}
                                        />
                                    )}
                                </AnimateHeight>
                            </Box>
                        </Box>
                    )
                })}
            </Box>
            <Box py={5}>
                {buttonLink && buttonLabel && (
                    <Button
                        href={parsePrismicLink(buttonLink)}
                        variant="filled"
                        size="large"
                        color="tertiary"
                        fullWidth={true}
                    >
                        {buttonLabel}
                    </Button>
                )}
            </Box>
        </div>
    )
}

export default MobileMenu
