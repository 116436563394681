import { Box, Container, Icon, MotionBox } from '@orbit/ui/core'
import { BreakPoints } from '@orbit/ui/themes'
import { cardRevealUp } from '@orbit/utils/framer-motion'
import { useEffect, useRef, useState } from 'react'
import { useWindowSize } from 'react-use'
import { TShortcutsSlice } from '../Shortcuts.types'
import * as styles from '../Shortcuts.css'
import {
    parsePrismicDarkColor,
    parsePrismicLightBackgroundColor,
} from '@orbit/prismic'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore from 'swiper'

export const DefaultShortcuts = (slice: TShortcutsSlice) => {
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)
    const swiperRef = useRef<SwiperCore>()
    const navigationPrevRef = useRef<HTMLDivElement>(null)
    const navigationNextRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (width < BreakPoints.tabletSmall) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    return (
        <Box paddingBottom={40} marginTop={36}>
            <Container>
                <Box extraClassName={styles.ButtonContainer}>
                    <Swiper
                        slidesPerView={'auto'}
                        spaceBetween={0}
                        navigation={{
                            prevEl: navigationPrevRef.current,
                            nextEl: navigationNextRef.current,
                        }}
                        onBeforeInit={(swiper) => {
                            swiperRef.current = swiper
                        }}
                        grabCursor={true}
                        className={styles.swiperStyle}
                    >
                        {slice.items.map((item, i) => {
                            return (
                                <SwiperSlide
                                    // Þarf að vera inline
                                    style={{
                                        width: 'fit-content',
                                        padding: '1rem',
                                        height: 'auto',
                                    }}
                                >
                                    <MotionBox
                                        initial={
                                            isMobile ? 'visible' : 'hidden'
                                        }
                                        viewport={{
                                            once: true,
                                        }}
                                        whileInView="visible"
                                        transition={{
                                            delay: isMobile
                                                ? 0
                                                : 0.15 * (i + 1),
                                            duration: 0.6,
                                        }}
                                        variants={cardRevealUp}
                                    >
                                        <a
                                            className={styles.ButtonStyling}
                                            style={{
                                                backgroundColor:
                                                    parsePrismicLightBackgroundColor(
                                                        slice.primary
                                                            .slicecolor ||
                                                            'Blue'
                                                    ),
                                            }}
                                            href={item.link.url}
                                            target={
                                                item.openNewTab ? '_blank' : ''
                                            }
                                            rel="noreferrer"
                                        >
                                            <Icon
                                                icon={item.icon}
                                                size="small"
                                                color={parsePrismicDarkColor(
                                                    slice.primary.slicecolor ||
                                                        'Blue'
                                                )}
                                            />
                                            {item.label}
                                        </a>
                                    </MotionBox>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </Box>
            </Container>
        </Box>
    )
}

export default DefaultShortcuts
