import { Box, Heading, MotionBox, Text } from '@orbit/ui/core'
import { cardRevealUp } from '@orbit/utils/framer-motion'
import { AlgoliaSearchResultProps } from '../../../types'
import {
    card,
    cardImageContainer,
    cardLabel,
    fullWidthMobile,
    searchContent,
} from '../SearchOverview.css'

interface AlgoliaHitsProps {
    hits: AlgoliaSearchResultProps[]
}

export const AlgoliaHits = ({ hits }: AlgoliaHitsProps) => {
    const skipImgForEntityType = ['Lausnir', 'Ráðgjöf og þjónusta']

    return (
        <Box
            flexDirection="column"
            width="full"
            extraClassName={fullWidthMobile}
        >
            {hits.map(
                (hit, index) =>
                    hit && (
                        <a
                            href={hit.url}
                            style={{
                                width: '100%',
                                textDecoration: 'none',
                            }}
                            key={`${hit.uid}-${index}`}
                        >
                            <MotionBox
                                width="full"
                                marginBottom={24}
                                padding={10}
                                align="center"
                                initial="hidden"
                                whileInView="visible"
                                extraClassName={card}
                                viewport={{
                                    once: true,
                                }}
                                transition={{
                                    delay: 0.1 * (index + 1),
                                    duration: 0.6,
                                }}
                                variants={cardRevealUp}
                            >
                                {!skipImgForEntityType.includes(
                                    hit.entityType
                                ) &&
                                    hit.image &&
                                    hit.image.url && (
                                        <Box
                                            extraClassName={cardImageContainer}
                                            paddingRight={36}
                                        >
                                            <img
                                                src={hit.image.url || ''}
                                                alt={hit.image.alt || ''}
                                                style={{
                                                    width: '13rem',
                                                    height: '13rem',
                                                    borderRadius:
                                                        hit.entityType ===
                                                        'Vörur'
                                                            ? 0
                                                            : '50%',
                                                    objectFit:
                                                        hit.entityType ===
                                                        'Vörur'
                                                            ? 'contain'
                                                            : 'cover',
                                                    display: 'block',
                                                }}
                                            />
                                        </Box>
                                    )}
                                <Box flexDirection="column" justify="flexStart">
                                    <div className={cardLabel}>
                                        {hit.entityType}
                                        {hit.sectionTitle &&
                                            ` / ${hit.sectionTitle}`}
                                    </div>
                                    <Heading
                                        type="h4"
                                        text={hit.title}
                                        my={0}
                                    />
                                    {hit.summary &&
                                        (hit.entityType === 'Vörur' ? (
                                            <div
                                                className={searchContent}
                                                style={{
                                                    margin: 0,
                                                    padding: 0,
                                                    textDecoration: 'none',
                                                    color: 'black',
                                                }}
                                                dangerouslySetInnerHTML={{
                                                    __html: hit.summary,
                                                }}
                                            />
                                        ) : (
                                            <Text
                                                style={{
                                                    marginBottom: 0,
                                                    marginTop: '1.5rem',
                                                }}
                                            >
                                                {hit.summary.length > 200
                                                    ? `${hit.summary.substring(
                                                          0,
                                                          200
                                                      )}...`
                                                    : hit.summary}
                                            </Text>
                                        ))}
                                </Box>
                            </MotionBox>
                        </a>
                    )
            )}
        </Box>
    )
}
