import { theme } from '@orbit/ui/themes'
import React from 'react'
import { IconType, iconMap } from './IconMap'

export interface IconProps {
    size?: 'small' | 'medium' | 'large' | 'xlarge'
    color?: string
    icon: IconType
}

export const Icon: React.FC<IconProps> = ({ size, color, icon }) => {
    const s =
        size === 'small'
            ? 20
            : size === 'medium'
            ? 24
            : size === 'xlarge'
            ? 40
            : 36
    if (iconMap(icon) === undefined) return null

    return React.createElement(
        'svg',
        {
            xmlns: 'http://www.w3.org/2000/svg',
            width: s,
            height: s,
            viewBox: `0 0 24 24`,
            fill: color,
            style: {
                transition: `fill ${theme.animations.transitionShort} ${theme.animations.bezier}`,
                overflow: 'visible',
            },
        },
        React.createElement('path', {
            d: iconMap(icon),
        })
    )
}
