import React from 'react'
import { TButtonSlices } from './Button.types'
import { GhostButton, DefaultButton, FilledButton } from './variations'

const Button: React.FC<{ slice: TButtonSlices }> = ({ slice }) => {
    switch (slice.variation) {
        case 'default': {
            return DefaultButton(slice)
        }
        case 'ghostButton': {
            return GhostButton(slice)
        }
        case 'filledButton': {
            return FilledButton(slice)
        }
        default:
            return null
    }
}

export default Button
