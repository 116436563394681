// Primary colors
export const primaryBlue900 = '#114389'
export const primaryBlue800 = '#1B40C5' // ekki hluta af hönnun, en þarf að vera í þemanu
export const primaryBlue700 = '#0B56B3'
export const primaryBlue600 = '#016FF9'
export const primaryBlue500 = '#0295F5'
export const primaryBlue400 = '#80CAFA'
export const primaryBlue300 = '#E2F3FC'
export const primaryBlue200 = '#F5FBFE'

export const primaryOrange900 = '#8E3A09'
export const primaryOrange800 = '#AD460B' // ekki hluta af hönnun, en þarf að vera í þemanu
export const primaryOrange700 = '#D3560D' // ekki hluta af hönnun, en þarf að vera í þemanu
export const primaryOrange600 = '#DD6C09'
export const primaryOrange500 = '#FF8300'
export const primaryOrange400 = '#FFB582'
export const primaryOrange300 = '#FFE8CF'
export const primaryOrange200 = '#FFF9F3'

export const primaryTeal900 = '#005D6A'
export const primaryTeal800 = '#005D6A' // ekki hluta af hönnun, en þarf að vera í þemanu
export const primaryTeal700 = '#006477'
export const primaryTeal600 = '#009B9E'
export const primaryTeal500 = '#06B7A7'
export const primaryTeal400 = '#82DBD3'
export const primaryTeal300 = '#DCF3F1'
export const primaryTeal200 = '#F6FCFC'

//Neutral colors
export const neutral900 = '#0F1015'
export const neutral800 = '#191B24' // ekki hluta af hönnun, en þarf að vera í þemanu
export const neutral700 = '#303A47'
export const neutral600 = '#6C7683'
export const neutral500 = '#A4AFBE'
export const neutral400 = '#D8DCE1'
export const neutral300 = '#F1F5F7'
export const neutral200 = '#F8FAFB'
export const neutral100 = '#FFFFFF'

//Secondary / Accents
export const secondaryCoral900 = '#99113B'
export const secondaryCoral700 = '#B00152'
export const secondaryCoral600 = '#E3254E'
export const secondaryCoral500 = '#FF3760'
export const secondaryCoral400 = '#FF9BAF'
export const secondaryCoral300 = '#FCE4E7'
export const secondaryCoral200 = '#FEF6F7' // ekki hluta af hönnun, en þarf að vera í þemanu

export const secondaryYellow700 = '#E9A304'
export const secondaryYellow600 = '#FDCC1E'
export const secondaryYellow500 = '#FEE458'
export const secondaryYellow400 = '#FEF1AB'
export const secondaryYellow300 = '#FFF8D5'
export const secondaryYellow200 = '#FFFDF2'

export const secondaryGreen700 = '#028762'
export const secondaryGreen600 = '#22BE99'
export const secondaryGreen500 = '#41DDAD'
export const secondaryGreen400 = '#85FFBA'
export const secondaryGreen300 = '#D4FFE7'
export const secondaryGreen200 = '#F1FFF7'

export const secondaryBerry700 = '#4A217D'
export const secondaryBerry600 = '#683F9B'
export const secondaryBerry500 = '#885CB5'
export const secondaryBerry400 = '#C4ADDA'
export const secondaryBerry300 = '#EDE7F4'
export const secondaryBerry200 = '#F9F7FC'
