import { theme } from '@orbit/ui/themes'
import Link from 'next/link'
import { Box } from '../../Box'
import { Heading } from '../../Heading'
import { Icon } from '../../Icons'
import * as styles from './TextCard.css'

export type TextCardProps = {
    icon: any
    iconColor?: string
    title?: string
    content: React.ReactNode | string
    linkType: string
    linkText: string
    link: string | undefined
    color: string
    bullets?: string[]
    displayButton: boolean
    buttonTextColor: string
    buttonColor?: string
    backgroundFilled: boolean
    textCardColor: string
    isWhite: boolean
    headingLevel?: 'h2' | 'h3' | 'h4'
    newTab?: boolean
}

export const OrbitTextCard: React.FC<TextCardProps> = (props) => {
    return props.link ? <LinkCard {...props} /> : <Card {...props} />
}

const Card: React.FC<TextCardProps> = (props) => {
    return (
        <Box
            extraClassName={styles.TextCardWrapper}
            boxStyles={{
                backgroundColor:
                    !props.isWhite && props.color !== 'white'
                        ? props.textCardColor
                        : 'white',
            }}
        >
            <Box
                flexDirection="column"
                padding={6}
                boxStyles={{
                    height: '100%',
                }}
            >
                {props.icon && (
                    <Box marginBottom={16}>
                        <Icon
                            icon={props.icon}
                            color={
                                props.backgroundFilled
                                    ? props.iconColor
                                    : theme.colors.neutral[900]
                            }
                            size={'large'}
                        />
                    </Box>
                )}

                <Box width={'full'}>
                    {props.title && (
                        <Heading
                            type={
                                props.headingLevel ? props.headingLevel : 'h3'
                            }
                            text={props.title}
                            my={0}
                            style={{
                                fontSize: '1.5rem',
                                paddingBottom: props.content ? 0 : '2rem',
                                lineHeight: '2rem',
                            }}
                        />
                    )}
                </Box>

                <Box flexDirection="column">{props.content} </Box>
                {props.link && props.linkText && props.displayButton && (
                    <Box extraClassName={styles.LinkBox}>
                        {props.linkType === 'Default' ? (
                            <p
                                className={
                                    !props.backgroundFilled
                                        ? styles.GhostLink
                                        : styles.ButtonLink
                                }
                                style={{
                                    background: !props.backgroundFilled
                                        ? 'transparent'
                                        : props.buttonTextColor,
                                    color: !props.backgroundFilled
                                        ? theme.colors.neutral[900]
                                        : props.buttonColor,
                                }}
                            >
                                {props.linkText}
                            </p>
                        ) : props.linkType === 'Tag' ? (
                            <p
                                className={styles.TaglineLink}
                                style={{
                                    color: !props.backgroundFilled
                                        ? theme.colors.neutral[900]
                                        : props.buttonColor,
                                }}
                            >
                                {props.linkText}
                            </p>
                        ) : (
                            <Box boxStyles={{ alignItems: 'center' }}>
                                <p
                                    className={styles.ArrowStyleLink}
                                    style={{
                                        color: !props.backgroundFilled
                                            ? theme.colors.neutral[900]
                                            : props.buttonColor,
                                    }}
                                >
                                    {props.linkText}
                                </p>

                                <Icon
                                    icon={'ArrowRight'}
                                    size={'medium'}
                                    color={
                                        !props.backgroundFilled
                                            ? theme.colors.neutral[900]
                                            : props.buttonColor
                                    }
                                />
                            </Box>
                        )}
                    </Box>
                )}
            </Box>
        </Box>
    )
}

const LinkCard: React.FC<TextCardProps> = (props) => {
    return (
        <Link href={props.link || ''}>
            <a
                aria-label={props.title + '-' + props.linkText}
                className={styles.LinkTextCardWrapper}
                style={{
                    backgroundColor:
                        !props.isWhite && props.color !== 'white'
                            ? props.textCardColor
                            : 'white',
                }}
                target={props.newTab ? '_blank' : ''}
                rel="noopener noreferrer"
            >
                <Box
                    flexDirection="column"
                    padding={6}
                    boxStyles={{
                        height: '100%',
                    }}
                >
                    {props.icon && (
                        <Box marginBottom={16}>
                            <Icon
                                icon={props.icon}
                                color={
                                    props.backgroundFilled
                                        ? props.iconColor
                                        : theme.colors.neutral[900]
                                }
                                size={'large'}
                            />
                        </Box>
                    )}

                    <Box width={'full'}>
                        {props.title && (
                            <Heading
                                type={
                                    props.headingLevel
                                        ? props.headingLevel
                                        : 'h3'
                                }
                                text={props.title}
                                my={0}
                                style={{
                                    fontSize: '1.5rem',
                                    paddingBottom: props.content ? 0 : '2rem',
                                    lineHeight: '2rem',
                                }}
                            />
                        )}
                    </Box>

                    <Box flexDirection="column">{props.content} </Box>
                    {props.link && props.linkText && props.displayButton && (
                        <Box extraClassName={styles.LinkBox}>
                            {props.linkType === 'Default' ? (
                                <p
                                    className={
                                        !props.backgroundFilled
                                            ? styles.GhostLink
                                            : styles.ButtonLink
                                    }
                                    style={{
                                        background: !props.backgroundFilled
                                            ? 'transparent'
                                            : props.buttonTextColor,
                                        color: !props.backgroundFilled
                                            ? theme.colors.neutral[900]
                                            : props.buttonColor,
                                    }}
                                >
                                    {props.linkText}
                                </p>
                            ) : props.linkType === 'Tag' ? (
                                <p
                                    className={styles.TaglineLink}
                                    style={{
                                        color: !props.backgroundFilled
                                            ? theme.colors.neutral[900]
                                            : props.buttonColor,
                                    }}
                                >
                                    {props.linkText}
                                </p>
                            ) : (
                                <Box boxStyles={{ alignItems: 'center' }}>
                                    <p
                                        className={styles.ArrowStyleLink}
                                        style={{
                                            color: !props.backgroundFilled
                                                ? theme.colors.neutral[900]
                                                : props.buttonColor,
                                        }}
                                    >
                                        {props.linkText}
                                    </p>

                                    <Icon
                                        icon={'ArrowRight'}
                                        size={'medium'}
                                        color={
                                            !props.backgroundFilled
                                                ? theme.colors.neutral[900]
                                                : props.buttonColor
                                        }
                                    />
                                </Box>
                            )}
                        </Box>
                    )}
                </Box>
            </a>
        </Link>
    )
}
