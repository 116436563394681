import { TextProps } from '../../types'
import './Text.css'
import * as styles from './Text.css'

export const Text: React.FC<TextProps> = ({
    children,
    size = 'medium',
    textAlign,
    my,
    mx,
    padding,
    paddingTop,
    paddingBottom,
    py,
    px,
    style,
    textClass,
}) => {
    return (
        <p
            className={`${styles.text({
                size: size,
                my: my,
                mx: mx,
                py: py,
                px: px,
                paddingTop: paddingTop,
                paddingBottom: paddingBottom,
                padding: padding,
                textAlign: textAlign,
            })} ${textClass ? textClass : ''}`}
            style={style}
        >
            {children}
        </p>
    )
}

export default Text
