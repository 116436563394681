import {
    parsePrismicBackgroundColor,
    parsePrismicDarkColor,
    parsePrismicHeadingColor,
} from '@orbit/prismic'
import { Box, Container, HeadingContainer, SpeakersCard } from '@orbit/ui/core'
import { BreakPoints, theme } from '@orbit/ui/themes'
import { useState, useEffect } from 'react'
import { useWindowSize } from 'react-use'
import {
    TSpeakersSlice,
    TSpeakersItemsWhiteBackground,
} from '../Speakers.types'
import * as styles from '../Speakers.css'

export const WhiteBackground = (slice: TSpeakersSlice) => {
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)
    const [size, setSize] = useState('')
    useEffect(() => {
        if (width < BreakPoints.tabletSmall) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    useEffect(() => {
        if (slice.items.length === 1) {
            return setSize('large')
        }
        if (slice.items.length === 2) {
            return setSize('medium')
        }
        if (slice.items.length >= 3) {
            return setSize('small')
        }
    }, [slice.items.length])

    const cardSize = (size: string) => {
        switch (size) {
            case 'large':
                return 'large'
            case 'medium':
                return 'medium'
            case 'small':
                return 'small'
            default:
                return 'small'
        }
    }

    const speakersCard = (
        item: TSpeakersItemsWhiteBackground,
        index: number
    ) => {
        return (
            <Box
                key={'speakers_card_' + index}
                extraClassName={styles.cardWrapper({
                    size: cardSize(size),
                })}
            >
                <SpeakersCard
                    subtitleColor={parsePrismicDarkColor(
                        slice.primary.slicecolor
                    )}
                    variant={slice.variation}
                    image={item.image}
                    name={item.name}
                    jobtitle={item.jobtitle}
                    text={item.text}
                    linkedinLink={item.linkedinlink && item.linkedinlink.url}
                    linkedinLabel={item.linkedinlabel && item.linkedinlabel}
                    color={theme.colors.neutral[100]}
                    size={cardSize(size)}
                />
            </Box>
        )
    }

    return (
        <Box
            anchorid={slice.primary.anchor || undefined}
            paddingBottom={isMobile ? 40 : 80}
            paddingTop={isMobile ? 40 : 80}
            flexDirection="column"
            boxStyles={{
                backgroundColor: parsePrismicBackgroundColor(
                    slice.primary.slicecolor
                ),
            }}
        >
            <Container>
                <Box
                    align="center"
                    flexDirection="column"
                    boxStyles={{
                        marginBottom: isMobile ? '-2.5rem' : '-2rem',
                    }}
                >
                    <HeadingContainer
                        variant="center"
                        tagline={slice.primary.tagline || undefined}
                        title={slice.primary.title || undefined}
                        color={parsePrismicHeadingColor(
                            slice.primary.slicecolor
                        )}
                    />
                </Box>

                <Box extraClassName={styles.cardGridWrapper} gap="2.5rem">
                    {slice.items.map(
                        (
                            item: TSpeakersItemsWhiteBackground,
                            index: number
                        ) => {
                            return speakersCard(item, index)
                        }
                    )}
                </Box>
            </Container>
        </Box>
    )
}

export default WhiteBackground
