import { useEffect, useState } from 'react'
import {
    parsePrismicBackgroundColor,
    parsePrismicDarkColor,
    parsePrismicHeadingColor,
    parsePrismicLightBackgroundColor,
} from '@orbit/prismic'
import { TFaqItems, TFaqSlice } from '../Faq.types'
import {
    Box,
    HeadingContainer,
    Accordion,
    Container,
    MotionBox,
} from '@orbit/ui/core'
import { PrismicRichText } from '@prismicio/react'
import { Text } from '@orbit/ui/core'
import * as styles from '../Faq.css'
import { BreakPoints, theme } from '@orbit/ui/themes'
import { useWindowSize } from 'react-use'
import { RichTextField } from '@prismicio/types'
import { cardRevealUp } from '@orbit/utils/framer-motion'

export const DefaultFaq = (slice: TFaqSlice) => {
    const [questions, setQuestions] = useState<string[]>([])
    const [answers, setAnswers] = useState<unknown>([])
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)
    useEffect(() => {
        if (width < BreakPoints.medium) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    useEffect(() => {
        slice.items.map((item: TFaqItems, i) => {
            setQuestions((question) => [
                ...question,
                item.question || 'Missing question',
            ])
            setAnswers((answers: RichTextField) => [
                ...answers,
                (
                    <PrismicRichText
                        key={i}
                        field={item.answer}
                        components={{
                            hyperlink: ({ text, node }) => (
                                <a
                                    style={{
                                        color: parsePrismicDarkColor(
                                            slice.primary.slicecolor
                                        ),
                                        fontWeight: 500,
                                        textDecoration: 'none',
                                    }}
                                    href={node.data.url}
                                    target={
                                        node.data.link_type === 'Web'
                                            ? node.data.target
                                            : ''
                                    }
                                    rel="noopener noreferrer"
                                >
                                    {text}
                                </a>
                            ),
                            list: ({ children }) => (
                                <ul className={styles.bullet}>{children}</ul>
                            ),
                            listItem: ({ children }) => (
                                <li
                                    className={`${styles.listItem({
                                        colors: parsePrismicHeadingColor(
                                            slice.primary.slicecolor
                                        ),
                                    })}`}
                                >
                                    <Text> {children} </Text>
                                </li>
                            ),
                        }}
                    />
                ) || 'Missing answer',
            ])
            return null
        })
    }, [slice.items, slice.primary.slicecolor])

    return (
        <Box
            anchorid={slice.primary.anchor || undefined}
            justify={'center'}
            align={'center'}
            paddingTop={80}
            paddingBottom={80}
            boxStyles={{
                backgroundColor: slice.primary.backgroundfilled
                    ? theme.colors.neutral[100]
                    : parsePrismicBackgroundColor(slice.primary.slicecolor),
            }}
            flexDirection="column"
        >
            <Container>
                <HeadingContainer
                    tagline={
                        slice.primary.tagline
                            ? slice.primary.tagline
                            : undefined
                    }
                    title={slice.primary.title ? slice.primary.title : ''}
                    color={parsePrismicHeadingColor(slice.primary.slicecolor)}
                />
                <MotionBox
                    width={'full'}
                    boxStyles={{ height: '100%' }}
                    initial={isMobile ? 'visible' : 'hidden'}
                    viewport={{
                        once: true,
                    }}
                    whileInView="visible"
                    transition={{
                        delay: 0.3,
                        duration: 0.6,
                    }}
                    variants={cardRevealUp}
                >
                    <Accordion
                        headingsize={
                            slice.primary.tagline && slice.primary.title
                                ? 'h4'
                                : !slice.primary.tagline && !slice.primary.title
                                ? 'h2'
                                : 'h3'
                        }
                        questions={questions}
                        answers={answers as string[]}
                        size={
                            slice.primary.size === '2 columns' && !isMobile
                                ? 'half'
                                : 'full'
                        }
                        wrap={
                            slice.primary.size === '2 columns' && !isMobile
                                ? true
                                : false
                        }
                        colors={{
                            background: slice.primary.backgroundfilled
                                ? parsePrismicLightBackgroundColor(
                                      slice.primary.slicecolor
                                  )
                                : 'white',
                            iconBackground: parsePrismicBackgroundColor(
                                slice.primary.slicecolor
                            ),
                            icon: parsePrismicDarkColor(
                                slice.primary.slicecolor
                            ),
                        }}
                    />
                </MotionBox>
            </Container>
        </Box>
    )
}

export default DefaultFaq
