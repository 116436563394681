import React from 'react'
import { TFoxwayCalculatorSlice } from './FoxwayCalculator.types'
import { DefaultFoxwayCalculator } from './variations'

const FoxwayCalculator: React.FC<{ slice: TFoxwayCalculatorSlice }> = ({
    slice,
}) => {
    switch (slice.variation) {
        case 'default': {
            return DefaultFoxwayCalculator(slice)
        }
        default: {
            return null
        }
    }
}

export default FoxwayCalculator
