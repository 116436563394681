/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Heading, Button, Text, Box } from '@orbit/ui/core'
import { theme } from '@orbit/ui/themes'
import { FacebookIcon } from 'libs/shared/orbit-ui/core/src/assets/FacebookIcon'
import { LinkedInIcon } from 'libs/shared/orbit-ui/core/src/assets/LinkedInIcon'
import { TwitterIcon } from 'libs/shared/orbit-ui/core/src/assets/TwitterIcon'
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
} from 'react-share'
import * as styles from './Event.css'

export const NoEventForm = (headingText: string, description: string) => {
    return (
        <Box
            extraClassName={styles.noEventContainer}
            boxStyles={{
                backgroundColor: theme.colors.primary[300],
            }}
            flexDirection="column"
            width={'full'}
            paddingTop={36}
            paddingBottom={36}
            align={'center'}
            justify={'center'}
            padding={8}
        >
            <Heading
                style={{ fontSize: '0.75rem' }}
                textAlign="center"
                color={'primary'}
                type={'h6'}
                text={'Við látum þig vita'}
                my={0}
                paddingBottom={2}
            />

            <Heading
                textAlign="center"
                type={'h4'}
                text={headingText}
                my={0}
                paddingBottom={2}
            ></Heading>
            <Text
                style={{ width: '80%' }}
                textAlign="center"
                size={'small'}
                paddingBottom={5}
            >
                {description}
            </Text>
            <Button
                color={'primary'}
                href={'https://origo.is/postlisti'}
                variant="filled"
            >
                Skrá mig á póstlistann
            </Button>
            <Box
                paddingTop={36}
                width={'full'}
                align={'center'}
                justify={'center'}
            >
                <img
                    src={
                        'https://new-origo.cdn.prismic.io/new-origo/80338a33-7b90-44d8-aa52-e3a0ddfbb065_image+238.svg'
                    }
                    alt={'postlisti'}
                />
            </Box>
        </Box>
    )
}
export const EventSocialShare = (baseUrl: string) => {
    return (
        <Box
            width={'full'}
            boxStyles={{ backgroundColor: theme.colors.primary[200] }}
            flexDirection="column"
            align="center"
            justify="center"
        >
            <Heading
                text={'segðu frá'}
                type={'h6'}
                my={0}
                paddingTop={5}
                paddingBottom={4}
                style={{ fontSize: '0.75rem' }}
            />
            <Box
                extraClassName={styles.socialShareContainer}
                justify={'spaceAround'}
                paddingBottom={36}
            >
                <Box extraClassName={styles.iconStyle} alignItems="center">
                    <FacebookShareButton
                        url={baseUrl}
                        className={styles.socialShareButton}
                    >
                        <FacebookIcon width="24" height="24" />
                    </FacebookShareButton>
                </Box>
                <Box extraClassName={styles.iconStyle} alignItems="center">
                    <LinkedinShareButton
                        url={baseUrl}
                        className={styles.socialShareButton}
                    >
                        <LinkedInIcon width="24" height="24" />
                    </LinkedinShareButton>
                </Box>
                <Box extraClassName={styles.iconStyle} alignItems="center">
                    <TwitterShareButton
                        url={baseUrl}
                        className={styles.socialShareButton}
                    >
                        <TwitterIcon width="27" height="22" />
                    </TwitterShareButton>
                </Box>
            </Box>
        </Box>
    )
}
