import { Box, Container, Heading, Text } from '@orbit/ui/core'
import { BreakPoints, theme } from '@orbit/ui/themes'
import { useState, useEffect } from 'react'
import { useWindowSize } from 'react-use'
import moment from 'moment'
import 'moment/locale/is'
import { PrismicRichText } from '@prismicio/react'
import * as styles from './Announcement.css'
import { TAnnouncementDataProps } from '../../types'

export const Announcement: React.FC<{
    announcement: TAnnouncementDataProps
}> = ({ announcement }) => {
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)
    useEffect(() => {
        if (width < BreakPoints.mobile) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])
    moment.locale('is')
    return (
        <Box flexDirection="column" alignSelf="center">
            <Box
                flexDirection="column"
                align="center"
                boxStyles={{
                    backgroundColor: 'white',
                    width: '100%',
                    paddingLeft: '1rem',
                    paddingRight: '1rem',
                }}
            >
                <Text
                    style={{
                        fontWeight: '600',
                        color: theme.colors.secondary[600],
                    }}
                >
                    {moment(announcement.date).format('LL').toUpperCase()}
                </Text>
                {announcement.title && (
                    <Heading
                        text={announcement.title}
                        type={isMobile ? 'h2' : 'h1'}
                        style={{
                            textAlign: 'center',
                            marginTop: '0.5rem',
                            maxWidth: '53.5rem',
                        }}
                    />
                )}
            </Box>
            <Container>
                <Box
                    margin={'auto'}
                    flexDirection="column"
                    boxStyles={{ maxWidth: '53.5rem' }}
                >
                    <Box
                        flexDirection="column"
                        paddingTop={isMobile ? 24 : 80}
                        boxStyles={{
                            backgroundColor: theme.colors.secondary[300],
                            maxWidth: '53.5rem',
                        }}
                    >
                        <PrismicRichText
                            field={announcement.content}
                            components={{
                                strong: ({ text }) => (
                                    <text
                                        style={{
                                            fontWeight: 600,
                                            marginTop: 0,
                                            marginBottom: 0,
                                            padding: 0,
                                        }}
                                    >
                                        {text}
                                    </text>
                                ),
                                heading5: ({ text }) =>
                                    text && (
                                        <Heading
                                            color={'secondary'}
                                            type={'h5'}
                                            text={text}
                                            style={{
                                                marginTop: '0.5rem',
                                                marginBottom: '0.5rem',
                                            }}
                                        />
                                    ),
                                heading4: ({ text }) =>
                                    text && (
                                        <Heading
                                            type={'h4'}
                                            text={text}
                                            style={{
                                                marginTop: '0.5rem',
                                                marginBottom: '0.5rem',
                                            }}
                                        />
                                    ),
                                heading3: ({ text }) =>
                                    text && (
                                        <Heading
                                            type={'h3'}
                                            text={text}
                                            style={{
                                                marginTop: '0.5rem',
                                                marginBottom: '0.5rem',
                                            }}
                                        />
                                    ),
                                heading2: ({ text }) =>
                                    text && (
                                        <Heading
                                            type={'h2'}
                                            text={text}
                                            style={{
                                                marginTop: '0.5rem',
                                                marginBottom: '0.5rem',
                                            }}
                                        />
                                    ),
                                hyperlink: ({ text, node }) => (
                                    <a
                                        style={{
                                            color: theme.colors.secondary[600],
                                            fontWeight: 600,
                                            textDecoration: 'none',
                                        }}
                                        href={node.data.url}
                                        target={
                                            node.data.link_type === 'Web'
                                                ? node.data.target
                                                : ''
                                        }
                                        rel="noopener noreferrer"
                                    >
                                        {text}
                                    </a>
                                ),
                                image: ({ node }) => (
                                    <>
                                        <img
                                            src={node.url}
                                            alt={node.alt || ''}
                                            style={{
                                                display: 'flex',
                                                alignSelf: 'center',
                                                width: '100%',
                                            }}
                                        ></img>
                                        {node.alt && (
                                            <text
                                                style={{
                                                    color: theme.colors
                                                        .tertiary[600],
                                                    fontSize: '0.75rem',
                                                    fontWeight: 600,
                                                    paddingTop: '1rem',
                                                    paddingBottom: '1rem',
                                                }}
                                            >
                                                {node.alt}
                                            </text>
                                        )}
                                    </>
                                ),
                                list: ({ children }) => (
                                    <ul className={styles.bullet}>
                                        {children}
                                    </ul>
                                ),
                                listItem: ({ children }) => (
                                    <li
                                        className={`${styles.listItem({
                                            colors: 'Orange',
                                        })}`}
                                    >
                                        <Text> {children} </Text>
                                    </li>
                                ),
                            }}
                        />
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}
