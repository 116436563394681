import React from 'react'
import { TLogoCardsSlices } from './LogoCards.types'

import DefaultLogoCards from './variations/Default'

const LogoCards: React.FC<{ slice: TLogoCardsSlices }> = ({ slice }) => {
    switch (slice.variation) {
        case 'default': {
            return DefaultLogoCards(slice)
        }
        default:
            return null
    }
}

export default LogoCards
