import React from 'react'
import { TProductCarouselSlice } from './ProductCarousel.types'
import { DefaultProductCarousel } from './variations/Default'

const ProductCategories: React.FC<{ slice: TProductCarouselSlice }> = ({
    slice,
}) => {
    switch (slice.variation) {
        case 'default': {
            return DefaultProductCarousel(slice)
        }

        default:
            return null
    }
}

export default ProductCategories
