import React, { useEffect, useRef, useState } from 'react'
import CircularArrowDown from '../../assets/ArrowDown'
import { Box } from '../Box'
import * as styles from './Select.css'

type TSelectProps = {
    options: string[]
    variant?: 'outlined' | 'filled' | 'colorFilled'
    onSelectChange: (value: string) => void
    label?: string
    placeholder?: string
    defaultValue?: string
}

export const Select: React.FC<TSelectProps> = ({
    options,
    variant = 'outlined',
    label,
    placeholder,
    defaultValue,
    onSelectChange,
}) => {
    const [selectedValue, setSelectedValue] = useState(defaultValue ?? '')
    const [expanded, setExpanded] = useState(false)

    const keydownHandler = (
        option: string,
        e: React.KeyboardEvent<HTMLLIElement>
    ) => {
        switch (e.key) {
            case ' ':
            case 'SpaceBar':
            case 'Enter':
                e.preventDefault()
                setSelectedValue(option)
                setExpanded(false)
                break
            case 'Escape':
                setExpanded(false)
                break
            default:
                break
        }
    }

    const menuRef = useRef<HTMLUListElement>(null)

    useEffect(() => {
        if (expanded && menuRef.current) {
            menuRef.current.focus()
        }
    }, [expanded, menuRef])

    useEffect(() => {
        onSelectChange(selectedValue)
    }, [selectedValue])

    const handleBlur = (e: React.FocusEvent) => {
        if (!e.currentTarget.contains(e.relatedTarget)) setExpanded(false)
    }

    return (
        <Box flex={1} extraClassName={styles.SelectWrapperStyle}>
            {label && <p className={styles.LabelStyle}>{label}</p>}
            <button
                onClick={() => setExpanded((prev) => !prev)}
                className={styles.SelectStyle({
                    variant: variant,
                    expanded: expanded,
                })}
            >
                {selectedValue ? selectedValue : placeholder}
                <CircularArrowDown />
            </button>
            {expanded && (
                <ul
                    onBlur={(e) => handleBlur(e)}
                    ref={menuRef}
                    tabIndex={-1}
                    className={styles.SelectMenuListStyle({ variant: variant })}
                >
                    {selectedValue != '' && (
                        <li
                            className={styles.SelectMenuListItemStyle({
                                variant: variant,
                                disabled: true,
                            })}
                        >
                            {placeholder}
                        </li>
                    )}
                    {expanded &&
                        options.map((opt, i) => (
                            <li
                                key={opt + i}
                                tabIndex={0}
                                className={styles.SelectMenuListItemStyle({
                                    variant: variant,
                                })}
                                onKeyDown={(
                                    e: React.KeyboardEvent<HTMLLIElement>
                                ) => keydownHandler(opt, e)}
                                onClick={() => {
                                    setExpanded((prev) => !prev)
                                    setSelectedValue(opt)
                                }}
                            >
                                {opt}
                            </li>
                        ))}
                </ul>
            )}
        </Box>
    )
}
