import React from 'react'
import { TQuoteBlockSlice } from './QuoteBlock.types'
import DefaultQuoteBlock from './variations/Default'

const QuoteBlock: React.FC<{ slice: TQuoteBlockSlice }> = ({ slice }) => {
    switch (slice.variation) {
        case 'default': {
            return DefaultQuoteBlock(slice)
        }

        default:
            return null
    }
}

export default QuoteBlock
