import { useWindowSize } from 'react-use'
import { Box, MotionBox } from '../../Box'
import { Button } from '../../Button'
import { Heading } from '../../Heading'
import * as styles from './AdvertisingCard.css'
import { useState, useEffect } from 'react'
import { BreakPoints, theme } from '@orbit/ui/themes'
import { IconType, OrbitImage, OrbitVideo } from '@orbit/ui/core'
import { Modal } from '../../Modal'
import { Container } from '../../Container'
import { VideoPlayIcon } from '../../../assets/VideoPlayIcon'
import { Text } from '../../Text'
import { cardRevealUp } from '@orbit/utils/framer-motion'

export interface SolutionCardProps {
    image: string
    imagealt: string
    title?: string
    highlightStart?: number
    highlightEnd?: number
    description?: string
    buttonLabel?: string
    buttonIcon?: IconType
    cardColor: 'primary' | 'secondary' | 'tertiary' | 'accent'
    dotcolor: string
    label?: string
    buttonLink?: string
    buttonText?: string
    openNewTab?: boolean
    imageWidth?: number
    imageHeight?: number
    imageShape: 'Square' | 'Window' | 'Circle'
    text: React.ReactNode
    cardSize: string
    highlightColor?: string
    video?: string
    index?: number
}

interface CardProps extends SolutionCardProps {
    width: number
}

export const ColoredBackground: React.FC<SolutionCardProps> = (props) => {
    const { width } = useWindowSize()

    return width < BreakPoints.mobileSmall ? (
        <SmallCard {...props} width={width} />
    ) : width < BreakPoints.tabletSmall ? (
        <MediumCard {...props} width={width} />
    ) : props.cardSize === 'large' ? (
        <LargeCard {...props} width={width} />
    ) : props.cardSize === 'medium' ? (
        <MediumCard {...props} width={width} />
    ) : (
        <SmallCard {...props} width={width} />
    )
}

export const LargeCard: React.FC<CardProps> = (props) => {
    const [isMobile, setIsMobile] = useState(false)
    const { width } = useWindowSize()
    const [isOpen, setOpen] = useState(false)
    const [isVideo, setVideo] = useState(false)
    const [isImage, setImage] = useState(false)
    const [isVideoImage, setVideoImage] = useState(false)

    useEffect(() => {
        if (props.image && !props.video) {
            return setImage(true)
        }
        if (props.video && !props.image) {
            return setVideo(true)
        }
        if (props.video && props.image) {
            return setVideoImage(true)
        }
    }, [])
    useEffect(() => {
        if (width < BreakPoints.mobile) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])
    return (
        <MotionBox
            width="full"
            initial="hidden"
            viewport={{
                once: true,
            }}
            whileInView="visible"
            transition={{
                delay: 0.15 * (props.index ? props.index + 1 : 1),
                duration: 0.6,
            }}
            variants={cardRevealUp}
            key={`${props.title}-${props.index}`}
            align={'center'}
            extraClassName={`${styles.largeColoredCardContainer({
                color: props.cardColor,
            })}  ${
                props.video ? styles.animationCardsVideo : styles.animationCards
            }`}
        >
            <Box flexDirection="column" width="full">
                <Box
                    flexDirection="row"
                    width="full"
                    gap="2.5rem"
                    align="center"
                >
                    <Box flexDirection="column" boxStyles={{ width: '40%' }}>
                        <Heading
                            type={'h4'}
                            text={props.title || ''}
                            highlightStart={
                                props.highlightStart && props.highlightStart - 1
                            }
                            highlightEnd={
                                props.highlightEnd && props.highlightEnd - 1
                            }
                            highlightColor={props.highlightColor}
                            mx={0}
                            my={1}
                            style={{
                                fontSize: '2.5rem',
                                lineHeight: '3rem',
                            }}
                        />
                        <Text style={{ lineHeight: '2rem', fontWeight: 300 }}>
                            {props.text}
                        </Text>
                        <Box justify="spaceBetween" width="full" align="center">
                            {props.label && (
                                <Box>
                                    <Text
                                        textClass={styles.labelStyle({
                                            color: props.cardColor,
                                        })}
                                    >
                                        {props.label}
                                    </Text>
                                </Box>
                            )}
                            {props.buttonLink && (
                                <Box>
                                    <a
                                        href={props.buttonLink}
                                        className={styles.buttonStyling({
                                            color: props.cardColor,
                                        })}
                                    >
                                        {props.buttonLabel}
                                    </a>
                                </Box>
                            )}
                        </Box>
                    </Box>
                    {isImage ? (
                        <Box
                            boxStyles={{ width: '60%' }}
                            justify="flexEnd"
                            align="center"
                        >
                            <OrbitImage
                                containerclass={`${
                                    props.imageShape === 'Square'
                                        ? styles.squareShapeImageSmallCard
                                        : styles.circleShapeImageSmallCard
                                }`}
                                src={props.image || ''}
                                alt={props.imagealt || ''}
                                height={'31.75rem'}
                                width={'37rem'}
                                objectFit={'cover'}
                                layout="fill"
                            />
                        </Box>
                    ) : isVideoImage ? (
                        <Box
                            onClick={() => setOpen(true)}
                            boxStyles={{
                                width: isMobile ? '80%' : '60%',
                            }}
                            justify="flexEnd"
                        >
                            <Box justify="center" align="center">
                                <OrbitImage
                                    containerclass={`${styles.squareShapeImageSmallCard}`}
                                    src={props.image || ''}
                                    alt={props.imagealt || ''}
                                    height={'31.75rem'}
                                    width={'37rem'}
                                    objectFit={'cover'}
                                    layout="fill"
                                />
                                <Box extraClassName={styles.PlayPauseButton}>
                                    <VideoPlayIcon
                                        className={styles.PlayPauseButtonIcon}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    ) : (
                        isVideo && (
                            <Box onClick={() => setOpen(true)}>
                                <OrbitVideo
                                    url={props.video || ''}
                                    color={props.cardColor}
                                    renderAsModal={true}
                                    title={`Myndband um ${props.title}`}
                                />
                            </Box>
                        )
                    )}
                </Box>
                {props.video && (
                    <Box>
                        <Modal open={isOpen} onClose={() => setOpen(false)}>
                            <Box width={80}>
                                <OrbitVideo
                                    url={props.video}
                                    color={props.cardColor}
                                    renderAsModal={false}
                                    title={`Myndband um ${props.title}`}
                                />
                            </Box>
                        </Modal>
                    </Box>
                )}
            </Box>
        </MotionBox>
    )
}

export const MediumCard: React.FC<CardProps> = (props) => {
    const [isMobile, setIsMobile] = useState(false)
    const { width } = useWindowSize()
    const [isOpen, setOpen] = useState(false)
    const [isVideo, setVideo] = useState(false)
    const [isImage, setImage] = useState(false)
    const [isVideoImage, setVideoImage] = useState(false)

    useEffect(() => {
        if (props.image && !props.video) {
            return setImage(true)
        }
        if (props.video && !props.image) {
            return setVideo(true)
        }
        if (props.video && props.image) {
            return setVideoImage(true)
        }
    }, [])

    useEffect(() => {
        if (width < BreakPoints.mobileSmall) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])
    return (
        <MotionBox
            width="full"
            initial="hidden"
            viewport={{
                once: true,
            }}
            whileInView="visible"
            transition={{
                delay: 0.2 * (props.index ? props.index + 1 : 1),
                duration: 0.6,
            }}
            variants={cardRevealUp}
            key={`${props.title}-${props.index}`}
            flexDirection={'column'}
            align="center"
            extraClassName={`${styles.mediumColoredCardContainer({
                color: props.cardColor,
            })}  ${
                props.video ? styles.animationCardsVideo : styles.animationCards
            }`}
            boxStyles={{
                width: '100%',
            }}
            justify="center"
        >
            <Box flexDirection="column" width="full">
                <Box flexDirection="row" gap="1rem" width="full">
                    <Box
                        flexDirection="column"
                        width="full"
                        boxStyles={{ height: '100%' }}
                        justify="spaceBetween"
                    >
                        <Box
                            flexDirection="column"
                            width="full"
                            boxStyles={{ height: '100%' }}
                        >
                            <Heading
                                type={'h4'}
                                text={props.title || ''}
                                highlightStart={
                                    props.highlightStart &&
                                    props.highlightStart - 1
                                }
                                highlightEnd={
                                    props.highlightEnd && props.highlightEnd - 1
                                }
                                highlightColor={props.highlightColor}
                                mx={0}
                                my={1}
                            />
                            <Text>{props.text}</Text>
                        </Box>

                        <Box justify="spaceBetween" width="full" align="center">
                            {props.label && (
                                <Box>
                                    <Text
                                        textClass={styles.labelStyle({
                                            color: props.cardColor,
                                        })}
                                    >
                                        {props.label}
                                    </Text>
                                </Box>
                            )}
                            {props.buttonLink && (
                                <Box>
                                    <a
                                        href={props.buttonLink}
                                        className={styles.buttonStyling({
                                            color: props.cardColor,
                                        })}
                                    >
                                        {props.buttonLabel}
                                    </a>
                                </Box>
                            )}
                        </Box>
                    </Box>
                    {isImage ? (
                        <Box
                            width="full"
                            justify="flexEnd"
                            align="center"
                            boxStyles={{ maxHeight: '13.438rem' }}
                        >
                            <OrbitImage
                                containerclass={`${
                                    props.imageShape === 'Square'
                                        ? styles.squareShapeImageMediumCard
                                        : styles.circleShapeImageMediumCard
                                }`}
                                src={props.image || ''}
                                alt={props.imagealt || ''}
                                height={'215'}
                                width={'215'}
                                objectFit={'cover'}
                                layout="fill"
                            />
                        </Box>
                    ) : isVideoImage ? (
                        <Box
                            onClick={() => setOpen(true)}
                            boxStyles={{
                                justifyContent: 'center',
                            }}
                        >
                            <Box justify="center" align="center">
                                <OrbitImage
                                    containerclass={`${styles.squareShapeImageMediumCard}`}
                                    src={props.image || ''}
                                    alt={props.imagealt || ''}
                                    height={'215'}
                                    width={'215'}
                                    objectFit={'cover'}
                                    layout="fill"
                                />
                                <Box extraClassName={styles.PlayPauseButton}>
                                    <VideoPlayIcon
                                        className={styles.PlayPauseButtonIcon}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    ) : (
                        isVideo && (
                            <Box onClick={() => setOpen(true)}>
                                <OrbitVideo
                                    url={props.video || ''}
                                    color={props.cardColor}
                                    renderAsModal={true}
                                    title={`Myndband um ${props.title}`}
                                />
                            </Box>
                        )
                    )}
                    {props.video && (
                        <Modal open={isOpen} onClose={() => setOpen(false)}>
                            <Box width={80}>
                                <OrbitVideo
                                    url={props.video}
                                    color={props.cardColor}
                                    renderAsModal={false}
                                    title={`Myndband um ${props.title}`}
                                />
                            </Box>
                        </Modal>
                    )}
                </Box>
            </Box>
        </MotionBox>
    )
}

const SmallCard: React.FC<CardProps> = (props) => {
    const [isOpen, setOpen] = useState(false)
    const [isVideo, setVideo] = useState(false)
    const [isImage, setImage] = useState(false)
    const [isVideoImage, setVideoImage] = useState(false)

    useEffect(() => {
        if (props.image && !props.video) {
            return setImage(true)
        }
        if (props.video && !props.image) {
            return setVideo(true)
        }
        if (props.video && props.image) {
            return setVideoImage(true)
        }
    }, [])
    return (
        <MotionBox
            initial="hidden"
            viewport={{
                once: true,
            }}
            whileInView="visible"
            transition={{
                delay: 0.15 * (props.index ? props.index + 1 : 1),
                duration: 0.6,
            }}
            variants={cardRevealUp}
            key={`${props.title}-${props.index}`}
            flex={1}
            flexDirection={'column'}
            extraClassName={`${styles.smallColoredCardContainer({
                color: props.cardColor,
            })}  ${
                props.video ? styles.animationCardsVideo : styles.animationCards
            }`}
            boxStyles={{
                transition: ` all  ${theme.animations.transitionLong} ease-in-out 0s`,
                backgroundColor: '',
            }}
        >
            <Box
                flexDirection="column"
                width="full"
                boxStyles={{ rowGap: '2rem', height: '100%' }}
                justify="spaceBetween"
            >
                <Box
                    width={'full'}
                    flexDirection="column"
                    boxStyles={{ height: '100%' }}
                    justify="spaceBetween"
                >
                    <Box width={'full'} flexDirection="column">
                        <Heading
                            type={'h4'}
                            text={props.title || ''}
                            highlightStart={
                                props.highlightStart && props.highlightStart - 1
                            }
                            highlightEnd={
                                props.highlightEnd && props.highlightEnd - 1
                            }
                            highlightColor={props.highlightColor}
                            mx={0}
                            my={1}
                        />
                        <Text style={{ margin: 0 }}>{props.text}</Text>
                    </Box>

                    {isImage ? (
                        <Box
                            width="full"
                            justify="center"
                            align="center"
                            boxStyles={{ maxHeight: '15rem' }}
                        >
                            <OrbitImage
                                containerclass={`${
                                    props.imageShape === 'Square'
                                        ? styles.squareShapeImageSmallCard
                                        : styles.circleShapeImageSmallCard
                                }`}
                                src={props.image || ''}
                                alt={props.imagealt || ''}
                                height={'240'}
                                width={'240'}
                                objectFit={'cover'}
                                layout="fill"
                            />
                        </Box>
                    ) : isVideoImage ? (
                        <Box
                            onClick={() => setOpen(true)}
                            boxStyles={{
                                justifyContent: 'center',
                            }}
                            width="full"
                        >
                            <Box width="full" justify="center" align="center">
                                <OrbitImage
                                    containerclass={`${styles.squareShapeImageSmallCard}`}
                                    src={props.image || ''}
                                    alt={props.imagealt || ''}
                                    height={'240'}
                                    width={'240'}
                                    objectFit={'cover'}
                                    layout="fill"
                                />
                                <Box extraClassName={styles.PlayPauseButton}>
                                    <VideoPlayIcon
                                        className={styles.PlayPauseButtonIcon}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    ) : (
                        isVideo && (
                            <Box onClick={() => setOpen(true)}>
                                <OrbitVideo
                                    url={props.video || ''}
                                    color={props.cardColor}
                                    renderAsModal={true}
                                    title={`Myndband um ${props.title}`}
                                />
                            </Box>
                        )
                    )}
                    {props.video && (
                        <Modal open={isOpen} onClose={() => setOpen(false)}>
                            <Box width={80}>
                                <OrbitVideo
                                    url={props.video}
                                    color={props.cardColor}
                                    renderAsModal={false}
                                    title={`Myndband um ${props.title}`}
                                />
                            </Box>
                        </Modal>
                    )}
                </Box>
                <Box justify="spaceBetween" width="full" align="center">
                    {props.label && (
                        <Box>
                            <Text
                                textClass={styles.labelStyle({
                                    color: props.cardColor,
                                })}
                            >
                                {props.label}
                            </Text>
                        </Box>
                    )}
                    {props.buttonLink && (
                        <Box>
                            <a
                                href={props.buttonLink}
                                className={styles.buttonStyling({
                                    color: props.cardColor,
                                })}
                            >
                                {props.buttonLabel}
                            </a>
                        </Box>
                    )}
                </Box>
            </Box>
        </MotionBox>
    )
}
