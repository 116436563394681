import { BreakPoints } from '@orbit/ui/themes'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useWindowSize } from 'react-use'
import { Box } from '../Box'
import { Container } from '../Container'
import { Heading } from '../Heading'
import { OrbitImage } from '../Image'
import { Text } from '../Text'
import * as styles from './SubpageHeroBanner.css'

interface ISubpageHeroBannertProps {
    imageUrl?: string
    imageAlt?: string
    date?: string
    title?: string
    introtext?: string
    authorName?: string
    slicecolor: string
    type: 'Blog' | 'Event' | 'News'
}

export const SubpageHeroBanner: React.FC<ISubpageHeroBannertProps> = ({
    imageUrl,
    imageAlt,
    date,
    title,
    introtext,
    authorName,
    slicecolor,
    type,
}) => {
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)
    useEffect(() => {
        if (width < BreakPoints.tabletSmall) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])
    return (
        <Container>
            <Box
                boxStyles={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column-reverse' : 'row-reverse',
                }}
            >
                <Box
                    extraClassName={styles.infoBoxStyle}
                    boxStyles={{
                        backgroundColor: slicecolor,

                        minHeight: isMobile
                            ? 'unset'
                            : type === 'Event'
                            ? '400px'
                            : '450px',
                    }}
                >
                    <Box>
                        {!authorName && date && (
                            <Text
                                textClass={styles.infoSubTitle({
                                    noAuthorName: !authorName,
                                })}
                                size="large"
                            >
                                {moment(date).format('DD/MM/YYYY')}
                            </Text>
                        )}
                        {authorName && (
                            <Heading
                                my={0}
                                paddingBottom={3}
                                type={'h5'}
                                text={
                                    date
                                        ? moment(date).format('DD/MM/YYYY') +
                                          ' • ' +
                                          authorName
                                        : authorName
                                }
                                className={styles.infoSubTitle({
                                    noAuthorName: !authorName,
                                })}
                            />
                        )}
                    </Box>
                    <Box
                        flexDirection="column"
                        boxStyles={{
                            width: '100%',
                            wordBreak: 'break-word',
                        }}
                    >
                        {title && (
                            <Heading
                                my={0}
                                paddingTop={2}
                                type={'h1'}
                                text={title}
                            />
                        )}
                        {introtext && (
                            <Text textClass={styles.infoIntroText}>
                                {introtext.length < 260
                                    ? introtext
                                    : introtext.substring(0, 260) + '...'}
                            </Text>
                        )}
                    </Box>
                </Box>
                <Box
                    width={isMobile ? 'full' : 'half'}
                    justify={isMobile ? 'center' : undefined}
                >
                    {imageUrl && (
                        <OrbitImage
                            height={450}
                            containerclass={styles.imageStyle({
                                type: type,
                            })}
                            width={'100%'}
                            layout="fill"
                            src={imageUrl || ''}
                            alt={imageAlt || ''}
                        />
                    )}
                </Box>
            </Box>
        </Container>
    )
}
