import { useEffect, useRef, useState } from 'react'
import {
    parsePrismicBackgroundColor,
    parsePrismicHeadingColor,
    parsePrismicHighlightColor,
} from '@orbit/prismic'
import {
    AnnouncementsCard,
    Box,
    Container,
    Heading,
    MotionBox,
    Pagination,
} from '@orbit/ui/core'
import {
    announcementData,
    TOverviewContext,
    TOverviewSlice,
} from '../Overview.types'
import useSwr from 'swr'
import { AnimatePresence } from 'framer-motion'
import { cardRevealUp } from '@orbit/utils/framer-motion'
import * as styles from '../Overview.css'
import { BreakPoints } from '@orbit/ui/themes'
import { useWindowSize } from 'react-use'

const fetcher = (url: string) => fetch(url).then((res) => res.json())

export const AnnouncementOverview = (
    slice: TOverviewSlice,
    context: TOverviewContext
) => {
    const [currentPage, setCurrentPage] = useState(1)
    const [totalResultsSize, setTotalResultsSize] = useState<number>(0)
    const [pageSize, setPageSize] = useState<number>(0)
    const announcementSection = useRef<HTMLDivElement>(null)
    const [totalPages, setTotalPages] = useState<number>(0)
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)
    const count = 12

    const { data, isValidating } = useSwr(
        `/api/announcement?count=${count}&page=${currentPage}&lang=${context.lang}`,
        fetcher
    )
    const announcements = data && data.results

    useEffect(() => {
        if (!isValidating && data) {
            data.total_pages !== totalPages && setTotalPages(data.total_pages)
            data.total_results_size !== totalResultsSize &&
                setTotalResultsSize(data.total_results_size)
            data.results_per_page !== pageSize &&
                setPageSize(data.results_per_page)
        }
    }, [data, isValidating, totalPages, pageSize, totalResultsSize])

    useEffect(() => {
        if (width < BreakPoints.tabletSmall) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    const scrollToTopOfblogSection = () => {
        if (announcementSection.current)
            window.scrollTo({
                top: announcementSection.current.offsetTop - 40,
                behavior: 'smooth',
            })
    }

    return (
        <Box
            anchorid={slice.primary.anchor || undefined}
            flexDirection="column"
            justify="center"
            boxStyles={{ height: '100%' }}
        >
            <Box
                boxStyles={{
                    background: parsePrismicBackgroundColor(
                        slice.primary.slicecolor
                    ),
                }}
                alignSelf="stretch"
                width="auto"
            >
                <Container>
                    <Box
                        boxStyles={{
                            background: parsePrismicBackgroundColor(
                                slice.primary.slicecolor
                            ),
                        }}
                        align="center"
                        flexDirection="column"
                        marginTop={80}
                    >
                        {slice.primary.tagline && (
                            <Heading
                                text={slice.primary.tagline}
                                type="h1"
                                color={parsePrismicHeadingColor(
                                    slice.primary.slicecolor
                                )}
                                className={styles.taglineStyle}
                            />
                        )}
                        {slice.primary.title && (
                            <Heading
                                text={slice.primary.title}
                                type={slice.primary.tagline ? 'h2' : 'h1'}
                                className={styles.announcementTitleStyle}
                            />
                        )}
                        <Box
                            position="relative"
                            flexDirection="column"
                            marginTop={24}
                            marginBottom={64}
                            width="full"
                        >
                            <AnimatePresence>
                                <Box
                                    extraClassName={
                                        styles.AnnouncementCardWrapper
                                    }
                                    wrap="wrap"
                                >
                                    {announcements &&
                                        announcements.map(
                                            (
                                                annoucement: announcementData,
                                                index: number
                                            ) => {
                                                return (
                                                    <MotionBox
                                                        width="full"
                                                        flexDirection="column"
                                                        initial="hidden"
                                                        viewport={{
                                                            once: true,
                                                        }}
                                                        whileInView="visible"
                                                        transition={{
                                                            delay:
                                                                0.1 *
                                                                (index + 1),
                                                            duration: 0.6,
                                                        }}
                                                        variants={cardRevealUp}
                                                        key={`${annoucement.data.uid}-${index}`}
                                                    >
                                                        <AnnouncementsCard
                                                            url={
                                                                annoucement.url
                                                            }
                                                            datecolor={parsePrismicHighlightColor(
                                                                slice.primary
                                                                    .slicecolor
                                                            )}
                                                            title={
                                                                annoucement.data
                                                                    .title
                                                            }
                                                            summary={
                                                                annoucement.data
                                                                    .summary
                                                            }
                                                            date={
                                                                annoucement.data
                                                                    .date
                                                            }
                                                        />
                                                    </MotionBox>
                                                )
                                            }
                                        )}
                                </Box>
                            </AnimatePresence>
                            {totalPages > 0 && (
                                <Pagination
                                    currentPage={currentPage}
                                    variant="numbers"
                                    color={parsePrismicHeadingColor(
                                        slice.primary.slicecolor
                                    )}
                                    total={totalResultsSize}
                                    pageSize={pageSize}
                                    onPageChange={(page: number) => {
                                        scrollToTopOfblogSection()
                                        setCurrentPage(page)
                                    }}
                                />
                            )}
                        </Box>
                    </Box>
                </Container>
            </Box>
        </Box>
    )
}

export default AnnouncementOverview
