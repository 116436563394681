import { forwardRef } from 'react'
import cx from 'classnames'
import { BoxProps } from '../../types'
import { motion, MotionProps } from 'framer-motion'
import { boxStyle } from './Box.css'

// type NativeButtonProps = AllHTMLAttributes<HTMLButtonElement>

export const Box = forwardRef<HTMLDivElement, BoxProps>(
    (
        {
            ariaHidden,
            anchorid,
            id,
            display = 'flex',
            alignSelf,
            onClick,
            order,
            flex,
            children,
            padding,
            px,
            py,
            paddingRight,
            paddingLeft,
            paddingBottom,
            paddingTop,
            mx,
            my,
            margin,
            marginRight,
            marginLeft,
            marginBottom,
            marginTop,
            flexDirection = 'row',
            justify,
            align,
            boxStyles,
            wrap,
            borderRadius,
            responsiveContainer,
            width,
            extraClassName,
            maxWidth,
            position,
            tabIndex,
            gap,
        },
        ref
    ) => (
        <div
            aria-hidden={ariaHidden}
            data-anchorid={anchorid || undefined}
            id={id}
            onClick={onClick}
            style={boxStyles && boxStyles}
            className={cx(
                `${boxStyle({
                    display: display,
                    flex: flex,
                    alignItems: align,
                    padding: padding,
                    px: px,
                    py: py,
                    paddingRight: paddingRight,
                    paddingLeft: paddingLeft,
                    paddingBottom: paddingBottom,
                    paddingTop: paddingTop,
                    margin: margin,
                    mx: mx,
                    my: my,
                    marginRight: marginRight,
                    marginLeft: marginLeft,
                    marginBottom: marginBottom,
                    marginTop: marginTop,
                    flexDirection: flexDirection,
                    wrap: wrap,
                    justify: justify,
                    alignSelf: alignSelf,
                    borderRadius: borderRadius,
                    responsiveContainer: responsiveContainer,
                    width: width,
                    maxWidth: maxWidth,
                    order: order,
                    position: position,
                    gap: gap,
                })}`,
                extraClassName && extraClassName
            )}
            ref={ref}
            tabIndex={tabIndex && tabIndex}
        >
            {children}
        </div>
    )
)

motion(Box)

export const MotionBox = motion(Box, { forwardMotionProps: true })

export default Box
