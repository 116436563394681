import { FC } from 'react'
import { TAgendaSlices } from './Agenda.types'
import { DefaultAgenda } from './variations'

const Agenda: FC<{ slice: TAgendaSlices }> = ({ slice }) => {
    switch (slice.variation) {
        case 'default': {
            return DefaultAgenda(slice)
        }

        default:
            return null
    }
}

export default Agenda
