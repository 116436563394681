import {
    Box,
    Button,
    Container,
    Heading,
    Text,
    LinkedInIcon,
    FacebookIcon,
} from '@orbit/ui/core'
import { BreakPoints, theme } from '@orbit/ui/themes'
import { useState, useEffect } from 'react'
import { useWindowSize } from 'react-use'
import moment from 'moment'
import 'moment/locale/is'
import * as styles from './JobsPage.css'
import { FacebookShareButton, LinkedinShareButton } from 'react-share'

type KjarniJobDetails = {
    AdvertStatusName: string
    Contact: string
    DisplayExternal: boolean
    Id: number
    ShowContact: boolean
    ShowText: boolean
    ShowValidFrom: boolean
    ShowValidTo: boolean
    Text: string
    Title: string
    ValidFrom: string
    ValidTo: string
}

export const JobsPage: React.FC<{
    job: KjarniJobDetails
}> = ({ job }) => {
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)
    const [baseUrl, setBaseUrl] = useState('')
    useEffect(() => {
        if (window !== undefined) {
            setBaseUrl(window.location.href)
        }
    }, [])
    useEffect(() => {
        if (width < BreakPoints.mobile) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])
    moment.locale('is')
    return (
        <Box flexDirection="column" alignSelf="center">
            <Box
                flexDirection="column"
                align="center"
                boxStyles={{
                    backgroundColor: 'white',
                    width: '100%',
                    paddingLeft: '1rem',
                    paddingRight: '1rem',
                }}
            >
                <Text
                    style={{
                        fontWeight: '600',
                        color: theme.colors.tertiary[600],
                    }}
                >
                    {moment(job.ValidFrom).format('LL').toUpperCase()}
                </Text>
                {job.Title && (
                    <Heading
                        text={job.Title}
                        type={isMobile ? 'h2' : 'h1'}
                        style={{
                            textAlign: 'center',
                            marginTop: '0.5rem',
                            maxWidth: '53.5rem',
                        }}
                    />
                )}
            </Box>
            <Box
                gap="1.5rem"
                margin={'auto'}
                flexDirection="column"
                boxStyles={{
                    maxWidth: '67.5rem',
                }}
                extraClassName={styles.coloredBackgroundBox}
            >
                <Container
                    style={{
                        backgroundColor: isMobile
                            ? theme.colors.tertiary[200]
                            : 'white',
                    }}
                >
                    <Box
                        margin={'auto'}
                        flexDirection="column"
                        boxStyles={{
                            maxWidth: '67.5rem',
                            backgroundColor: theme.colors.tertiary[200],
                        }}
                        extraClassName={styles.coloredBackgroundBox}
                    >
                        <Box
                            flexDirection="column"
                            margin={'auto'}
                            paddingTop={isMobile ? 24 : 80}
                            boxStyles={{
                                padding: isMobile ? '2rem' : '5rem',
                            }}
                            align="center"
                            paddingBottom={isMobile ? 24 : 80}
                        >
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: job.Text,
                                }}
                                className={styles.htmlKjarniJobsDetailsStyle}
                            />

                            <Box paddingTop={isMobile ? 24 : 40}>
                                <Button
                                    variant="filled"
                                    color="tertiary"
                                    href={`https://origo.umsokn.is/application/${job.Id}`}
                                    icon="ArrowRight"
                                >
                                    Sækja um starf / Apply for job
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Container>
                <Container>
                    <Box
                        width="full"
                        justify="spaceBetween"
                        paddingLeft={24}
                        paddingRight={24}
                        paddingTop={8}
                        paddingBottom={8}
                        align="center"
                        boxStyles={{
                            backgroundColor: theme.colors.tertiary[400],
                        }}
                    >
                        <Box extraClassName={styles.shareText}>
                            Deila starfi
                        </Box>

                        <Box
                            flexDirection="row"
                            alignItems="center"
                            display="flex"
                        >
                            <Box
                                extraClassName={styles.iconStyle}
                                align="center"
                            >
                                <FacebookShareButton
                                    url={baseUrl}
                                    title={job.Title}
                                >
                                    <FacebookIcon />
                                </FacebookShareButton>
                            </Box>

                            <Box
                                extraClassName={styles.iconStyle}
                                alignItems="center"
                            >
                                <LinkedinShareButton
                                    url={baseUrl}
                                    title={job.Title}
                                >
                                    <LinkedInIcon />
                                </LinkedinShareButton>
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </Box>
    )
}
