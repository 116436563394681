import { parsePrismicTextCardBackgroundColor } from '@orbit/prismic'
import { Box, Icon, MotionBox, Text } from '@orbit/ui/core'
import { BreakPoints } from '@orbit/ui/themes'
import { cardRevealUp } from '@orbit/utils/framer-motion'
import { useEffect, useState } from 'react'
import { useWindowSize } from 'react-use'
import { ContainerBox } from '../QuoteBlock.css'
import { TQuoteBlockSlice } from '../QuoteBlock.types'
import * as styles from '../QuoteBlock.css'

export const DefaultQuoteBlock = (slice: TQuoteBlockSlice) => {
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)
    useEffect(() => {
        if (width < BreakPoints.tabletSmall) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])
    return (
        <MotionBox
            anchorid={slice.primary.anchor || undefined}
            boxStyles={{
                backgroundColor: parsePrismicTextCardBackgroundColor(
                    slice.primary.slicecolor
                ),
            }}
            padding={7}
            flexDirection="column"
            initial={isMobile ? 'visible' : 'hidden'}
            viewport={{
                once: true,
            }}
            whileInView="visible"
            transition={{
                delay: 0.2,
                duration: 0.6,
            }}
            variants={cardRevealUp}
            extraClassName={styles.ContainerBox}
        >
            <Box flexDirection="row" align="flexStart">
                <Box boxStyles={{ width: '1.5rem' }}>
                    <Icon icon="Quote" color={'black'} />
                </Box>
                <Box flexDirection="column" marginLeft={24}>
                    <Box>
                        <Text textClass={styles.quoteText}>
                            {slice.primary.quote}
                        </Text>
                    </Box>
                    <Box align="center">
                        {slice.primary.name && (
                            <Text style={{ fontSize: '16px', fontWeight: 600 }}>
                                {slice.primary.name}
                            </Text>
                        )}
                        {slice.primary.jobtitle && (
                            <>
                                <Text textClass={styles.jobtitleText}>•</Text>
                                <Text
                                    style={{
                                        fontSize: '14px',
                                    }}
                                >
                                    {slice.primary.jobtitle}
                                </Text>
                            </>
                        )}
                    </Box>
                </Box>
            </Box>
        </MotionBox>
    )
}

export default DefaultQuoteBlock
