import * as styles from './MegaMenu.css'
import { ArrowLink, Box, Button, Container, Heading } from '@orbit/ui/core'
import useSwr from 'swr'
import { useContext, useEffect, useState } from 'react'
import { BreakPoints, theme } from '@orbit/ui/themes'
import VerslunMobileMenu from './VerslunMobileMenu'
import { MegamenuContext } from '@oribt/context'
import { TMenu } from '../Header'
import { SliceZone } from '@prismicio/react'
import { CategoryProductItem } from './verslun/CategoryProductItem'
import MenuItem from '../MenuItem'
import { useWindowSize } from 'react-use'

export interface VerslunMegamenuProps {
    menu: TMenu
    active: boolean
    mobile?: boolean
}

export type TCategory = {
    color?: string
    children?: TCategory[]
    custom1: string
    custom2: string
    description: string
    id: number
    image: string
    invisible: boolean
    level: number
    name: string
    numberOfProducts: number
    parentId: number
    selected: boolean
    sortOrder: number
}

export type TFeaturedCategory = {
    id: number
    image: number
    invisible: boolean
    level: number
    name: string
    numProducts: number
    searchKey: string
    selected: boolean
}

export type TFeaturedManufacturers = {
    contentId: number
    clickUrl: string
    description: {
        name1: string
    }
    searchKey: string
}

export type TCategoriesData = {
    cats: TCategory[]
    featuredCats: TFeaturedCategory[]
    featuredManufacturers: TFeaturedManufacturers[]
    offerURL: string
}

export const VerslunMegaMenu: React.FC<VerslunMegamenuProps> = ({
    menu,
    active,
    mobile = false,
}) => {
    const [categories, setCategories] = useState<TCategoriesData>()
    const [selectedCategory, setSelectedCategory] = useState<TCategory>()
    const fetcher = (url: string) => fetch(url).then((res) => res.json())
    const { data: productCats } = useSwr<TCategoriesData>(
        '/api/verslun/categories',
        fetcher
    )

    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)
    useEffect(() => {
        if (width < BreakPoints.tabletSmall) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    const megamenuContext = useContext(MegamenuContext)

    useEffect(() => {
        setCategories(productCats)
        setSelectedCategory(productCats?.cats[0])
    }, [productCats])
    if (mobile === true)
        return <VerslunMobileMenu categories={categories} menu={menu} />

    const CategoryListItem: React.FC<TCategory> = (props) => {
        return (
            <li
                tabIndex={0}
                key={props.id}
                className={`${styles.categoryListItem} ${
                    selectedCategory &&
                    selectedCategory.id === props.id &&
                    styles.activeCategoryListItem
                }`}
                onClick={() => setSelectedCategory(props)}
                onKeyDown={(e) =>
                    e.key === 'Enter' && setSelectedCategory(props)
                }
            >
                <span className={styles.categoryListItemText}>
                    {props.name}
                </span>
            </li>
        )
    }

    return (
        <Box flexDirection="column">
            <div
                className={`overlay ${styles.overlay} ${
                    active ? styles.activeMenu : styles.inActiveMenu
                }`}
                onClick={(e) =>
                    e.currentTarget === e.target &&
                    megamenuContext.setOpenMegamenu('')
                }
            >
                <div className={styles.megamenuWrapper}>
                    <Container variant="default">
                        <div
                            className={`${styles.submenu} ${
                                active && styles.activeSubmenu
                            }`}
                            role={'menu'}
                        >
                            <Box
                                flex={1}
                                flexDirection="column"
                                justify="spaceBetween"
                                extraClassName={styles.categoriesWrapper}
                                gap="2.5rem"
                                paddingBottom={48}
                            >
                                <Box
                                    flexDirection="column"
                                    gap="1rem"
                                    width="full"
                                    boxStyles={{ paddingTop: '2rem' }}
                                >
                                    <Heading
                                        text="VÖRUFLOKKAR"
                                        type={'h5'}
                                        style={{
                                            fontSize: '0.75rem',
                                            letterSpacing: '0.125rem',
                                            width: '100%',
                                            textTransform: 'uppercase',
                                            marginTop: '0',
                                            marginBottom: '0',
                                            color: theme.colors.neutral[500],
                                        }}
                                    />
                                    {categories && selectedCategory && (
                                        <ul className={styles.categoryList}>
                                            {categories.cats.map((c, i) => {
                                                if (
                                                    c.children?.length &&
                                                    !c.invisible &&
                                                    c.numberOfProducts
                                                ) {
                                                    return (
                                                        <CategoryListItem
                                                            key={i}
                                                            {...c}
                                                        />
                                                    )
                                                }
                                                return null
                                            })}
                                        </ul>
                                    )}
                                </Box>
                                <Box flexDirection="column" gap="1.5rem">
                                    <ArrowLink
                                        linkText="Tilboðsvörur"
                                        link={productCats?.offerURL}
                                    />
                                    <Button
                                        href="https://verslun.origo.is"
                                        variant="filled"
                                        color="primary"
                                        icon="ArrowRight"
                                    >
                                        Verslun
                                    </Button>
                                </Box>
                            </Box>
                            {selectedCategory && (
                                <Box
                                    flex={4}
                                    extraClassName={
                                        styles.categoryProductsWrapper
                                    }
                                >
                                    <Box
                                        extraClassName={
                                            styles.categoryProductContainer
                                        }
                                    >
                                        {selectedCategory.children &&
                                            selectedCategory.children.map(
                                                (c, i) => {
                                                    if (
                                                        c.numberOfProducts &&
                                                        c.image
                                                    ) {
                                                        return (
                                                            <CategoryProductItem
                                                                key={i}
                                                                {...c}
                                                            />
                                                        )
                                                    }
                                                    return null
                                                }
                                            )}
                                    </Box>
                                </Box>
                            )}
                            <Box
                                flex={1}
                                extraClassName={styles.categoriesWrapper}
                                justify="spaceBetween"
                                boxStyles={{
                                    boxShadow: isMobile
                                        ? `0 100vmax 0 100vmax ${theme.colors.neutral[200]}`
                                        : `0 0 0 100vmax ${theme.colors.neutral[200]}`,
                                    clipPath: isMobile
                                        ? 'inset(0 -100vmax 0 -100vmax)'
                                        : 'inset(0 -100vmax 0 0)',
                                }}
                            >
                                <SliceZone
                                    slices={menu.slices}
                                    components={{ menu_item: MenuItem }}
                                />
                            </Box>
                        </div>
                    </Container>
                </div>
            </div>
        </Box>
    )
}
