import React from 'react'
import { TAdvertisingCardsSlices } from './AdvertisingCards.types'
import DefaultAdvertisingCards from './variations/Default'
import ColoredBackgroundAdvertisingCards from './variations/ColoredBackground'

const AdvertisingCards: React.FC<{ slice: TAdvertisingCardsSlices }> = ({
    slice,
}) => {
    switch (slice.variation) {
        case 'default': {
            return DefaultAdvertisingCards(slice)
        }
        case 'coloredBackground': {
            return ColoredBackgroundAdvertisingCards(slice)
        }
        default:
            return null
    }
}

export default AdvertisingCards
