import { Box } from '../../Box'
import { Heading } from '../../Heading'
import { Text } from '../../Text'
import * as styles from './AnnouncementsCard.css'
import moment from 'moment'
import { DateField } from '@prismicio/types'
import Link from 'next/link'

export interface NewsCardProps {
    url: string
    datecolor: string
    title: string | null
    summary: string | null
    date: DateField
}

export const AnnouncementsCard: React.FC<NewsCardProps> = ({
    url,
    date,
    title,
    summary,
    datecolor,
}) => {
    return (
        <Link href={url}>
            <a className={styles.linkWrapper}>
                <Box
                    flexDirection="column"
                    extraClassName={styles.containerBox}
                    justify="spaceBetween"
                >
                    <Box flexDirection="column">
                        {title && (
                            <Heading
                                text={title}
                                type={'h3'}
                                className={styles.titleStyle}
                            />
                        )}
                        <Text textClass={styles.textStyle}>{summary}</Text>
                    </Box>

                    <Text
                        style={{
                            fontWeight: '500',
                            color: datecolor,
                        }}
                    >
                        {moment(date).format('LL').toUpperCase()}
                    </Text>
                </Box>
            </a>
        </Link>
    )
}
