import { useEffect, useState } from 'react'

import {
    parsePrismicBackgroundColor,
    parsePrismicHeadingColor,
} from '@orbit/prismic'
import {
    Box,
    Carousel,
    Container,
    HeadingContainer,
    MotionBox,
    NewsModuleCard,
} from '@orbit/ui/core'
import { BreakPoints } from '@orbit/ui/themes'
import concat from 'lodash/concat'
import moment from 'moment'
import Masonry from 'react-masonry-css'
import { useWindowSize } from 'react-use'
import useSwr from 'swr'
import * as styles from '../NewsModule.css'
import {
    TNewsModuleContext,
    TNewsModuleSliceDefault,
} from '../NewsModule.types'
import { cardRevealUp } from '@orbit/utils/framer-motion'

const fetcher = (url: string) => fetch(url).then((res) => res.json())

export interface MasonryProps {
    columns: number
    gap: number
}

export const DefaultNewsModule = (
    slice: TNewsModuleSliceDefault,
    context: TNewsModuleContext
) => {
    moment.locale('is')
    const [isMobile, setIsMobile] = useState(false)
    const { width } = useWindowSize()

    const { lang } = context

    const breakpointColumnsObj = {
        default: 3,
        1100: 3,
        900: 2,
        500: 1,
    }

    useEffect(() => {
        if (width < BreakPoints.mobileSmall) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    const { data: allNews } = useSwr(
        `/api/allNews/newsHomepage?count=12&lang=${lang}`,
        fetcher
    )
    const { data: allBlogs } = useSwr(
        `/api/blogg/bloggHomepage?count=12&lang=${lang}`,
        fetcher
    )
    const { data: allAnnouncments } = useSwr(
        `/api/announcement/announcementsHomepage?count=12&lang=${lang}`,
        fetcher
    )

    if (!allNews || !allBlogs || !allAnnouncments) {
        return null
    }
    const allNewsData = allNews.results
    const allBlogsData = allBlogs.results
    const allAnnouncmentsData = allAnnouncments.results
    const allItems = concat(allNewsData, allBlogsData, allAnnouncmentsData)

    const linkTypeName = (type: string) => {
        switch (type) {
            case 'blog':
                return 'blog'
            case 'announcement':
                return 'tilkynningar'
            case 'news':
                return 'frettir'
            default:
                return 'frettir'
        }
    }

    const sortedItems = Object.entries(allItems)
        .sort((a, b) =>
            a[1]?.data.date < b[1]?.data.date
                ? 1
                : a[1]?.data.date === b[1]?.data.date
                ? 0
                : -1
        )
        .map((item) => item[1])
    const items = sortedItems.slice(0, 12).map((item, index: number) => {
        return (
            <MotionBox
                width="full"
                initial="hidden"
                viewport={{
                    once: true,
                }}
                whileInView="visible"
                transition={{
                    delay: 0.15 * ((index % 3) + 1),
                    duration: 0.6,
                }}
                variants={cardRevealUp}
                key={`${item?.data.title}-${index}`}
                align={'center'}
                flexDirection="column"
                justify={isMobile ? 'flexStart' : 'spaceBetween'}
                boxStyles={{
                    width: isMobile ? '95%' : '100%',
                    height: isMobile ? '100%' : 'unset',
                }}
            >
                <NewsModuleCard
                    key={'news-module-card-' + index}
                    heading={item?.data.title && item.data.title}
                    uid={item?.uid}
                    linkText={linkTypeName(item.type)}
                    url={item.url}
                    imageUrl={
                        item?.type !== 'announcement' &&
                        item?.data.image.url &&
                        item.data.image.url
                    }
                    imageAlt={
                        item?.type !== 'announcement' && item.data.image.alt
                            ? item.data.image.alt
                            : ''
                    }
                    imageOption={item?.type === 'blog' ? 'bottom' : 'top'}
                    color={item?.type === 'blog' ? 'primary' : 'tertiary'}
                    summary={item?.type === 'announcement' && item.data.summary}
                    lang={lang}
                />
            </MotionBox>
        )
    })
    return (
        <Box
            anchorid={slice.primary.anchor || undefined}
            boxStyles={{
                backgroundColor: parsePrismicBackgroundColor(
                    slice.primary.slicecolor
                ),
            }}
            paddingTop={isMobile ? 40 : 80}
        >
            <Container>
                <Box justify="center" align="center" flexDirection="column">
                    <HeadingContainer
                        variant="center"
                        tagline={slice.primary.tagline || undefined}
                        title={slice.primary.title || undefined}
                        color={parsePrismicHeadingColor(
                            slice.primary.slicecolor
                        )}
                        pb="4rem"
                    />
                </Box>

                {isMobile ? (
                    <Box paddingBottom={36}>
                        <Carousel
                            totalItems={items.length}
                            pageSize={{
                                mobile: 1,
                                tablet: 2,
                                desktop: 3,
                            }}
                            autoPlaySpeed={0}
                            color={parsePrismicHeadingColor(
                                slice.primary.slicecolor
                            )}
                            variant={'dots'}
                        >
                            {items}
                        </Carousel>
                    </Box>
                ) : (
                    <Box>
                        <Masonry
                            breakpointCols={breakpointColumnsObj}
                            className={styles.mymasonrygrid}
                            columnClassName={styles.mymasonrygridcolumn}
                        >
                            {items}
                        </Masonry>
                    </Box>
                )}
            </Container>
        </Box>
    )
}

export default DefaultNewsModule
