// import { Icon } from '../icon'
import { BreakPoints, theme } from '@orbit/ui/themes'
import cn from 'classnames'
import React from 'react'
import { Icon } from '../Icons'
import {
    pagignationDarkBackgroundButtonColor,
    pagination,
    paginationButton,
    paginationButtonColor,
    paginationDots,
    paginationText,
    paginationWrapper,
    seperator,
} from './Pagination.css'
import { DOTS, usePagination } from './usePagination'
import { useWindowSize } from 'react-use'

export interface PaginationProps {
    currentPage: number
    pageSize: number
    total: number
    variant?: 'numbers' | 'dots'
    color?: 'primary' | 'secondary' | 'tertiary' | 'accent'
    onPageChange: (page: number) => void
    darkBackground?: boolean
}

export const Pagination: React.FC<PaginationProps> = ({
    currentPage,
    pageSize,
    total,
    variant = 'numbers',
    color = 'tertiary',
    onPageChange,
    darkBackground,
}) => {
    const { width } = useWindowSize()
    const paginationRange = usePagination({
        page: currentPage,
        total,
        siblings: width < BreakPoints.mobile ? 0 : 1,
        pageSize,
        variant,
    })

    if (currentPage === 0 || paginationRange.length < 2) {
        return null
    }

    const onNext = () => {
        onPageChange(currentPage + 1)
    }

    const onPrevious = () => {
        onPageChange(currentPage - 1)
    }

    return (
        <div className={pagination}>
            <button
                disabled={currentPage === 1 ? true : false}
                type="button"
                onClick={onPrevious}
                className={cn(
                    darkBackground
                        ? pagignationDarkBackgroundButtonColor[color]
                        : paginationButtonColor[color],
                    paginationButton
                )}
                aria-label={`Go to page ${currentPage - 1}`}
            >
                {/* <Unicons.UilArrowLeft color="#006A77" size={'1.5rem'} /> */}
                <Icon
                    color={
                        currentPage !== 1
                            ? darkBackground
                                ? theme.colors.neutral[100]
                                : theme.colors.neutral[700]
                            : theme.colors[color][500]
                    }
                    icon="ArrowLeft"
                    size="medium"
                />
            </button>
            <ul className={paginationWrapper({ variant: variant })}>
                {/* Left navigation arrow */}
                {paginationRange.map((pageNumber, index) => {
                    // If the pageItem is a DOT, render the DOTS unicode character

                    // Render our Page Pills
                    return pageNumber === DOTS ? (
                        <li key={'dot' + index} className={seperator}>
                            {DOTS}
                        </li>
                    ) : (
                        <button
                            key={'dot' + index}
                            className={
                                variant === 'dots'
                                    ? paginationDots({
                                          active: pageNumber === currentPage,
                                          activecolor:
                                              pageNumber === currentPage
                                                  ? color
                                                  : 'none',
                                          inactivecolor:
                                              pageNumber !== currentPage
                                                  ? color
                                                  : 'none',
                                      })
                                    : paginationText({
                                          active: pageNumber === currentPage,
                                          variant: variant,
                                          color:
                                              pageNumber === currentPage
                                                  ? color
                                                  : 'none',
                                          darkBackground: darkBackground,
                                      })
                            }
                            tabIndex={0}
                            onClick={() =>
                                onPageChange(
                                    typeof pageNumber === 'number'
                                        ? pageNumber
                                        : parseInt(pageNumber)
                                )
                            }
                            aria-label={
                                pageNumber === currentPage
                                    ? `Current page, Page ${currentPage}`
                                    : `Go to page ${pageNumber}`
                            }
                            aria-current={
                                pageNumber === currentPage ? true : false
                            }
                        >
                            {variant === 'numbers' && pageNumber}
                        </button>
                    )
                })}
                {/*  Right Navigation arrow */}
            </ul>
            <button
                disabled={
                    currentPage === Math.ceil(total / pageSize) ? true : false
                }
                type="button"
                onClick={onNext}
                className={cn(
                    darkBackground
                        ? pagignationDarkBackgroundButtonColor[color]
                        : paginationButtonColor[color],
                    paginationButton
                )}
                aria-label={`Go to page ${currentPage + 1}`}
            >
                <Icon
                    color={
                        currentPage === Math.ceil(total / pageSize)
                            ? theme.colors[color][500]
                            : darkBackground
                            ? theme.colors.neutral[100]
                            : theme.colors[color][900]
                    }
                    icon="ArrowRight"
                    size="medium"
                />
            </button>
        </div>
    )
}
