import { defaultRichTextComponents } from '@orbit/prismic'
import { OrbitTheme } from '@orbit/ui/themes'
import { PrismicProvider } from '@prismicio/react'
import { AppProps } from 'next/app'

import Head from 'next/head'
import Link from 'next/link'
import '../styles/style.css'
import './app.css'
import { HeaderContextProvider, MegamenuContextProvider } from '@oribt/context'
import { MobilemenuContextProvider } from '@orbit/ui/prismic'
import { PrismicPreview } from '@prismicio/next'

function CustomApp({ Component, pageProps }: AppProps) {
    return (
        <PrismicProvider
            internalLinkComponent={({ href, children, ...props }) => (
                <Link scroll={false} href={href}>
                    <a {...props}>{children}</a>
                </Link>
            )}
            richTextComponents={defaultRichTextComponents}
        >
            <PrismicPreview repositoryName={process.env.PRISMIC_REPO_NAME}>
                <Head>
                    <title>Origo</title>
                </Head>
                <HeaderContextProvider>
                    <MegamenuContextProvider>
                        <MobilemenuContextProvider>
                            <div className={OrbitTheme.toString()}>
                                <Component {...pageProps} />
                            </div>
                        </MobilemenuContextProvider>
                    </MegamenuContextProvider>
                </HeaderContextProvider>
            </PrismicPreview>
        </PrismicProvider>
    )
}

export default CustomApp
