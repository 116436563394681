export const cardRevealUp = {
    hidden: { y: 40, opacity: 0 },
    visible: {
        y: 0,
        opacity: 1,

        // height: '100%',
    },
}

export const newsBlogCardRevealUp = {
    hidden: { y: 20, opacity: 0 },
    visible: {
        y: 0,
        opacity: 1,
        height: '100%',
    },
}

export const cardRevealRight = {
    hidden: { x: -20, opacity: 0 },
    visible: {
        y: 0,
        opacity: 1,
        // height: '100%',
    },
}
