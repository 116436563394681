import {
    parsePrismicHeadingColor,
    parsePrismicTextCardBackgroundColor,
} from '@orbit/prismic'
import {
    Box,
    Container,
    HeadingContainer,
    Icon,
    MotionBox,
    SpeakersCard,
} from '@orbit/ui/core'
import { BreakPoints, theme } from '@orbit/ui/themes'
import { useState, useEffect, useRef } from 'react'
import { useWindowSize } from 'react-use'
import { TSpeakersSlice, TSpeakersItemsDefault } from '../Speakers.types'
import * as styles from '../Speakers.css'
import { cardRevealUp } from '@orbit/utils/framer-motion'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore from 'swiper'

export const DefaultSpeakers = (slice: TSpeakersSlice) => {
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)
    const [swiperProgress, setSwiperProgress] = useState(0)
    const [forwardDisabled, setForwardDisabled] = useState(false)
    const [backwardDisabled, setBackwardDisabled] = useState(true)
    const swiperRef = useRef<SwiperCore>()
    const navigationPrevRef = useRef<HTMLDivElement>(null)
    const navigationNextRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (swiperProgress === 1) {
            setForwardDisabled(true)
        } else if (swiperRef.current?.isEnd) {
            setForwardDisabled(true)
        } else {
            setForwardDisabled(false)
        }
        if (swiperProgress === 0) {
            setBackwardDisabled(true)
        } else {
            setBackwardDisabled(false)
        }
    }, [swiperProgress, width])

    useEffect(() => {
        if (width < BreakPoints.tabletSmall) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    const speakersCard = (
        item: TSpeakersItemsDefault,
        index: number,
        size: 'small' | 'medium' | 'large'
    ) => {
        return (
            <MotionBox
                key={'speakers_card_' + index}
                extraClassName={styles.cardWrapper({
                    size: isMobile ? 'large' : size,
                    slider: slice.primary.position === 'Slide',
                })}
                initial={isMobile ? 'visible' : 'hidden'}
                viewport={{
                    once: true,
                }}
                whileInView="visible"
                transition={{
                    duration: 0.6,
                    delay: 0.15 * (index + 1),
                }}
                variants={cardRevealUp}
                width="full"
            >
                <SpeakersCard
                    variant={slice.variation}
                    image={item.image}
                    name={item.name}
                    jobtitle={item.jobtitle}
                    text={item.text}
                    linkedinLink={item.linkedinlink && item.linkedinlink.url}
                    linkedinLabel={item.linkedinlabel && item.linkedinlabel}
                    color={parsePrismicTextCardBackgroundColor(
                        slice.primary.slicecolor
                    )}
                    size={isMobile ? 'large' : size}
                />
            </MotionBox>
        )
    }
    return (
        <Box
            marginTop={isMobile ? 36 : 80}
            marginBottom={isMobile ? 36 : 80}
            flexDirection="column"
            anchorid={slice.primary.anchor || undefined}
        >
            <Container>
                <Box
                    align="center"
                    flexDirection="column"
                    boxStyles={{
                        marginBottom: isMobile ? '-2.5rem' : '-2rem',
                    }}
                >
                    <HeadingContainer
                        variant="center"
                        tagline={slice.primary.tagline || undefined}
                        title={slice.primary.title || undefined}
                        color={parsePrismicHeadingColor(
                            slice.primary.slicecolor
                        )}
                    />
                </Box>

                {(slice.items && slice.items.length !== 1 && isMobile) ||
                slice.primary.position === 'Slide' ? (
                    <Box flexDirection="row" width="full" position="relative">
                        <Box
                            extraClassName={styles.leftButtonPos}
                            display={
                                backwardDisabled || isMobile ? 'none' : 'block'
                            }
                        >
                            <button
                                disabled={backwardDisabled}
                                onClick={() => {
                                    swiperRef.current?.slidePrev()
                                    swiperRef.current &&
                                        setSwiperProgress(
                                            swiperRef.current?.progress
                                        )
                                }}
                                className={styles.buttons}
                                aria-label={'Backward'}
                                style={{ marginRight: '2rem' }}
                            >
                                <Icon
                                    color={
                                        backwardDisabled
                                            ? theme.colors.neutral[500]
                                            : theme.colors.primary[500]
                                    }
                                    icon="ArrowLeft"
                                    size="medium"
                                />
                            </button>
                        </Box>
                        <Swiper
                            slidesPerView={
                                isMobile ? 1.1 : slice.items.length > 2 ? 3 : 2
                            }
                            spaceBetween={0}
                            navigation={{
                                prevEl: navigationPrevRef.current,
                                nextEl: navigationNextRef.current,
                            }}
                            onSlideChange={(swiper) =>
                                setSwiperProgress(swiper.progress)
                            }
                            onSwiper={(swiper) => {
                                setSwiperProgress(swiper.progress)
                            }}
                            onBeforeInit={(swiper) => {
                                swiperRef.current = swiper
                            }}
                            grabCursor={true}
                            className={styles.swiperStyle}
                        >
                            {slice.items.map(
                                (
                                    item: TSpeakersItemsDefault,
                                    index: number
                                ) => {
                                    return (
                                        <SwiperSlide
                                            // Þarf að vera inline
                                            style={{
                                                // width: 'fit-content',
                                                padding: '1rem',
                                                paddingRight: '2rem',
                                                paddingLeft: '0',
                                                height: 'auto',
                                            }}
                                            key={
                                                'Swiper' +
                                                slice.primary.title +
                                                index
                                            }
                                        >
                                            {speakersCard(item, index, 'small')}
                                        </SwiperSlide>
                                    )
                                }
                            )}
                        </Swiper>
                        <Box
                            extraClassName={styles.rightButtonPos}
                            display={
                                forwardDisabled || isMobile ? 'none' : 'block'
                            }
                        >
                            <button
                                disabled={forwardDisabled}
                                onClick={() => {
                                    swiperRef.current?.slideNext()
                                    swiperRef.current &&
                                        setSwiperProgress(
                                            swiperRef.current?.progress
                                        )
                                }}
                                aria-label={'Forward'}
                                className={styles.buttons}
                            >
                                <Icon
                                    color={
                                        forwardDisabled
                                            ? theme.colors.neutral[600]
                                            : theme.colors.primary[500]
                                    }
                                    icon="ArrowRight"
                                    size="medium"
                                />
                            </button>
                        </Box>
                    </Box>
                ) : (
                    (slice.items.length === 1 || !isMobile) && (
                        <Box
                            flexDirection={isMobile ? 'column' : 'row'}
                            wrap={slice.items.length > 3 ? 'wrap' : 'nowrap'}
                            display="flex"
                            gap={'2.5rem'}
                            boxStyles={{ rowGap: '2.5rem' }}
                            extraClassName={
                                slice.items.length > 3
                                    ? styles.cardGridWrapper
                                    : ''
                            }
                        >
                            {slice.items.map(
                                (
                                    item: TSpeakersItemsDefault,
                                    index: number
                                ) => {
                                    const cardCount = slice.items?.length
                                    if (cardCount === 1) {
                                        return speakersCard(
                                            item,
                                            index,
                                            'large'
                                        )
                                    } else if (cardCount === 2) {
                                        return speakersCard(
                                            item,
                                            index,
                                            'medium'
                                        )
                                    } else {
                                        return speakersCard(
                                            item,
                                            index,
                                            'small'
                                        )
                                    }
                                }
                            )}
                        </Box>
                    )
                )}
            </Container>
        </Box>
    )
}

export default DefaultSpeakers
