import { Box, Text } from '@orbit/ui/core'
import { checkbox, refinementList } from '../SearchOverview.css'

interface AlgoliaRefinementListProps {
    items: { isRefined: boolean; label: string; value: string[] }[]
    refine: (...args: unknown[]) => unknown
    lang?: string
}

export const AlgoliaRefinementList = ({
    items,
    refine,
}: AlgoliaRefinementListProps) => {
    const itemsSorted = items.sort((a, b) =>
        a.label > b.label ? 1 : b.label > a.label ? -1 : 0
    )

    return (
        <div className={refinementList}>
            {itemsSorted !== undefined &&
                itemsSorted.length &&
                itemsSorted.map(({ isRefined, label, value }) => {
                    return (
                        <Box align="center" paddingRight={16} key={label}>
                            <input
                                className={checkbox}
                                onChange={() => {
                                    refine(value)
                                }}
                                checked={isRefined}
                                type="checkbox"
                                name={label}
                            />
                            <label htmlFor={label}>
                                <Text>{label}</Text>
                            </label>
                        </Box>
                    )
                })}
        </div>
    )
}
