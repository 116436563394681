import { Box, Button, Container } from '@orbit/ui/core'
import { BreakPoints } from '@orbit/ui/themes'
import { HeaderContext } from '@oribt/context'

import React, { useContext, useEffect, useRef, useState } from 'react'
import { useWindowSize } from 'react-use'
import { TTableOfContentsSlice } from '../TableOfContents.types'
import {
    ActiveNavStyleItemLink,
    ActiveTableOfContentWrapperStyle,
    CTAButtonWrapper,
    NavStyle,
    NavStyleItem,
    NavStyleItemLink,
    TableOfContentStyle,
    TableOfContentWrapperStyle,
} from './TableOfContents.css'

export const DefaultTableOfContents = (slice: TTableOfContentsSlice) => {
    const [ids, setIds] = useState<string[]>([])
    const [renderPrimaryButton, setRenderPrimaryButton] = useState(false)
    const [renderSecondaryButton, setRenderSecondaryButton] = useState(false)
    const [activeEntryId, setActiveEntryId] = useState<string>('')
    const tableOfContentsRef = useRef<HTMLDivElement>(null)
    const headerContext = useContext(HeaderContext)
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)
    useEffect(() => {
        if (width < BreakPoints.mobile) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    const observerContentHandler: IntersectionObserverCallback = (entries) => {
        const [entry] = entries
        if (entry.isIntersecting) {
            if (entry.intersectionRatio >= 0.9)
                setActiveEntryId(
                    entry.target.getAttribute('data-anchorid') || ''
                )
        }
    }

    const { primary } = slice

    useEffect(() => {
        let anchors: NodeListOf<Element>
        let io: IntersectionObserver
        if (window) {
            const observerOptions: IntersectionObserverInit = {
                root: null,
                rootMargin: '262px',
                threshold: 0.8,
            }

            io = new IntersectionObserver(
                observerContentHandler,
                observerOptions
            )

            anchors = window.document.querySelectorAll('[data-anchorid]')

            anchors.forEach((item: Element) => {
                io.observe(item)

                const value = item.getAttribute('data-anchorid')
                if (value !== null) {
                    setIds((prev) => [...prev, decodeURI(value)])
                }
            })

            const unsubscribe = anchors.forEach((item: Element) => {
                io.unobserve(item)
            })

            return unsubscribe
        }

        if (
            slice.primary.primaryButtonLink &&
            slice.primary.primaryButtonLink.url !== undefined &&
            slice.primary.primaryButtonText !== undefined
        ) {
            setRenderPrimaryButton(true)
        }

        if (
            slice.primary.secondaryButtonLink &&
            slice.primary.secondaryButtonLink.url !== undefined &&
            slice.primary.secondaryButtonText !== undefined
        ) {
            setRenderSecondaryButton(true)
        }

        return () => anchors.forEach((a) => io.unobserve(a))
    }, [
        slice.primary.primaryButtonLink,
        slice.primary.primaryButtonText,
        slice.primary.secondaryButtonLink,
        slice.primary.secondaryButtonText,
    ])

    useEffect(() => {
        if (tableOfContentsRef.current && activeEntryId) {
            const elements = tableOfContentsRef.current.querySelectorAll(`a`)
            elements.forEach((e) => e.classList.remove(ActiveNavStyleItemLink))
            const activeItem = tableOfContentsRef.current.querySelector(
                `a[href="#${activeEntryId}"]`
            )
            if (activeItem) activeItem.classList.add(ActiveNavStyleItemLink)
        }
    }, [activeEntryId, tableOfContentsRef])

    useEffect(() => {
        const headerScrollHandler = () => {
            if (tableOfContentsRef.current) {
                const distance =
                    tableOfContentsRef.current?.getBoundingClientRect().top

                if (distance <= 84) {
                    headerContext.setHeaderVisible(false)
                    tableOfContentsRef.current.classList.add(
                        ActiveTableOfContentWrapperStyle
                    )
                } else {
                    tableOfContentsRef.current.classList.remove(
                        ActiveTableOfContentWrapperStyle
                    )
                    headerContext.setHeaderVisible(true)
                }
            }
        }

        if (window && tableOfContentsRef.current) {
            window.addEventListener('scroll', headerScrollHandler)
        }
        return () => window.removeEventListener('scroll', headerScrollHandler)
    }, [tableOfContentsRef, headerContext])

    const scrollHandler = (e: React.MouseEvent | undefined, id: string) => {
        if (e) e.preventDefault()
        if (id !== null && window) {
            const header = window.document.querySelector('header')
            const headerHeight = header ? header.clientHeight : 0
            const element = window.document.body.querySelector<HTMLElement>(
                `[data-anchorid="${id.substring(1, id.length)}"]`
            )
            if (element) {
                const scrollDistance =
                    element.getBoundingClientRect().top -
                    headerHeight -
                    100 +
                    window.scrollY

                !isMobile
                    ? window.scrollTo({
                          behavior: 'smooth',
                          top: scrollDistance,
                      })
                    : element.scrollIntoView({
                          behavior: 'smooth',
                          block: 'nearest',
                      })
            }
        }
    }

    useEffect(() => {
        if (window) {
            const hash = decodeURI(window.location.hash)
            if (hash) scrollHandler(undefined, hash)
        }
    })

    return (
        <Box
            ref={tableOfContentsRef}
            extraClassName={TableOfContentWrapperStyle}
            ariaHidden={true}
            tabIndex={-1}
        >
            <Container>
                <Box
                    extraClassName={TableOfContentStyle}
                    align="center"
                    justify={
                        renderPrimaryButton || renderSecondaryButton
                            ? 'spaceBetween'
                            : 'center'
                    }
                >
                    <Box width="full">
                        <ul className={NavStyle}>
                            {ids.length &&
                                ids.map((id, i) => (
                                    <li className={NavStyleItem} key={i}>
                                        {
                                            <a
                                                onClick={(e) =>
                                                    scrollHandler(e, `#${id}`)
                                                }
                                                className={NavStyleItemLink}
                                                href={`#${id}`}
                                            >
                                                {id}
                                            </a>
                                        }
                                    </li>
                                ))}
                        </ul>
                    </Box>
                    {(renderPrimaryButton || renderSecondaryButton) && (
                        <Box extraClassName={CTAButtonWrapper}>
                            {renderPrimaryButton && (
                                <Button
                                    color="primary"
                                    href={primary.primaryButtonLink.url}
                                    newTab={primary.openNewTab}
                                >
                                    {primary.primaryButtonText}
                                </Button>
                            )}
                            {renderSecondaryButton && (
                                <Button
                                    color="primary"
                                    variant="ghost"
                                    href={primary.secondaryButtonLink.url}
                                    newTab={primary.openSecondNewTab}
                                >
                                    {primary.secondaryButtonText}
                                </Button>
                            )}
                        </Box>
                    )}
                </Box>
            </Container>
        </Box>
    )
}

export default DefaultTableOfContents
