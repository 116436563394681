import React from 'react'
import { TProductCategoriesSlice } from './ProductCategories.types'
import DefaultProductCategories from './variations/Default'

const ProductCategories: React.FC<{ slice: TProductCategoriesSlice }> = ({
    slice,
}) => {
    switch (slice.variation) {
        case 'default': {
            return DefaultProductCategories(slice)
        }

        default:
            return null
    }
}

export default ProductCategories
