import { Box } from '@orbit/ui/core'
import AllEventsOverview from '../AllEventsOverview'
import {
    TEventsOverviewContext,
    TEventsOverviewSliceAllEvents,
} from '../EventsOverview.types'
import NoEvents from '../NoEvents'
import useSwr from 'swr'
import OldEventOverview from '../OldEventsOverview'
const fetcher = (url: string) => fetch(url).then((res) => res.json())

export const AllEvents = (
    slice: TEventsOverviewSliceAllEvents,
    context: TEventsOverviewContext
) => {
    const { data: allEvents } = useSwr(
        `/api/events?lang=${context.lang}`,
        fetcher
    )

    const allEventsData = allEvents
    if (!allEventsData) {
        return null
    }

    return (
        <Box
            flexDirection="column"
            anchorid={slice.primary.anchor || undefined}
        >
            {allEventsData === undefined || allEventsData?.length === 0 ? (
                <NoEvents />
            ) : (
                <AllEventsOverview
                    slice={slice}
                    allEventsData={allEventsData}
                />
            )}
            {slice && <OldEventOverview slice={slice} context={context} />}
        </Box>
    )
}

export default AllEvents
