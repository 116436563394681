import { Box } from '../../Box'
import { Heading } from '../../Heading'
import { OrbitImage } from '../../Image'
import { Text } from '../../Text'
import * as styles from './SolutionCard.css'
export interface SolutionCardProps {
    url: string
    color: string
    type: 'circle' | 'square' | 'default'
    title: string
    imageAlt?: string
    imageUrl?: string
    tag: string
    summary: string
    buttonColor: string
    headingLevel?: 'h2' | 'h3' | 'h4'
}
export interface SolutionCardFields {
    fields: SolutionCardProps
}

export const SolutionCard: React.FC<SolutionCardProps> = (props) => {
    return props.type === 'circle' ? (
        <CircularImageSolution fields={props} />
    ) : props.type === 'square' ? (
        <SquareImageSolution fields={props} />
    ) : (
        <DefaultSolution fields={props} />
    )
}

const CircularImageSolution: React.FC<SolutionCardFields> = ({ fields }) => {
    return (
        <a
            href={fields.url}
            className={`${styles.linkWrapper} `}
            style={{ width: '100%', height: '100%' }}
        >
            <Box
                flexDirection="column"
                extraClassName={styles.boxWithCircleImageWrapper}
            >
                <Box
                    boxStyles={{
                        alignSelf: 'flex-start',
                        flexDirection: 'column',
                        height: '100%',
                    }}
                >
                    {fields.title && (
                        <Heading
                            type={
                                fields.headingLevel ? fields.headingLevel : 'h3'
                            }
                            text={fields.title}
                            style={{
                                marginTop: 0,
                                marginBottom: '0.625rem',
                                fontSize: '2rem',
                            }}
                        />
                    )}
                </Box>
                {fields.imageUrl && (
                    <Box marginTop={16} width="full">
                        <OrbitImage
                            containerclass={styles.imageWrapper}
                            src={fields.imageUrl || ''}
                            alt={fields.imageAlt}
                            layout="fill"
                        />
                    </Box>
                )}
                <Box
                    extraClassName={styles.TaglineLink}
                    marginTop={24}
                    boxStyles={{
                        color: fields.buttonColor,
                        // marginTop: 'auto',
                    }}
                >
                    {fields.tag}
                </Box>
            </Box>
        </a>
    )
}
const SquareImageSolution: React.FC<SolutionCardFields> = ({ fields }) => {
    return (
        <a href={fields.url} className={styles.linkWrapper}>
            {fields.imageUrl && (
                <OrbitImage
                    src={fields.imageUrl || ''}
                    alt={fields.imageAlt || ''}
                    layout="fill"
                    objectFit="cover"
                    objectPosition="center"
                    height={240}
                />
            )}
            <Box
                padding={5}
                flexDirection="column"
                boxStyles={{ height: '50%' }}
            >
                <Box
                    boxStyles={{
                        height: '100%',
                        alignSelf: 'flex-start',
                        flexDirection: 'column',
                    }}
                >
                    <Heading
                        type={fields.headingLevel ? fields.headingLevel : 'h3'}
                        text={fields.title}
                        style={{
                            marginTop: 0,
                            marginBottom: '0.625rem',
                            fontSize: '2rem',
                        }}
                    />
                </Box>
                <Box
                    extraClassName={styles.TaglineLink}
                    boxStyles={{
                        color: fields.buttonColor,
                        marginTop: 'auto',
                    }}
                >
                    {fields.tag}
                </Box>
            </Box>
        </a>
    )
}
const DefaultSolution: React.FC<SolutionCardFields> = ({ fields }) => {
    return (
        <a href={fields.url} className={styles.linkWrapper}>
            <Box
                flexDirection="column"
                boxStyles={{
                    alignSelf: 'flex-start',
                    padding: '1.5rem',
                    height: '100%',
                }}
            >
                <Heading
                    type={fields.headingLevel ? fields.headingLevel : 'h3'}
                    text={fields.title}
                    style={{
                        marginTop: 0,
                        marginBottom: '0.625rem',
                        fontSize: '2rem',
                    }}
                />
                {fields.summary && <Text size="medium">{fields.summary}</Text>}

                {fields.tag && (
                    <Box
                        extraClassName={styles.ButtonLink}
                        boxStyles={{
                            color: fields.buttonColor,
                            backgroundColor: fields.color,
                            marginTop: 'auto',
                        }}
                    >
                        {fields.tag}
                    </Box>
                )}
            </Box>
        </a>
    )
}
