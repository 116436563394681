import React from 'react'
import { ContextType, TRelatedContentSlice } from './RelatedContent.types'
import { DefaultRelatedContent, RelatedSolutions } from './variations'
import RelatedServices from './variations/RelatedServices'

const RelatedContent: React.FC<{
    slice: TRelatedContentSlice
    context: ContextType
}> = ({ slice, context }) => {
    switch (slice.variation) {
        case 'default': {
            return DefaultRelatedContent(slice, context)
        }
        case 'relatedSolutions': {
            return RelatedSolutions(slice, context)
        }
        case 'relatedServices': {
            return RelatedServices(slice, context)
        }
        default:
            return null
    }
}

export default RelatedContent
