import { Text } from '@orbit/ui/core'
import { PrismicRichText } from '@prismicio/react'
import { RichTextField } from '@prismicio/types'
import Link from 'next/link'
import * as styles from '../TextCards.css'

export interface SerializerProps {
    slicecolor: 'primary' | 'secondary' | 'tertiary' | 'accent' | 'black'
    text: RichTextField | null | undefined
}

export const PrismicTextCardSerializer: React.FC<SerializerProps> = ({
    slicecolor,
    text,
}) => {
    return (
        <PrismicRichText
            field={text}
            components={{
                list: ({ children }) => (
                    <ul className={styles.bullet}>{children}</ul>
                ),
                listItem: ({ children }) => (
                    <li
                        className={`${styles.listItem({
                            colors: slicecolor ? slicecolor : 'black',
                        })}`}
                    >
                        <Text> {children} </Text>
                    </li>
                ),
                hyperlink: ({ text, node }) => {
                    return node.data.url ? (
                        <Link href={node.data.url}>
                            <a
                                className={styles.hyperlink({
                                    color: slicecolor ? slicecolor : 'black',
                                })}
                                href={node.data.url}
                                target={
                                    node.data.link_type === 'Web'
                                        ? node.data.target
                                        : ''
                                }
                                rel="noopener noreferrer"
                            >
                                {text}
                            </a>
                        </Link>
                    ) : null
                },
            }}
        />
    )
}

export default PrismicTextCardSerializer
