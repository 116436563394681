export const itemsLength = (size: number) => {
    switch (size) {
        case 4:
            return 4
        case 3:
            return 3
        case 2:
            return 2
        case 1:
            return 1
        default:
            return 5
    }
}


