import React from 'react'
import DefaultImageGallery from './variations/Default'
import { TImageGallerySlices } from './ImageGallery.types'

const ImageGallery: React.FC<{ slice: TImageGallerySlices }> = ({ slice }) => {
    switch (slice.variation) {
        case 'default': {
            return DefaultImageGallery(slice)
        }
        default:
            return null
    }
}

export default ImageGallery
