import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useRef } from 'react'
import {
    BottomBar,
    ModalBackdropStyle,
    ModalClose,
    ModalCloseBar,
    TopBar,
} from './Modal.css'
import FocusTrap from 'focus-trap-react'

type IModalProps = {
    open: boolean
    children: React.ReactNode
    onClose: () => void
}

export const Modal: React.FC<IModalProps> = ({ open, children, onClose }) => {
    const modalRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        const keyDownHandler = (e: KeyboardEvent) =>
            e.code === 'Escape' && onClose()
        document.addEventListener('keydown', keyDownHandler)

        // clean up
        return () => {
            document.removeEventListener('keydown', keyDownHandler)
        }
    }, [])

    useEffect(() => {
        if (modalRef.current) modalRef.current.focus()
    }, [modalRef])

    return (
        <AnimatePresence>
            {open && (
                <FocusTrap>
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ delay: 0.2 }}
                        className={ModalBackdropStyle}
                        onClick={(e) => {
                            return e.target === e.currentTarget && onClose()
                        }}
                        ref={modalRef}
                    >
                        <button onClick={onClose} className={ModalClose}>
                            <span className={`${ModalCloseBar} ${TopBar}`} />
                            <span className={`${ModalCloseBar} ${BottomBar}`} />
                        </button>
                        {children}
                    </motion.div>
                </FocusTrap>
            )}
        </AnimatePresence>
    )
}
