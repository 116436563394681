import { theme } from './lib/contract/contract.css'
import OrbitTheme from './lib/orbit'
export type Theme = typeof theme
export type Colors = keyof typeof theme.colors

export { TimianTheme } from './lib/timian'
export { KjarniTheme } from './lib/kjarni'
export { OrbitTheme } from './lib/orbit'
export { theme } from './lib/contract/PageLayoutContract.css'

export { OrbitColors } from './lib/orbit'
export { TimianColors } from './lib/timian'
export { KjarniColors } from './lib/kjarni'

interface BreakPoints {
    mobileSmall: number
    mobile: number
    medium: number
    tabletSmall: number
    tablet: number
    desktop: number
}

// export const BreakPoints = {
//     smallMobile: '30rem',
//     mobile: '48rem',
//     tabletSmall: '62rem',
//     tablet: '75rem',
//     desktop: '90rem',
// } as BreakPoints

export const BreakPoints = {
    mobileSmall: 576,
    mobile: 768,
    tabletSmall: 992,
    medium: 1024,
    tablet: 1200,
    desktop: 1400,
} as unknown as BreakPoints

export default OrbitTheme
