import { cardRevealUp } from '@orbit/utils/framer-motion'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect } from 'react'
import { ArrowLink } from '../ArrowLink'
import { Box, MotionBox } from '../Box'
import { Text } from '../Text'
import * as styles from './Table.css'

export type TTableHeadProps = {
    items: string[]
}

export type TTableRowDefault = {
    items: string[]
}

export type TTableRowWithLink = {
    items: string[]
    link: string
    linkText: string
}

export type TTableData<TWithLink = boolean> = TWithLink extends true
    ? {
          withLink: TWithLink
          tableHead: TTableHeadProps
          tableRows: TTableRowWithLink[]
      }
    : {
          withLink?: TWithLink
          tableHead: TTableHeadProps
          tableRows: TTableRowDefault[]
      }

export interface TTableProps {
    name?: string
    tableData: TTableData
    boldRow?: boolean
    tableDescription?: React.ReactNode
    backgroundFilled?: boolean
    color?: 'primary' | 'secondary' | 'tertiary' | 'accent'
}

interface TTableHeaderProps {
    items: string[]
    color?: 'primary' | 'secondary' | 'tertiary' | 'accent'
    name?: string
    // withLink?: boolean
}

interface TTableRows {
    items: string[]
    link?: string
    linkText?: string
}

interface TTableRowsProps {
    tableRows: TTableRows[]
    backgroundFilled?: boolean
    color?: 'primary' | 'secondary' | 'tertiary' | 'accent'
    // withLink?: boolean
}

const TableHead: React.FC<TTableHeaderProps> = ({
    items,
    color = 'primary',
    name,
    // withLink = 'false',
}) => {
    return (
        <thead
            className={styles.TableHead({
                color: color,
            })}
        >
            <tr
                style={{
                    width: '100%',
                    justifyContent: 'space-between',
                }}
            >
                {name ? (
                    <th className={styles.Cell({ type: 'head' })}>{name}</th>
                ) : (
                    items.map((h, i) => (
                        <th
                            key={i}
                            className={styles.Cell({
                                type: 'head',
                            })}
                        >
                            {h}
                        </th>
                    ))
                )}
                {/* {withLink && <th></th> } */}
            </tr>
        </thead>
    )
}

const TableRows: React.FC<TTableRowsProps> = ({
    tableRows,
    color,
    backgroundFilled,
    // withLink = 'false',
}) => {
    return (
        <tbody style={{ width: '100%' }}>
            {tableRows.map((row, index) => {
                return (
                    <AnimatePresence key={index}>
                        <motion.tr
                            initial="hidden"
                            viewport={{
                                once: true,
                            }}
                            whileInView="visible"
                            transition={{
                                delay: 0.1,
                                duration: 0.6,
                            }}
                            variants={cardRevealUp}
                        >
                            {row.items.map((item, i) => {
                                return (
                                    <td
                                        key={i}
                                        className={styles.Cell({
                                            type: 'row',
                                            backgroundFilled: backgroundFilled,
                                            color: color,
                                        })}
                                    >
                                        {item}
                                    </td>
                                )
                            })}
                            {/* {withLink && (  row.link && row.linkText &&
                                <td>
                                    {' '}
                                    <ArrowLink
                                        color="neutral900"
                                        link={row.link}
                                        linkText={row.linkText}
                                        size="small"
                                    />
                                </td>
                            )} */}
                        </motion.tr>
                    </AnimatePresence>
                )
            })}
        </tbody>
    )
}

const DefaultTable: React.FC<TTableProps> = ({
    tableData,
    backgroundFilled = false,
    boldRow,
    color = 'primary',
    name,
    tableDescription,
}) => {
    const tableRows = tableData.tableRows as TTableRowDefault[]
    useEffect(() => {}, [tableData])
    return (
        <>
            <Box
                extraClassName={styles.TableWrapper({
                    backgroundColor: color,
                    backgroundFilled: backgroundFilled,
                })}
            >
                <table className={styles.TableStyle({})}>
                    <TableHead
                        items={tableData.tableHead.items}
                        color={color}
                        name={name}
                    />
                    <TableRows
                        tableRows={tableRows}
                        color={color}
                        backgroundFilled={backgroundFilled}
                        // withLink={false}
                    />
                </table>
            </Box>
            {tableDescription && (
                <MotionBox
                    initial="hidden"
                    viewport={{
                        once: true,
                    }}
                    whileInView="visible"
                    transition={{
                        delay: 1,
                        duration: 0.6,
                    }}
                    variants={cardRevealUp}
                >
                    <Text size="small" style={{ fontStyle: 'italic' }}>
                        {tableDescription}
                    </Text>
                </MotionBox>
            )}
        </>
    )
}

const WithLinkTable: React.FC<TTableProps> = ({
    tableData,
    backgroundFilled = false,
    boldRow,
    color = 'primary',
    name,
    tableDescription,
}) => {
    const tableRows = tableData.tableRows as TTableRowWithLink[]

    return (
        <>
            <Box
                extraClassName={styles.TableWrapper({
                    backgroundColor: color,
                    backgroundFilled: backgroundFilled,
                })}
            >
                <table className={styles.TableStyle({})}>
                    <TableHead
                        items={tableData.tableHead.items}
                        color={color}
                        name={name}
                    />
                    <TableRows
                        tableRows={tableRows}
                        color={color}
                        backgroundFilled={backgroundFilled}
                        // withLink={true}
                    />

                    {tableRows.map((row) => {
                        return (
                            <Box
                                extraClassName={styles.TableRowWrapper({
                                    backgroundColor: color,
                                    backgroundFilled: backgroundFilled,
                                    boldRow: boldRow,
                                })}
                            >
                                <>
                                    {row.items.map((item, i) => {
                                        return (
                                            <Box key={i} flex={1}>
                                                {item}
                                            </Box>
                                        )
                                    })}
                                    <ArrowLink
                                        color="neutral900"
                                        link={row.link}
                                        linkText={row.linkText}
                                        size="small"
                                    />
                                </>
                            </Box>
                        )
                    })}
                </table>
            </Box>
            {tableDescription && (
                <Box>
                    <Text size="small" style={{ fontStyle: 'italic' }}>
                        {tableDescription}
                    </Text>
                </Box>
            )}
        </>
    )
}

export const Table: React.FC<TTableProps> = (props) => {
    // switch (props.tableData.withLink) {
    //     case true:
    //         return <WithLinkTable {...props} />
    // default:
    return <DefaultTable {...props} />
}
