import { useEffect, useState } from 'react'
import {
    parsePrismicLightBackgroundColor,
    parsePrismicHeadingColor,
    stringToSlug,
    parsePrismicBackgroundColor,
    parsePrismicHighlightColor,
    parseSliceSpace,
    parsePrismicDarkColor,
} from '@orbit/prismic'
import { useWindowSize } from 'react-use'
import { BreakPoints } from '@orbit/ui/themes'
import { PrismicRichText } from '@prismicio/react'
import { TVideoContext, TVideoSliceWithText } from '../Video.types'
import {
    Box,
    Button,
    Container,
    Heading,
    MotionBox,
    OrbitVideo,
} from '@orbit/ui/core'
import * as styles from './Video.css'
import { cardRevealUp } from '@orbit/utils/framer-motion'

export const WithText = (
    slice: TVideoSliceWithText,
    context: TVideoContext
) => {
    const { primary } = slice
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        if (width < BreakPoints.medium) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    if (isMobile) return MobileWithText(slice)

    return (
        <MotionBox
            anchorid={slice.primary.anchor || undefined}
            boxStyles={{
                backgroundColor: primary.backgroundfilled
                    ? parsePrismicLightBackgroundColor(
                          primary.slicecolor || 'primary'
                      )
                    : 'white',
                justifyContent: 'center',
            }}
            paddingBottom={parseSliceSpace(context.type, isMobile)}
            paddingTop={parseSliceSpace(context.type, isMobile)}
            initial={isMobile ? 'visible' : 'hidden'}
            viewport={{
                once: true,
            }}
            whileInView="visible"
            transition={{
                delay: 0.2,
                duration: 0.6,
            }}
            variants={cardRevealUp}
        >
            <Container>
                <Box
                    id={stringToSlug(String(primary.title))}
                    extraClassName={styles.videoGripWrapper}
                    boxStyles={{ gap: '3rem', alignItems: 'unset' }}
                    width={'full'}
                    flexDirection={
                        primary.textposition === 'Right side'
                            ? 'rowReverse'
                            : 'row'
                    }
                >
                    <Box
                        flexDirection={'column'}
                        width={'half'}
                        justify={'spaceBetween'}
                    >
                        <Box flexDirection={'column'}>
                            {primary.tagline && (
                                <Heading
                                    paddingBottom={2}
                                    my={1}
                                    type={'h5'}
                                    text={primary.tagline}
                                    color={parsePrismicHeadingColor(
                                        primary.slicecolor || 'primary'
                                    )}
                                />
                            )}
                            {primary.title && (
                                <Heading
                                    my={0}
                                    paddingBottom={3}
                                    type={'h2'}
                                    highlightColor={parsePrismicHighlightColor(
                                        primary.slicecolor || 'primary'
                                    )}
                                    highlightStart={
                                        primary.highlightstart &&
                                        primary.highlightstart - 1
                                    }
                                    highlightEnd={
                                        primary.highlightend &&
                                        primary.highlightend - 1
                                    }
                                    text={primary.title}
                                />
                            )}
                            <PrismicRichText
                                field={primary.text}
                                components={{
                                    hyperlink: ({ text, node }) => (
                                        <a
                                            style={{
                                                color: parsePrismicDarkColor(
                                                    primary.slicecolor ||
                                                        'primary'
                                                ),
                                                fontWeight: 600,
                                                textDecoration: 'none',
                                            }}
                                            href={node.data.url}
                                            target={
                                                node.data.link_type === 'Web'
                                                    ? node.data.target
                                                    : ''
                                            }
                                            rel="noopener noreferrer"
                                        >
                                            {text}
                                        </a>
                                    ),
                                }}
                            />
                        </Box>

                        {primary.buttonlink?.url && (
                            <Box
                                width={'full'}
                                align={'flexEnd'}
                                boxStyles={{ height: '100%' }}
                                marginTop={'auto'}
                            >
                                <Button
                                    variant={
                                        primary.buttonlabel ? 'ghost' : 'icon'
                                    }
                                    href={primary.buttonlink.url || ''}
                                    color={parsePrismicHeadingColor(
                                        primary.slicecolor || 'primary'
                                    )}
                                    icon={primary.buttonicon}
                                    ariaLabel={
                                        primary.buttonlabel
                                            ? undefined
                                            : primary.buttonicon
                                    }
                                    newTab={slice.primary.openNewTab}
                                >
                                    {primary.buttonlabel && primary.buttonlabel}
                                </Button>
                            </Box>
                        )}
                    </Box>
                    <Box width={'half'} boxStyles={{ height: '100%' }}>
                        {primary.video && (
                            <OrbitVideo
                                title={`Myndband um ${slice.primary.title}`}
                                url={primary.video}
                                color={
                                    primary.slicecolor
                                        ? parsePrismicBackgroundColor(
                                              primary.slicecolor
                                          )
                                        : ''
                                }
                            />
                        )}
                    </Box>
                </Box>
            </Container>
        </MotionBox>
    )
}

const MobileWithText = (slice: TVideoSliceWithText) => {
    const { primary } = slice
    return (
        <Box
            anchorid={slice.primary.anchor || undefined}
            boxStyles={{
                backgroundColor: primary.backgroundfilled
                    ? parsePrismicLightBackgroundColor(
                          primary.slicecolor || 'primary'
                      )
                    : 'white',
                justifyContent: 'center',
            }}
        >
            <Container>
                <Box
                    id={stringToSlug(String(primary.title))}
                    paddingBottom={48}
                    paddingTop={48}
                >
                    <Box
                        flexDirection={'column'}
                        flex={1}
                        justify={'spaceBetween'}
                        gap={'2rem'}
                    >
                        <Box flexDirection="column" gap="1rem">
                            {primary.tagline && (
                                <Heading
                                    my={1}
                                    type={'h5'}
                                    text={primary.tagline}
                                    style={{
                                        textTransform: 'uppercase',
                                    }}
                                    color={parsePrismicHeadingColor(
                                        primary.slicecolor || 'primary'
                                    )}
                                />
                            )}
                            {primary.title && (
                                <Heading
                                    my={0}
                                    paddingBottom={2}
                                    type={'h2'}
                                    highlightColor={parsePrismicHighlightColor(
                                        primary.slicecolor || 'primary'
                                    )}
                                    highlightStart={
                                        primary.highlightstart &&
                                        primary.highlightstart - 1
                                    }
                                    highlightEnd={
                                        primary.highlightend &&
                                        primary.highlightend - 1
                                    }
                                    text={primary.title}
                                />
                            )}
                        </Box>

                        <Box flex={1} alignSelf="stretch">
                            {primary.video && (
                                <OrbitVideo
                                    title={`Myndband um ${slice.primary.title}`}
                                    url={primary.video}
                                    color={
                                        primary.slicecolor
                                            ? parsePrismicBackgroundColor(
                                                  primary.slicecolor
                                              )
                                            : ''
                                    }
                                />
                            )}
                        </Box>

                        <PrismicRichText
                            field={primary.text}
                            components={{
                                hyperlink: ({ text, node }) => (
                                    <a
                                        style={{
                                            color: parsePrismicDarkColor(
                                                primary.slicecolor || 'primary'
                                            ),
                                            fontWeight: 600,
                                            textDecoration: 'none',
                                        }}
                                        href={node.data.url}
                                        target={
                                            node.data.link_type === 'Web'
                                                ? node.data.target
                                                : ''
                                        }
                                        rel="noopener noreferrer"
                                    >
                                        {text}
                                    </a>
                                ),
                            }}
                        />

                        {primary.buttonlink.url && (
                            <Box width={'full'}>
                                <Button
                                    variant={
                                        primary.buttonlabel ? 'ghost' : 'icon'
                                    }
                                    href={primary.buttonlink.url || ''}
                                    color={parsePrismicHeadingColor(
                                        primary.slicecolor || 'primary'
                                    )}
                                    icon={primary.buttonicon}
                                    ariaLabel={
                                        primary.buttonlabel
                                            ? undefined
                                            : primary.buttonicon
                                    }
                                    newTab={slice.primary.openNewTab}
                                >
                                    {primary.buttonlabel && primary.buttonlabel}
                                </Button>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

export default WithText
