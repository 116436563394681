import React from 'react'
import { DefaultFooterItem } from './variations'
import { TFooterItemSlice } from './FooterItem.types'
const FooterItem: React.FC<{ slice: TFooterItemSlice; index: number }> = ({
    slice,
    index,
}) => {
    switch (slice.variation) {
        case 'default': {
            return DefaultFooterItem(slice, index)
        }
        default:
            return null
    }
}
export default FooterItem
