import React from 'react'
import { DefaultTextBlock, TextBlockWithTableOfContents } from './variations'
import { TTextBlockContext, TTextBlockSlices } from './TextBlock.types'

const TextBlock: React.FC<{
    slice: TTextBlockSlices
    context: TTextBlockContext
}> = ({ slice, context }) => {
    switch (slice.variation) {
        case 'default': {
            return DefaultTextBlock(slice, context)
        }
        case 'withTableOfContents': {
            return TextBlockWithTableOfContents(slice)
        }
        default:
            return null
    }
}

export default TextBlock
