import { useEffect, useRef, useState } from 'react'
import { useWindowSize } from 'react-use'
import { BreakPoints, theme } from '@orbit/ui/themes'
import SwiperCore, { Autoplay, Pagination } from 'swiper'

import {
    TLogoSliderItemsDefault,
    TLogoSliderSliceSlider,
} from '../LogoSlider.types'
import { parsePrismicDarkColor, parsePrismicHeadingColor } from '@orbit/prismic'
import { Box, Container, HeadingContainer, OrbitImage } from '@orbit/ui/core'
import { Swiper, SwiperSlide } from 'swiper/react'
import * as styles from '../LogoSlider.css'
import { PrismicRichText } from '@prismicio/react'

export const SliderLogoSlider = (slice: TLogoSliderSliceSlider) => {
    const { width } = useWindowSize()
    const [isTablet, setIsTablet] = useState(false)
    const [isMobile, setIsMobile] = useState(false)
    const swiperRef = useRef<SwiperCore>()
    const navigationPrevRef = useRef<HTMLDivElement>(null)
    const navigationNextRef = useRef<HTMLDivElement>(null)
    SwiperCore.use([Pagination, Autoplay])

    useEffect(() => {
        if (width < BreakPoints.mobile) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    useEffect(() => {
        if (width < BreakPoints.tabletSmall) {
            return setIsTablet(true)
        }
        setIsTablet(false)
    }, [width])

    const numberOfSlidesPerView = () => {
        if (width > BreakPoints.tablet) {
            return 5
        }

        if (width > BreakPoints.tabletSmall) {
            return 4
        }

        if (width > BreakPoints.mobile) {
            return 3
        }
        if (width > BreakPoints.mobileSmall) {
            return 2
        }
        return 2
    }
    return (
        <Box anchorid={slice.primary.anchor || undefined}>
            <Container>
                <Box
                    flexDirection="column"
                    width="full"
                    justify="center"
                    align="center"
                    paddingTop={36}
                    paddingBottom={isTablet ? 36 : 48}
                >
                    <Box align={'center'} flexDirection="column">
                        <HeadingContainer
                            tagline={slice.primary.tagline || ''}
                            title={slice.primary.title || ''}
                            color={parsePrismicHeadingColor(
                                slice.primary.slicecolor
                            )}
                            pb={'1rem'}
                        />
                        <PrismicRichText
                            field={slice.primary.text}
                            components={{
                                hyperlink: ({ text, node }) => (
                                    <a
                                        style={{
                                            color: parsePrismicDarkColor(
                                                slice.primary.slicecolor
                                            ),
                                            fontWeight: 600,
                                            textDecoration: 'none',
                                        }}
                                        href={node.data.url}
                                        target={
                                            node.data.link_type === 'Web'
                                                ? node.data.target
                                                : ''
                                        }
                                        rel="noopener noreferrer"
                                    >
                                        {text}
                                    </a>
                                ),
                            }}
                        />
                    </Box>
                    {slice.items.length && (
                        <Box
                            display="block"
                            flexDirection="column"
                            align="center"
                            position="relative"
                            width={'full'}
                        >
                            <Swiper
                                slidesPerView={numberOfSlidesPerView()}
                                spaceBetween={0}
                                navigation={{
                                    prevEl: navigationPrevRef.current,
                                    nextEl: navigationNextRef.current,
                                }}
                                onBeforeInit={(swiper) => {
                                    swiperRef.current = swiper
                                }}
                                grabCursor={true}
                                className={styles.swiperStyle}
                                loop={true}
                                autoplay={{ delay: 3000 }}
                                speed={500}
                                lazy={false}
                            >
                                {slice.items.map(
                                    (
                                        item: TLogoSliderItemsDefault,
                                        index: number
                                    ) => (
                                        <SwiperSlide
                                            // Þarf að vera inline
                                            style={{
                                                width: 'fit-content',
                                                height: 'auto',
                                            }}
                                            key={
                                                'Swiper' +
                                                slice.primary.title +
                                                index
                                            }
                                        >
                                            <Box
                                                width={'full'}
                                                justify="center"
                                                key={index}
                                                py={isTablet ? 3 : 5}
                                            >
                                                <OrbitImage
                                                    src={item.logo.url || ''}
                                                    alt={item.logo.alt || ''}
                                                    height={80}
                                                    width={isMobile ? 120 : 160}
                                                    layout="fill"
                                                    objectFit="contain"
                                                    objectPosition="center"
                                                    quality={50}
                                                    loading="eager"
                                                />
                                            </Box>
                                        </SwiperSlide>
                                    )
                                )}
                            </Swiper>
                        </Box>
                    )}
                </Box>
            </Container>
        </Box>
    )
}

export default SliderLogoSlider
