import { ReactNode, useEffect, useMemo, useState } from 'react'
import ReactCarousel, { ButtonGroupProps } from 'react-multi-carousel'
import { Box } from '../Box'
import { Pagination } from '../Pagination'
import * as styles from './Carousel.css'
import 'react-multi-carousel/lib/styles.css'
import { useWindowSize } from 'react-use'

interface CarouselProps {
    totalItems: number
    pageSize?: {
        mobile: number
        tablet: number
        desktop: number
    }
    itemsToSlide?: {
        mobile: number
        tablet: number
        desktop: number
    }
    color?: 'primary' | 'secondary' | 'tertiary' | 'accent'
    autoPlaySpeed?: number
    children: ReactNode
    paginationStyle?: React.CSSProperties
    tabletWidth?: number
    mobileWidth?: number
    partialVisibilityGutter?: number
    margin?: boolean
    variant?: 'numbers' | 'dots'
    itemMargin?: boolean
}

export const Carousel: React.FC<CarouselProps> = ({
    totalItems,
    pageSize = {
        mobile: 1,
        tablet: 2,
        desktop: 3,
    },
    itemsToSlide = {
        mobile: 1,
        tablet: 1,
        desktop: 1,
    },
    children,
    autoPlaySpeed = undefined,
    color = 'primary',
    variant = 'numbers',
    paginationStyle,
    tabletWidth = 920,
    mobileWidth = 768,
    partialVisibilityGutter = 40,
    margin = false,
    itemMargin = false,
}) => {
    const { width } = useWindowSize()
    const [currentPageSize, setCurrentPageSize] = useState<number>(1)
    useEffect(() => {
        if (width < mobileWidth || totalItems === pageSize.mobile) {
            setCurrentPageSize(pageSize.mobile)
            return
        }
        if (width < tabletWidth || totalItems === pageSize.tablet) {
            setCurrentPageSize(pageSize.tablet)
            return
        }
        setCurrentPageSize(pageSize.desktop)
    }, [width, pageSize, totalItems])

    const responsive = useMemo(() => {
        return {
            mobile: {
                breakpoint: { max: mobileWidth, min: 0 },
                items: pageSize.mobile,
                slidesToSlide: itemsToSlide.mobile,
                partialVisibilityGutter: partialVisibilityGutter,
            },
            tablet: {
                breakpoint: { max: tabletWidth, min: mobileWidth },
                items: pageSize.tablet,
                slidesToSlide: itemsToSlide.tablet,
                partialVisibilityGutter: partialVisibilityGutter,
            },

            desktop: {
                breakpoint: { max: 9999, min: tabletWidth },
                items: currentPageSize,
                slidesToSlide: itemsToSlide.desktop,
            },
        }
    }, [currentPageSize])

    const ButtonGroup = ({
        next,
        previous,
        goToSlide,
        total,
        ...rest
    }: ButtonGroupProps & { total: number }) => {
        const { carouselState } = rest
        const { currentSlide } = carouselState || { currentSlide: 0 }

        return (
            <div
                style={paginationStyle && paginationStyle}
                className={styles.sliderDots}
            >
                <Pagination
                    currentPage={Math.ceil(currentSlide / currentPageSize + 1)}
                    variant={variant}
                    color={color}
                    total={total}
                    pageSize={currentPageSize}
                    onPageChange={(page) => {
                        goToSlide && goToSlide((page - 1) * currentPageSize)
                    }}
                />
            </div>
        )
    }
    return (
        <Box extraClassName={styles.carouselBox}>
            <Box
                width="full"
                paddingBottom={totalItems <= currentPageSize ? 0 : 64}
                position="relative"
                marginBottom={margin ? 80 : 0}
            >
                <ReactCarousel
                    additionalTransfrom={0}
                    arrows={false}
                    autoPlaySpeed={autoPlaySpeed}
                    centerMode={false}
                    swipeable={true}
                    showDots={false}
                    draggable={true}
                    renderButtonGroupOutside={true}
                    deviceType={'mobile'}
                    ssr={true}
                    partialVisible
                    infinite={false}
                    autoPlay={false}
                    responsive={responsive}
                    containerClass={styles.carouselContainer}
                    sliderClass={styles.carouselSlider({
                        onteitem: totalItems === 1,
                        itemMargin: itemMargin,
                    })}
                    customButtonGroup={<ButtonGroup total={totalItems} />}
                >
                    {children}
                </ReactCarousel>
            </Box>
        </Box>
    )
}
