import React from 'react'
import { TOverviewContext, TOverviewSlice } from './Overview.types'
import {
    BlogOverview,
    DefaultOverview,
    InvestorsNews,
    AnnouncementOverview,
} from './variations'

const Overview: React.FC<{
    slice: TOverviewSlice
    context: TOverviewContext
}> = ({ slice, context }) => {
    switch (slice.variation) {
        case 'default': {
            return DefaultOverview(slice, context)
        }
        case 'blogOverview': {
            return BlogOverview(slice, context)
        }
        case 'announcementOverview': {
            return AnnouncementOverview(slice, context)
        }
        case 'investorNews': {
            return InvestorsNews(slice, context)
        }
        default:
            return null
    }
}

export default Overview
