import {
    Box,
    Button,
    Container,
    Heading,
    HeadingContainer,
} from '@orbit/ui/core'
import { theme } from '@orbit/ui/themes'
import {
    DateField,
    FilledImageFieldImage,
    FilledLinkToDocumentField,
    KeyTextField,
    LinkField,
    PrismicDocument,
    Query,
    SelectField,
} from '@prismicio/types'
import useSwr from 'swr'
import { NewsCard } from '../Card'
import { BlogCard } from '../Card/Blog'
import * as styles from './SeeMore.css'

export interface SeeMoreProps {
    type: 'blog' | 'news'
    tags?: string | string[]
    id: string
}

type TAuthor = {
    name: KeyTextField
    image: FilledImageFieldImage
}

enum SliceColorsEnum {
    Blue = 'Blue',
    Green = 'Green',
    Orange = 'Orange',
    Pink = 'Pink',
}

type TBlogData = {
    url: LinkField
    image: FilledImageFieldImage
    title: KeyTextField<'filled'>
    summary: KeyTextField
    author: any //RelationField<'Author', 'is', TAuthor> todo: add relation field
    slicecolor: SelectField<SliceColorsEnum, 'filled'>
}

type TNewsData = {
    url: FilledLinkToDocumentField
    image: FilledImageFieldImage
    title: KeyTextField<'filled'>
    date: DateField
    summary: KeyTextField
    slicecolor: SelectField<SliceColorsEnum, 'filled'>
}

const fetcher = (url: string) => fetch(url).then((res) => res.json())

export const SeeMore: React.FC<SeeMoreProps> = ({ type, tags, id }) => {
    const { data, error } = useSwr<Query<PrismicDocument>>(
        `/api/contentByTag/seemore?tags=${tags}${
            id && `&id=${id}${type && `&type=${type}`}&pageSize=3&pageCount=1`
        }`,
        fetcher
    )

    if (error || !data) return null
    return (
        <Box
            flexDirection="column"
            alignSelf="center"
            width="full"
            boxStyles={{
                backgroundColor:
                    type === 'blog'
                        ? theme.colors.tertiary[300]
                        : theme.colors.primary[300],
            }}
        >
            <Container>
                <Box align="center" flexDirection="column" paddingTop={40}>
                    <HeadingContainer
                        tagline={type === 'blog' ? 'BLOGG' : 'FRÉTTIR'}
                        title={'Þú gætir einnig haft áhuga á'}
                        variant="center"
                        color={type === 'blog' ? 'tertiary' : 'primary'}
                    />
                    <Box extraClassName={styles.gridWrapper}>
                        {data.results.map((item) => {
                            if (type === 'blog') {
                                const blogData = item.data as TBlogData
                                return (
                                    item.url && (
                                        <BlogCard
                                            url={item.url}
                                            imageAlt={
                                                blogData.image.alt || undefined
                                            }
                                            imageUrl={blogData.image.url}
                                            title={blogData.title}
                                            summary={blogData.summary || ''}
                                            author={
                                                blogData.author.data &&
                                                blogData.author.data.name
                                            }
                                            authorimageUrl={
                                                blogData.author.data?.image
                                                    ?.url || undefined
                                            }
                                            authorimageAlt={
                                                blogData.author.data?.image
                                                    ?.alt || undefined
                                            }
                                            slicecolor={
                                                blogData.slicecolor || 'Blue'
                                            }
                                        />
                                    )
                                )
                            } else {
                                const newsData = item.data as TNewsData
                                return (
                                    item.url && (
                                        <NewsCard
                                            url={item.url}
                                            imageUrl={newsData.image.url}
                                            imageAlt={newsData.image.alt || ''}
                                            date={newsData.date as string}
                                            title={newsData.title}
                                            summary={newsData.summary || ''}
                                            slicecolor={newsData.slicecolor}
                                        />
                                    )
                                )
                            }
                        })}
                    </Box>
                    <Box paddingTop={36} paddingBottom={80}>
                        <Button
                            size="small"
                            variant="filled"
                            color={type === 'blog' ? 'tertiary' : 'primary'}
                            href={type == 'blog' ? '/blogg' : '/frettir'}
                        >
                            {type == 'blog'
                                ? 'Sjá öll blogg'
                                : 'Sjá allar fréttir'}
                        </Button>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}
