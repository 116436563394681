import * as React from 'react'

export interface IconProps {
    width?: string
    height?: string
    color?: string
}

export const Youtube: React.FC<IconProps> = ({
    width,
    height,
    color = 'black',
}) => {
    return (
        <svg
            width={width || '24'}
            height={height || '24'}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M23 9.71C23.0494 8.27865 22.7364 6.85802 22.09 5.58C21.6514 5.0556 21.0427 4.70171 20.37 4.58C17.5875 4.32752 14.7935 4.22404 12 4.27C9.21664 4.22196 6.4327 4.3221 3.66 4.57C3.11182 4.66972 2.60451 4.92684 2.2 5.31C1.3 6.14 1.2 7.56 1.1 8.76C0.954908 10.9176 0.954908 13.0824 1.1 15.24C1.12893 15.9154 1.22949 16.5858 1.4 17.24C1.52057 17.7451 1.76452 18.2123 2.11 18.6C2.51726 19.0035 3.03638 19.2752 3.6 19.38C5.75591 19.6461 7.92821 19.7564 10.1 19.71C13.6 19.76 16.67 19.71 20.3 19.43C20.8775 19.3316 21.4112 19.0595 21.83 18.65C22.11 18.3699 22.3191 18.0271 22.44 17.65C22.7976 16.5526 22.9733 15.4041 22.96 14.25C23 13.69 23 10.31 23 9.71ZM9.74 14.85V8.66L15.66 11.77C14 12.69 11.81 13.73 9.74 14.85Z"
                fill={color}
            />
        </svg>
    )
}
