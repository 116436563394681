import React, { useEffect, useState } from 'react'
import {
    TTextCardsContext,
    TTextCardsItemWithIcon,
    TTextCardsSliceSliderWithIcon,
} from '../TextCards.types'
import {
    parsePrismicLightBackgroundColor,
    parsePrismicHeadingColor,
    parsePrismicTextCardBackgroundColor,
    parsePrismicHighlightColor,
    stringToPascalCase,
    parseSliceSpace,
    parsePrismicBackgroundColor,
    parsePrismicDarkColor,
} from '@orbit/prismic'
import { BreakPoints, theme } from '@orbit/ui/themes'
import { useWindowSize } from 'react-use'
import {
    Box,
    Carousel,
    Container,
    HeadingContainer,
    MotionBox,
    OrbitTextCard,
    Text,
} from '@orbit/ui/core'
import { PrismicRichText } from '@prismicio/react'
import PrismicTextCardSerializer from './TextCardSerializer'
import { cardRevealUp } from '@orbit/utils/framer-motion'

export const SliderWithIconTextCards = (
    slice: TTextCardsSliceSliderWithIcon,
    context: TTextCardsContext
) => {
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        if (width < BreakPoints.tabletSmall) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    return (
        <Box
            anchorid={slice.primary.anchor || undefined}
            paddingTop={parseSliceSpace(context.type, isMobile)}
            // paddingBottom={parseSliceSpace(context.type, isMobile)}
            flexDirection="column"
            boxStyles={{
                alignItems: 'center',
                backgroundColor: slice.primary.backgroundfilled
                    ? parsePrismicBackgroundColor(slice.primary.slicecolor)
                    : theme.colors.neutral[100],
            }}
        >
            <Container>
                <Box
                    flexDirection="column"
                    align={slice.primary.textposition ? 'center' : 'flexStart'}
                >
                    <HeadingContainer
                        variant={
                            slice.primary.textposition || isMobile
                                ? 'center'
                                : 'left'
                        }
                        tagline={slice.primary.tagline || undefined}
                        title={slice.primary.title || undefined}
                        color={parsePrismicHeadingColor(
                            slice.primary.slicecolor
                        )}
                        pb={slice.primary.text ? '1.5rem' : undefined}
                    />
                    <Box
                        boxStyles={{
                            maxWidth: '53.5rem',
                            paddingBottom: '2.5rem',
                        }}
                    >
                        {slice.primary.text && (
                            <PrismicRichText
                                field={slice.primary.text}
                                components={{
                                    paragraph: ({ children }) => (
                                        <Text
                                            textAlign={
                                                slice.primary.textposition
                                                    ? 'center'
                                                    : 'start'
                                            }
                                            my={0}
                                            size="large"
                                        >
                                            {children}
                                        </Text>
                                    ),
                                    hyperlink: ({ text, node }) => (
                                        <a
                                            style={{
                                                color: parsePrismicDarkColor(
                                                    slice.primary.slicecolor
                                                ),
                                                fontWeight: 600,
                                                textDecoration: 'none',
                                            }}
                                            href={node.data.url}
                                            target={
                                                node.data.link_type === 'Web'
                                                    ? node.data.target
                                                    : ''
                                            }
                                            rel="noopener noreferrer"
                                        >
                                            {text}
                                        </a>
                                    ),
                                }}
                            />
                        )}
                    </Box>
                </Box>
                <Box>
                    <Carousel
                        totalItems={slice.items.length}
                        pageSize={{
                            mobile: 1,
                            tablet: 2,
                            desktop: 3,
                        }}
                        autoPlaySpeed={0}
                        color={parsePrismicHeadingColor(
                            slice.primary.slicecolor
                        )}
                        variant={'dots'}
                        margin
                        itemMargin
                    >
                        {slice.items?.map(
                            (item: TTextCardsItemWithIcon, index: number) => {
                                return (
                                    <MotionBox
                                        key={'textcards-withicon' + index}
                                        boxStyles={{
                                            marginLeft: '1rem',
                                            marginRight: '1rem',
                                            height:
                                                slice.primary.height === 'auto'
                                                    ? 'auto'
                                                    : '100%',
                                        }}
                                        initial={
                                            isMobile ? 'visible' : 'hidden'
                                        }
                                        viewport={{
                                            once: true,
                                        }}
                                        whileInView="visible"
                                        transition={{
                                            duration: 0.6,
                                            delay: 0.15 * ((index % 3) + 1),
                                        }}
                                        variants={cardRevealUp}
                                    >
                                        <OrbitTextCard
                                            textCardColor={parsePrismicTextCardBackgroundColor(
                                                slice.primary.slicecolor
                                            )}
                                            isWhite={
                                                slice.primary.slicecolor ===
                                                'White'
                                                    ? true
                                                    : false
                                            }
                                            title={
                                                item.cardtitle
                                                    ? item.cardtitle
                                                    : ''
                                            }
                                            content={
                                                <PrismicTextCardSerializer
                                                    text={item.cardtext}
                                                    slicecolor={
                                                        slice.primary
                                                            .backgroundfilled
                                                            ? parsePrismicHeadingColor(
                                                                  slice.primary
                                                                      .slicecolor
                                                              )
                                                            : 'black'
                                                    }
                                                />
                                            }
                                            color={
                                                slice.primary.backgroundfilled
                                                    ? 'white'
                                                    : slice.primary.slicecolor
                                            }
                                            link={item.buttonlink.url}
                                            linkText={
                                                item.buttonlabel
                                                    ? item.buttonlabel
                                                    : ''
                                            }
                                            linkType={
                                                slice.primary.buttonvariation
                                            }
                                            backgroundFilled={
                                                slice.primary.backgroundfilled
                                            }
                                            displayButton={
                                                slice.primary
                                                    .buttonvariation !== 'None'
                                                    ? true
                                                    : false
                                            }
                                            buttonTextColor={parsePrismicLightBackgroundColor(
                                                slice.primary.slicecolor
                                            )}
                                            buttonColor={parsePrismicHighlightColor(
                                                slice.primary.slicecolor
                                            )}
                                            icon={
                                                slice.variation ===
                                                'sliderWithIcon'
                                                    ? item.icon &&
                                                      stringToPascalCase(
                                                          item.icon
                                                      )
                                                    : ''
                                            }
                                            iconColor={parsePrismicHighlightColor(
                                                slice.primary.slicecolor
                                            )}
                                            headingLevel={
                                                slice.primary.tagline &&
                                                slice.primary.title
                                                    ? 'h4'
                                                    : !slice.primary.title &&
                                                      !slice.primary.tagline
                                                    ? 'h2'
                                                    : 'h3'
                                            }
                                            newTab={item.openNewTab}
                                        />
                                    </MotionBox>
                                )
                            }
                        )}
                    </Carousel>
                </Box>
            </Container>
        </Box>
    )
}

export default SliderWithIconTextCards
