import {
    parsePrismicBackgroundColor,
    parsePrismicHeadingColor,
} from '@orbit/prismic'
import { Box, Container, Heading, Icon, MotionBox, Text } from '@orbit/ui/core'
import { BreakPoints, theme } from '@orbit/ui/themes'
import { cardRevealUp } from '@orbit/utils/framer-motion'
import { useEffect, useState } from 'react'
import { useWindowSize } from 'react-use'
import { TLogoCardsSlices } from '../LogoCards.types'
import * as styles from './LogoCards.css'

export const DefaultLogoCards = (slice: TLogoCardsSlices) => {
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)
    useEffect(() => {
        if (width < BreakPoints.mobile) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    const BlobDownload = (blob: Blob, name: string) => {
        const blobUrl = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = blobUrl
        link.download = name

        document.body.appendChild(link)
        link.dispatchEvent(
            new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window,
            })
        )
        document.body.removeChild(link)
    }

    const CreateBlob = (url: RequestInfo, name: string) => {
        const options = {}

        fetch(url, options)
            .then((res) => res.blob())
            .then((blob) => {
                BlobDownload(blob, name)
            })
    }

    return (
        <Box
            anchorid={slice.primary.anchor || undefined}
            paddingBottom={80}
            paddingTop={80}
            boxStyles={{
                backgroundColor: parsePrismicBackgroundColor(
                    slice.primary.slicecolor
                ),
            }}
        >
            <Container>
                <Box flexDirection={'column'} width={'full'}>
                    <Box
                        flexDirection={'column'}
                        width={'full'}
                        align="center"
                        boxStyles={{
                            marginBottom: !isMobile ? '5rem' : '3rem',
                        }}
                    >
                        {slice.primary.tagline && (
                            <Heading
                                my={0}
                                paddingBottom={3}
                                type={'h6'}
                                text={slice.primary.tagline}
                                color={parsePrismicHeadingColor(
                                    slice.primary.slicecolor
                                )}
                            />
                        )}
                        {slice.primary.title && (
                            <Heading
                                my={0}
                                type={'h2'}
                                text={slice.primary.title}
                            />
                        )}
                    </Box>
                    <Box width={'full'}>
                        <Box extraClassName={styles.logoCardsGridWrapper}>
                            {slice.items.map((item, i) => (
                                <MotionBox
                                    key={i}
                                    flexDirection={'column'}
                                    align="center"
                                    boxStyles={{
                                        backgroundColor: !item.cardcolor
                                            ? 'white'
                                            : theme.colors.neutral[700],
                                        cursor: 'pointer',
                                    }}
                                    onClick={() =>
                                        CreateBlob(
                                            item.medialink?.url as RequestInfo,
                                            item.medialink?.name || ''
                                        )
                                    }
                                    // extraClassName={styles.itemBox}
                                    initial={isMobile ? 'visible' : 'hidden'}
                                    viewport={{
                                        once: true,
                                    }}
                                    whileInView="visible"
                                    transition={{
                                        delay: 0.15 * ((i % 3) + 1),
                                        duration: 0.6,
                                    }}
                                    variants={cardRevealUp}
                                >
                                    <Box paddingTop={64}>
                                        <img
                                            alt={item.cardimage.alt || ''}
                                            src={item.cardimage.url || ''}
                                            width={'100%'}
                                            height={'100%'}
                                        />
                                    </Box>
                                    <Box
                                        boxStyles={{
                                            marginTop: '3.5rem',
                                            marginBottom: '2.5rem',
                                        }}
                                    >
                                        <Text
                                            size="small"
                                            my={0}
                                            style={{
                                                color: item.cardcolor
                                                    ? 'white'
                                                    : 'black',
                                                fontWeight: 600,
                                                letterSpacing: '0.09375rem',
                                                paddingRight: '1.125rem',
                                            }}
                                        >
                                            {item.cardtext}
                                        </Text>
                                        <Icon
                                            size="small"
                                            color={
                                                item.cardcolor
                                                    ? 'white'
                                                    : 'black'
                                            }
                                            icon={'Download'}
                                        ></Icon>
                                    </Box>
                                </MotionBox>
                            ))}
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

export default DefaultLogoCards
