import Link from 'next/link'
import { Box } from '../../Box'
import { Heading } from '../../Heading'
import { OrbitImage } from '../../Image'
import { Text } from '../../Text'
import * as styles from './ItemCard.css'

export interface ItemCardProps {
    url: string
    slicecolor: 'primary' | 'secondary' | 'tertiary' | 'accent'
    datatype: string
    image?: string
    imagealt?: string
    title: string
    summary: string
    headingLevel?: 'h2' | 'h3' | 'h4'
}

export const ItemCard: React.FC<ItemCardProps> = ({
    datatype,
    url,
    slicecolor,
    image,
    imagealt,
    title,
    summary,
    headingLevel = 'h3',
}) => {
    return (
        <Link href={url}>
            <a className={styles.linkWrapper}>
                <OrbitImage
                    alt={imagealt || ''}
                    src={image || ''}
                    layout="fill"
                    width={'100%'}
                    height={204}
                    sizes="(max-width: 776px) 100vw, (max-width: 918px) 50vw, 33vw"
                />

                <Box padding={5} flexDirection="column">
                    <Heading
                        my={0}
                        text={title}
                        type={headingLevel}
                        style={{ fontSize: '2rem' }}
                    />

                    <Box wrap={'wrap'}></Box>
                    {summary && <Text size={'medium'}>{summary}</Text>}
                </Box>
                <Box
                    align={'flexEnd'}
                    paddingLeft={16}
                    paddingBottom={16}
                    boxStyles={{ marginTop: 'auto' }}
                >
                    <Text textClass={styles.tagStyle({ color: slicecolor })}>
                        {datatype === 'blog' ? 'BLOGG' : 'FRÉTTIR'}
                    </Text>
                </Box>
            </a>
        </Link>
    )
}
