import React from 'react'
import DefaultBulletList from './variations/Default'
import { TBulletListSlices } from './BulletList.types'

const HeroBanner: React.FC<{ slice: TBulletListSlices }> = ({ slice }) => {
    switch (slice.variation) {
        case 'default': {
            return DefaultBulletList(slice)
        }
        default:
            return null
    }
}

export default HeroBanner
