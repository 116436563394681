import { BreakPoints, theme } from '@orbit/ui/themes'
import Link from 'next/link'
import { useState, useEffect } from 'react'
import useWindowSize from 'react-use/lib/useWindowSize'
import { Box } from '../../Box'
import { Heading } from '../../Heading'
import { OrbitImage } from '../../Image'
import { Text } from '../../Text'
import * as styles from './NewsModuleCard.css'

export interface NewsModuleCardProps {
    heading: string
    content?: string
    link?: string
    imageAlt: string
    imageUrl?: string
    imageHeight?: number | string
    imageOption?: 'top' | 'bottom' | 'none'
    color: 'primary' | 'secondary' | 'tertiary'
    linkText?: string
    summary?: string
    url?: string
    uid?: string
    lang: 'is' | 'en-gb'
}

export const NewsModuleCard: React.FC<NewsModuleCardProps> = ({
    heading,
    content,
    uid,
    color,
    url,
    imageUrl,
    imageAlt,
    linkText = 'blogg',
    imageOption = 'top',
    imageHeight = 240,
    summary,
    lang = 'is',
}) => {
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        if (width < BreakPoints.mobileSmall) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    const translateLinkText = (text: string) => {
        switch (text) {
            case 'blog':
                return lang === 'is' ? 'Blogg' : 'Blog'
            case 'announcement':
                return lang === 'is' ? 'Tilkynningar' : 'Announcement'
            case 'fréttir':
            case 'frettir':
                return lang === 'is' ? 'Fréttir' : 'News'
            default:
                return lang === 'is' ? 'Fréttir' : 'News'
        }
    }

    return (
        <Link href={url || ''}>
            <a
                style={{
                    textDecoration: 'none',
                    height: '100%',
                    width: '100%',
                }}
            >
                <Box
                    marginBottom={isMobile ? 0 : 36}
                    flexDirection="column"
                    justify={isMobile ? 'flexStart' : 'spaceBetween'}
                    boxStyles={{
                        background:
                            linkText === 'tilkynningar'
                                ? theme.colors.neutral[900]
                                : theme.colors.neutral[100],

                        width: isMobile ? '95%' : '100%',
                        height: isMobile ? '100%' : 'unset',
                    }}
                    extraClassName={styles.Container}
                >
                    <Box
                        width={'full'}
                        boxStyles={{
                            display: imageOption == 'top' ? 'flex' : 'none',
                            height:
                                isMobile && linkText !== 'tilkynningar'
                                    ? '100%'
                                    : 'unset',
                        }}
                    >
                        {imageUrl && (
                            <OrbitImage
                                layout="fill"
                                width={'100%'}
                                height={imageHeight}
                                src={imageUrl}
                                alt={imageAlt}
                                sizes="(max-width: 575px) 100vw, (max-width: 900px) 50vw, 33vw"
                            />
                        )}
                    </Box>
                    <Box flexDirection="column">
                        <Box
                            boxStyles={{
                                paddingLeft: '24px',
                                paddingRight: '24px',
                                color:
                                    linkText === 'tilkynningar'
                                        ? theme.colors.neutral[100]
                                        : theme.colors.neutral[900],
                            }}
                            width={'full'}
                        >
                            <Box flexDirection={'column'}>
                                <Heading
                                    my={0}
                                    paddingTop={4}
                                    type="h3"
                                    text={heading}
                                    style={{
                                        color:
                                            linkText === 'tilkynningar'
                                                ? theme.colors.neutral[100]
                                                : theme.colors.neutral[900],
                                    }}
                                />
                                <Text
                                    style={{ color: theme.colors.neutral[100] }}
                                >
                                    {summary}
                                </Text>
                            </Box>
                        </Box>
                        <Box
                            boxStyles={{
                                paddingLeft: '1.5rem',
                                paddingRight: '1.5rem',
                                marginTop: '-1.5rem',
                                color:
                                    linkText === 'tilkynningar'
                                        ? theme.colors.neutral[100]
                                        : theme.colors.neutral[900],
                            }}
                            extraClassName={styles.ContentStyle}
                        >
                            {content}
                        </Box>
                    </Box>
                    <Box
                        width={'full'}
                        justify={'center'}
                        paddingLeft={24}
                        paddingRight={24}
                        boxStyles={{
                            display: imageOption == 'bottom' ? 'flex' : 'none',
                            height: isMobile ? '100%' : 'unset',
                        }}
                    >
                        {imageUrl && (
                            <OrbitImage
                                layout="fill"
                                width={'100%'}
                                height={imageHeight}
                                src={imageUrl}
                                alt={imageAlt}
                                sizes="(max-width: 575px) 100vw, (max-width: 900px) 50vw, 33vw"
                            />
                        )}
                    </Box>
                    <Box
                        padding={5}
                        justify="flexEnd"
                        boxStyles={{ marginTop: isMobile ? 'auto' : 0 }}
                    >
                        {linkText && uid && (
                            <span
                                className={styles.TaglineLink}
                                style={{
                                    color:
                                        linkText === 'tilkynningar'
                                            ? theme.colors.neutral[100]
                                            : theme.colors[color][500],
                                }}
                            >
                                {linkText === 'frettir'
                                    ? translateLinkText('fréttir')
                                    : translateLinkText(linkText)}
                            </span>
                        )}
                    </Box>
                </Box>
            </a>
        </Link>
    )
}
