import React from 'react'

import { TAirtableSlice } from './Airtable.types'
import { DefaultAirtable, WithTabsAirtable } from './variations'

const Airtable: React.FC<{ slice: TAirtableSlice }> = ({ slice }) => {
    switch (slice.variation) {
        case 'default': {
            return DefaultAirtable(slice)
        }
        case 'withTabs': {
            return WithTabsAirtable(slice)
        }
        default: {
            return null
        }
    }
}

export default Airtable
