import {
    Box,
    Container,
    Heading,
    SubpageHeroBanner,
    Text,
} from '@orbit/ui/core'
import { theme } from '@orbit/ui/themes'
import moment from 'moment'
import 'moment/locale/is'
import { PrismicRichText } from '@prismicio/react'
import * as styles from './News.css'
import { NewsDataProps } from '../../types'

export const News: React.FC<{ news: NewsDataProps }> = ({ news }) => {
    moment.locale('is')
    return (
        <Box flexDirection="column" alignSelf="center">
            <SubpageHeroBanner
                imageUrl={news.image.url || undefined}
                imageAlt={news.image.alt || undefined}
                title={news.title || undefined}
                date={news.date}
                introtext={news.introtext || undefined}
                slicecolor={theme.colors.primary[400]}
                type="News"
            />
            <Container>
                {news.content && (
                    <Box
                        margin={'auto'}
                        flexDirection="column"
                        boxStyles={{ maxWidth: '53.5rem' }}
                    >
                        {news.content && (
                            <PrismicRichText
                                field={news.content}
                                components={{
                                    paragraph: ({ children }) => (
                                        <Text size="large">{children}</Text>
                                    ),
                                    heading5: ({ text }) =>
                                        text && (
                                            <Heading
                                                color={'tertiary'}
                                                type={'h5'}
                                                text={text}
                                                style={{
                                                    marginTop: '0.5rem',
                                                    marginBottom: '0.5rem',
                                                }}
                                            />
                                        ),
                                    heading4: ({ text }) =>
                                        text && (
                                            <Heading
                                                type={'h4'}
                                                text={text}
                                                style={{
                                                    marginTop: '0.5rem',
                                                    marginBottom: '0.5rem',
                                                }}
                                            />
                                        ),
                                    heading3: ({ text }) =>
                                        text && (
                                            <Heading
                                                type={'h3'}
                                                text={text}
                                                style={{
                                                    marginTop: '0.5rem',
                                                    marginBottom: '0.5rem',
                                                }}
                                            />
                                        ),
                                    heading2: ({ text }) =>
                                        text && (
                                            <Heading
                                                type={'h2'}
                                                text={text}
                                                style={{
                                                    marginTop: '0.5rem',
                                                    marginBottom: '0.5rem',
                                                }}
                                            />
                                        ),
                                    hyperlink: ({ text, node }) => (
                                        <a
                                            target={
                                                node.data.link_type === 'Web'
                                                    ? node.data.target
                                                    : ''
                                            }
                                            rel="noopener noreferrer"
                                            href={node.data.url}
                                            style={{
                                                color: theme.colors
                                                    .primary[600],
                                                fontWeight: 600,
                                                textDecoration: 'none',
                                            }}
                                        >
                                            {text}
                                        </a>
                                    ),
                                    image: ({ node }) => (
                                        <>
                                            <img
                                                src={node.url}
                                                alt={node.alt || ''}
                                                style={{
                                                    display: 'flex',
                                                    alignSelf: 'center',
                                                    width: '100%',
                                                }}
                                            ></img>

                                            <text
                                                style={{
                                                    color: theme.colors
                                                        .primary[600],
                                                    fontSize: '0.75rem',
                                                    fontWeight: 600,
                                                    paddingTop: '1rem',
                                                    paddingBottom: node.alt
                                                        ? '1rem'
                                                        : 0,
                                                }}
                                            >
                                                {(node.alt && node.alt) || ''}
                                            </text>
                                        </>
                                    ),
                                    list: ({ children }) => (
                                        <ul className={styles.bullet}>
                                            {children}
                                        </ul>
                                    ),
                                    listItem: ({ children }) => (
                                        <li
                                            className={`${styles.listItem({
                                                colors: 'Blue',
                                            })}`}
                                        >
                                            <Text size="large">
                                                {' '}
                                                {children}{' '}
                                            </Text>
                                        </li>
                                    ),
                                }}
                            />
                        )}
                    </Box>
                )}
            </Container>
        </Box>
    )
}
