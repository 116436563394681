import React from 'react'
import {
    DefaultTextCards,
    WithIconTextCards,
    SliderTextCards,
    SliderWithIconTextCards,
    MiniTextCards,
} from './variations'
import { TTextCardsContext, TTextCardsSlices } from './TextCards.types'

const TextCards: React.FC<{
    slice: TTextCardsSlices
    context: TTextCardsContext
}> = ({ slice, context }) => {
    switch (slice.variation) {
        case 'default': {
            return DefaultTextCards(slice, context)
        }
        case 'withIcon': {
            return WithIconTextCards(slice, context)
        }
        case 'slider': {
            return SliderTextCards(slice, context)
        }
        case 'sliderWithIcon': {
            return SliderWithIconTextCards(slice, context)
        }
        case 'mini': {
            return MiniTextCards(slice, context)
        }
        default:
            return null
    }
}

export default TextCards
