import {
    parsePrismicHeadingColor,
    parsePrismicDarkColor,
    parsePrismicColorName,
} from '@orbit/prismic'
import { Box, Button, Container, Heading, Text } from '@orbit/ui/core'
import { BreakPoints } from '@orbit/ui/themes'
import { PrismicRichText } from '@prismicio/react'
import { useState, useEffect } from 'react'
import { useWindowSize } from 'react-use'
import * as styles from '../TextBlock.css'
import { TTextBlockContext, TTextBlockSlices } from '../TextBlock.types'

export const DefaultTextBlock = (
    slice: TTextBlockSlices,
    context: TTextBlockContext
) => {
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        if (width < BreakPoints.tabletSmall) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    const sliceBetween = (context: string) => {
        if (context === 'news' || context === 'blog') {
            return 20
        } else {
            return isMobile ? 40 : 80
        }
    }

    return (
        <Box
            anchorid={slice.primary.anchor || undefined}
            boxStyles={{
                marginTop: sliceBetween(context.type),
                marginBottom: sliceBetween(context.type),
            }}
            flexDirection="column"
        >
            <Container>
                <Box
                    margin={'auto'}
                    flexDirection="column"
                    boxStyles={{ maxWidth: '53.5rem' }}
                >
                    <Box flexDirection={'column'} width={'full'}>
                        {slice.primary.tagline && (
                            <Heading
                                my={0}
                                paddingBottom={3}
                                type={'h6'}
                                text={slice.primary.tagline}
                                color={parsePrismicHeadingColor(
                                    slice.primary.slicecolor
                                )}
                            />
                        )}
                    </Box>
                    <PrismicRichText
                        field={slice.primary.content}
                        components={{
                            paragraph: ({ children }) => (
                                <Text size="large">{children}</Text>
                            ),
                            heading6: ({ text }) =>
                                text && (
                                    <Heading
                                        color={parsePrismicHeadingColor(
                                            slice.primary.slicecolor
                                        )}
                                        type={'h6'}
                                        text={text}
                                        style={{
                                            marginTop: '0.5rem',
                                            marginBottom: '0.5rem',
                                        }}
                                    />
                                ),
                            heading5: ({ text }) =>
                                text && (
                                    <Heading
                                        color={parsePrismicHeadingColor(
                                            slice.primary.slicecolor
                                        )}
                                        type={'h5'}
                                        text={text}
                                        style={{
                                            marginTop: '0.5rem',
                                            marginBottom: '0.5rem',
                                        }}
                                    />
                                ),
                            heading4: ({ text }) =>
                                text && (
                                    <Heading
                                        type={'h4'}
                                        text={text}
                                        style={{
                                            marginTop: '0.5rem',
                                            marginBottom: '0.5rem',
                                        }}
                                    />
                                ),
                            heading3: ({ text }) =>
                                text && (
                                    <Heading
                                        type={'h3'}
                                        text={text}
                                        style={{
                                            marginTop: '0.5rem',
                                            marginBottom: '0.5rem',
                                        }}
                                    />
                                ),
                            heading2: ({ text }) =>
                                text && (
                                    <Heading
                                        type={'h2'}
                                        text={text}
                                        style={{
                                            marginTop: '0.5rem',
                                            marginBottom: '0.5rem',
                                        }}
                                    />
                                ),
                            hyperlink: ({ text, node }) => (
                                <a
                                    style={{
                                        color: parsePrismicDarkColor(
                                            slice.primary.slicecolor
                                        ),
                                        fontWeight: 600,
                                        textDecoration: 'none',
                                    }}
                                    target={
                                        node.data.link_type === 'Web'
                                            ? node.data.target
                                            : ''
                                    }
                                    rel="noopener noreferrer"
                                    href={node.data.url}
                                >
                                    {text}
                                </a>
                            ),
                            image: ({ node }) => (
                                <>
                                    <img
                                        src={node.url}
                                        alt={node.alt || ''}
                                        style={{
                                            display: 'flex',
                                            alignSelf: 'center',
                                            width: '100%',
                                        }}
                                    ></img>
                                    {node.alt && (
                                        <span
                                            style={{
                                                color: parsePrismicDarkColor(
                                                    slice.primary.slicecolor
                                                ),
                                                fontSize: '0.75rem',
                                                fontWeight: 600,
                                                paddingTop: '1rem',
                                                paddingBottom: '1rem',
                                            }}
                                        >
                                            {node.alt}
                                        </span>
                                    )}
                                </>
                            ),
                            list: ({ children }) => (
                                <ul className={styles.bullet}>{children}</ul>
                            ),
                            listItem: ({ children }) => (
                                <li
                                    className={`${styles.listItem({
                                        colors: parsePrismicColorName(
                                            slice.primary.slicecolor
                                        ),
                                    })}`}
                                >
                                    <Text size="large"> {children} </Text>
                                </li>
                            ),
                            oList: ({ children }) => (
                                <ol className={styles.number}>{children}</ol>
                            ),
                            oListItem: ({ children }) => (
                                <li className={styles.olistItem}>
                                    <Text size="large"> {children} </Text>
                                </li>
                            ),
                        }}
                    />
                    {slice.primary.buttonlink && slice.primary.buttonlabel && (
                        <Box
                            width={'full'}
                            paddingTop={24}
                            justify={'flexStart'}
                        >
                            <Button
                                variant={'text'}
                                href={slice.primary.buttonlink.url}
                                icon="ArrowRight"
                                color={parsePrismicHeadingColor(
                                    slice.primary.slicecolor
                                )}
                                newTab={slice.primary.openNewTab}
                            >
                                {slice.primary.buttonlabel &&
                                    slice.primary.buttonlabel}
                            </Button>
                        </Box>
                    )}
                </Box>
            </Container>
        </Box>
    )
}

export default DefaultTextBlock
