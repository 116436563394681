import { FC } from 'react'
import { TCustomerStorySlices } from './CustomerStory.types'
import { CustomerStoryWithImage, DefaultCustomerStory } from './variations'

const CustomeryStory: FC<{ slice: TCustomerStorySlices }> = ({ slice }) => {
    switch (slice.variation) {
        case 'default': {
            return DefaultCustomerStory(slice)
        }
        case 'withImage': {
            return CustomerStoryWithImage(slice)
        }
        default:
            return null
    }
}

export default CustomeryStory
