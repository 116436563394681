import * as styles from './MegaMenu.css'
import { Box, Container } from '@orbit/ui/core'
import React, { useContext, useEffect, useRef } from 'react'
import { MegamenuContext } from '@oribt/context'
import { TMenu } from '../Header'
import { SliceZone } from '@prismicio/react'
import MenuItem from '../MenuItem'
export interface SubMenuProps {
    menu: TMenu
    active: boolean
    menuId: string
}

export const MegaMenu: React.FC<SubMenuProps> = ({ menu, active }) => {
    const menuRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (menuRef.current && active) {
            menuRef.current.focus()
        }
    }, [menuRef, active])

    const megamenuContext = useContext(MegamenuContext)

    // TODO ADD ONKEYDOWN TO NAVIGATE
    // const keydownHandler = (e: React.KeyboardEvent) => {
    //     if (e.key === 'Enter') megamenuContext.setOpenMegamenu('')
    // }

    return (
        <Box flexDirection="column" tabIndex={-1} ref={menuRef}>
            <div
                className={`overlay ${styles.overlay} ${
                    active ? styles.activeMenu : styles.inActiveMenu
                }`}
                onClick={(e) => {
                    return (
                        e.currentTarget === e.target &&
                        megamenuContext.setOpenMegamenu('')
                    )
                }}
            >
                <div className={styles.megamenuWrapper}>
                    <Container variant="default">
                        <div
                            className={`${styles.submenu} ${
                                active && styles.activeSubmenu
                            }`}
                            role={'menu'}
                        >
                            <Box
                                boxStyles={{ gap: '2rem' }}
                                flex={'auto'}
                                flexDirection="row"
                            >
                                <SliceZone
                                    slices={menu.slices}
                                    components={{ menu_item: MenuItem }}
                                />
                            </Box>
                        </div>
                    </Container>
                </div>
            </div>
        </Box>
    )
}
