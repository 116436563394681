import { parsePrismicLink } from '@orbit/prismic'
import { ArrowLink, Box, Heading } from '@orbit/ui/core'
import { BreakPoints, theme } from '@orbit/ui/themes'
import { useEffect, useState } from 'react'
import { useWindowSize } from 'react-use'
import { TMenuItemSliceWithBrandIcon } from '../MenuItem.types'

export const WithBrandIcon = (
    slice: TMenuItemSliceWithBrandIcon,
    key?: number
) => {
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        setIsMobile(width < BreakPoints.tablet)
    }, [width])

    const itemsPerGroup = width < BreakPoints.tabletSmall ? 6 : 4
    const itemsLength = slice.items.length
    const rows = Math.ceil(itemsLength / itemsPerGroup)

    const renderItems = () => {
        let itemIndex = 0
        return Array.from({ length: rows }, (_, i) => {
            return (
                <Box
                    key={i}
                    wrap="wrap"
                    justify="spaceBetween"
                    paddingBottom={32}
                    boxStyles={{
                        rowGap: '3rem',
                        columnGap: '1.5rem',
                        borderBottom:
                            i !== rows - 1
                                ? 'solid 0.0625rem var(--colors-neutral-400__1h1cr2ur)'
                                : 'none',
                    }}
                    align="center"
                >
                    {Array.from({ length: itemsPerGroup }, (_, j) => {
                        if (
                            itemIndex <= itemsLength - 1 &&
                            slice.items[itemIndex].icon.url
                        ) {
                            const item = slice.items[itemIndex]
                            itemIndex++
                            return (
                                <Box
                                    boxStyles={
                                        width < BreakPoints.tabletSmall
                                            ? { width: 'calc(33% - 1rem)' }
                                            : { width: 'calc(50% - 1rem)' }
                                    }
                                    key={j}
                                    justify="center"
                                    align="center"
                                >
                                    <a
                                        href={parsePrismicLink(item.link)}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img
                                            src={item.icon.url}
                                            alt={item.icon.alt || ''}
                                            style={{
                                                width: '100%',
                                                height: 'auto',
                                            }}
                                        />
                                    </a>
                                </Box>
                            )
                        }

                        return null
                    })}
                </Box>
            )
        })
    }

    return (
        <Box
            wrap="wrap"
            flexDirection="column"
            key={key}
            boxStyles={{
                background: theme.colors.neutral[200],
                paddingTop: '2rem',
                paddingBottom: '3rem',
                paddingLeft: isMobile ? '1rem' : '2.5rem',
                paddingRight: isMobile ? '1rem' : '0rem',
            }}
        >
            {slice.primary.title && (
                <Heading
                    text={slice.primary.title}
                    type={'h5'}
                    style={{
                        fontSize: '0.75rem',
                        letterSpacing: '0.125rem',
                        width: '100%',
                        margin: '0',
                        marginBottom: '2.5rem',
                        color: theme.colors.primary[700],
                    }}
                />
            )}
            <Box wrap="wrap" boxStyles={{ gap: '2.5rem' }}>
                {renderItems()}
            </Box>
            <Box marginTop={64}>
                <ArrowLink
                    link="https://verslun.origo.is/vorumerki"
                    linkText="Sjá öll vörumerki"
                    color="primaryBlue700"
                />
            </Box>
        </Box>
    )
}

export default WithBrandIcon
