import {
    parsePrismicHeadingColor,
    parsePrismicHighlightColor,
} from '@orbit/prismic'
import { BreakPoints } from '@orbit/ui/themes'
import {
    Box,
    Text,
    Container,
    Button,
    Icon,
    Modal,
    OrbitVideo,
    OrbitImage,
} from '@orbit/ui/core'
import { useState, useEffect, useContext } from 'react'
import { useWindowSize } from 'react-use'
import * as styles from '../HeroBanner.css'
import { THeroBannerSliceFrontpage } from '../HeroBanner.types'
import { AnimatedHeading, CompanyLogos } from '../components'
import { HeaderContext } from '@oribt/context'
import { PrismicRichText } from '@prismicio/react'

export const FrontpageHeroBanner = (slice: THeroBannerSliceFrontpage) => {
    const { width } = useWindowSize()
    const [openModal, setOpenModal] = useState(false)
    const [isMobile, setIsMobile] = useState(false)
    const headerContext = useContext(HeaderContext)
    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        if (width <= BreakPoints.tabletSmall) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    const realBubbleColor = slice.primary.bubblecolor
        ? parsePrismicHeadingColor(slice.primary.bubblecolor)
        : 'none'

    return (
        <Box id={slice.primary.anchor || undefined}>
            <Box width="full" boxStyles={{ overflow: 'hidden' }}>
                <Container>
                    <Box
                        extraClassName={styles.HeroBannerWrapper}
                        boxStyles={{
                            height:
                                width <= BreakPoints.mobile
                                    ? '100%'
                                    : 'calc(100vh - 9rem)',
                        }}
                        align="center"
                    >
                        <Box
                            flexDirection="column"
                            marginTop={isMobile ? 36 : 0}
                            extraClassName={
                                styles.LargeHeroBannerContentWrapper
                            }
                            width="full"
                        >
                            <AnimatedHeading
                                textBegin={slice.primary.textBegin || ''}
                                textEnd={slice.primary.textEnd || ''}
                                textHighlights={
                                    slice.primary.textHighlights || ''
                                }
                                startColor={slice.primary.slicecolor}
                                animate={slice.primary.animate}
                            />

                            <PrismicRichText
                                components={{
                                    paragraph: ({ text }) => (
                                        <Text size="large">{text}</Text>
                                    ),
                                }}
                                field={slice.primary.description}
                            />
                            <Box wrap="wrap">
                                {slice.primary.buttonlink?.url &&
                                    slice.primary.buttoncolor && (
                                        <Box marginRight={24} marginTop={24}>
                                            <Button
                                                variant={
                                                    slice.primary.buttonlabel
                                                        ? 'filled'
                                                        : 'icon'
                                                }
                                                size="large"
                                                color={parsePrismicHeadingColor(
                                                    slice.primary.buttoncolor
                                                )}
                                                icon={slice.primary.buttonicon}
                                                href={
                                                    slice.primary.buttonlink.url
                                                }
                                                ariaLabel={
                                                    slice.primary.buttonlabel
                                                        ? undefined
                                                        : slice.primary
                                                              .buttonicon
                                                }
                                                newTab={
                                                    slice.primary.openNewTab
                                                }
                                            >
                                                {slice.primary.buttonlabel}
                                            </Button>
                                        </Box>
                                    )}
                                {slice.primary.secondbuttonlink?.url &&
                                    slice.primary.buttoncolor && (
                                        <Box marginTop={24}>
                                            <Button
                                                variant={
                                                    slice.primary
                                                        .secondbuttonlabel
                                                        ? 'ghost'
                                                        : 'icon'
                                                }
                                                size="large"
                                                color={parsePrismicHeadingColor(
                                                    slice.primary.buttoncolor
                                                )}
                                                icon={
                                                    slice.primary
                                                        .secondbuttonicon
                                                }
                                                href={
                                                    slice.primary
                                                        .secondbuttonlink.url
                                                }
                                                ariaLabel={
                                                    slice.primary
                                                        .secondbuttonlabel
                                                        ? undefined
                                                        : slice.primary
                                                              .secondbuttonicon
                                                }
                                                newTab={
                                                    slice.primary
                                                        .openSecondNewTab
                                                }
                                            >
                                                {
                                                    slice.primary
                                                        .secondbuttonlabel
                                                }
                                            </Button>
                                        </Box>
                                    )}
                            </Box>
                            {slice.items.length > 0 && (
                                <Box paddingTop={isMobile ? 36 : 64}>
                                    <CompanyLogos
                                        items={slice.items}
                                        alt={slice.primary.textBegin || ''}
                                    />
                                </Box>
                            )}
                        </Box>
                        {headerContext.animationComplete && (
                            <Box
                                width="full"
                                boxStyles={{ opacity: isLoaded ? 1 : 0 }}
                            >
                                <Box
                                    position="relative"
                                    width="full"
                                    alignItems="center"
                                    justify="center"
                                >
                                    {slice.primary.videolink &&
                                        slice.primary.videolink.url &&
                                        isLoaded && (
                                            <button
                                                className={
                                                    styles.PlayVideoButton
                                                }
                                                onClick={() =>
                                                    setOpenModal(true)
                                                }
                                                aria-label={'Play Video'}
                                            >
                                                <Box
                                                    extraClassName={
                                                        styles.PlayIconWrapper
                                                    }
                                                >
                                                    <Icon
                                                        icon="Play"
                                                        color="white"
                                                        size="xlarge"
                                                    />
                                                </Box>
                                            </button>
                                        )}
                                    {slice.primary.image &&
                                        slice.primary.image.url && (
                                            <Box
                                                position="relative"
                                                extraClassName={`${styles.CircleImage} `}
                                            >
                                                {isLoaded && (
                                                    <Box
                                                        extraClassName={`${styles.BannerImageMask(
                                                            {
                                                                color: realBubbleColor,
                                                            }
                                                        )} ${
                                                            isLoaded
                                                                ? styles.ScaleMaskAnimation
                                                                : ''
                                                        }`}
                                                    />
                                                )}

                                                <OrbitImage
                                                    src={
                                                        slice.primary.image.url
                                                    }
                                                    alt={
                                                        slice.primary.image
                                                            .alt ||
                                                        'Myndskreyting'
                                                    }
                                                    width={1000}
                                                    height={1000}
                                                    layout="fill"
                                                    // noSkeleton
                                                    loading="eager"
                                                    objectFit="cover"
                                                    objectPosition="center"
                                                    containerclass={`${
                                                        styles.CircleInnerImage
                                                    } ${
                                                        isLoaded
                                                            ? styles.BannerMaskAnimation
                                                            : styles.notLoaded
                                                    }`}
                                                    onLoadingComplete={() => {
                                                        return setIsLoaded(true)
                                                    }}
                                                    style={{
                                                        backgroundColor:
                                                            parsePrismicHighlightColor(
                                                                slice.primary
                                                                    .imagebackgroundcolor
                                                            ),
                                                        borderRadius: '50%',
                                                    }}
                                                />
                                            </Box>
                                        )}
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Container>
            </Box>

            {slice.primary.videolink && slice.primary.videolink.url && (
                <Modal open={openModal} onClose={() => setOpenModal(false)}>
                    <Container>
                        <OrbitVideo
                            title={`Forsíðu myndband Origo`}
                            url={slice.primary.videolink.url || ''}
                            color="accent"
                        />
                    </Container>
                </Modal>
            )}
        </Box>
    )
}

export default FrontpageHeroBanner
