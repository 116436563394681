import {
    Box,
    Container,
    Heading,
    Icon,
    MotionBox,
    Text,
    Loader,
} from '@orbit/ui/core'
import { BreakPoints, theme } from '@orbit/ui/themes'
import { cardRevealUp } from '@orbit/utils/framer-motion'
import { AnimatePresence } from 'framer-motion'
import moment from 'moment'
import Link from 'next/link'
import { useEffect, useRef, useState } from 'react'
import { useWindowSize } from 'react-use'
import useSwr from 'swr'
import * as styles from '../Overview.css'
import cn from 'classnames'
import Lottie from 'lottie-react'
import {
    TInvestorsNewsOverviewSliceDefault,
    TOverviewContext,
} from '../Overview.types'
import {
    parsePrismicHighlightColor,
    parsePrismicTextCardBackgroundColor,
} from '@orbit/prismic'

const fetcher = (url: string) => fetch(url).then((res) => res.json())

type TInvestorNewsData = {
    title: string
    text: string
    publish_date: string
    slug: string
    metatitle: string
    metadescription: string
    ogimageidfullpath: string
}
export interface InvestorNewsProps {
    content: TInvestorNewsData
}

export const InvestorsNews = (
    slice: TInvestorsNewsOverviewSliceDefault,
    context: TOverviewContext
) => {
    const [offset, setOffset] = useState(0)
    const [isMobile, setIsMobile] = useState(false)
    const [current, setCurrent] = useState<number>(0)
    const investorNews = useRef<HTMLDivElement>(null)
    const { width } = useWindowSize()
    const limit = 9

    moment(context.lang)

    const { data } = useSwr(
        `/api/mfn/feed?lang=${
            context.lang === 'en-gb' ? 'en' : 'is'
        }&limit=${limit}&offset=${offset}`,
        fetcher
    )
    const isMonths = [
        'Janúar',
        'Ferbrúar',
        'Mars',
        'Apríl',
        'Maí',
        'Júní',
        'Júlí',
        'Ágúst',
        'September',
        'Október',
        'Nóvember',
        'Desember',
    ]

    const enMonths = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ]

    useEffect(() => {
        if (width < BreakPoints.tabletSmall) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    const onNext = () => {
        setOffset(offset + limit)
        scrollToTopOfInvestorNews()
    }

    const onPrevious = () => {
        setOffset(offset - limit)
        scrollToTopOfInvestorNews()
    }
    const scrollToTopOfInvestorNews = () => {
        window.scrollTo({
            top: current,
            behavior: 'smooth',
        })
    }
    const newsdate = (date: string) => {
        const thedate = new Date(date)
        const monthdate =
            context.lang === 'is'
                ? isMonths[thedate.getMonth()]
                : enMonths[thedate.getMonth()]
        return `${thedate.getDate()}.  ${monthdate.toUpperCase()}  ${thedate.getFullYear()}`
    }

    useEffect(() => {
        if (investorNews.current) {
            setCurrent(investorNews.current.offsetTop - 120)
        }
    }, [data])
    const [hydrated, setHydrated] = useState(false)

    useEffect(() => {
        setHydrated(true)
    }, [])
    if (!hydrated) {
        // Returns null on first render, so the client and server match
        return null
    }

    return (
        <Box
            flexDirection="column"
            justify="center"
            boxStyles={{ justifyContent: 'center', height: '100%' }}
        >
            <Box
                boxStyles={{
                    alignSelf: 'stretch',
                    width: 'auto',
                }}
            >
                <Container>
                    <Box
                        boxStyles={{
                            alignItems: 'center',
                            alignSelf: 'center',
                        }}
                        flexDirection="column"
                        ref={investorNews}
                    >
                        <Heading
                            text={
                                slice.primary.title
                                    ? slice.primary.title
                                    : context.lang === 'is'
                                    ? 'Fjárfestafréttir Origo'
                                    : 'Investor News Origo'
                            }
                            type="h1"
                            style={{
                                marginTop: '0.75rem',
                                marginBottom: isMobile ? '2.5rem' : '5rem',
                                textAlign: isMobile ? 'center' : 'start',
                            }}
                        />

                        <Box
                            boxStyles={{
                                position: 'relative',
                                height: '100%',
                            }}
                            flexDirection="column"
                            marginTop={24}
                            marginBottom={64}
                            width="full"
                        >
                            {data && data.items !== null ? (
                                <AnimatePresence>
                                    <Box
                                        extraClassName={
                                            styles.InvestorNewsCardWrapper
                                        }
                                    >
                                        {data.items?.map(
                                            (
                                                investornews: InvestorNewsProps,
                                                index: number
                                            ) => {
                                                return (
                                                    <MotionBox
                                                        width="full"
                                                        flexDirection="column"
                                                        initial="hidden"
                                                        viewport={{
                                                            once: true,
                                                        }}
                                                        whileInView="visible"
                                                        transition={{
                                                            delay:
                                                                0.1 *
                                                                (index + 1),
                                                            duration: 0.6,
                                                        }}
                                                        variants={cardRevealUp}
                                                        key={`${investornews.content.slug}-${index}`}
                                                        extraClassName={
                                                            styles.fillGrid
                                                        }
                                                    >
                                                        <Link
                                                            href={
                                                                context.lang ===
                                                                'is'
                                                                    ? `fjarfestafrettir/${investornews.content.slug}`
                                                                    : `investor-news/${investornews.content.slug}`
                                                            }
                                                        >
                                                            <Box
                                                                boxStyles={{
                                                                    backgroundColor:
                                                                        parsePrismicTextCardBackgroundColor(
                                                                            slice
                                                                                .primary
                                                                                .slicecolor
                                                                        ),
                                                                    height: '100%',
                                                                    width: '100%',
                                                                    cursor: 'pointer',
                                                                }}
                                                                extraClassName={
                                                                    styles.linkWrapper
                                                                }
                                                                padding={10}
                                                                flexDirection="column"
                                                            >
                                                                <Text
                                                                    style={{
                                                                        margin: 0,
                                                                        fontWeight: 500,
                                                                        fontFamily:
                                                                            'TT Norms',
                                                                    }}
                                                                >
                                                                    {newsdate(
                                                                        investornews
                                                                            .content
                                                                            .publish_date
                                                                    )}
                                                                </Text>
                                                                <Heading
                                                                    text={
                                                                        investornews
                                                                            .content
                                                                            .title
                                                                    }
                                                                    type={'h3'}
                                                                />
                                                            </Box>
                                                        </Link>
                                                    </MotionBox>
                                                )
                                            }
                                        )}
                                    </Box>
                                </AnimatePresence>
                            ) : (
                                <Box width={'full'} justify="center">
                                    <Lottie animationData={Loader} />
                                </Box>
                            )}

                            <Box
                                width="full"
                                justify="spaceBetween"
                                paddingTop={48}
                            >
                                <Box>
                                    <button
                                        disabled={offset < limit ? true : false}
                                        type="button"
                                        onClick={onPrevious}
                                        className={cn(
                                            styles.paginationButtonColor,
                                            styles.paginationButton
                                        )}
                                    >
                                        <Icon
                                            color={
                                                offset < limit
                                                    ? parsePrismicHighlightColor(
                                                          slice.primary
                                                              .slicecolor
                                                      )
                                                    : theme.colors.primary[900]
                                            }
                                            icon="ArrowLeft"
                                            size="medium"
                                        />
                                    </button>
                                </Box>
                                <Box>
                                    <button
                                        disabled={data && data.items === null}
                                        type="button"
                                        onClick={onNext}
                                        className={cn(
                                            styles.paginationButtonColor,
                                            styles.paginationButton
                                        )}
                                    >
                                        <Icon
                                            color={
                                                data && data.items === null
                                                    ? parsePrismicHighlightColor(
                                                          slice.primary
                                                              .slicecolor
                                                      )
                                                    : theme.colors.primary[900]
                                            }
                                            icon="ArrowRight"
                                            size="medium"
                                        />
                                    </button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </Box>
    )
}

export default InvestorsNews
