export const VolumeIcon = () => {
    return (
        <svg
            width="11"
            height="12"
            viewBox="0 0 11 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.95332 4.11327C9.89116 4.05111 9.81736 4.00181 9.73615 3.96817C9.65493 3.93453 9.56789 3.91721 9.47998 3.91721C9.39208 3.91721 9.30503 3.93453 9.22382 3.96817C9.1426 4.00181 9.06881 4.05111 9.00665 4.11327C8.94449 4.17543 8.89518 4.24923 8.86154 4.33044C8.8279 4.41165 8.81059 4.4987 8.81059 4.58661C8.81059 4.67451 8.8279 4.76156 8.86154 4.84277C8.89518 4.92399 8.94449 4.99778 9.00665 5.05994C9.25704 5.30884 9.39849 5.64689 9.39998 5.99994C9.40014 6.19417 9.35786 6.38609 9.2761 6.56228C9.19434 6.73847 9.07508 6.89466 8.92665 7.01994C8.85915 7.07591 8.80334 7.14464 8.76244 7.22221C8.72154 7.29977 8.69634 7.38464 8.68829 7.47196C8.68024 7.55928 8.6895 7.64733 8.71553 7.73106C8.74156 7.8148 8.78385 7.89257 8.83998 7.95994C8.89642 8.02696 8.96551 8.0822 9.04331 8.1225C9.12112 8.1628 9.2061 8.18736 9.2934 8.19479C9.3807 8.20222 9.46861 8.19237 9.55211 8.1658C9.6356 8.13923 9.71303 8.09646 9.77998 8.03994C10.0781 7.79002 10.3178 7.47791 10.4825 7.1255C10.6472 6.7731 10.7328 6.38893 10.7333 5.99994C10.7296 5.29335 10.4496 4.61624 9.95332 4.11327ZM7.01998 0.733273C6.91172 0.686435 6.79296 0.669161 6.67584 0.683216C6.55872 0.69727 6.44743 0.74215 6.35332 0.813273L3.16665 3.33327H0.733317C0.556506 3.33327 0.386937 3.40351 0.261913 3.52854C0.136888 3.65356 0.0666504 3.82313 0.0666504 3.99994V7.99994C0.0666504 8.17675 0.136888 8.34632 0.261913 8.47134C0.386937 8.59637 0.556506 8.66661 0.733317 8.66661H3.16665L6.31998 11.1866C6.43728 11.2807 6.58294 11.3324 6.73332 11.3333C6.8329 11.3349 6.93135 11.312 7.01998 11.2666C7.13343 11.2126 7.2293 11.1276 7.29653 11.0214C7.36377 10.9153 7.39963 10.7923 7.39998 10.6666V1.33327C7.39963 1.20762 7.36377 1.08462 7.29653 0.978459C7.2293 0.872301 7.13343 0.787306 7.01998 0.733273Z"
                fill="white"
            />
        </svg>
    )
}
