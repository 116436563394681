import { ImageField } from '@prismicio/types'
import { Box } from '../Box'
import { Text } from '../Text'
import * as styles from './AuthorTab.css'
import { OrbitColors as colors } from '@orbit/ui/themes'
import { OrbitImage } from '../Image'

export interface AuthorTabProps {
    name?: string
    image?: ImageField
    jobtitle?: string
    color: keyof typeof colors
    ismobile: boolean
}

export const AuthorTab: React.FC<AuthorTabProps> = ({
    image,
    name,
    jobtitle,
    color,
    ismobile,
}) => {
    return (
        <Box
            boxStyles={{
                backgroundColor: colors[color],
            }}
            align="center"
            alignSelf="center"
            extraClassName={styles.boxStyle}
        >
            <Box extraClassName={styles.authorImage}>
                {image && (
                    <OrbitImage
                        src={image.url || ''}
                        height={80}
                        width={80}
                        layout="fill"
                        alt={image.url || ''}
                    />
                )}
            </Box>
            <Box flexDirection="column" paddingLeft={24}>
                <Text style={{ margin: 0, fontSize: '0.75rem' }}>
                    Höfundur blogs
                </Text>
                <Text style={{ margin: 0, fontWeight: 600 }}>{name}</Text>
                <Text style={{ margin: 0, fontSize: '0.875rem' }}>
                    {jobtitle}
                </Text>
            </Box>
        </Box>
    )
}
