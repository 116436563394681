import { BreakPoints } from '@orbit/ui/themes'
import { ImageField } from '@prismicio/types'

import { OrbitColors as colors } from '@orbit/ui/themes'
import { useState, useEffect } from 'react'
import { useWindowSize } from 'react-use'
import { ShareTab } from '../ShareTab'
import { AuthorTab } from '../AuthorTab'

export interface AuthorTabProps {
    authorname?: string
    authorimage?: ImageField
    authorjobtitle?: string
    color: keyof typeof colors
    url: string
    pagetitle: string
    datatype: string
}

export const ContentFooter: React.FC<AuthorTabProps> = ({
    authorimage,
    authorname,
    authorjobtitle,
    color,
    url,
    pagetitle,
    datatype,
}) => {
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)
    useEffect(() => {
        if (width < BreakPoints.mobile) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])
    return (
        <div
            style={{
                flexDirection: isMobile ? 'column' : 'row',
                display: 'flex',
                justifyContent: 'center',
                paddingBottom: isMobile ? '1.5rem' : '3rem',
                paddingTop: isMobile ? '1.5rem' : '3rem',
            }}
        >
            {authorname && authorimage && (
                <AuthorTab
                    name={authorname}
                    image={authorimage}
                    jobtitle={authorjobtitle}
                    color={color}
                    ismobile={isMobile}
                />
            )}
            <ShareTab
                color={color}
                url={url}
                title={pagetitle}
                ismobile={isMobile}
                dataType={datatype}
            />
        </div>
    )
}
