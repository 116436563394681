import { Box, OrbitImage } from '@orbit/ui/core'
import Link from 'next/link'
import { THeroBannerItemsDefault } from '../HeroBanner.types'

interface CompanyLogosProps {
    items: THeroBannerItemsDefault[]
    alt?: string
}

export const CompanyLogos: React.FC<CompanyLogosProps> = ({
    items,
    alt = '',
}) => {
    return (
        <>
            {items.map((item: THeroBannerItemsDefault, index: number) => {
                const { companylogo, companylogolink } = item
                if (!companylogo.url) return null
                return (
                    <Box paddingRight={36} key={`companylogo-${index}`}>
                        {companylogolink.url ? (
                            <Link
                                scroll={false}
                                href={companylogolink.url || ''}
                            >
                                <a href={companylogolink.url || ''}>
                                    <OrbitImage
                                        src={companylogo.url || ''}
                                        alt={companylogo.alt || `${alt}`}
                                        width={80}
                                        height={24}
                                        objectFit="contain"
                                        objectPosition="center"
                                    />
                                </a>
                            </Link>
                        ) : (
                            <OrbitImage
                                src={companylogo.url || ''}
                                alt={companylogo.alt || `${alt}`}
                                width={80}
                                height={24}
                                objectFit="contain"
                                objectPosition="center"
                            />
                        )}
                    </Box>
                )
            })}
        </>
    )
}
