import {
    Box,
    Button,
    Container,
    Heading,
    HeadingContainer,
    Icon,
    Loader,
    OrbitImage,
    Text,
} from '@orbit/ui/core'
import { BreakPoints, theme } from '@orbit/ui/themes'
import { useEffect, useMemo, useState } from 'react'
import useSwr from 'swr'
import {
    FoxwayPropsQuestionaireProps,
    FoxwayPropsQuestionaireQuestion,
    FoxwayPropsQuestionsProps,
    FoxwayPropsQuote,
    FoxwaySearchPageProps,
    TFoxwayCalculatorSliceDefault,
    FoxwayDataItem,
    FoxwaySuggestion,
} from '../FoxwayCalculator.types'
import StepWizard from 'react-step-wizard'
import AutoSuggest from 'react-autosuggest'
import * as styles from '../FoxwayCalculator.css'
import { useWindowSize } from 'react-use'
import Lottie from 'lottie-react'
import { currencyFormatter } from '@orbit/prismic'
const fetcher = (url: string) => fetch(url).then((res) => res.json())

const SearchPage = (props: FoxwaySearchPageProps) => {
    const { width } = useWindowSize()
    const [options, setOptions] = useState<FoxwaySuggestion[]>([])
    const [searchTerm, setSearchTerm] = useState<string | undefined>('')
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<
        string | undefined
    >('')
    const [isSelected, setIsSelected] = useState(false)
    const [ImageSrc, setImageSrc] = useState(props.productImage)
    const [loading, setLoading] = useState(false)
    const { data } = useSwr(
        `/api/foxway/models?term=${debouncedSearchTerm}`,
        fetcher
    )

    useEffect(() => {
        if (!data || data.Data === undefined || !data.Data.length)
            return setOptions([])

        setOptions(
            data.Data.map((item: FoxwayDataItem) => {
                return { name: item.ProductName, id: item.Id }
            })
        )
    }, [data])

    useEffect(() => {
        setImageSrc(props.productImage)
    }, [props.productImage])

    const memoedSuggestions = useMemo(() => {
        setLoading(true)
        if (searchTerm === undefined || options.length === 0) {
            setLoading(false)
            return []
        }

        const opts = options.filter((option) => {
            return option.name
                .toLowerCase()
                .includes(searchTerm.trim().toLowerCase())
        })

        setLoading(false)
        return opts
    }, [options, searchTerm])

    useEffect(() => {
        const timer = setTimeout(() => setDebouncedSearchTerm(searchTerm), 200)

        return () => {
            clearTimeout(timer)
        }
    }, [searchTerm])

    return (
        <Box flexDirection="column" width="full" justify="spaceBetween">
            <Box
                flexDirection={width < BreakPoints.mobile ? 'column' : 'row'}
                width="full"
                justify="spaceBetween"
                paddingBottom={width < BreakPoints.mobile ? 40 : 0}
            >
                <Box
                    flexDirection="column"
                    boxStyles={{
                        width: width < BreakPoints.mobile ? '100%' : '60%',
                    }}
                >
                    {props.title && (
                        <HeadingContainer title={props.title} pb="1.5rem" />
                    )}
                    {props.text && (
                        <Text textClass={styles.TextStyle}>{props.text}</Text>
                    )}
                    <Box
                        width="full"
                        boxStyles={{
                            height: '100%',
                            paddingBottom: '2.5rem',
                        }}
                        flexDirection="column"
                    >
                        <Text textClass={styles.SearchTextStyle}>
                            Tegund tækis
                        </Text>
                        <AutoSuggest
                            suggestions={memoedSuggestions}
                            onSuggestionsClearRequested={() => setOptions([])}
                            onSuggestionsFetchRequested={({ value }) => {
                                setSearchTerm(value)
                                props.onValueChange(value)
                            }}
                            onSuggestionSelected={(_, { suggestionValue }) => {
                                setIsSelected(true)
                                props.onValueChange(suggestionValue)
                            }}
                            getSuggestionValue={(suggestion) => {
                                setIsSelected(true)
                                props.onSelectedModelChange(suggestion.id)
                                return suggestion.name
                            }}
                            renderSuggestion={(suggestion) => (
                                <div>{suggestion.name}</div>
                            )}
                            inputProps={{
                                placeholder:
                                    'T.d. tegund fartölvu, spjaldtölvu, farsíma eða snjallúrs',
                                value: props.value || '',
                                style: {
                                    borderRadius: '100px',
                                    backgroundColor: 'white',
                                    border: 'none',
                                    fontFamily: 'TT norms',
                                    fontSize: loading ? '3rem' : '1rem',
                                    fontWeight: 600,
                                    color: 'black',
                                    padding: '0.5rem 1rem 0.5rem 1rem',
                                    maxWidth: '632px',
                                    width: '100%',
                                    minWidth: '100%',
                                },
                                onChange: (_, { newValue }) => {
                                    /// Þarf að vera
                                    setSearchTerm(newValue)
                                    props.onValueChange(newValue)
                                    // setValue(newValue)
                                },
                            }}
                            highlightFirstSuggestion={false}
                            theme={{
                                container: styles.AutoSuggestContainer,
                                input: styles.AutoSuggestInput,
                                inputOpen: styles.AutoSuggestInputOpen,
                                inputFocused: styles.AutoSuggestInputFocused,
                                suggestionsContainer:
                                    styles.AutoSuggestSuggestionsContainer,
                                suggestionsContainerOpen:
                                    styles.AutoSuggestSuggestionsContainerOpen,
                                suggestionsList:
                                    styles.AutoSuggestSuggestionsList,
                                suggestion: styles.AutoSuggestSuggestions,
                                suggestionHighlighted:
                                    styles.AutoSuggestSuggestionHighlighted,
                            }}
                        />
                    </Box>
                </Box>
                <Box
                    justify={width < BreakPoints.mobile ? 'center' : 'flexEnd'}
                    boxStyles={{
                        width: width < BreakPoints.mobile ? '100%' : '30%',
                        minWidth: width < BreakPoints.mobile ? '100%' : '30%',
                    }}
                >
                    <Box flexDirection="column" align="center" width="full">
                        {props.productName && (
                            <Text textClass={styles.ProductNameStyle}>
                                {props.productName}
                            </Text>
                        )}
                        {(props.productImage && ImageSrc) !== undefined && (
                            <OrbitImage
                                containerclass={styles.ImageContainer}
                                src={ImageSrc}
                                height="100%"
                                onError={() =>
                                    setImageSrc(
                                        '/images/product-image-placeholder.png'
                                    )
                                }
                                layout="fill"
                                objectFit="contain"
                                style={{ display: 'flex' }}
                                width={
                                    width < BreakPoints.mobile
                                        ? '500px'
                                        : '100%'
                                }
                            />
                        )}
                    </Box>
                </Box>
            </Box>
            <Button
                variant="filled"
                color="tertiary"
                onClick={() => props.nextStep && props.nextStep()}
                size="large"
                disabled={!isSelected}
            >
                Reikna virði
            </Button>
        </Box>
    )
}

const Questions = (props: FoxwayPropsQuestionsProps) => {
    const [ImageSrc, setImageSrc] = useState(props.productImage)

    function GetQuestions(isSelectedModel: number) {
        const { data } = useSwr(
            `/api/foxway/questions?model=${isSelectedModel}`,
            fetcher
        )
        return data
    }
    const questions = GetQuestions(props.selectedModel)
    return (
        <Box width="full" flexDirection="column">
            <Box>
                <Box
                    flexDirection="row"
                    align="center"
                    marginBottom={36}
                    gap="1rem"
                >
                    {props.productImage && (
                        <OrbitImage
                            containerclass={styles.SmallImageContainer}
                            src={ImageSrc}
                            onError={() =>
                                setImageSrc(
                                    '/images/product-image-placeholder.png'
                                )
                            }
                            width={'80px'}
                            height="80px"
                            objectFit="contain"
                        />
                    )}
                    {props.productName && (
                        <Text textClass={styles.ProductNameStyle}>
                            {props.productName}
                        </Text>
                    )}
                </Box>
            </Box>
            <Box flexDirection="column" width="full">
                <Box
                    flexDirection="row"
                    align="center"
                    gap="1rem"
                    boxStyles={{ maxWidth: '1080px' }}
                ></Box>
                <StepWizard
                    initialStep={1}
                    isLazyMount={true}
                    className={styles.StepWizardClassName}
                    transitions={{
                        enterRight: '',
                        enterLeft: '',
                        exitRight: '',
                        exitLeft: '',
                    }}
                >
                    {questions ? (
                        questions.Questions.map(
                            (
                                question: FoxwayPropsQuestionaireQuestion,
                                i: number
                            ) => (
                                <Questionaire
                                    stepName={`step${i}`}
                                    question={question}
                                    questions={questions}
                                    extraprops={props}
                                    setarray={props.setAnswers}
                                    array={props.answers}
                                />
                            )
                        )
                    ) : (
                        <Box width={'full'} justify="center">
                            <Lottie animationData={Loader} />
                        </Box>
                    )}
                </StepWizard>
            </Box>
        </Box>
    )
}

const Questionaire = (props: FoxwayPropsQuestionaireProps) => {
    const { width } = useWindowSize()

    function handleChange(index: number, score: number) {
        props.setarray((array: { [key: number]: number }) => ({
            ...array,
            [index]: score,
        }))
    }

    return (
        <Box width="full">
            <Box flexDirection="column" width="full">
                <Box
                    flexDirection={
                        width < BreakPoints.mobile ? 'column' : 'row'
                    }
                    gap={width < BreakPoints.mobile ? undefined : '1rem'}
                    width="full"
                >
                    {props.questions &&
                        props.questions.Questions.map((question, i: number) => (
                            <Box
                                align="center"
                                gap="1rem"
                                width={i === 0 ? 'auto' : 'full'}
                            >
                                {i !== 0 && width > BreakPoints.mobile && (
                                    <Box extraClassName={styles.ColoredLines} />
                                )}
                                <Box
                                    extraClassName={styles.StepBoxStyling({
                                        currentstep: i === props.question.Id,
                                    })}
                                >
                                    {i + 1}
                                </Box>
                                <Text
                                    style={{
                                        fontWeight:
                                            i === props.question.Id ? 700 : 500,
                                    }}
                                >
                                    {question.Label}
                                </Text>
                            </Box>
                        ))}
                </Box>
                <Heading
                    text={props.question.Name}
                    type={'h2'}
                    style={{ fontSize: '2rem' }}
                />

                <div
                    dangerouslySetInnerHTML={{
                        __html: props.question.InfoCards[0].Text,
                    }}
                    className={styles.htmlFoxwayCalculatorDetailsStyle}
                />
                <Box gap="1rem">
                    {props.question.Answers.map((item) => {
                        return (
                            <Box justify="center" align="center" gap="1rem">
                                <input
                                    type={'radio'}
                                    value={item.Id}
                                    name={`${props.question.Id}`}
                                    size={24}
                                    id={`checked${props.question.Id}`}
                                    onChange={() =>
                                        handleChange(props.question.Id, item.Id)
                                    }
                                />{' '}
                                <p
                                    style={{
                                        fontFamily: 'TT norms',
                                        fontSize: '1rem',
                                    }}
                                >
                                    {item.Name}
                                </p>
                            </Box>
                        )
                    })}
                </Box>
                <Box width="full" justify="spaceBetween" paddingTop={40}>
                    {props.question.Id !== 0 ? (
                        <Button
                            onClick={() =>
                                props.previousStep && props.previousStep()
                            }
                            variant="ghost"
                            color="tertiary"
                            size="large"
                        >
                            Til baka
                        </Button>
                    ) : (
                        <Button
                            onClick={() =>
                                props.extraprops.previousStep &&
                                props.extraprops.previousStep()
                            }
                            variant="ghost"
                            color="tertiary"
                            size="large"
                        >
                            Til baka
                        </Button>
                    )}
                    {props.question.Id <
                    props.questions.Questions.length - 1 ? (
                        <Button
                            color="tertiary"
                            onClick={() => props.nextStep && props.nextStep()}
                            size="large"
                            disabled={
                                !document.querySelector(
                                    `input[id=checked${props.question.Id}]:checked`
                                )
                            }
                        >
                            Áfram
                        </Button>
                    ) : (
                        <Button
                            color="tertiary"
                            onClick={() =>
                                props.extraprops.nextStep &&
                                props.extraprops.nextStep()
                            }
                            size="large"
                            disabled={
                                !document.querySelector(
                                    `input[id=checked${props.question.Id}]:checked`
                                )
                            }
                        >
                            Fá verð
                        </Button>
                    )}
                </Box>
            </Box>
        </Box>
    )
}

const Quote = (props: FoxwayPropsQuote) => {
    const { width } = useWindowSize()
    const [ImageSrc, setImageSrc] = useState(props.productImage)
    return (
        <Box flexDirection={'column'}>
            <Box
                width="full"
                gap="2rem"
                flexDirection={width < BreakPoints.mobile ? 'column' : 'row'}
                align="center"
            >
                <Box
                    boxStyles={{
                        width: width < BreakPoints.mobile ? '100%' : '60%',
                        height: '100%',
                    }}
                    justify="spaceBetween"
                    flexDirection={
                        width < BreakPoints.mobile ? 'column' : 'row'
                    }
                >
                    <Box flexDirection="column" width="full">
                        <Heading
                            text={
                                'Það er hægt að endurnýta tækið gegn endurgjaldi'
                            }
                            type={'h2'}
                        />
                        <Text textClass={styles.ColoredProductNameStyle}>
                            {props.productName}
                        </Text>

                        <Text textClass={styles.FinalStepTextStyle}>
                            Miðað við reiknivélina er verðmæti þíns tækis:{' '}
                            <span
                                className={styles.FinalStepTextStyle}
                                style={{ fontWeight: 600 }}
                            >
                                {currencyFormatter(props.answers?.OfferedPrice)}
                            </span>
                        </Text>
                        <Text textClass={styles.FinalStepTextStyle}>
                            Komdu með tækið í verslun okkar Borgartúni 37 eða á
                            Köllunarklettsvegi 8 og við staðfestum endanlegt
                            verðmat. Ef tækið er metið hæft til endurnýtingar
                            færðu inneignarnótu í verslun Origo.
                        </Text>
                    </Box>
                </Box>
                <Box
                    flexDirection="column"
                    boxStyles={{
                        width: width < BreakPoints.mobile ? '100%' : '40%',
                    }}
                    alignSelf="flexEnd"
                >
                    <Box extraClassName={styles.IconBox}>
                        <Icon
                            icon={'Checkmark'}
                            color={theme.colors.neutral[900]}
                            size="small"
                        />
                    </Box>
                    <Box align="center" flexDirection="column" width="full">
                        <Text textClass={styles.ProductNameStyle}>
                            {props.productName}
                        </Text>
                        <OrbitImage
                            containerclass={styles.ImageContainer}
                            src={ImageSrc}
                            height="100%"
                            onError={() =>
                                setImageSrc(
                                    '/images/product-image-placeholder.png'
                                )
                            }
                            layout="fill"
                            objectFit="contain"
                            style={{ display: 'flex' }}
                            width={
                                width < BreakPoints.mobile ? '500px' : '100%'
                            }
                        />
                    </Box>
                </Box>
            </Box>
            <Box paddingTop={36}>
                <Button
                    onClick={() => {
                        props.onValueChange('')
                        props.firstStep && props.firstStep()
                    }}
                    variant="filled"
                    color="tertiary"
                    size="large"
                >
                    Meta annað tæki
                </Button>
            </Box>
        </Box>
    )
}

export const DefaultFoxwayCalculator = (
    slice: TFoxwayCalculatorSliceDefault
) => {
    const [isSelectedModel, setIsSelectedModel] = useState(0)
    const [value, setValue] = useState('')
    const [answersArray, setAnswersArray] = useState<{ [key: number]: number }>(
        []
    )
    const modelToShow = GetModelByID(isSelectedModel)
    function GetModelByID(isSelectedModel: number) {
        //Here we get Model that was selected
        const { data } = useSwr(
            `/api/foxway/modelsById?modelID=${isSelectedModel}`,
            fetcher
        )
        return data
    }
    function GetQuote() {
        const { data } = useSwr(
            `/api/foxway/priceQuote?model=${isSelectedModel}&answers=${JSON.stringify(
                answersArray
            )}`,
            fetcher
        )
        return data
    }
    return (
        <Box
            anchorid={slice.primary.anchor || undefined}
            justify={'center'}
            align={'center'}
            paddingTop={80}
            paddingBottom={80}
            flexDirection="column"
            width="full"
        >
            <Container>
                <Box extraClassName={styles.ColoredBox}>
                    <StepWizard
                        initialStep={1}
                        isLazyMount={true}
                        className={styles.StepWizardClassName}
                        transitions={{
                            enterRight: '',
                            enterLeft: '',
                            exitRight: '',
                            exitLeft: '',
                        }}
                    >
                        <SearchPage
                            onValueChange={setValue}
                            title={slice.primary.title || undefined}
                            text={slice.primary.text || undefined}
                            onSelectedModelChange={setIsSelectedModel}
                            value={value}
                            productName={
                                modelToShow
                                    ? modelToShow.ProductName
                                    : undefined
                            }
                            productImage={
                                modelToShow &&
                                (modelToShow.PictureUrl
                                    ? modelToShow.PictureUrl
                                    : modelToShow && modelToShow.ProductName
                                    ? '/images/product-image-placeholder.png'
                                    : undefined)
                            }
                        />
                        <Questions
                            onSelectedModelChange={setIsSelectedModel}
                            selectedModel={isSelectedModel}
                            productName={
                                modelToShow
                                    ? modelToShow.ProductName
                                    : undefined
                            }
                            productImage={
                                modelToShow &&
                                (modelToShow.PictureUrl
                                    ? modelToShow.PictureUrl
                                    : modelToShow && modelToShow.ProductName
                                    ? '/images/product-image-placeholder.png'
                                    : undefined)
                            }
                            setAnswers={setAnswersArray}
                            answers={answersArray}
                        />
                        <Quote
                            selectedModel={isSelectedModel}
                            productName={
                                modelToShow
                                    ? modelToShow.ProductName
                                    : undefined
                            }
                            onValueChange={setValue}
                            productImage={
                                modelToShow &&
                                (modelToShow.PictureUrl
                                    ? modelToShow.PictureUrl
                                    : modelToShow && modelToShow.ProductName
                                    ? '/images/product-image-placeholder.png'
                                    : undefined)
                            }
                            answers={GetQuote()}
                        />
                    </StepWizard>
                </Box>
            </Container>
        </Box>
    )
}

export default DefaultFoxwayCalculator
