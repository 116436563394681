import { useEffect, useMemo, useState } from 'react'
import {
    Box,
    Button,
    Container,
    HeadingContainer,
    Icon,
    MotionBox,
} from '@orbit/ui/core'
import {
    parsePrismicLightBackgroundColor,
    parsePrismicHeadingColor,
    parsePrismicBackgroundColor,
    parsePrismicDarkColor,
} from '@orbit/prismic'
import { useWindowSize } from 'react-use'
import { BreakPoints } from '@orbit/ui/themes'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import { arrow, imageStyle } from '../ImageGallery.css'
import { PrismicRichText } from '@prismicio/react'
import {
    TCustomArrow,
    TImageGalleryItemsDefault,
    TImageGallerySlices,
} from '../ImageGallery.types'
import { cardRevealUp } from '@orbit/utils/framer-motion'

export const DefaultImageGallery = (slice: TImageGallerySlices) => {
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)
    const { primary, items } = slice

    useEffect(() => {
        if (width < BreakPoints.mobile) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    const CustomArrow = ({ onClick, color, direction }: TCustomArrow) => (
        <div
            className={arrow({ direction })}
            style={{
                bottom: 20,
                right:
                    isMobile && direction === 'left'
                        ? 'unset'
                        : direction === 'right'
                        ? -30
                        : 80,
                left: isMobile
                    ? direction === 'right'
                        ? 'unset'
                        : 'unset'
                    : 'unset',
                zIndex: 3,
                backgroundColor: parsePrismicBackgroundColor(color),
            }}
            tabIndex={0}
            onClick={onClick ? () => onClick() : undefined}
        >
            <Icon
                icon={direction === 'right' ? 'ArrowRight' : 'ArrowLeft'}
                size="medium"
                color={parsePrismicDarkColor(color)}
            />
        </div>
    )

    const responsive = useMemo(() => {
        return {
            mobile: {
                breakpoint: { max: 768, min: 0 },
                items: 1,
                slidesToSlide: 1, // optional, default to 1.
            },
            desktop: {
                breakpoint: { max: 9999, min: 768 },
                items: 1,
                slidesToSlide: 1, // optional, default to 1.
            },
        }
    }, [])

    return (
        <MotionBox
            anchorid={slice.primary.anchor || undefined}
            boxStyles={{
                backgroundColor: primary.backgroundFilled
                    ? parsePrismicLightBackgroundColor(primary.sliceColor)
                    : 'white',
                justifyContent: 'center',
            }}
            initial={isMobile ? 'visible' : 'hidden'}
            viewport={{
                once: true,
            }}
            whileInView="visible"
            transition={{
                duration: 0.6,
                delay: 0.3,
            }}
            variants={cardRevealUp}
        >
            <Container>
                <Box
                    paddingBottom={isMobile ? 36 : 64}
                    paddingTop={isMobile ? 36 : 64}
                >
                    <Box
                        width={'full'}
                        align={'center'}
                        flexDirection={
                            isMobile
                                ? 'column'
                                : primary.textPosition === 'Right side'
                                ? 'rowReverse'
                                : 'row'
                        }
                        boxStyles={{ alignItems: 'stretch' }}
                        wrap="wrap"
                    >
                        <Box
                            width={isMobile ? 'full' : 'half'}
                            flexDirection={'column'}
                            paddingBottom={isMobile ? 8 : 0}
                            paddingRight={isMobile ? 0 : 48}
                            paddingLeft={isMobile ? 0 : 48}
                            align={'flexStart'}
                            justify={'spaceBetween'}
                            boxStyles={{ minHeight: isMobile ? 0 : '400px' }}
                        >
                            <Box
                                flexDirection="column"
                                boxStyles={{ maxWidth: '500px' }}
                            >
                                <HeadingContainer
                                    title={primary.title || undefined}
                                    tagline={primary.tagline || undefined}
                                    variant={'left'}
                                    gap="1rem"
                                    color={parsePrismicHeadingColor(
                                        slice.primary.sliceColor
                                    )}
                                    pb="1rem"
                                />

                                <PrismicRichText field={primary.text} />
                            </Box>

                            {primary.buttonLink?.url && (
                                <Box
                                    justify="flexStart"
                                    width={'full'}
                                    paddingTop={24}
                                    paddingBottom={isMobile ? 36 : 0}
                                >
                                    <Button
                                        variant={
                                            primary.buttonLabel &&
                                            primary.backgroundFilled
                                                ? 'filled'
                                                : primary.buttonLabel &&
                                                  !primary.backgroundFilled
                                                ? 'ghost'
                                                : 'icon'
                                        }
                                        href={
                                            primary.buttonLink &&
                                            primary.buttonLink.url
                                        }
                                        color={parsePrismicHeadingColor(
                                            primary.sliceColor
                                        )}
                                        icon={primary.buttonIcon}
                                        ariaLabel={
                                            primary.buttonLabel
                                                ? undefined
                                                : primary.buttonIcon
                                        }
                                        newTab={primary.openNewTab}
                                    >
                                        {primary.buttonLabel}
                                    </Button>
                                </Box>
                            )}
                        </Box>
                        {/* If Image or Video */}
                        <Box
                            width={isMobile ? 'full' : 'half'}
                            paddingTop={16}
                            justify={
                                isMobile
                                    ? 'center'
                                    : primary.textPosition === 'Right side'
                                    ? 'flexStart'
                                    : 'flexEnd'
                            }
                        >
                            <Box
                                boxStyles={{
                                    width: isMobile ? '100%' : '95%',
                                    alignItems: 'center',
                                }}
                            >
                                {items && (
                                    <Carousel
                                        additionalTransfrom={0}
                                        centerMode={false}
                                        swipeable={true}
                                        showDots={false}
                                        draggable={true}
                                        slidesToSlide={1}
                                        responsive={responsive}
                                        deviceType={
                                            isMobile ? 'mobile' : 'desktop'
                                        }
                                        autoPlay={false}
                                        ssr={true}
                                        infinite={false}
                                        partialVisible={false}
                                        customLeftArrow={
                                            <CustomArrow
                                                color={primary.sliceColor}
                                                direction="left"
                                            />
                                        }
                                        customRightArrow={
                                            <CustomArrow
                                                color={primary.sliceColor}
                                                direction="right"
                                            />
                                        }
                                    >
                                        {items.map(
                                            (
                                                item: TImageGalleryItemsDefault,
                                                index: number
                                            ) =>
                                                item.image ? (
                                                    <img
                                                        key={index}
                                                        className={imageStyle}
                                                        src={
                                                            item.image.url || ''
                                                        }
                                                        alt={
                                                            item.image.alt || ''
                                                        }
                                                    />
                                                ) : null
                                        )}
                                    </Carousel>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </MotionBox>
    )
}
export default DefaultImageGallery
