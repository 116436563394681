import {
    Box,
    Container,
    Heading,
    HeadingContainer,
    Icon,
    MotionBox,
    Text,
} from '@orbit/ui/core'
import { BreakPoints, theme } from '@orbit/ui/themes'
import { PrismicRichText } from '@prismicio/react'
import { useState, useEffect, useRef } from 'react'
import { useWindowSize } from 'react-use'
import { TAgendaSliceDefault } from '../Agenda.types'
import 'react-multi-carousel/lib/styles.css'
import * as styles from '../Agenda.css'
import SwiperCore from 'swiper'
import 'swiper/css'
import { Swiper, SwiperSlide } from 'swiper/react'
import { parsePrismicDarkColor } from '@orbit/prismic'
import { cardRevealUp } from '@orbit/utils/framer-motion'

export const DefaultAgenda = (slice: TAgendaSliceDefault) => {
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)
    const { items } = slice
    const [forwardDisabled, setForwardDisabled] = useState(false)
    const [backwardDisabled, setBackwardDisabled] = useState(true)
    const [swiperProgress, setSwiperProgress] = useState(0)
    const swiperRef = useRef<SwiperCore>()
    const navigationPrevRef = useRef<HTMLDivElement>(null)
    const navigationNextRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (swiperProgress === 1) {
            setForwardDisabled(true)
        } else if (swiperRef.current?.isEnd) {
            setForwardDisabled(true)
        } else {
            setForwardDisabled(false)
        }
        if (swiperProgress === 0) {
            setBackwardDisabled(true)
        } else {
            setBackwardDisabled(false)
        }
    }, [swiperProgress, width])

    useEffect(() => {
        if (width < BreakPoints.mobile) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    return (
        <Box
            anchorid={slice.primary.anchor || undefined}
            width={'full'}
            flexDirection="column"
            marginTop={isMobile ? 36 : 80}
            paddingBottom={isMobile ? 48 : 80}
            marginBottom={isMobile ? 36 : 0}
            position="relative"
        >
            <Container>
                <Box
                    width={'full'}
                    flexDirection="row"
                    align="center"
                    justify={slice.primary.tagline ? 'center' : 'spaceBetween'}
                    position={
                        slice.primary.title && slice.primary.tagline && isMobile
                            ? 'unset'
                            : 'relative'
                    }
                >
                    <Box>
                        <HeadingContainer
                            tagline={slice.primary.tagline || undefined}
                            title={slice.primary.title || undefined}
                            color="primary"
                            variant={slice.primary.tagline ? 'center' : 'left'}
                            size={slice.primary.tagline ? 'large' : 'small'}
                            pb={slice.primary.tagline ? '2.5rem' : '0rem'}
                        />
                    </Box>
                    <Box
                        extraClassName={
                            slice.primary.title && slice.primary.tagline
                                ? styles.largeHeadingbuttonGroup
                                : styles.buttonGroup
                        }
                        display={
                            backwardDisabled && forwardDisabled
                                ? 'none'
                                : 'flex'
                        }
                        paddingBottom={32}
                    >
                        <button
                            disabled={backwardDisabled}
                            onClick={() => {
                                swiperRef.current?.slidePrev()
                                swiperRef.current &&
                                    setSwiperProgress(
                                        swiperRef.current?.progress
                                    )
                            }}
                            className={styles.buttons}
                            aria-label={'Backward'}
                            style={{ marginRight: '2rem' }}
                        >
                            <Icon
                                color={
                                    backwardDisabled
                                        ? theme.colors.neutral[500]
                                        : theme.colors.primary[500]
                                }
                                icon="ArrowLeft"
                                size="medium"
                            />
                        </button>

                        <button
                            disabled={forwardDisabled}
                            onClick={() => {
                                swiperRef.current?.slideNext()
                                swiperRef.current &&
                                    setSwiperProgress(
                                        swiperRef.current?.progress
                                    )
                            }}
                            aria-label={'Forward'}
                            className={styles.buttons}
                        >
                            <Icon
                                color={
                                    forwardDisabled
                                        ? theme.colors.neutral[600]
                                        : theme.colors.primary[500]
                                }
                                icon="ArrowRight"
                                size="medium"
                            />
                        </button>
                    </Box>
                </Box>
            </Container>

            <Box extraClassName={styles.swiperBox} width="full">
                <Container>
                    <Box
                        display="block"
                        flexDirection="column"
                        align="center"
                        position="relative"
                        width={'full'}
                    >
                        <Swiper
                            slidesPerView={'auto'}
                            spaceBetween={0}
                            navigation={{
                                prevEl: navigationPrevRef.current,
                                nextEl: navigationNextRef.current,
                            }}
                            onSlideChange={(swiper) =>
                                setSwiperProgress(swiper.progress)
                            }
                            onSwiper={(swiper) => {
                                setSwiperProgress(swiper.progress)
                            }}
                            onBeforeInit={(swiper) => {
                                swiperRef.current = swiper
                            }}
                            grabCursor={true}
                            className={styles.swiperStyle}
                        >
                            {items?.map((item, index) => (
                                <SwiperSlide
                                    // Þarf að vera inline
                                    style={{
                                        width: 'fit-content',
                                        padding: '1rem',
                                        paddingRight: '2rem',
                                        paddingLeft: '0',
                                        height: 'auto',
                                    }}
                                    key={'Swiper' + slice.primary.title + index}
                                >
                                    <MotionBox
                                        extraClassName={styles.agendaItem({
                                            break: item.break,
                                        })}
                                        flexDirection="column"
                                        initial={
                                            isMobile ? 'visible' : 'hidden'
                                        }
                                        viewport={{
                                            once: true,
                                        }}
                                        whileInView="visible"
                                        transition={{
                                            duration: 0.6,
                                            delay: 0.1,
                                        }}
                                        variants={cardRevealUp}
                                    >
                                        {item.break ? (
                                            <Box
                                                align="center"
                                                flexDirection="column"
                                            >
                                                {item.start_time && (
                                                    <Box
                                                        align="center"
                                                        flexDirection="column"
                                                        paddingBottom={24}
                                                    >
                                                        <Text
                                                            textClass={
                                                                styles.breakText
                                                            }
                                                        >
                                                            Frá
                                                        </Text>
                                                        <Text
                                                            textClass={
                                                                styles.breakTimeText
                                                            }
                                                        >
                                                            {item.start_time}
                                                        </Text>
                                                    </Box>
                                                )}
                                                <Icon icon={'ForkKnife'} />
                                                {item.end_time && (
                                                    <Box
                                                        align="center"
                                                        flexDirection="column"
                                                        paddingTop={24}
                                                    >
                                                        <Text
                                                            textClass={
                                                                styles.breakText
                                                            }
                                                        >
                                                            Til
                                                        </Text>
                                                        <Text
                                                            textClass={
                                                                styles.breakTimeText
                                                            }
                                                        >
                                                            {item.end_time}
                                                        </Text>
                                                    </Box>
                                                )}
                                            </Box>
                                        ) : (
                                            <>
                                                <Text
                                                    my={0}
                                                    paddingBottom={3}
                                                    style={{
                                                        fontSize: isMobile
                                                            ? '0.75rem'
                                                            : '0.875rem',
                                                        fontWeight: 600,
                                                    }}
                                                >
                                                    {item.start_time}
                                                </Text>

                                                <PrismicRichText
                                                    field={item.title}
                                                    components={{
                                                        heading4: ({ text }) =>
                                                            text && (
                                                                <Heading
                                                                    type={
                                                                        slice
                                                                            .primary
                                                                            .title &&
                                                                        slice
                                                                            .primary
                                                                            .tagline
                                                                            ? 'h4'
                                                                            : !slice
                                                                                  .primary
                                                                                  .title &&
                                                                              !slice
                                                                                  .primary
                                                                                  .tagline
                                                                            ? 'h2'
                                                                            : 'h3'
                                                                    }
                                                                    text={text}
                                                                    my={0}
                                                                    paddingBottom={
                                                                        3
                                                                    }
                                                                    style={{
                                                                        fontSize:
                                                                            '1rem',
                                                                        lineHeight:
                                                                            '1.5rem',
                                                                    }}
                                                                />
                                                            ),
                                                    }}
                                                />
                                                <PrismicRichText
                                                    field={item.speaker}
                                                    components={{
                                                        paragraph: ({
                                                            children,
                                                        }) =>
                                                            children && (
                                                                <Text
                                                                    my={0}
                                                                    style={{
                                                                        fontSize:
                                                                            '0.875rem',
                                                                        lineHeight:
                                                                            '1.375rem',
                                                                    }}
                                                                >
                                                                    {children}
                                                                </Text>
                                                            ),
                                                        hyperlink: ({
                                                            text,
                                                            node,
                                                        }) => (
                                                            <a
                                                                style={{
                                                                    color: parsePrismicDarkColor(
                                                                        'blue'
                                                                    ),
                                                                    fontWeight: 600,
                                                                    textDecoration:
                                                                        'none',
                                                                }}
                                                                href={
                                                                    node.data
                                                                        .url
                                                                }
                                                                target={
                                                                    node.data
                                                                        .link_type ===
                                                                    'Web'
                                                                        ? node
                                                                              .data
                                                                              .target
                                                                        : ''
                                                                }
                                                                rel="noopener noreferrer"
                                                            >
                                                                {text}
                                                            </a>
                                                        ),
                                                    }}
                                                />
                                            </>
                                        )}
                                    </MotionBox>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </Box>
                </Container>
            </Box>
        </Box>
    )
}

export default DefaultAgenda
