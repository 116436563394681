import React from 'react'
import { DefaultVideo } from './variations'
import { TVideoContext, TVideoSlices } from './Video.types'
import { WithText } from './variations/WithText'

const Video: React.FC<{
    slice: TVideoSlices
    context: TVideoContext
}> = ({ slice, context }) => {
    switch (slice.variation) {
        case 'default': {
            return DefaultVideo(slice, context)
        }
        case 'videoWithText': {
            return WithText(slice, context)
        }
        default:
            return null
    }
}

export default Video
