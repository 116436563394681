import React from 'react'

type THeaderContext = {
    headerVisible: boolean
    setHeaderVisible: (isVisible: boolean) => void
    animationComplete: boolean
    setAnimationComplete: (isComplete: boolean) => void
    isNotificationOpen: boolean
    setNotificationOpen: (isOpen: boolean) => void
}

export const HeaderContext = React.createContext<THeaderContext>({
    headerVisible: true,
    setHeaderVisible: () => undefined,
    animationComplete: false,
    setAnimationComplete: () => undefined,
    isNotificationOpen: false,
    setNotificationOpen: () => undefined,
})

type THeaderContextProps = {
    children: React.ReactNode
}

export const HeaderContextProvider = (props: THeaderContextProps) => {
    const setHeaderVisible = (isVisible: boolean) => {
        setHeaderState((prev) => ({ ...prev, headerVisible: isVisible }))
    }
    const setAnimationComplete = (isComplete: boolean) => {
        setHeaderState((prev) => ({ ...prev, animationComplete: isComplete }))
    }
    const setNotificationOpen = (isOpen: boolean) => {
        setHeaderState((prev) => ({ ...prev, isNotificationOpen: isOpen }))
    }

    const [headerState, setHeaderState] = React.useState<THeaderContext>({
        headerVisible: true,
        setHeaderVisible: setHeaderVisible,
        animationComplete: false,
        setAnimationComplete: setAnimationComplete,
        isNotificationOpen: false,
        setNotificationOpen: setNotificationOpen,
    })

    return (
        <HeaderContext.Provider value={headerState}>
            {props.children}
        </HeaderContext.Provider>
    )
}
