import React, { useEffect, useState } from 'react'
import { TTimelineSlice } from './Timeline.types'
import { DefaultTimeline, HorizontalTimeline } from './variations'
import { Box, Container, HeadingContainer } from '@orbit/ui/core'
import { parsePrismicHeadingColor } from '@orbit/prismic'
import * as style from './Timeline.css'
import { BreakPoints } from '@orbit/ui/themes'
import { useWindowSize } from 'react-use'
import PrismicTextSerializer from './TextSerializer'

const Timeline: React.FC<{ slice: TTimelineSlice }> = ({ slice }) => {
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)
    useEffect(() => {
        if (width < BreakPoints.tablet) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])
    const returnVariation = () => {
        switch (slice.variation) {
            case 'default': {
                return DefaultTimeline(slice)
            }
            case 'horizontal': {
                return HorizontalTimeline(slice)
            }
            default:
                return null
        }
    }
    return (
        <Box
            display="flex"
            flexDirection="column"
            width="full"
            paddingTop={isMobile ? 36 : 80}
        >
            <Container>
                <Box
                    flexDirection="column"
                    align="center"
                    extraClassName={style.headingWrapper}
                >
                    <HeadingContainer
                        tagline={slice.primary.tagline || undefined}
                        title={slice.primary.title || undefined}
                        color={parsePrismicHeadingColor(
                            slice.primary.slicecolor
                        )}
                        pb={'0rem'}
                    />
                    <PrismicTextSerializer
                        slicecolor={parsePrismicHeadingColor(
                            slice.primary.slicecolor
                        )}
                        text={slice.primary.text}
                        textAlign="center"
                    />
                </Box>
            </Container>
            {returnVariation()}
        </Box>
    )
}

export default Timeline
