import {
    parsePrismicBackgroundColor,
    parsePrismicHeadingColor,
    parsePrismicHighlightColor,
    parsePrismicLightBackgroundColor,
} from '@orbit/prismic'
import { Box, Container, EventsCard, Heading, Pagination } from '@orbit/ui/core'
import { BreakPoints, theme } from '@orbit/ui/themes'
import { ImageFieldImage } from '@prismicio/types'
import moment from 'moment'
import { useState, useEffect, useRef } from 'react'
import { useWindowSize } from 'react-use'
import useSwr from 'swr'
import {
    TEventsOverviewContext,
    TEventsOverviewSliceAllEvents,
} from './EventsOverview.types'
import * as styles from './EventsOverview.css'
const fetcher = (url: string) => fetch(url).then((res) => res.json())

type TEvent = {
    data: TEventData
    uid: string
    id: string
}

type TEventData = {
    summary: string
    type: string
    image: ImageFieldImage
    datetime: string
    title: string
    availability: 'Full' | 'Available'
}
export const OldEventsOverview: React.FC<{
    slice: TEventsOverviewSliceAllEvents
    context: TEventsOverviewContext
}> = ({ slice, context }) => {
    moment.locale('is')
    const [isMobile, setIsMobile] = useState(false)
    const { width } = useWindowSize()
    const [highlightEventsCount, setHiglightEventsCount] = useState<number>(0)

    const [currentPage, setCurrentPage] = useState(1)
    const [totalResultsSize, setTotalResultsSize] = useState<number>(0)
    const [pageSize, setPageSize] = useState<number>(0)
    const oldEventsSectionRef = useRef<HTMLDivElement>(null)
    const [totalPages, setTotalPages] = useState<number>(0)
    const count = 5

    const { data: oldEvents, isValidating } = useSwr(
        `/api/oldEvents?count=${
            currentPage === 1 ? count + 1 : count
        }&page=${currentPage}&lang=${context.lang}`,
        fetcher
    )
    const oldEventsData = oldEvents && oldEvents.results

    useEffect(() => {
        if (!isValidating && oldEvents) {
            oldEvents.total_pages !== totalPages &&
                setTotalPages(oldEvents.total_pages)
            oldEvents.total_results_size !== totalResultsSize &&
                setTotalResultsSize(oldEvents.total_results_size)
            oldEvents.results_per_page !== pageSize &&
                setPageSize(oldEvents.results_per_page)
        }
    }, [oldEvents, isValidating, totalPages, pageSize, totalResultsSize])

    useEffect(() => {
        if (width < BreakPoints.tabletSmall) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    useEffect(() => {
        const cardCount = oldEventsData?.length
        setHiglightEventsCount(cardCount % 3)
    }, [oldEventsData, highlightEventsCount])

    if (oldEventsData === undefined || oldEventsData?.length === 0) {
        return null
    }
    const getCardProps = (event: TEvent, index: number) => {
        const shortSummary = event.data.summary?.substring(0, 160) + '...'
        if (
            oldEventsData.length % 3 === 0 &&
            event.data.summary?.length > 160
        ) {
            event.data.summary = shortSummary
        } else if (index !== 0 && event.data.summary?.length > 160) {
            event.data.summary = shortSummary
        }
        if (highlightEventsCount === 2 && event.data.summary?.length > 160) {
            event.data.summary = shortSummary
        }
        const cardColor =
            event.data.type === 'Webcast'
                ? 'Green'
                : event.data.type === 'Course'
                ? 'Orange'
                : 'Blue'
        const cardProps = {
            backgroundColor: parsePrismicBackgroundColor(cardColor),
            textColor: parsePrismicHighlightColor(cardColor),
            headingColor: parsePrismicHeadingColor(cardColor),
            cardColor: parsePrismicLightBackgroundColor(cardColor),
            id: 'old_event_' + event.id + ' ' + index,
            heading: event.data.title,
            tag: event.data.type,
            date: event.data.datetime,
            image: event.data.image,
            link: event.uid,
            summary: event.data.summary,
            availability: event.data.availability,
            uid: event.uid,
        }

        return cardProps
    }

    const scrollToTopOfOldEventsSection = () => {
        if (oldEventsSectionRef.current) {
            oldEventsSectionRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'start',
            })
        }
    }
    //TODO Bæta við lottie lengi að loadast
    return (
        <Box
            ref={oldEventsSectionRef}
            paddingTop={isMobile ? 36 : 80}
            paddingBottom={isMobile ? 36 : 80}
            width={'full'}
            boxStyles={{
                backgroundColor: theme.colors.neutral[700],
            }}
            flexDirection={isMobile ? 'column' : 'row'}
        >
            <Container>
                <Heading
                    my={0}
                    style={{
                        color: theme.colors.neutral[100],
                        paddingBottom: isMobile ? 36 : 80,
                    }}
                    textAlign="center"
                    type={'h2'}
                    text={'Eldri viðburðir'}
                />
                {oldEventsData && highlightEventsCount === 1 && (
                    <Box
                        extraClassName={styles.eventCardWrapper({
                            size: highlightEventsCount,
                        })}
                    >
                        <EventsCard
                            {...getCardProps(oldEventsData[0], 0)}
                            size="large"
                            headingLevel="h3"
                            index={0}
                        />
                    </Box>
                )}

                {oldEventsData && highlightEventsCount === 2 && (
                    <Box
                        extraClassName={styles.eventCardWrapper({
                            size: highlightEventsCount,
                        })}
                    >
                        {}
                        <EventsCard
                            {...getCardProps(oldEventsData[0], 0)}
                            size="medium"
                            headingLevel="h3"
                            index={0}
                        />
                        <EventsCard
                            {...getCardProps(oldEventsData[1], 1)}
                            size="medium"
                            headingLevel="h3"
                        />
                    </Box>
                )}

                {oldEvents && (
                    <Box
                        extraClassName={styles.eventCardWrapper({
                            size: 3,
                        })}
                    >
                        {Array.isArray(oldEventsData) &&
                            oldEventsData.map(
                                (event: TEvent, index: number) => {
                                    if (index >= highlightEventsCount)
                                        return (
                                            <EventsCard
                                                key={index}
                                                {...getCardProps(event, index)}
                                                size="small"
                                                headingLevel="h3"
                                                index={index}
                                            />
                                        )
                                    else {
                                        return null
                                    }
                                }
                            )}
                    </Box>
                )}
                {totalPages > 0 && (
                    <Pagination
                        darkBackground={true}
                        currentPage={currentPage}
                        variant="numbers"
                        color={parsePrismicHeadingColor(
                            slice.primary.slicecolor
                        )}
                        total={totalResultsSize}
                        pageSize={pageSize}
                        onPageChange={(page: number) => {
                            setCurrentPage(page)
                            setTimeout(
                                () => scrollToTopOfOldEventsSection(),
                                1000
                            )
                        }}
                    />
                )}
            </Container>
        </Box>
    )
}

export default OldEventsOverview
