import { useEffect, useRef, useState } from 'react'
import {
    parsePrismicBackgroundColor,
    parsePrismicHeadingColor,
} from '@orbit/prismic'
import {
    Box,
    Container,
    MotionBox,
    Pagination,
    BlogCard,
    HeadingContainer,
} from '@orbit/ui/core'
import { TOverviewContext, TOverviewSlice } from '../Overview.types'
import useSwr from 'swr'
import { AnimatePresence } from 'framer-motion'
import { newsBlogCardRevealUp } from '@orbit/utils/framer-motion'
import * as styles from '../Overview.css'
import { BreakPoints } from '@orbit/ui/themes'
import { useWindowSize } from 'react-use'
import { FirstItem } from '../FirstItem'
import { BlogData } from '../../../types'

const fetcher = (url: string) => fetch(url).then((res) => res.json())

export const BlogOverview = (
    slice: TOverviewSlice,
    context: TOverviewContext
) => {
    const [currentPage, setCurrentPage] = useState(1)
    const [totalResultsSize, setTotalResultsSize] = useState<number>(0)
    const [pageSize, setPageSize] = useState<number>(0)
    const blogSection = useRef<HTMLDivElement>(null)
    const [totalPages, setTotalPages] = useState<number>(0)
    const count = 9
    const { data, isValidating } = useSwr(
        `/api/blogg?count=${
            currentPage === 1 ? count + 1 : count
        }&page=${currentPage}&lang=${context.lang}`,
        fetcher
    )
    const { data: firstData } = useSwr(
        `/api/blogg?count=1&lang=${context.lang}`,
        fetcher
    )

    const blogs =
        data && (currentPage === 1 ? data.results.slice(1) : data.results)
    const latestBlog = firstData && firstData.results[0]

    useEffect(() => {
        if (!isValidating && data) {
            data.total_pages !== totalPages && setTotalPages(data.total_pages)
            data.total_results_size !== totalResultsSize &&
                setTotalResultsSize(data.total_results_size)
            data.results_per_page !== pageSize &&
                setPageSize(data.results_per_page)
        }
    }, [data, isValidating, totalPages, pageSize, totalResultsSize])

    const scrollToTopOfblogSection = () => {
        if (blogSection.current)
            window.scrollTo({
                top: blogSection.current.offsetTop - 60,
                behavior: 'smooth',
            })
    }

    return (
        <Box
            anchorid={slice.primary.anchor || undefined}
            flexDirection="column"
            justify="center"
            boxStyles={{ justifyContent: 'center', height: '100%' }}
        >
            <Container>
                <Box marginBottom={64}>
                    {latestBlog && (
                        <FirstItem
                            title={latestBlog.data.title}
                            tagline={
                                context.lang === 'en-gb' ? 'NEW' : 'NÝJAST'
                            }
                            text={latestBlog.data.introtext}
                            buttonLabel={
                                context.lang === 'en-gb' ? 'Read' : 'Lesa blogg'
                            }
                            buttonLink={latestBlog.url}
                            sliceColor={'tertiary'}
                            image={latestBlog.data.image}
                            author={
                                latestBlog.author && latestBlog.author.data.name
                            }
                        />
                    )}
                </Box>
            </Container>
            <Box
                boxStyles={{
                    background: parsePrismicBackgroundColor(
                        slice.primary.slicecolor
                    ),
                    alignSelf: 'stretch',
                    width: 'auto',
                }}
                ref={blogSection}
            >
                <Container>
                    <Box
                        boxStyles={{
                            background: parsePrismicBackgroundColor(
                                slice.primary.slicecolor
                            ),
                            alignItems: 'center',
                            alignSelf: 'center',
                        }}
                        flexDirection="column"
                        marginTop={80}
                    >
                        <HeadingContainer
                            variant="center"
                            tagline={slice.primary.tagline || undefined}
                            title={slice.primary.title || undefined}
                            color={parsePrismicHeadingColor(
                                slice.primary.slicecolor
                            )}
                            pb="2rem"
                        />

                        <Box
                            boxStyles={{
                                position: 'relative',
                            }}
                            flexDirection="column"
                            marginTop={24}
                            marginBottom={64}
                            width="full"
                        >
                            <AnimatePresence>
                                <Box
                                    extraClassName={styles.CardWrapper}
                                    wrap="wrap"
                                >
                                    {blogs &&
                                        blogs.map(
                                            (blog: BlogData, index: number) => {
                                                return (
                                                    <MotionBox
                                                        width="full"
                                                        flexDirection="column"
                                                        initial="hidden"
                                                        viewport={{
                                                            once: true,
                                                        }}
                                                        whileInView="visible"
                                                        transition={{
                                                            delay:
                                                                0.1 *
                                                                (index + 1),
                                                            duration: 0.6,
                                                        }}
                                                        variants={
                                                            newsBlogCardRevealUp
                                                        }
                                                        key={`${blog.data.uid}-${index}`}
                                                    >
                                                        {blog.data.author && (
                                                            <BlogCard
                                                                author={
                                                                    (blog.data
                                                                        .author
                                                                        .data &&
                                                                        blog
                                                                            .data
                                                                            .author
                                                                            .data
                                                                            .name) ||
                                                                    undefined
                                                                }
                                                                authorimageAlt={
                                                                    (blog.data
                                                                        .author
                                                                        .data &&
                                                                        blog
                                                                            .data
                                                                            .author
                                                                            .data
                                                                            .image
                                                                            ?.alt) ||
                                                                    undefined
                                                                }
                                                                authorimageUrl={
                                                                    (blog.data
                                                                        .author
                                                                        .data &&
                                                                        blog
                                                                            .data
                                                                            .author
                                                                            .data
                                                                            .image
                                                                            ?.url) ||
                                                                    undefined
                                                                }
                                                                url={blog.url}
                                                                slicecolor={
                                                                    'primary'
                                                                }
                                                                imageAlt={
                                                                    blog.data
                                                                        .image
                                                                        .alt ||
                                                                    undefined
                                                                }
                                                                imageUrl={
                                                                    blog.data
                                                                        .image
                                                                        .url ||
                                                                    undefined
                                                                }
                                                                title={
                                                                    blog.data
                                                                        .title ||
                                                                    undefined
                                                                }
                                                                summary={
                                                                    blog.data
                                                                        .summary ||
                                                                    undefined
                                                                }
                                                            />
                                                        )}
                                                    </MotionBox>
                                                )
                                            }
                                        )}
                                </Box>
                            </AnimatePresence>
                            {totalPages > 0 && (
                                <Pagination
                                    currentPage={currentPage}
                                    variant="numbers"
                                    color={'tertiary'}
                                    total={totalResultsSize}
                                    pageSize={pageSize}
                                    onPageChange={(page: number) => {
                                        setCurrentPage(page)
                                        setTimeout(
                                            () => scrollToTopOfblogSection(),
                                            100
                                        )
                                    }}
                                />
                            )}
                        </Box>
                    </Box>
                </Container>
            </Box>
        </Box>
    )
}

export default BlogOverview
