import { TButtonSlices } from '../Button.types'
import { ArrowLink, Box, Container } from '@orbit/ui/core'
import { parsePrismicButtonColor } from '@orbit/prismic'
export const DefaultButton = (slice: TButtonSlices) => {
    return (
        <Container>
            <Box
                anchorid={slice.primary.anchor || undefined}
                boxStyles={{
                    maxWidth: '53.5rem',
                    margin: 'auto',
                }}
            >
                <ArrowLink
                    linkText={slice.primary.label || ''}
                    link={slice.primary.link.url}
                    color={parsePrismicButtonColor(slice.primary.slicecolor)}
                    newTab={slice.primary.openNewTab}
                />
            </Box>
        </Container>
    )
}

export default DefaultButton
