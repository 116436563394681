import React from 'react'
import {
    DefaultImageWithText,
    ImageWithRepeatableZone,
    WithLogosAndTextImageWithText,
    WithLogosImageWithText,
    WithIconBulletsImageWithText,
} from './variations'
import { TImageWithTextSlices } from './ImageWithText.types'

const ImageWithText: React.FC<{ slice: TImageWithTextSlices }> = ({
    slice,
}) => {
    switch (slice.variation) {
        case 'default': {
            return DefaultImageWithText(slice)
        }
        case 'imageWithRepeatableZone': {
            return ImageWithRepeatableZone(slice)
        }
        case 'withLogos': {
            return WithLogosImageWithText(slice)
        }
        case 'withLogosAndText': {
            return WithLogosAndTextImageWithText(slice)
        }
        case 'withIconBullets': {
            return WithIconBulletsImageWithText(slice)
        }
        default:
            return null
    }
}

export default ImageWithText
