import {
    ArrowLink,
    Box,
    Container,
    HeadingContainer,
    PreFooter,
    Loader,
} from '@orbit/ui/core'
import {
    FriendlyURLTitle,
    parsePrismicHeadingColor,
    parsePrismicHighlightColor,
    parsePrismicLightBackgroundColor,
} from '@orbit/prismic'

import { TJobsContext, TJobsSliceDefault } from '../Jobs.types'
import { BreakPoints } from '@orbit/ui/themes'
import { useState, useEffect } from 'react'
import { useWindowSize } from 'react-use'
import useSwr from 'swr'
import * as styles from '../Jobs.css'
import moment from 'moment'
import Lottie from 'lottie-react'
const fetcher = (url: string) => fetch(url).then((res) => res.json())

type TJob = {
    RCAdvertTitle: string
    RCAdvertAdText: string
    RCAdvertUrl: string
    RCAdvertValidFrom: string
    RCAdvertID: number
    RCAdvertContact: string
}

export const Jobs = (slice: TJobsSliceDefault, context: TJobsContext) => {
    const { width } = useWindowSize()
    const [jobs, setJobs] = useState<TJob[]>([])
    const [isMobile, setIsMobile] = useState(false)
    const [jobIndex, setJobIndex] = useState(0)
    const { data: data1 } = useSwr('/api/jobs?page=1', fetcher)
    const { data: data2 } = useSwr('/api/jobs?page=2', fetcher)

    useEffect(() => {
        if (width < BreakPoints.mobile) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    useEffect(() => {
        if (data1 && data2) {
            const data = data1.concat(data2)
            data.forEach((job: TJob, index: number) => {
                if (job.RCAdvertID === 288) {
                    setJobIndex(index)
                }
            })
            setJobs([
                ...data.slice(0, jobIndex),
                ...data.slice(jobIndex + 1),
                data[jobIndex],
            ])
        }
    }, [data1, data2, jobIndex])

    return (
        <Box
            anchorid={slice.primary.anchor || undefined}
            flexDirection="column"
            paddingBottom={isMobile ? 48 : 80}
            paddingTop={isMobile ? 48 : 80}
            justify={'center'}
            align={'center'}
            boxStyles={{
                background: 'white',
            }}
        >
            <Container>
                <HeadingContainer
                    title={slice.primary.title || undefined}
                    tagline={slice.primary.tagline || undefined}
                    color="tertiary"
                />
                {jobs && data1 && data2 ? (
                    <Box display="grid" extraClassName={styles.gridBox}>
                        {jobs.map((job: TJob) => {
                            return (
                                <Box
                                    extraClassName={styles.gridItemBox}
                                    flexDirection={
                                        width < BreakPoints.medium
                                            ? 'row'
                                            : jobs.length > 2
                                            ? 'column'
                                            : 'row'
                                    }
                                    width="full"
                                >
                                    <Box
                                        width={'full'}
                                        boxStyles={{ alignItems: 'flex-start' }}
                                    >
                                        <PreFooter
                                            backgroundFilled={false}
                                            backgroundColor={parsePrismicLightBackgroundColor(
                                                'green'
                                            )}
                                            dotColor={parsePrismicHighlightColor(
                                                'tertiary'
                                            )}
                                            color={parsePrismicHeadingColor(
                                                'tertiary'
                                            )}
                                            tagline={
                                                moment(job.RCAdvertValidFrom)
                                                    .format('DD/MM/YYYY')
                                                    .toUpperCase() || undefined
                                            }
                                            title={
                                                job.RCAdvertTitle || undefined
                                            }
                                            openNewTab={false}
                                            headingSize={
                                                !isMobile && jobs.length === 1
                                                    ? 'small'
                                                    : 'xsmall'
                                            }
                                            variant="left"
                                            imageUrl={undefined}
                                        />
                                    </Box>
                                    <Box
                                        boxStyles={{
                                            paddingTop:
                                                jobs.length > 2 &&
                                                width > BreakPoints.medium
                                                    ? '2.5rem'
                                                    : '',

                                            width:
                                                width < BreakPoints.mobile
                                                    ? '30%'
                                                    : '',
                                        }}
                                        extraClassName={styles.arrowLinkBox}
                                    >
                                        <ArrowLink
                                            link={
                                                context.lang === 'is'
                                                    ? `/starfstaekifaeri/${FriendlyURLTitle(
                                                          job.RCAdvertTitle.normalize(
                                                              'NFKD'
                                                          )
                                                      )}`
                                                    : `/english/job-opportunities/${FriendlyURLTitle(
                                                          job.RCAdvertTitle.normalize(
                                                              'NFKD'
                                                          )
                                                      )}`
                                            }
                                            linkText={
                                                context.lang === 'is'
                                                    ? 'Sjá nánar'
                                                    : 'See more'
                                            }
                                            color="primaryTeal500"
                                        />
                                    </Box>
                                </Box>
                            )
                        })}
                    </Box>
                ) : (
                    <Box width={'full'} justify="center" align="center">
                        <Lottie animationData={Loader} />
                    </Box>
                )}
            </Container>
        </Box>
    )
}

export default Jobs
