import { JumboHeading } from './AnimatedHeading.css'
import { parsePrismicHighlightColor } from '@orbit/prismic'
import { Box } from '@orbit/ui/core'
import * as styles from './AnimatedHeading.css'
import { useEffect, useState } from 'react'

const brandColors = [
    'green',
    'blue',
    'orange',
    'pink',
    'green',
    'blue',
    'orange',
    'pink',
    'green',
    'blue',
    'orange',
    'pink',
]

interface AnimatedHeadingProps {
    textBegin: string
    textEnd: string
    textHighlights: string
    startColor?: string
    animate?: boolean
}

export const AnimatedHeading: React.FC<AnimatedHeadingProps> = ({
    textBegin,
    textEnd,
    textHighlights,
    startColor = 'green',
    animate = true,
}) => {
    const [textIndex, setTextIndex] = useState<number>(0)
    const highlights = textHighlights.split(';')
    let start = 0
    for (let i = 0; i < 4; i += 1) {
        if (brandColors[i] === startColor) start = i
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setTextIndex((t) => (t !== highlights.length - 1 ? t + 1 : 0))
        }, 3000)

        return () => {
            clearInterval(interval)
        }
    }, [highlights.length, textIndex])

    return (
        <h1 className={JumboHeading}>
            {`${textBegin}`}
            {animate ? (
                <Box>
                    <span className={styles.animation}>
                        <span
                            style={{
                                color: parsePrismicHighlightColor(
                                    brandColors[textIndex]
                                ),
                            }}
                        >
                            {highlights[textIndex]}
                        </span>
                    </span>
                </Box>
            ) : (
                <span
                    style={{
                        color: parsePrismicHighlightColor(
                            brandColors[0 + start]
                        ),
                    }}
                >
                    {` ${highlights[0]} `}
                </span>
            )}
            {textEnd}
        </h1>
    )
}
