import { Box, Container, Heading, Pagination } from '@orbit/ui/core'
import { TTimelineSlice } from '../Timeline.types'
import Carousel, { ButtonGroupProps } from 'react-multi-carousel'
import { useEffect, useMemo, useState } from 'react'
import * as style from '../Timeline.css'
import 'react-multi-carousel/lib/styles.css'
import { useWindowSize } from 'react-use'
import { parsePrismicColorName, parsePrismicHeadingColor } from '@orbit/prismic'
import cn from 'classnames'
import PrismicTextSerializer from '../TextSerializer'

export const HorizontalTimeline = (slice: TTimelineSlice) => {
    const { width } = useWindowSize()
    const { items } = slice
    const [totalPages, setTotalPages] = useState(items.length)

    useEffect(() => {
        if (width < 550 || items.length === 1) {
            setTotalPages(items.length)
        } else if (width < 920 || items.length === 2) {
            setTotalPages(items.length - 1)
        } else if (width < 1200 || items.length === 3) {
            setTotalPages(items.length - 2)
        } else {
            setTotalPages(items.length - 3)
        }
    }, [width, items])

    const responsive = useMemo(() => {
        return {
            mobile: {
                breakpoint: { max: 550, min: 0 },
                items: 1,
                partialVisibilityGutter: 50,
            },
            tablet: {
                breakpoint: { max: 920, min: 550 },
                items: 2,
                partialVisibilityGutter: 50,
            },
            desktop: {
                breakpoint: { max: 1200, min: 920 },
                items: 3,
                partialVisibilityGutter: 50,
            },
            retina: {
                breakpoint: { max: 9999, min: 1200 },
                items: 4,
                partialVisibilityGutter: 50,
            },
        }
    }, [])

    const ButtonGroup = ({
        next,
        previous,
        goToSlide,
        total,
        ...rest
    }: ButtonGroupProps & { total: number }) => {
        const { carouselState } = rest
        const { currentSlide } = carouselState || { currentSlide: 0 }

        return (
            <div className={style.sliderDots}>
                <Container>
                    <Pagination
                        currentPage={Math.ceil(currentSlide / 1 + 1)}
                        variant={width > 768 ? 'dots' : 'numbers'}
                        color={parsePrismicHeadingColor(
                            slice.primary.slicecolor
                        )}
                        total={total}
                        pageSize={1}
                        onPageChange={(page) => {
                            goToSlide && goToSlide((page - 1) * 1)
                        }}
                    />
                </Container>
            </div>
        )
    }

    return (
        <Box
            extraClassName={style.carouselBox}
            anchorid={slice.primary.anchor || undefined}
        >
            <Box
                flexDirection="column"
                align="center"
                position="relative"
                marginBottom={80}
                width="full"
            >
                <Carousel
                    additionalTransfrom={0}
                    responsive={responsive}
                    centerMode={false}
                    swipeable={true}
                    showDots={false}
                    draggable={true}
                    infinite={false}
                    autoPlay={false}
                    partialVisible
                    slidesToSlide={1}
                    arrows={false}
                    renderButtonGroupOutside={true}
                    containerClass={style.carouselContainer}
                    sliderClass={style.carouselSlider}
                    itemClass={style.carouselItem}
                    customButtonGroup={<ButtonGroup total={totalPages} />}
                >
                    {items?.map((item, index) => (
                        <Box
                            width="full"
                            flexDirection="column"
                            key={`${index}-horizontal-timeline`}
                        >
                            <Box
                                paddingBottom={48}
                                justify="center"
                                extraClassName={cn(
                                    style.horizontalTimelineImageWrapper,
                                    style.TimelineSliceColorBorderBottom({
                                        colors: parsePrismicColorName(
                                            slice.primary.slicecolor
                                        ),
                                    })
                                )}
                            >
                                <img
                                    src={item.image.url || ''}
                                    alt={item.image.alt || item.title}
                                    className={style.defaultTimelineImage}
                                />
                            </Box>
                            <Box
                                display="flex"
                                flexDirection="column"
                                paddingTop={48}
                                extraClassName={style.horizontalTimelineContent}
                            >
                                {item.date && (
                                    <Heading
                                        type={'h4'}
                                        text={item.date.toUpperCase()}
                                        color={parsePrismicHeadingColor(
                                            slice.primary.slicecolor
                                        )}
                                        my={0}
                                        paddingBottom={2}
                                        style={{
                                            fontSize: '1rem',
                                            letterSpacing: '0.0625rem',
                                        }}
                                    />
                                )}
                                <Heading
                                    type={item.date ? 'h5' : 'h4'}
                                    text={item.title}
                                    my={0}
                                    style={{
                                        fontSize: '1.5rem',
                                        letterSpacing: '0.0625rem',
                                        textTransform: 'unset',
                                    }}
                                />
                                <PrismicTextSerializer
                                    slicecolor={parsePrismicHeadingColor(
                                        slice.primary.slicecolor
                                    )}
                                    text={item.text}
                                />
                            </Box>
                        </Box>
                    ))}
                </Carousel>
            </Box>
        </Box>
    )
}
