import { JSXMapSerializer } from '@prismicio/react'
import React from 'react'
import { Heading } from '../../../../orbit-ui/core/src'

export const Heading1: JSXMapSerializer['heading1'] = ({
    text = '',
    node,
    key,
    children,
}) => {
    return <Heading key={key} type="h1" text={text} />
}

export const Heading2: JSXMapSerializer['heading2'] = ({
    text = '',
    node,
    key,
    children,
}) => {
    return <Heading key={key} type="h2" text={text} />
}

export const Heading3: JSXMapSerializer['heading3'] = ({
    text = '',
    node,
    key,
    children,
}) => {
    return <Heading key={key} type="h3" text={text} />
}

export const Heading4: JSXMapSerializer['heading4'] = ({
    text = '',
    node,
    key,
    children,
}) => {
    return <Heading key={key} type="h4" text={text} />
}

export const Heading5: JSXMapSerializer['heading5'] = ({
    text = '',
    node,
    key,
    children,
}) => {
    return <Heading key={key} type="h5" text={text} />
}

export const Heading6: JSXMapSerializer['heading6'] = ({
    text = '',
    node,
    key,
    children,
}) => {
    return <Heading key={key} type="h6" text={text} />
}
