import {
    parsePrismicHighlightColorDarker,
    parsePrismicHighlightColorDarkerText,
    parsePrismicLightBackgroundColor,
} from '@orbit/prismic'
import { TMenuItemsSliceWithIcon } from '../MenuItem.types'
import { Box, Heading, Icon, Text } from '@orbit/ui/core'
import { BreakPoints, theme } from '@orbit/ui/themes'
import { useState, useEffect } from 'react'
import { useWindowSize } from 'react-use'
import { WithIconLinkStyle, WithIconWrapper } from './MenuItem.css'
import Link from 'next/link'
import { parsePrismicColorName } from '@orbit/prismic'

export const WithIconMenuItem = (slice: TMenuItemsSliceWithIcon) => {
    const flexSize = slice.items.length > 4 ? 2 : 1
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)
    useEffect(() => {
        if (width < BreakPoints.tabletSmall) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    return (
        <Box
            tabIndex={0}
            key={slice.primary.title}
            flexDirection="column"
            boxStyles={{
                backgroundColor: parsePrismicLightBackgroundColor(
                    slice.primary.slicecolor
                ),
                boxShadow: isMobile
                    ? `0 100vmax 0 100vmax ${parsePrismicLightBackgroundColor(
                          slice.primary.slicecolor
                      )}`
                    : `0 0 0 100vmax ${parsePrismicLightBackgroundColor(
                          slice.primary.slicecolor
                      )}`,
                clipPath: isMobile
                    ? 'inset(0 -100vmax 0 -100vmax)'
                    : 'inset(0 -100vmax 0 0)',
                paddingTop: isMobile ? '1.5rem' : '2.5rem',
                paddingBottom: isMobile ? '2rem' : '3rem',
                paddingLeft: isMobile ? 0 : '2.5rem',
                // paddingRight: '2.5rem',
                height: '100%',
            }}
            flex={flexSize}
        >
            <Box
                wrap="wrap"
                flexDirection="column"
                flex={1}
                boxStyles={{ height: '100%' }}
            >
                {slice.primary.title && (
                    <Heading
                        text={slice.primary.title}
                        type={'h5'}
                        style={{
                            fontSize: '0.75rem',
                            letterSpacing: '0.125rem',
                            width: '100%',
                            margin: '0',
                            marginBottom: isMobile ? '1rem' : '2.5rem',
                            color: parsePrismicHighlightColorDarkerText(
                                slice.primary.slicecolor
                            ),
                        }}
                    />
                )}
                <Box
                    wrap="wrap"
                    boxStyles={
                        isMobile ? { paddingLeft: '0.5rem' } : { gap: '2.5rem' }
                    }
                >
                    {slice.items.map(
                        (item, j: number) =>
                            item.link.url && (
                                <Link href={item.link.url} key={j}>
                                    <a
                                        className={WithIconLinkStyle({
                                            flexSize: flexSize,
                                        })}
                                        href={item.link.url}
                                    >
                                        {item.linktitle && (
                                            <Box
                                                flexDirection="row"
                                                align="flexStart"
                                                justify={'flexStart'}
                                                width="full"
                                                extraClassName={WithIconWrapper(
                                                    {
                                                        color:
                                                            slice.primary
                                                                .slicecolor ===
                                                            'White'
                                                                ? 'orange'
                                                                : parsePrismicColorName(
                                                                      slice
                                                                          .primary
                                                                          .slicecolor
                                                                  ),
                                                    }
                                                )}
                                            >
                                                <Box marginRight={24}>
                                                    <Icon
                                                        icon={item.icon}
                                                        color={parsePrismicHighlightColorDarker(
                                                            slice.primary
                                                                .slicecolor
                                                        )}
                                                        size="small"
                                                    />
                                                </Box>

                                                <Box flexDirection="column">
                                                    <span
                                                        style={{
                                                            fontSize:
                                                                '0.875rem',
                                                            lineHeight:
                                                                '1.375rem',
                                                            margin: '0rem',
                                                            fontWeight: '600',
                                                            color: theme.colors
                                                                .neutral[700],
                                                        }}
                                                    >
                                                        {item.linktitle}
                                                    </span>
                                                    <Text size="small">
                                                        {item.linkdescription}
                                                    </Text>
                                                </Box>
                                            </Box>
                                        )}
                                    </a>
                                </Link>
                            )
                    )}
                </Box>
            </Box>
        </Box>
    )
}

export default WithIconMenuItem
