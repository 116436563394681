import React from 'react'
import { TSpeakersSlice } from './Speakers.types'
import { DefaultSpeakers, WhiteBackground } from './variations'

const Speakers: React.FC<{ slice: TSpeakersSlice }> = ({ slice }) => {
    switch (slice.variation) {
        case 'default': {
            return DefaultSpeakers(slice)
        }
        case 'whiteBackground': {
            return WhiteBackground(slice)
        }
        default:
            return null
    }
}

export default Speakers
