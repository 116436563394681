import Link from 'next/link'
import { Box } from '../../Box'
import { Button } from '../../Button'
import { Icon } from '../../Icons'
import { Text } from '../../Text'
import * as styles from './ProductCard.css'

export interface ProductCardProps {
    cardLink: string
    productNumber?: string
    productDescription?: string
    productImage?: string
    productPrice?: string
    recommend?: boolean
    discount?: string
    oldPrice?: string
    piecesLeft?: number
    backgroundColor?: string
    onClickButton: () => void
    onClickFave: () => void
}

export const ProductCard: React.FC<ProductCardProps> = ({
    onClickButton,
    onClickFave,
    cardLink,
    productNumber,
    productDescription,
    productImage,
    productPrice,
    recommend = false,
    discount,
    oldPrice,
    piecesLeft,
    backgroundColor = 'white',
}) => {
    return (
        <Box
            flexDirection="column"
            alignSelf="stretch"
            justify="spaceBetween"
            boxStyles={{
                backgroundColor: backgroundColor,
                width: '100%',
            }}
            extraClassName={styles.cardLinkStyling}
            paddingBottom={24}
        >
            <Link href={cardLink}>
                <a
                    href={cardLink}
                    style={{
                        textDecoration: 'none',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box flexDirection="column" width={'full'}>
                        <Box justify="spaceBetween" width="full">
                            <Box
                                gap="0.5rem"
                                justify="center"
                                align="center"
                                paddingLeft={16}
                                paddingTop={8}
                            >
                                <input type="checkbox"></input>
                                <Text style={{ margin: 0 }}>Bera saman</Text>
                            </Box>
                            {piecesLeft && (
                                <Box
                                    extraClassName={styles.PiecesLeftBox}
                                    align="center"
                                    gap="0.5rem"
                                >
                                    <Box
                                        extraClassName={styles.PiecesLeftBubble}
                                    >
                                        <Text
                                            textClass={styles.PiecesLeftText}
                                            my={0}
                                        >
                                            {piecesLeft}
                                        </Text>
                                    </Box>
                                    <Text style={{ margin: 0 }}>eftir</Text>
                                </Box>
                            )}
                        </Box>
                        <Box
                            flexDirection="column"
                            width="full"
                            align="center"
                            justify="center"
                            paddingBottom={recommend ? 24 : 0}
                            paddingTop={piecesLeft && piecesLeft < 6 ? 8 : 16}
                        >
                            {productImage && (
                                <img
                                    src={productImage}
                                    style={{
                                        height: '150px',
                                        objectFit: 'contain',
                                        width: '100%',
                                    }}
                                ></img>
                            )}
                            {recommend && (
                                <Box
                                    extraClassName={styles.RecommendBox}
                                    width="full"
                                    justify="center"
                                >
                                    <Text textClass={styles.RecommendText}>
                                        VIÐ MÆLUM MEÐ
                                    </Text>
                                </Box>
                            )}
                        </Box>
                    </Box>
                    <Box flexDirection="column" width={'full'}>
                        <Box
                            flexDirection="column"
                            width="full"
                            justify="center"
                            boxStyles={{ padding: '0rem 1.6rem 1.5rem 1.6rem' }}
                        >
                            <Box
                                align="center"
                                width="full"
                                justify="spaceBetween"
                            >
                                <Box flexDirection="column" width="full">
                                    {oldPrice && (
                                        <Text textClass={styles.OldpriceText}>
                                            {oldPrice}
                                        </Text>
                                    )}
                                    <Box
                                        width="full"
                                        justify="spaceBetween"
                                        align="center"
                                        boxStyles={{ marginBottom: '0.533rem' }}
                                    >
                                        <Text
                                            textClass={styles.ProductPrice}
                                            style={{
                                                marginTop: oldPrice && 0,
                                            }}
                                        >
                                            {productPrice}
                                        </Text>
                                        {discount && (
                                            <Box
                                                extraClassName={
                                                    styles.DiscountBox
                                                }
                                            >
                                                <Text
                                                    textClass={
                                                        styles.DiscountText
                                                    }
                                                >
                                                    {discount}
                                                </Text>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                            <Text textClass={styles.ProductNumber}>
                                {productNumber}
                            </Text>
                            <Text
                                style={{
                                    margin: 0,
                                    lineHeight: '1.467rem',
                                    fontSize: '0.933rem',
                                }}
                            >
                                {productDescription}
                            </Text>
                        </Box>
                        <Box
                            align="center"
                            width="full"
                            justify="spaceBetween"
                            boxStyles={{
                                padding: '0rem 1rem 0rem 1rem',
                                gap: '1rem',
                            }}
                        >
                            <Button
                                variant="filled"
                                icon="ShoppingCart"
                                color="primary"
                                size="small"
                                onClick={onClickButton}
                            >
                                Bæta í körfu
                            </Button>
                            <a
                                onClick={onClickFave}
                                className={styles.FavourtieButton}
                            >
                                <Icon
                                    icon={'Heart'}
                                    size="medium"
                                    color="black"
                                />
                            </a>
                        </Box>
                    </Box>
                </a>
            </Link>
        </Box>
    )
}
