import { Box, Button, Heading, Text } from '@orbit/ui/core'
import { ImageFieldImage } from '@prismicio/types'
import {
    parsePrismicHeadingColor,
    parsePrismicHighlightColor,
} from '@orbit/prismic'
import { useWindowSize } from 'react-use'
import { BreakPoints } from '@orbit/ui/themes'
import { useState, useEffect } from 'react'
import * as styles from './Overview.css'
export interface ImageWithTextProps {
    tagline?: string
    title?: string
    text: string
    buttonLabel?: string
    buttonLink?: string
    sliceColor: string
    image?: ImageFieldImage
    imageshape?: string
    author?: string
    date?: string
}

export const FirstItem: React.FC<ImageWithTextProps> = ({
    tagline,
    title,
    text,
    buttonLabel,
    author,
    date,
    buttonLink,
    sliceColor,
    image,
    imageshape,
}) => {
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)
    useEffect(() => {
        if (width < BreakPoints.tabletSmall) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])
    return (
        <Box
            width={'full'}
            justify={'spaceBetween'}
            align={'stretch'}
            flexDirection={isMobile ? 'column' : 'rowReverse'}
        >
            <Box
                flexDirection="column"
                justify="spaceBetween"
                boxStyles={{
                    width: isMobile ? '100%' : 'calc(50% - 5rem)',
                }}
            >
                <Box flexDirection="column" gap="1rem">
                    {date && author ? (
                        <Text
                            textClass={styles.taglineStyle}
                            style={{
                                color: parsePrismicHighlightColor(sliceColor),
                            }}
                        >
                            {date + ' • ' + author}
                        </Text>
                    ) : (
                        tagline && (
                            <Text
                                textClass={styles.taglineStyle}
                                style={{
                                    color: parsePrismicHighlightColor(
                                        sliceColor
                                    ),
                                }}
                            >
                                {tagline}
                            </Text>
                        )
                    )}

                    {title && (
                        <Heading
                            paddingBottom={isMobile ? 1 : 2}
                            text={title}
                            type={'h1'}
                            className={styles.titleStyle}
                        />
                    )}

                    <Text textClass={styles.textStyle}>{text}</Text>
                </Box>
                {buttonLabel && (
                    <Box
                        width={'full'}
                        paddingTop={24}
                        paddingBottom={isMobile ? 24 : 0}
                    >
                        <Button
                            href={`${buttonLink}`}
                            variant="filled"
                            color={parsePrismicHeadingColor(sliceColor)}
                        >
                            {buttonLabel}
                        </Button>
                    </Box>
                )}
            </Box>
            {image && (
                <Box alignSelf="center" width={isMobile ? 'full' : 'half'}>
                    <Box>
                        <img
                            src={image.url || ''}
                            alt={image.alt || ''}
                            width={'100%'}
                            height={'auto'}
                            style={{
                                objectFit: 'cover',
                                borderRadius:
                                    imageshape === 'Rounded corners' ? 20 : 0,
                            }}
                        />
                    </Box>
                </Box>
            )}
        </Box>
    )
}
