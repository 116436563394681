import * as React from 'react'

export interface IconProps {
    width?: string
    height?: string
    color?: string
}

export const Twitter: React.FC<IconProps> = ({ width, height, color }) => {
    return (
        <svg
            width={width || '24'}
            height={height || '24'}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M22 5.8C21.2483 6.12609 20.4534 6.34166 19.64 6.44C20.4982 5.92732 21.1413 5.12078 21.45 4.17C20.6436 4.65006 19.7608 4.9883 18.84 5.17C18.2245 4.50257 17.405 4.0583 16.5098 3.90685C15.6147 3.75541 14.6945 3.90536 13.8938 4.33319C13.093 4.76102 12.4569 5.44253 12.0852 6.27084C11.7135 7.09914 11.6273 8.02739 11.84 8.91C10.2094 8.82753 8.61444 8.40295 7.15865 7.66386C5.70287 6.92477 4.41885 5.88769 3.39 4.62C3.02914 5.25017 2.83952 5.96382 2.84 6.69C2.83872 7.36438 3.00422 8.02862 3.32176 8.62356C3.63929 9.21851 4.09902 9.72571 4.66 10.1C4.00798 10.0823 3.36989 9.9073 2.8 9.59V9.64C2.80489 10.5849 3.13599 11.4991 3.73731 12.228C4.33864 12.9568 5.17326 13.4556 6.1 13.64C5.74326 13.7486 5.37288 13.8058 5 13.81C4.74189 13.807 4.48442 13.7836 4.23 13.74C4.49391 14.5528 5.00462 15.2631 5.69107 15.7722C6.37753 16.2812 7.20558 16.5636 8.06 16.58C6.6172 17.7153 4.83588 18.3349 3 18.34C2.66574 18.3411 2.33174 18.3211 2 18.28C3.87443 19.4903 6.05881 20.1327 8.29 20.13C9.82969 20.146 11.3571 19.855 12.7831 19.2741C14.2091 18.6931 15.505 17.8339 16.5952 16.7465C17.6854 15.6591 18.548 14.3654 19.1326 12.9409C19.7172 11.5164 20.012 9.98973 20 8.45C20 8.28 20 8.1 20 7.92C20.7847 7.33481 21.4615 6.61742 22 5.8V5.8Z"
                fill={color}
            />
        </svg>
    )
}
