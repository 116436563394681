import {
    parsePrismicBackgroundColor,
    parsePrismicDarkColor,
    parsePrismicHeadingColor,
} from '@orbit/prismic'
import {
    Box,
    Container,
    HeadingContainer,
    Table,
    Loader,
    ArrowLink,
} from '@orbit/ui/core'
import { useEffect, useState } from 'react'
import { TAirtableSlice } from '../Airtable.types'
import { theme } from '@orbit/ui/themes'
import { PrismicRichText } from '@prismicio/react'
import { sliceWrapper } from '../Airtable.css'
import useSwr from 'swr'
import Lottie from 'lottie-react'
import moment from 'moment'

type TTableRowDefault = {
    items: string[]
}
type TDataDefault = [
    {
        [index: string]: string | any
    }
]

const fetcher = (url: string) => fetch(url).then((res) => res.json())

export const DefaultAirtable = (slice: TAirtableSlice) => {
    const [tableHead, setTableHead] = useState<string[]>([])
    const [tableRows, setTableRows] = useState<TTableRowDefault[]>([])

    const { data: tableDataFromApi } = useSwr(
        `/api/airtable?tables=${slice.primary.base}-${slice.primary.table}`,
        fetcher
    )
    const isUrl = (str: string) => {
        try {
            new URL(str)
            return true
        } catch (e) {
            return false
        }
    }
    useEffect(() => {
        if (tableDataFromApi && tableDataFromApi.length > 0) {
            tableDataFromApi.forEach((data: TDataDefault) => {
                const heads = Object.keys(data[0])
                setTableHead(heads)
                const rows: any[] = data.map((item) => {
                    const tmpArr = []
                    for (let i = 0; i < heads.length; i++) {
                        if (isUrl(item[heads[i]])) {
                            tmpArr.push(
                                <ArrowLink
                                    link={item[heads[i]]}
                                    linkText={slice.primary.linktext || 'Skoða'}
                                    color="neutral900"
                                    newTab
                                ></ArrowLink>
                            )
                        } else if (
                            typeof item[heads[i]] !== 'string' &&
                            item[heads[i]] !== undefined
                        ) {
                            tmpArr.push(
                                <ArrowLink
                                    link={item[heads[i]][0].url}
                                    linkText={slice.primary.linktext || 'Skoða'}
                                    color="neutral900"
                                    newTab
                                ></ArrowLink>
                            )
                        } else if (
                            moment(item[heads[i]], 'YYYY-MM-DD', true).isValid()
                        ) {
                            tmpArr.push(moment(item[heads[i]]).format('L'))
                        } else {
                            tmpArr.push(item[heads[i]])
                        }
                    }
                    return {
                        items: tmpArr,
                    }
                })
                setTableRows(rows)
            })
        }
    }, [slice.items, slice.primary.linktext, tableDataFromApi])

    return (
        <Box
            anchorid={slice.primary.anchor || undefined}
            paddingTop={80}
            paddingBottom={80}
            boxStyles={{
                backgroundColor: slice.primary.backgroundfilled
                    ? parsePrismicBackgroundColor(slice.primary.slicecolor)
                    : theme.colors.neutral[100],
            }}
            extraClassName={sliceWrapper}
        >
            <Container>
                <HeadingContainer
                    tagline={
                        slice.primary.tagline
                            ? slice.primary.tagline
                            : undefined
                    }
                    title={slice.primary.title ? slice.primary.title : ''}
                    color={parsePrismicHeadingColor(slice.primary.slicecolor)}
                />
                {tableHead.length > 0 ? (
                    <Table
                        color={parsePrismicHeadingColor(
                            slice.primary.slicecolor
                        )}
                        backgroundFilled={slice.primary.backgroundfilled}
                        tableData={{
                            tableHead: {
                                items: tableHead,
                            },
                            tableRows: tableRows,
                            withLink: false,
                        }}
                        tableDescription={
                            <PrismicRichText
                                field={slice.primary.description}
                                components={{
                                    hyperlink: ({ text, node }) => (
                                        <a
                                            style={{
                                                color: parsePrismicDarkColor(
                                                    slice.primary.slicecolor
                                                ),
                                                fontWeight: 600,
                                                textDecoration: 'none',
                                            }}
                                            href={node.data.url}
                                            target={
                                                node.data.link_type === 'Web'
                                                    ? node.data.target
                                                    : ''
                                            }
                                            rel="noopener noreferrer"
                                        >
                                            {text}
                                        </a>
                                    ),
                                }}
                            />
                        }
                    />
                ) : (
                    <Box width={'full'} justify="center">
                        <Lottie animationData={Loader} />
                    </Box>
                )}
            </Container>
        </Box>
    )
}

export default DefaultAirtable
