import React from 'react'

import DefaultFaq from './variations/Default'
import { TFaqSlice } from './Faq.types'
import { WithImageFaq } from './variations'

const Faq: React.FC<{ slice: TFaqSlice }> = ({ slice }) => {
    switch (slice.variation) {
        case 'default': {
            return DefaultFaq(slice)
        }
        case 'withImage': {
            return WithImageFaq(slice)
        }
        default: {
            return null
        }
    }
}

export default Faq
