export const Pencil = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.6667 4.82667C14.6672 4.73893 14.6504 4.65195 14.6172 4.57073C14.584 4.4895 14.5351 4.41563 14.4733 4.35333L11.6467 1.52667C11.5844 1.46488 11.5105 1.416 11.4293 1.38282C11.348 1.34964 11.2611 1.33283 11.1733 1.33333C11.0856 1.33283 10.9986 1.34964 10.9174 1.38282C10.8362 1.416 10.7623 1.46488 10.7 1.52667L8.81333 3.41333L1.52667 10.7C1.46488 10.7623 1.416 10.8362 1.38282 10.9174C1.34964 10.9986 1.33283 11.0856 1.33333 11.1733V14C1.33333 14.1768 1.40357 14.3464 1.5286 14.4714C1.65362 14.5964 1.82319 14.6667 2 14.6667H4.82667C4.91995 14.6717 5.01326 14.6572 5.10055 14.6238C5.18783 14.5905 5.26714 14.5393 5.33333 14.4733L12.58 7.18667L14.4733 5.33333C14.5342 5.26872 14.5838 5.19435 14.62 5.11333C14.6264 5.06019 14.6264 5.00647 14.62 4.95333C14.6231 4.9223 14.6231 4.89103 14.62 4.86L14.6667 4.82667ZM4.55333 13.3333H2.66667V11.4467L9.28667 4.82667L11.1733 6.71333L4.55333 13.3333ZM12.1133 5.77333L10.2267 3.88667L11.1733 2.94667L13.0533 4.82667L12.1133 5.77333Z"
                fill="white"
            />
            <path d="M1 1L15 15" stroke="white" stroke-linecap="round" />
        </svg>
    )
}
