import * as React from 'react'

export interface IconProps {
    width?: string
    height?: string
    color?: string
}

export const LinkedIn: React.FC<IconProps> = ({
    width,
    height,
    color = 'black',
}) => {
    return (
        <svg
            width={width || '24'}
            height={height || '24'}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20.47 2H3.53C3.33958 1.99736 3.1505 2.03225 2.97357 2.10268C2.79663 2.17312 2.6353 2.27772 2.4988 2.41051C2.36229 2.5433 2.25328 2.70168 2.17799 2.87661C2.1027 3.05153 2.06261 3.23958 2.06 3.43V20.57C2.06261 20.7604 2.1027 20.9485 2.17799 21.1234C2.25328 21.2983 2.36229 21.4567 2.4988 21.5895C2.6353 21.7223 2.79663 21.8269 2.97357 21.8973C3.1505 21.9678 3.33958 22.0026 3.53 22H20.47C20.6604 22.0026 20.8495 21.9678 21.0264 21.8973C21.2034 21.8269 21.3647 21.7223 21.5012 21.5895C21.6377 21.4567 21.7467 21.2983 21.822 21.1234C21.8973 20.9485 21.9374 20.7604 21.94 20.57V3.43C21.9374 3.23958 21.8973 3.05153 21.822 2.87661C21.7467 2.70168 21.6377 2.5433 21.5012 2.41051C21.3647 2.27772 21.2034 2.17312 21.0264 2.10268C20.8495 2.03225 20.6604 1.99736 20.47 2V2ZM8.09 18.74H5.09V9.74H8.09V18.74ZM6.59 8.48C6.17626 8.48 5.77947 8.31564 5.48691 8.02309C5.19436 7.73053 5.03 7.33374 5.03 6.92C5.03 6.50626 5.19436 6.10947 5.48691 5.81691C5.77947 5.52436 6.17626 5.36 6.59 5.36C6.8097 5.33508 7.03218 5.35685 7.24287 5.42388C7.45357 5.49091 7.64774 5.60169 7.81266 5.74896C7.97757 5.89623 8.10953 6.07668 8.19987 6.27848C8.29022 6.48028 8.33692 6.6989 8.33692 6.92C8.33692 7.1411 8.29022 7.35971 8.19987 7.56152C8.10953 7.76332 7.97757 7.94377 7.81266 8.09104C7.64774 8.23831 7.45357 8.34909 7.24287 8.41612C7.03218 8.48315 6.8097 8.50491 6.59 8.48V8.48ZM18.91 18.74H15.91V13.91C15.91 12.7 15.48 11.91 14.39 11.91C14.0527 11.9125 13.7242 12.0183 13.4488 12.2132C13.1735 12.4081 12.9645 12.6827 12.85 13C12.7717 13.235 12.7378 13.4826 12.75 13.73V18.73H9.75C9.75 18.73 9.75 10.55 9.75 9.73H12.75V11C13.0225 10.5271 13.4189 10.1375 13.8964 9.8732C14.374 9.60888 14.9146 9.47985 15.46 9.5C17.46 9.5 18.91 10.79 18.91 13.56V18.74Z"
                fill={color}
            />
        </svg>
    )
}
