import Link from 'next/link'
import { Box } from '../../Box'
import { Heading } from '../../Heading'
import { OrbitImage } from '../../Image'
import { Text } from '../../Text'
import * as styles from './BlogCard.css'

export interface BlogCardProps {
    url: string
    slicecolor: string
    imageAlt?: string
    imageUrl?: string
    title?: string
    summary?: string
    author?: string
    authorimageAlt?: string
    authorimageUrl?: string
}

export const BlogCard: React.FC<BlogCardProps> = ({
    url,
    title,
    summary,
    author,
    authorimageAlt,
    authorimageUrl,
    imageUrl,
    imageAlt,
}) => {
    return (
        <Link href={url}>
            <a className={styles.linkWrapper}>
                {imageUrl && (
                    <Box boxStyles={{ height: 204 }} width="full">
                        <OrbitImage
                            alt={imageAlt || ''}
                            src={imageUrl || ''}
                            width="100%"
                            height={204}
                            layout="fill"
                            sizes="(max-width: 992px) 100vw, 33vw"
                        />
                    </Box>
                )}
                <Box
                    padding={5}
                    flexDirection="column"
                    boxStyles={{ height: '100%' }}
                >
                    {title && (
                        <Heading
                            my={0}
                            paddingTop={3}
                            paddingBottom={4}
                            text={title}
                            type={'h3'}
                        />
                    )}
                    {summary && (
                        <Box paddingBottom={24}>
                            <Text my={0} textClass={styles.textStyle}>
                                {summary}
                            </Text>
                        </Box>
                    )}

                    <Box />

                    <Box
                        align="flexEnd"
                        boxStyles={{
                            height: '100%',
                        }}
                    >
                        {author && (
                            <Box
                                align="center"
                                extraClassName={styles.authorBox}
                            >
                                {authorimageUrl && (
                                    <OrbitImage
                                        containerclass={styles.authorImageStyle}
                                        alt={authorimageAlt || ''}
                                        src={authorimageUrl || ''}
                                        width={'40'}
                                        height={'40'}
                                        layout="fill"
                                        style={{ borderRadius: '50%' }}
                                    />
                                )}
                                {author}
                            </Box>
                        )}
                    </Box>
                </Box>
            </a>
        </Link>
    )
}
