export const Loader = {
    v: '4.8.0',
    meta: { g: 'LottieFiles AE 3.0.2', a: '', k: '', d: '', tc: '' },
    fr: 30,
    ip: 0,
    op: 120,
    w: 300,
    h: 300,
    nm: 'C Lottie',
    ddd: 0,
    assets: [],
    layers: [
        {
            ddd: 0,
            ind: 1,
            ty: 4,
            nm: 'Content',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [150, 150, 0], ix: 2 },
                a: { a: 0, k: [-21.5, -0.75, 0], ix: 1 },
                s: { a: 0, k: [100, 100, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            d: 1,
                            ty: 'el',
                            s: { a: 0, k: [122, 122], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            nm: 'Ellipse Path 1',
                            mn: 'ADBE Vector Shape - Ellipse',
                            hd: false,
                        },
                        {
                            ty: 'st',
                            c: {
                                a: 0,
                                k: [0, 0, 0, 0],
                                ix: 3,
                            },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 33, ix: 5 },
                            lc: 1,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [-21.5, -0.75], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Ellipse 1',
                    np: 2,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
                {
                    ty: 'gr',
                    it: [
                        {
                            d: 1,
                            ty: 'el',
                            s: { a: 0, k: [46, 46], ix: 2 },
                            p: { a: 0, k: [100, 55], ix: 3 },
                            nm: 'Ellipse Path 1',
                            mn: 'ADBE Vector Shape - Ellipse',
                            hd: false,
                        },
                        {
                            ty: 'fl',
                            c: {
                                a: 0,
                                k: [
                                    0.02352941176, 0.71764705882, 0.65490196078,
                                    1,
                                ],
                                ix: 4,
                            },
                            o: { a: 0, k: 100, ix: 5 },
                            r: 1,
                            bm: 0,
                            nm: 'Fill 1',
                            mn: 'ADBE Vector Graphic - Fill',
                            hd: false,
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [-21.5, -0.75], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: {
                                a: 1,
                                k: [
                                    {
                                        i: { x: [0.833], y: [0.833] },
                                        o: { x: [0.167], y: [0.167] },
                                        t: 0,
                                        s: [0],
                                    },
                                    { t: 120, s: [359] },
                                ],
                                ix: 6,
                            },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform',
                        },
                    ],
                    nm: 'Ellipse 2',
                    np: 2,
                    cix: 2,
                    bm: 0,
                    ix: 2,
                    mn: 'ADBE Vector Group',
                    hd: false,
                },
            ],
            ip: 0,
            op: 120,
            st: 0,
            bm: 0,
        },
    ],
    markers: [],
}
