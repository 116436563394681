import { Box, Button } from '@orbit/ui/core'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import {
    FormStyles,
    SearchBoxWrapper,
    SearchButtonWrapper,
    SearchInputStyle,
} from './SearchInput.css'

type SearchInput = {
    lang: 'is' | 'en-gb'
}

export const SearchInput: React.FC<SearchInput> = ({ lang }) => {
    const [searchQuery, setSearchQuery] = useState('')

    const router = useRouter()
    const handleSearch = (
        e: React.MouseEvent<HTMLButtonElement> | React.FormEvent
    ) => {
        e.preventDefault()
        router.push(
            `/${
                lang === 'is' ? 'leitarnidurstodur' : 'english/search'
            }?query=${searchQuery}`
        )
    }
    return (
        <Box extraClassName={SearchBoxWrapper}>
            <Box flex={1} alignSelf="stretch">
                <form className={FormStyles} onSubmit={(e) => handleSearch(e)}>
                    <label htmlFor="search" className="sr-only">
                        {lang === 'is' ? 'Leit' : 'Search'}
                    </label>
                    <input
                        className={SearchInputStyle}
                        type="search"
                        id="search"
                        name="search"
                        placeholder={lang === 'is' ? 'Leit' : 'Search'}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </form>
            </Box>
            <Box extraClassName={SearchButtonWrapper}>
                <Button
                    size="small"
                    onClick={(e) => handleSearch(e)}
                    variant="icon"
                    icon="Search"
                    ariaLabel={lang === 'is' ? 'Leit' : 'Search'}
                />
            </Box>
        </Box>
    )
}
