import {
    RefObject,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
} from 'react'
import {
    ControlsBar,
    ControlsOverlay,
    iframeStyle,
    iframeWrapperStyle,
    PlayPauseButton,
    PlayPauseButtonIcon,
    VideoStyle,
    VideoWrapper,
} from './Video.css'
import { useState, useRef } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import debounce from 'lodash/debounce'
import { VideoContext, VideoContextProvider } from './VideoContext'
import { VideoControls } from './VideoControls'
import { VideoPlayIcon } from '../../assets/VideoPlayIcon'
import { Box } from '../Box'
import useSwr from 'swr'

export interface VideoProps {
    url: string
    color?: string
    renderAsModal?: boolean
    title?: string
    videoId?: string
}

type TVideoType = 'vimeo' | 'youtube' | 'unknown'

export const OrbitVideo: React.FC<VideoProps> = ({
    url,
    color,
    title,
    renderAsModal,
    videoId,
}) => {
    const [videoType, setVideoType] = useState<TVideoType>('vimeo')
    const iframeRef = useRef<HTMLIFrameElement>(null)

    useEffect(() => {
        if (url.indexOf('youtube') > -1 || url.indexOf('youtu.be') > -1) {
            setVideoType('youtube')
        } else if (url.indexOf('vimeo') > -1) {
            setVideoType('vimeo')
        } else {
            setVideoType('unknown')
        }
    }, [])

    if (videoType !== 'vimeo') {
        return (
            <Box extraClassName={`${iframeWrapperStyle}`}>
                <iframe
                    title={title}
                    className={iframeStyle}
                    ref={iframeRef}
                    src={url}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
            </Box>
        )
    }

    return (
        <VideoContextProvider>
            <OrbitVideoComponent
                url={url}
                color={color}
                renderAsModal={renderAsModal}
                videoId={videoId}
            />
        </VideoContextProvider>
    )
}

export const OrbitVideoComponent: React.FC<VideoProps> = ({
    url,
    color,
    renderAsModal = false,
    videoId,
}) => {
    const videoRef = useRef<HTMLVideoElement>(null)

    const [showControls, setShowControls] = useState(false)
    const [videoError, setVideoError] = useState(false)
    const videoState = useContext(VideoContext)
    const [thumbnailImage, setThumbnailImage] = useState<string | null>(null)

    const { data: thumbnailResponse, error } = useSwr(
        videoId ? `/api/video/thumbnail?video=${videoId}` : null,
        (url: string) => fetch(url).then((res) => res.json())
    )

    if (error && thumbnailImage !== null) setThumbnailImage(null)

    useEffect(() => {
        if (thumbnailResponse)
            setThumbnailImage(thumbnailResponse.thumbnail_large)
    }, [thumbnailResponse])

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Tab') {
            setShowControls(true)
        }
    }

    const debounceHideControls = debounce(() => {
        setShowControls(false)
    }, 3000)

    useEffect(() => {
        videoState.setRenderInModal(renderAsModal)
        // if (renderAsModal) handlePlayPause()
    }, [videoRef, renderAsModal])

    useEffect(() => {
        if (window) {
            window.addEventListener('keydown', handleKeyDown)
            window.addEventListener('keyup', debounceHideControls)
            return () => {
                window.removeEventListener('keydown', handleKeyDown)
                window.removeEventListener('keyup', debounceHideControls)
            }
        }

        return () => undefined
    }, [])

    const handleOnTimeUpdate = () => {
        if (videoRef.current) {
            videoState.setProgress(videoRef.current.currentTime)
        }
    }

    const handlePlayPause = () => {
        if (videoRef.current) {
            if (videoRef.current.paused) {
                videoRef.current.play()
                videoState.setIsPlaying(true)
            } else {
                videoRef.current.pause()
                videoState.setIsPlaying(false)
            }
        }
    }

    if (videoError) {
        return (
            <Box>
                <pre style={{ textAlign: 'center', flex: 1 }}>
                    An error occured loading the video
                </pre>
            </Box>
        )
    }

    return (
        <div
            onMouseOver={() => setShowControls(true)}
            onMouseLeave={debounce(() => setShowControls(false), 1500)}
            className={`${VideoWrapper}`}
        >
            <video
                className={VideoStyle}
                onError={() => {
                    if (!videoError) setVideoError(true)
                }}
                onClick={!renderAsModal ? handlePlayPause : undefined}
                onTimeUpdate={handleOnTimeUpdate}
                ref={videoRef}
                style={{ width: '100%' }}
                placeholder={thumbnailImage || undefined}
            >
                <source src={url} type="video/mp4" />
            </video>
            <AnimatePresence>
                {(showControls || !videoState.isPlaying || renderAsModal) && (
                    <motion.div
                        onClick={!renderAsModal ? handlePlayPause : undefined}
                        className={ControlsOverlay}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 0.5 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}
                    ></motion.div>
                )}
            </AnimatePresence>
            <AnimatePresence>
                {(!videoState.isPlaying || renderAsModal) && (
                    <motion.button
                        key={'playButton'}
                        initial={{
                            opacity: 0,
                            scale: 0.75,
                            x: '-50%',
                            y: '-50%',
                        }}
                        animate={{
                            opacity: 1,
                            scale: 1,
                            x: '-50%',
                            y: '-50%',
                        }}
                        exit={{
                            opacity: 0,
                            scale: 0.75,
                            x: '-50%',
                            y: '-50%',
                        }}
                        transition={{ duration: 0.1 }}
                        className={PlayPauseButton}
                        onClick={!renderAsModal ? handlePlayPause : undefined}
                    >
                        <VideoPlayIcon className={PlayPauseButtonIcon} />
                    </motion.button>
                )}
            </AnimatePresence>
            <AnimatePresence>
                {(showControls || !videoState.isPlaying) && !renderAsModal && (
                    <motion.div
                        className={ControlsBar}
                        initial={{ opacity: 0, y: '100%' }}
                        animate={{ opacity: 1, y: '0rem' }}
                        exit={{ opacity: 0, y: '100%' }}
                        transition={{ duration: 0.3, type: 'tween' }}
                    >
                        <VideoControls
                            playPauseHandler={handlePlayPause}
                            videoRef={videoRef}
                        />
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    )
}

export default OrbitVideoComponent
