import React, { useContext } from 'react'
import { createContext } from 'react'
import { Icon } from '../Icons'
import { listIconStyle, listItemStyle, listStyle } from './BulletList.css'

type ListType = 'ul' | 'ol'
type ListVariant = 'column' | 'twocolumns' | 'threecolumns'

export type BulletListProps = {
    variant?: ListVariant
    type?: ListType
    children: React.ReactNode
    color?: 'primary' | 'secondary' | 'tertiary' | 'accent'
    bulletPosition?: 'top' | 'center'
}

type BulletContext = Omit<BulletListProps, 'children'>

const BulletContext = createContext<BulletContext>({
    type: 'ul',
    variant: 'column',
    color: 'primary',
    bulletPosition: 'center',
})

export const OrbitBulletList: React.FC<BulletListProps> = ({
    variant = 'column',
    type = 'ul',
    children,
    color = 'primary',
    bulletPosition = 'center',
}) => {
    const renderList = (props: BulletListProps) => {
        return React.createElement(type, {
            className: listStyle({
                variant: props.variant,
                type: props.type,
                color: props.color,
            }),
            children: props.children,
        })
    }

    return (
        <BulletContext.Provider
            value={{
                type: type,
                variant: variant,
                color: color,
                bulletPosition: bulletPosition,
            }}
        >
            {renderList({ type, variant, children, color, bulletPosition })}
        </BulletContext.Provider>
    )
}

export const Bullet: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const { type, variant, color, bulletPosition } = useContext(BulletContext)
    return (
        <li
            className={listItemStyle({
                type: type,
                variant: variant,
                bulletposition: bulletPosition,
            })}
        >
            {type === 'ul' && (
                <span
                    className={listIconStyle({
                        color: color,
                        bulletposition: bulletPosition,
                    })}
                >
                    <Icon icon="Checkmark" color="white" size="small" />
                </span>
            )}
            {children}
        </li>
    )
}
