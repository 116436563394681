import React from 'react'
import { TContactFormContext, TContactFormSlices } from './ContactForm.types'
import { DefaultContactForm, ContactFormWithImage } from './variations'
import HubspotMeetingForm from './variations/HubspotMeetingForm'

const ContactForm: React.FC<{
    slice: TContactFormSlices
    context: TContactFormContext
}> = ({ slice, context }) => {
    switch (slice.variation) {
        case 'default': {
            return DefaultContactForm(slice, context)
        }
        case 'withImage': {
            return ContactFormWithImage(slice, context)
        }
        case 'hubspotMeeting': {
            return HubspotMeetingForm(slice)
        }
        default:
            return null
    }
}

export default ContactForm
