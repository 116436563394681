import { ArrowDownCircle } from '@orbit/assets'
import {
    parsePrismicBackgroundColor,
    parsePrismicHeadingColor,
    parsePrismicHighlightColor,
    parsePrismicLightBackgroundColor,
} from '@orbit/prismic'
import {
    Box,
    Container,
    Heading,
    MotionBox,
    Pagination as PaginationComponent,
    SolutionCard,
    Text,
} from '@orbit/ui/core'
import { BreakPoints, theme } from '@orbit/ui/themes'
import { cardRevealUp } from '@orbit/utils/framer-motion'
import { SliceComponentProps } from '@prismicio/react'
import algoliasearch from 'algoliasearch'
import { AnimatePresence } from 'framer-motion'
import sortBy from 'lodash/sortBy'
import { FC, useEffect, useRef, useState } from 'react'
import {
    Configure,
    connectHits,
    connectMenu,
    connectPagination,
    connectSearchBox,
    InstantSearch,
} from 'react-instantsearch-dom'
import { useWindowSize } from 'react-use'
import { AlgoliaSearchResultProps } from '../../types'
import { AlgoliaSearchBar } from './components'
import * as styles from './SolutionOverview.css'
import {
    TSolutionOverviewContext,
    TSolutionsOverviewSlices,
} from './SolutionsOverview.types'

const searchClient = algoliasearch(
    process.env['NEXT_PUBLIC_ALGOLIA_APPLICATION_ID'] ?? '',
    process.env['NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY'] ?? ''
)
const indexName = 'origo_new'

type MenuItems = {
    label: string
    isRefined: string
}

const SolutionsOverview: FC<{
    slice: TSolutionsOverviewSlices
    context: TSolutionOverviewContext
}> = ({ slice }) => {
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)
    const solutionsSection = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (width < BreakPoints.tabletSmall) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])
    const count = 9

    const sliceVariation =
        slice.variation === 'default'
            ? 'default'
            : slice.variation === 'withCircularImage'
            ? 'circle'
            : 'square'

    const CustomSearchBox = connectSearchBox(AlgoliaSearchBar)

    const Hits = ({ hits }: { hits: AlgoliaSearchResultProps[] }) => {
        return (
            <Box
                anchorid={slice.primary.anchor || undefined}
                extraClassName={styles.SolutionCardWrapper}
                position="relative"
                paddingBottom={hits.length < 9 ? 40 : 0}
            >
                {hits.map((hit, index) => {
                    return (
                        hit && (
                            <MotionBox
                                width="full"
                                position="relative"
                                flexDirection="column"
                                initial={isMobile ? 'visible' : 'hidden'}
                                whileInView="visible"
                                viewport={{
                                    once: true,
                                }}
                                transition={{
                                    delay: 0.1 * (index + 1),
                                    duration: 0.6,
                                }}
                                variants={cardRevealUp}
                                key={`${hit.uid}-${index}`}
                                style={{
                                    display:
                                        sliceVariation === 'default'
                                            ? 'grid'
                                            : 'block',
                                }}
                                extraClassName={styles.solutionCardWrapper}
                            >
                                <SolutionCard
                                    url={hit.url}
                                    color={parsePrismicLightBackgroundColor(
                                        slice.primary.slicecolor
                                    )}
                                    type={sliceVariation}
                                    title={hit.title}
                                    imageAlt={hit.image.url}
                                    imageUrl={
                                        hit.image.alt ? hit.image.alt : ''
                                    }
                                    tag={hit.sectionTitle}
                                    summary={hit.summary}
                                    buttonColor={parsePrismicHighlightColor(
                                        slice.primary.slicecolor
                                    )}
                                    headingLevel="h2"
                                />
                            </MotionBox>
                        )
                    )
                })}
            </Box>
        )
    }

    const CustomHits = connectHits(Hits)

    const Pagination = ({
        currentRefinement,
        nbPages,
        refine,
    }: {
        currentRefinement: number
        nbPages: number
        refine: (...args: unknown[]) => unknown
    }) => {
        return (
            <PaginationComponent
                currentPage={currentRefinement}
                variant="numbers"
                color={parsePrismicHeadingColor(slice.primary.slicecolor)}
                total={nbPages * count}
                pageSize={count}
                onPageChange={(page: number) => {
                    refine(page)
                    setTimeout(() => scrollToTopOfSolutionsSection(), 100)
                }}
            />
        )
    }

    const CustomPagination = connectPagination(Pagination)

    const MenuSelect = ({
        items,
        currentRefinement,
        refine,
    }: {
        items: object[]
        currentRefinement: string
        refine: (...args: unknown[]) => unknown
    }) => {
        return (
            <select
                name="selectSearch"
                placeholder="Lausnir eftir tegundum"
                aria-label="Lausnir eftir tegundum"
                onChange={(event) => refine(event.currentTarget.value)}
                className={styles.selectSearchBox}
            >
                Leitaðu í lausnunum okkar
                <option value="">Allar lausnir</option>
                {sortBy(items, (a: MenuItems) => a.label.toLowerCase()).map(
                    (item: any) => (
                        <option
                            key={item.label}
                            value={
                                item.isRefined ? currentRefinement : item.value
                            }
                        >
                            {item.label}
                        </option>
                    )
                )}
            </select>
        )
    }

    const CustomMenuSelect = connectMenu(MenuSelect)

    const scrollToTopOfSolutionsSection = () => {
        const element = document.getElementById('solutionsStart')
        if (solutionsSection.current)
            !isMobile
                ? window.scrollTo({
                      top: solutionsSection.current.offsetTop - 140,
                      behavior: 'smooth',
                  })
                : element?.scrollIntoView({
                      behavior: 'smooth',
                  })
    }

    return (
        <InstantSearch indexName={indexName} searchClient={searchClient}>
            <Configure
                filters="entityType:Lausnir AND listed:true"
                hitsPerPage={count}
            />
            <Box
                flexDirection="row"
                display="block"
                boxStyles={{
                    backgroundColor: parsePrismicBackgroundColor(
                        slice.primary.slicecolor
                    ),
                    height: '100%',
                }}
            >
                <Box
                    width="full"
                    justify="center"
                    align="center"
                    paddingLeft={16}
                    paddingRight={16}
                    paddingBottom={isMobile ? 40 : 80}
                    paddingTop={isMobile ? 8 : 16}
                    flexDirection="column"
                    boxStyles={{
                        backgroundColor: theme.colors.neutral[100],
                    }}
                    id="solutionsStart"
                >
                    <Heading
                        type="h1"
                        my={0}
                        paddingBottom={4}
                        text={slice.primary.title ?? ''}
                    />
                    <Text my={0} size="large">
                        {slice.primary.subtitle}
                    </Text>
                </Box>
                <Container>
                    <Box
                        justify="center"
                        flexDirection="column"
                        wrap="wrap"
                        display="block"
                        ref={solutionsSection}
                    >
                        <Box
                            marginTop={36}
                            marginBottom={36}
                            width="full"
                            extraClassName={styles.filterWrapper}
                        >
                            <Box position="relative" width="full">
                                <CustomMenuSelect
                                    limit={30}
                                    attribute="sectionTitle"
                                />
                                <Box extraClassName={styles.selectSearchIcon}>
                                    <ArrowDownCircle />
                                </Box>
                            </Box>
                            <Box
                                boxStyles={{ gridColumn: '2 / 4' }}
                                width="full"
                            >
                                <CustomSearchBox />
                            </Box>
                        </Box>
                        <AnimatePresence>
                            <CustomHits />
                        </AnimatePresence>
                        <CustomPagination />
                    </Box>
                </Container>
            </Box>
        </InstantSearch>
    )
}

export default SolutionsOverview
