import { linkResolver } from '..'
import moment from 'moment'
import { theme } from '@orbit/ui/themes'
import {
    FilledLinkToDocumentField,
    FilledLinkToMediaField,
    FilledLinkToWebField,
    LinkField,
} from '@prismicio/types'

export const FriendlyURLTitle = (title: string) => {
    title.normalize('NFKD')
    title = title.replace(/\?/g, '')
    title = title.replace(/-/g, '')
    title = title.replace(/\//, '')
    title = title.replace(/ /g, '-')
    title = title.replace(/--/g, '-')
    title = title.replace(/- /g, '-')
    title = title.replace(/æ/g, 'ae')
    title = title.replace(/ö/g, 'o')
    title = title.replace(/ð/g, 'd')
    title = title.replace(/þ/g, 'th')
    title = title.replace(/ú/g, 'u')
    title = title.replace(/á/g, 'a')
    title = title.replace(/í/g, 'i')
    title = title.replace(/ó/g, 'o')
    title = title.replace(/é/g, 'e')
    title = title.replace(/ý/g, 'y')
    if (title.charAt(title.length - 1) === '-') {
        return title.slice(0, -1).toLowerCase()
    }
    return title.toLowerCase()
}
export const productUrlGenerator = (
    title: string,
    productId: number,
    parent: string,
    grandparent?: string,
    greatgrandparent?: string
) => {
    if (grandparent && greatgrandparent) {
        return `${FriendlyURLTitle(greatgrandparent)}/${FriendlyURLTitle(
            grandparent
        )}/${FriendlyURLTitle(parent)}/${FriendlyURLTitle(title)}-${productId}`
    }
    if (grandparent && !greatgrandparent) {
        return `${FriendlyURLTitle(grandparent)}/${FriendlyURLTitle(
            parent
        )}/${FriendlyURLTitle(title)}-${productId}`
    }
    if (!parent && !grandparent && !greatgrandparent) {
        return `${FriendlyURLTitle(title)}-${productId}`
    }

    return `${FriendlyURLTitle(parent)}/${FriendlyURLTitle(title)}-${productId}`
}

export const parsePrismicHeadings = (type: string) => {
    type = type ? type.toLocaleLowerCase() : type
    switch (type) {
        case 'heading1':
            return 'h1'
        case 'heading2':
            return 'h2'
        case 'heading3':
            return 'h3'
        case 'heading4':
            return 'h4'
        case 'heading5':
            return 'h5'
        case 'heading6':
            return 'h6'
        default:
            return 'h2'
    }
}

export const parsePrismicHeadingColor = (type: string) => {
    type = type ? type.toLocaleLowerCase() : type
    switch (type) {
        case 'primary':
        case 'blue':
            return 'primary'
        case 'secondary':
        case 'orange':
            return 'secondary'
        case 'tertiary':
        case 'green':
            return 'tertiary'
        case 'accent':
        case 'pink':
            return 'accent'
        default:
            return 'primary'
    }
}

export const parsePrismicHighlightColor = (type: string) => {
    type = type ? type.toLocaleLowerCase() : type
    switch (type) {
        case 'primary':
        case 'blue':
            return theme.colors.primary[500]
        case 'secondary':
        case 'orange':
            return theme.colors.secondary[500]
        case 'tertiary':
        case 'green':
            return theme.colors.tertiary[500]
        case 'accent':
        case 'pink':
            return theme.colors.accent[500]
        case 'grey':
            return theme.colors.neutral[300]

        default:
            return theme.colors.primary[500]
    }
}

export const parsePrismicHighlightColorDarker = (type: string) => {
    type = type ? type.toLocaleLowerCase() : type
    switch (type) {
        case 'primary':
        case 'blue':
            return theme.colors.primary[600]
        case 'secondary':
        case 'orange':
            return theme.colors.secondary[600]
        case 'tertiary':
        case 'green':
            return theme.colors.tertiary[600]
        case 'accent':
        case 'pink':
            return theme.colors.accent[600]
        default:
            return theme.colors.secondary[600]
    }
}

export const parsePrismicHighlightColorDarkerText = (type: string) => {
    type = type ? type.toLocaleLowerCase() : type
    switch (type) {
        case 'primary':
        case 'blue':
            return theme.colors.primary[600]
        case 'secondary':
        case 'orange':
            return theme.colors.secondary[600]
        case 'tertiary':
        case 'green':
            return theme.colors.tertiary[600]
        case 'accent':
        case 'pink':
            return theme.colors.accent[600]
        default:
            return theme.colors.neutral[600]
    }
}

export const parsePrismicHighlightColorDarkest = (type: string) => {
    type = type ? type.toLocaleLowerCase() : type
    switch (type) {
        case 'primary':
        case 'blue':
            return theme.colors.primary[700]
        case 'secondary':
        case 'orange':
            return theme.colors.secondary[700]
        case 'tertiary':
        case 'green':
            return theme.colors.tertiary[700]
        case 'accent':
        case 'pink':
            return theme.colors.accent[700]
        default:
            return theme.colors.primary[700]
    }
}

export const parsePrismicBackgroundColor = (
    type: string | null,
    brightness?: string
) => {
    if (type === null) return theme.colors.neutral[100]

    type = type ? type.toLocaleLowerCase() : type
    switch (type) {
        case 'white':
            return theme.colors.neutral[100]
        case 'lightgray':
            return theme.colors.neutral[200]
        case 'blue':
            if (brightness === 'dark') {
                return theme.colors.primary[500]
            } else {
                return theme.colors.primary[300]
            }
        case 'orange':
            return theme.colors.secondary[300]
        case 'green':
            return theme.colors.tertiary[300]
        case 'pink':
            return theme.colors.accent[300]
        default:
            return theme.colors.neutral[100]
    }
}

export const parsePrismicTextCardBackgroundColor = (type: string) => {
    type = type ? type.toLocaleLowerCase() : type
    switch (type) {
        case 'primary':
        case 'blue':
            return theme.colors.primary[400]
        case 'secondary':
        case 'orange':
            return theme.colors.secondary[400]
        case 'tertiary':
        case 'green':
            return theme.colors.tertiary[400]
        case 'accent':
        case 'pink':
            return theme.colors.accent[400]
        default:
            return theme.colors.primary[400]
    }
}

export const parsePrismicLightBackgroundColor = (type: string) => {
    type = type ? type.toLocaleLowerCase() : type
    switch (type) {
        case 'white':
            return theme.colors.neutral[100]
        case 'lightgray':
            return theme.colors.neutral[200]
        case 'blue':
            return theme.colors.primary[200]
        case 'orange':
            return theme.colors.secondary[200]
        case 'green':
            return theme.colors.tertiary[200]
        case 'pink':
            return theme.colors.accent[200]
        default:
            return theme.colors.primary[200]
    }
}

export const parsePrismicDarkColor = (type: string) => {
    type = type ? type.toLocaleLowerCase() : type
    switch (type) {
        case 'white':
            return theme.colors.neutral[100]
        case 'lightgray':
            return theme.colors.neutral[200]
        case 'blue':
            return theme.colors.primary[600]

        case 'orange':
            return theme.colors.secondary[600]
        case 'green':
            return theme.colors.tertiary[600]
        case 'pink':
            return theme.colors.accent[600]
        default:
            return theme.colors.primary[600]
    }
}

export const parsePrismicColorName = (type: string) => {
    type = type ? type.toLocaleLowerCase() : type
    switch (type) {
        case 'blue':
            return 'blue'
        case 'orange':
            return 'orange'
        case 'green':
            return 'green'
        case 'pink':
            return 'pink'
        default:
            return 'blue'
    }
}

export const parsePrismicButtonColor = (type: string) => {
    switch (type) {
        case 'Blue':
            return 'primaryBlue600'
        case 'Orange':
            return 'primaryOrange600'
        case 'Green':
            return 'primaryTeal600'
        case 'Pink':
            return 'secondaryCoral600'
        default:
            return 'primaryBlue600'
    }
}

export const stringToSlug = (string: string) => {
    const parsed = string
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/[\u00f0]/g, 'd')
        .replace(/[\u00DE]/g, 'th')
        .replace(/[\u00FE]/g, 'th')
    return parsed
}

export interface DocumentLinkMeta {
    id: string
    type: string
    uid: string
}
export interface TypeProps {
    _linkType: string
    __typename: string
    _meta: DocumentLinkMeta
    url: string
    target: string
}

export const stringToPascalCase = (s: string) => {
    return (s.match(/[a-zA-Z0-9]+/g) || [])
        .map((w) => `${w.charAt(0).toUpperCase()}${w.slice(1)}`)
        .join('')
}

export const parsePrismicLinkType = (type: TypeProps) => {
    switch (type._linkType) {
        case 'Link.document':
            return linkResolver(type._meta)
        case 'Link.web':
            return type.url
        default:
            return type.url
    }
}

export const parsePrismicLink = (link: LinkField) => {
    switch (link.link_type) {
        case 'Document':
            return (link as FilledLinkToDocumentField).url
        case 'Web':
            return (link as FilledLinkToWebField).url
        case 'Media':
            return (link as FilledLinkToMediaField).url
        default:
            return 'url' in link ? link.url : ''
    }
}

export const parseTime = (time: string) => {
    return moment(time).format('LT')
}

export const parseDate = (date: string) => {
    return moment(date).format('LL')
}

export const parseSliceSpace = (type: string, responsive: boolean) => {
    switch (type) {
        case 'news':
        case 'blog':
        case 'frettir':
            return responsive ? 24 : 40
        default:
            return responsive ? 40 : 80
    }
}

export const currencyFormatter = (amount: number | undefined | null) => {
    if (amount !== undefined && amount !== null) {
        amount = Math.round(amount)
        return amount.toLocaleString('de') + ' kr.'
    } else {
        return '-'
    }
}

export const parseSelectedProductSort = (sort: string) => {
    switch (sort) {
        case 'Mest selt': {
            return 'ORDER_BY_TIMES_ORDERED_DESCENDING'
        }
        case 'Mest skoðað': {
            return 'ORDER_BY_TIMES_VIEWED_DESCENDING'
        }
        case 'Nýjast': {
            return 'ORDER_BY_DATE_ADDED_DESCENDING'
        }
        case 'Listaverð: Lægsta til hæsta': {
            return 'ORDER_BY_PRICE_ASCENDING'
        }
        case 'Listaverð: Hægsta til lægsta': {
            return 'ORDER_BY_PRICE_DESCENDING'
        }
        default: {
            return 'ORDER_BY_TIMES_VIEWED_DESCENDING'
        }
    }
}
