import {
    parsePrismicBackgroundColor,
    parsePrismicHeadingColor,
    parsePrismicHighlightColor,
    parseSliceSpace,
} from '@orbit/prismic'
import { useEffect, useState } from 'react'
import { Box, Container, MotionBox, SmallCardPreFooter } from '@orbit/ui/core'
import { BreakPoints } from '@orbit/ui/themes'
import { PrismicRichText } from '@prismicio/react'
import { useWindowSize } from 'react-use'
import {
    TPrefooterContext,
    TPreFooterpreFooterTwoCardsSliceDefault,
} from '../PreFooter.types'
import { cardRevealUp } from '@orbit/utils/framer-motion'

export const PreFooterTwoCards = (
    slice: TPreFooterpreFooterTwoCardsSliceDefault,
    context: TPrefooterContext
) => {
    const { width } = useWindowSize()

    const [deviceType, setDeviceType] = useState<
        'mobile' | 'tablet' | 'desktop'
    >('desktop')

    useEffect(() => {
        if (width < BreakPoints.mobile) {
            return setDeviceType('mobile')
        }
        if (width < BreakPoints.tabletSmall) {
            return setDeviceType('tablet')
        }
        return setDeviceType('desktop')
    }, [width])

    const { backgroundfilled, slicecolor, anchor } = slice.primary

    return (
        <Box
            anchorid={anchor || undefined}
            justify={'center'}
            boxStyles={{
                overflow: 'hidden',
                backgroundColor: backgroundfilled
                    ? parsePrismicBackgroundColor(slicecolor)
                    : 'white',
            }}
            paddingBottom={parseSliceSpace(
                context?.type,
                deviceType === 'tablet'
            )}
            paddingTop={parseSliceSpace(context?.type, deviceType === 'tablet')}
        >
            <Container>
                <Box
                    gap="2.5rem"
                    width={'full'}
                    flexDirection={
                        width < BreakPoints.tabletSmall ? 'column' : 'row'
                    }
                >
                    {slice.items.map((item, i) => {
                        return (
                            <MotionBox
                                paddingLeft={deviceType === 'mobile' ? 40 : 40}
                                paddingRight={deviceType === 'mobile' ? 40 : 40}
                                align={'center'}
                                justify="spaceBetween"
                                flexDirection={
                                    deviceType === 'mobile' ? 'column' : 'row'
                                }
                                boxStyles={{
                                    backgroundColor: item.cardbackgroundfilled
                                        ? parsePrismicBackgroundColor(
                                              item.cardcolor
                                          )
                                        : 'white',

                                    alignSelf: 'stretch',
                                    overflow: 'clip',
                                }}
                                paddingTop={40}
                                paddingBottom={40}
                                initial={
                                    deviceType === 'mobile'
                                        ? 'visible'
                                        : 'hidden'
                                }
                                viewport={{
                                    once: true,
                                }}
                                whileInView="visible"
                                transition={{
                                    duration: 0.6,
                                    delay: 0.2 + i * 1,
                                }}
                                variants={cardRevealUp}
                                width={
                                    width < BreakPoints.tablet ? 'full' : 'half'
                                }
                            >
                                <SmallCardPreFooter
                                    backgroundFilled={
                                        !item.cardbackgroundfilled || false
                                    }
                                    backgroundColor={
                                        item.cardbackgroundfilled
                                            ? parsePrismicBackgroundColor(
                                                  item.cardcolor
                                              )
                                            : 'white'
                                    }
                                    dotColor={parsePrismicHighlightColor(
                                        item.cardcolor
                                    )}
                                    color={parsePrismicHeadingColor(
                                        item.cardcolor
                                    )}
                                    imageUrl={
                                        (item.image && item.image.url) ||
                                        undefined
                                    }
                                    imageAlt={
                                        (item.image && item.image.alt) || ''
                                    }
                                    tagline={item.tagline || undefined}
                                    title={item.title || undefined}
                                    buttonIcon={item.buttonicon}
                                    buttonLink={item.buttonlink.url}
                                    buttonLabel={item.buttonlabel || undefined}
                                    text={<PrismicRichText field={item.text} />}
                                    openNewTab={false}
                                    variant={
                                        deviceType === 'mobile'
                                            ? 'center'
                                            : item.image &&
                                              item.image.url === undefined
                                            ? 'center'
                                            : 'left'
                                    }
                                    imageshape={item.imageshape || 'Circle'}
                                    headingSize={'xsmall'}
                                />
                            </MotionBox>
                        )
                    })}
                </Box>
            </Container>
        </Box>
    )
}

export default PreFooterTwoCards
