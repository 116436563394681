import { FC, useEffect, useState } from 'react'
import {
    Box,
    Button,
    Carousel,
    Container,
    MotionBox,
    OrbitImage,
    Text,
} from '@orbit/ui/core'
import { TAnnualReportsSlice } from './AnnualReports.types'
import { BreakPoints, theme } from '@orbit/ui/themes'
import { useWindowSize } from 'react-use'
import { cardRevealUp } from '@orbit/utils/framer-motion'

const AnnualReports: FC<{ slice: TAnnualReportsSlice }> = ({ slice }) => {
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)
    useEffect(() => {
        if (width < BreakPoints.tabletSmall) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    return (
        <Box
            anchorid={slice.primary.anchor || undefined}
            paddingTop={isMobile ? 36 : 80}
            paddingBottom={isMobile ? 36 : 80}
            flexDirection="column"
            boxStyles={{
                alignItems: 'center',
                backgroundColor: theme.colors.primary[200],
            }}
        >
            <Container>
                <Carousel
                    totalItems={slice.items.length}
                    pageSize={{
                        mobile: 1,
                        tablet: 2,
                        desktop: 4,
                    }}
                    autoPlaySpeed={0}
                    color="primary"
                    variant={'dots'}
                    itemsToSlide={{
                        mobile: 1,
                        tablet: 1,
                        desktop: 1,
                    }}
                    margin
                >
                    {slice.items.map((item, index) => {
                        return (
                            <MotionBox
                                key={'annual_report_' + index}
                                boxStyles={{
                                    marginLeft: '1rem',
                                    marginRight: '1rem',
                                    height: '100%',
                                }}
                                flexDirection="column"
                                initial={isMobile ? 'visible' : 'hidden'}
                                viewport={{
                                    once: true,
                                }}
                                whileInView="visible"
                                transition={{
                                    duration: 0.6,
                                    delay: 0.15 * (index + 1),
                                }}
                                variants={cardRevealUp}
                            >
                                {item.image && item.image.url && (
                                    <Box
                                        paddingBottom={16}
                                        width="full"
                                        boxStyles={{
                                            minWidth: '100%',
                                            minHeight: '20rem',
                                            height: '100%',
                                        }}
                                    >
                                        <OrbitImage
                                            src={item.image.url}
                                            alt={
                                                item.image.alt ||
                                                'myndskreyting'
                                            }
                                            style={{
                                                objectFit: 'cover',
                                            }}
                                            height="100%"
                                            width="100%"
                                            objectFit="cover"
                                            layout="fill"
                                        />
                                    </Box>
                                )}

                                <Text style={{ fontWeight: 600 }} my={0}>
                                    {item.title}
                                </Text>
                                {item.link?.url && (
                                    <Button
                                        href={item.link.url}
                                        variant="text"
                                        color="primary"
                                        icon="ArrowRight"
                                        newTab={item.openNewTab}
                                    >
                                        {item.link_text || 'Skoða ársskýrslu'}
                                    </Button>
                                )}
                            </MotionBox>
                        )
                    })}
                </Carousel>
            </Container>
        </Box>
    )
}

export default AnnualReports
