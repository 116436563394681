export const CircularArrowDown = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.29 13.71C9.3851 13.801 9.49725 13.8724 9.62 13.92C9.86346 14.02 10.1365 14.02 10.38 13.92C10.5028 13.8724 10.6149 13.801 10.71 13.71L13.71 10.71C13.8983 10.5217 14.0041 10.2663 14.0041 10C14.0041 9.7337 13.8983 9.47831 13.71 9.29C13.5217 9.1017 13.2663 8.99591 13 8.99591C12.7337 8.99591 12.4783 9.1017 12.29 9.29L11 10.59V7C11 6.73479 10.8946 6.48043 10.7071 6.2929C10.5196 6.10536 10.2652 6 10 6C9.73478 6 9.48043 6.10536 9.29289 6.2929C9.10536 6.48043 9 6.73479 9 7V10.59L7.71 9.29C7.61704 9.19628 7.50644 9.12188 7.38458 9.07111C7.26272 9.02034 7.13201 8.99421 7 8.99421C6.86799 8.99421 6.73728 9.02034 6.61542 9.07111C6.49356 9.12188 6.38296 9.19628 6.29 9.29C6.19627 9.38297 6.12188 9.49357 6.07111 9.61543C6.02034 9.73729 5.9942 9.86799 5.9942 10C5.9942 10.132 6.02034 10.2627 6.07111 10.3846C6.12188 10.5064 6.19627 10.617 6.29 10.71L9.29 13.71ZM10 20C11.9778 20 13.9112 19.4135 15.5557 18.3147C17.2002 17.2159 18.4819 15.6541 19.2388 13.8268C19.9957 11.9996 20.1937 9.98891 19.8079 8.0491C19.422 6.10929 18.4696 4.32746 17.0711 2.92894C15.6725 1.53041 13.8907 0.578004 11.9509 0.192152C10.0111 -0.193701 8.00043 0.00433284 6.17317 0.761209C4.3459 1.51809 2.78412 2.79981 1.6853 4.4443C0.58649 6.08879 0 8.02219 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7363 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20ZM10 2C11.5823 2 13.129 2.4692 14.4446 3.34825C15.7602 4.2273 16.7855 5.47673 17.391 6.93854C17.9965 8.40035 18.155 10.0089 17.8463 11.5607C17.5376 13.1126 16.7757 14.538 15.6569 15.6569C14.538 16.7757 13.1126 17.5376 11.5607 17.8463C10.0089 18.155 8.40034 17.9965 6.93853 17.391C5.47672 16.7855 4.22729 15.7602 3.34824 14.4446C2.46919 13.129 2 11.5823 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84286 7.87827 2 10 2Z"
                fill="#0295F5"
            />
        </svg>
    )
}

export default CircularArrowDown
