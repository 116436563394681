import { useEffect, useState } from 'react'
import { BreakPoints } from '@orbit/ui/themes'
import {
    Box,
    Carousel,
    Container,
    Heading,
    HeadingContainer,
    MotionBox,
    OrbitImage,
    Text,
} from '@orbit/ui/core'
import { TLogoCardsSlice } from '../LogoSlider.types'
import {
    parsePrismicBackgroundColor,
    parsePrismicHeadingColor,
} from '@orbit/prismic'
import { useWindowSize } from 'react-use'
import * as styles from '../LogoSlider.css'
import { cardRevealUp } from '@orbit/utils/framer-motion'

export const LogoCards = (slice: TLogoCardsSlice) => {
    const [isMobile, setIsMobile] = useState(false)
    const { width } = useWindowSize()

    useEffect(() => {
        if (width < BreakPoints.tabletSmall) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    return (
        <Box
            anchorid={slice.primary.anchor || undefined}
            flexDirection="column"
            width="full"
            justify="center"
            align="center"
            paddingTop={36}
            boxStyles={{
                backgroundColor: parsePrismicBackgroundColor(
                    slice.primary.slicecolor
                ),
            }}
        >
            <Container>
                <HeadingContainer
                    title={slice.primary.title || undefined}
                    tagline={slice.primary.tagline || undefined}
                    color={parsePrismicHeadingColor(slice.primary.slicecolor)}
                    variant="center"
                    gap="1rem"
                />

                <Carousel
                    totalItems={slice.items.length}
                    pageSize={{
                        mobile: 1,
                        tablet: 2,
                        desktop: 3,
                    }}
                    autoPlaySpeed={0}
                    color={parsePrismicHeadingColor(slice.primary.slicecolor)}
                    tabletWidth={992}
                    partialVisibilityGutter={0}
                    variant={'dots'}
                    margin
                >
                    {slice.items.map((card, i) => (
                        <MotionBox
                            key={i}
                            padding={isMobile ? 0 : 4}
                            extraClassName={styles.boxAroundCard}
                            initial={isMobile ? 'visible' : 'hidden'}
                            viewport={{
                                once: true,
                            }}
                            whileInView="visible"
                            transition={{
                                duration: 0.6,
                                delay: 0.15 * (i + 1),
                            }}
                            variants={cardRevealUp}
                        >
                            <Box
                                flexDirection="column"
                                justify="spaceBetween"
                                extraClassName={styles.boxCard}
                            >
                                {card.logo && (
                                    <Box boxStyles={{ paddingBottom: '50px' }}>
                                        <OrbitImage
                                            objectFit="contain"
                                            objectPosition="center"
                                            src={card.logo.url || ''}
                                            width={79}
                                            height={60}
                                        />
                                    </Box>
                                )}
                                <Box flexDirection="column">
                                    {card.tagline && (
                                        <Heading
                                            text={card.tagline}
                                            type={'h6'}
                                            color={parsePrismicHeadingColor(
                                                slice.primary.slicecolor
                                            )}
                                            style={{
                                                margin: 0,
                                                fontSize: '0.75rem',
                                            }}
                                        />
                                    )}
                                    {card.title && (
                                        <Heading
                                            text={card.title}
                                            type={'h4'}
                                            style={{
                                                marginTop: 8,
                                                marginBottom: 8,
                                            }}
                                        />
                                    )}
                                </Box>
                                {card.text && (
                                    <Box paddingBottom={24}>
                                        <Text
                                            style={{
                                                lineHeight: '2rem',
                                                margin: 0,
                                            }}
                                        >
                                            {card.text}
                                        </Text>
                                    </Box>
                                )}
                            </Box>
                        </MotionBox>
                    ))}
                </Carousel>
            </Container>
        </Box>
    )
}

export default LogoCards
