import { Box } from '@orbit/ui/core'
import React, { useEffect, useState } from 'react'
import {
    ActiveCategoryListItemArrowStyle,
    ActiveCategoryListItemStyle,
    SubCategoryItemsListStyle,
    CategoryListItemArrowStyle,
    CategoryListItemStyle,
    CategoryListStyle,
    SubCategoryItemsListItemStyle,
} from './MobileMenu.css'
import { TCategoriesData, TCategory } from './VerslunMegaMenu'
import { AnimatePresence, motion } from 'framer-motion'
import { CategoryProductItem } from './verslun/CategoryProductItem'
import { SliceZone } from '@prismicio/react'
import MenuItem from '../MenuItem'
import { TMenu } from '../Header'
import { BreakPoints, theme } from '@orbit/ui/themes'
import { useWindowSize } from 'react-use'

type TVerslunMobileMenu = {
    categories?: TCategoriesData
    menu: TMenu
}

const MobileArrowDown = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.29 13.71C9.3851 13.801 9.49725 13.8724 9.62 13.92C9.86346 14.02 10.1365 14.02 10.38 13.92C10.5028 13.8724 10.6149 13.801 10.71 13.71L13.71 10.71C13.8983 10.5217 14.0041 10.2663 14.0041 10C14.0041 9.7337 13.8983 9.4783 13.71 9.29C13.5217 9.10169 13.2663 8.99591 13 8.99591C12.7337 8.99591 12.4783 9.10169 12.29 9.29L11 10.59V7C11 6.73478 10.8946 6.48043 10.7071 6.29289C10.5196 6.10535 10.2652 6 10 6C9.73478 6 9.48043 6.10535 9.29289 6.29289C9.10536 6.48043 9 6.73478 9 7V10.59L7.71 9.29C7.61704 9.19627 7.50644 9.12187 7.38458 9.07111C7.26272 9.02034 7.13201 8.9942 7 8.9942C6.86799 8.9942 6.73728 9.02034 6.61542 9.07111C6.49356 9.12187 6.38296 9.19627 6.29 9.29C6.19627 9.38296 6.12188 9.49356 6.07111 9.61542C6.02034 9.73728 5.9942 9.86799 5.9942 10C5.9942 10.132 6.02034 10.2627 6.07111 10.3846C6.12188 10.5064 6.19627 10.617 6.29 10.71L9.29 13.71ZM10 20C11.9778 20 13.9112 19.4135 15.5557 18.3147C17.2002 17.2159 18.4819 15.6541 19.2388 13.8268C19.9957 11.9996 20.1937 9.9889 19.8079 8.04909C19.422 6.10928 18.4696 4.32745 17.0711 2.92893C15.6725 1.5304 13.8907 0.577996 11.9509 0.192144C10.0111 -0.193708 8.00043 0.00432521 6.17317 0.761202C4.3459 1.51808 2.78412 2.7998 1.6853 4.44429C0.58649 6.08879 0 8.02218 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9996 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20ZM10 2C11.5823 2 13.129 2.46919 14.4446 3.34824C15.7602 4.22729 16.7855 5.47672 17.391 6.93853C17.9965 8.40034 18.155 10.0089 17.8463 11.5607C17.5376 13.1126 16.7757 14.538 15.6569 15.6569C14.538 16.7757 13.1126 17.5376 11.5607 17.8463C10.0089 18.155 8.40034 17.9965 6.93853 17.391C5.47672 16.7855 4.22729 15.7602 3.34824 14.4446C2.46919 13.129 2 11.5822 2 10C2 7.87827 2.84285 5.84343 4.34315 4.34314C5.84344 2.84285 7.87827 2 10 2Z"
                fill="#016FF9"
            />
        </svg>
    )
}

export const VerslunMobileMenu: React.FC<TVerslunMobileMenu> = ({
    categories,
    menu,
}) => {
    const [activeCategory, setActiveCategory] = useState<TCategory | undefined>(
        undefined
    )

    const { width } = useWindowSize()

    const [isMobile, setIsMobile] = useState(false)
    useEffect(() => {
        if (width < BreakPoints.tabletSmall) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    return (
        <Box width="full" flexDirection="column" gap="2rem">
            <ul className={CategoryListStyle}>
                {categories &&
                    categories.cats.map((c, i: number) => {
                        if (
                            c.numberOfProducts &&
                            !c.invisible &&
                            c.children?.length
                        ) {
                            return (
                                <React.Fragment key={'category_ ' + i}>
                                    <li
                                        className={`${CategoryListItemStyle} ${
                                            activeCategory &&
                                            activeCategory.id === c.id &&
                                            ActiveCategoryListItemStyle
                                        }`}
                                        onClick={() =>
                                            setActiveCategory((prev) =>
                                                prev && prev.id === c.id
                                                    ? undefined
                                                    : c
                                            )
                                        }
                                    >
                                        <Box
                                            justify="spaceBetween"
                                            align="center"
                                        >
                                            <Box>{c.name}</Box>
                                            <Box
                                                extraClassName={`${CategoryListItemArrowStyle} ${
                                                    activeCategory &&
                                                    activeCategory.id === c.id
                                                        ? ActiveCategoryListItemArrowStyle
                                                        : ''
                                                }`}
                                            >
                                                <MobileArrowDown />
                                            </Box>
                                        </Box>
                                    </li>
                                    <AnimatePresence>
                                        {activeCategory &&
                                            activeCategory.id === c.id &&
                                            activeCategory.children && (
                                                <motion.ul
                                                    initial={{
                                                        height: 0,
                                                        opacity: 0,
                                                    }}
                                                    animate={{
                                                        height: 'auto',
                                                        opacity: 1,
                                                        transition: {
                                                            height: {
                                                                duration: 0.4,
                                                            },
                                                            opacity: {
                                                                duration: 0.25,
                                                                delay: 0.15,
                                                            },
                                                        },
                                                    }}
                                                    exit={{
                                                        height: 0,
                                                        opacity: 0,
                                                        transition: {
                                                            height: {
                                                                duration: 0.4,
                                                            },
                                                            opacity: {
                                                                duration: 0.25,
                                                            },
                                                        },
                                                    }}
                                                    className={
                                                        SubCategoryItemsListStyle
                                                    }
                                                >
                                                    {activeCategory.children.map(
                                                        (
                                                            child: TCategory,
                                                            i
                                                        ) => {
                                                            if (
                                                                child.numberOfProducts &&
                                                                child.image
                                                            ) {
                                                                return (
                                                                    <li
                                                                        key={i}
                                                                        className={
                                                                            SubCategoryItemsListItemStyle
                                                                        }
                                                                    >
                                                                        <CategoryProductItem
                                                                            mobile={
                                                                                true
                                                                            }
                                                                            {...child}
                                                                        />
                                                                    </li>
                                                                )
                                                            }
                                                            return null
                                                        }
                                                    )}
                                                </motion.ul>
                                            )}
                                    </AnimatePresence>
                                </React.Fragment>
                            )
                        }

                        return null
                    })}
            </ul>
            <Box
                boxStyles={{
                    boxShadow: isMobile
                        ? `0 100vmax 0 100vmax ${theme.colors.neutral[200]}`
                        : `0 0 0 100vmax ${theme.colors.neutral[200]}`,
                    clipPath: isMobile
                        ? 'inset(0 -100vmax 0 -100vmax)'
                        : 'inset(0 -100vmax 0 0)',
                }}
            >
                <SliceZone
                    components={{ menu_item: MenuItem }}
                    slices={menu.slices}
                />
            </Box>
        </Box>
    )
}

export default VerslunMobileMenu
