import { FC } from 'react'
import {
    AllEvents,
    DefaultEventsOverview,
    EventsOverviewWithTag,
} from './variations'
import {
    TEventsOverviewContext,
    TEventsOverviewSlices,
} from './EventsOverview.types'

const EventsOverview: FC<{
    slice: TEventsOverviewSlices
    context: TEventsOverviewContext
}> = ({ slice, context }) => {
    switch (slice.variation) {
        case 'default': {
            return DefaultEventsOverview(slice, context)
        }
        case 'allEvents': {
            return AllEvents(slice, context)
        }
        case 'withTag': {
            return EventsOverviewWithTag(slice, context)
        }
        default: {
            return null
        }
    }
}

export default EventsOverview
