import React from 'react'

interface SvgProps {
    color?: string
}

export const Quote = ({ color }: SvgProps) => {
    return (
        <svg
            width="24"
            height="16"
            viewBox="0 0 24 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 10.8356C0 9.63168 0.221782 8.4066 0.665347 7.1604C1.10891 5.91419 1.65809 4.81584 2.31287 3.86535C2.96766 2.91485 3.5802 2.12277 4.1505 1.48911C4.72079 0.855446 5.22772 0.359076 5.67129 0L8.01584 1.36238C7.7835 1.74257 7.48779 2.36568 7.12871 3.23168C6.76964 4.09769 6.5901 4.76304 6.5901 5.22772C6.5901 5.33333 6.61122 5.4495 6.65347 5.57624C7.64621 5.80858 8.50165 6.37888 9.2198 7.28713C9.95908 8.17426 10.3287 9.3571 10.3287 10.8356C10.3287 12.103 9.82178 13.2752 8.80792 14.3525C7.79406 15.4086 6.62178 15.9366 5.29109 15.9366C3.62244 15.9366 2.32343 15.4086 1.39406 14.3525C0.464686 13.2752 0 12.103 0 10.8356ZM12.7366 10.899C12.7366 9.69505 12.9584 8.46997 13.402 7.22376C13.8455 5.97756 14.3947 4.87921 15.0495 3.92871C15.7043 2.97822 16.3168 2.18614 16.8871 1.55248C17.4574 0.918812 17.9644 0.422442 18.4079 0.0633663L20.7525 1.42574C20.5201 1.80594 20.2244 2.42904 19.8653 3.29505C19.5063 4.16106 19.3267 4.8264 19.3267 5.29109C19.3267 5.3967 19.3479 5.51287 19.3901 5.6396C20.3828 5.87195 21.2383 6.44224 21.9564 7.35049C22.6957 8.23762 23.0653 9.42046 23.0653 10.899C23.0653 12.1663 22.5584 13.3386 21.5446 14.4158C20.5307 15.4719 19.3584 16 18.0277 16C16.3591 16 15.0601 15.4719 14.1307 14.4158C13.2013 13.3386 12.7366 12.1663 12.7366 10.899Z"
                fill={color || '#000'}
            />
        </svg>
    )
}
