import { useEffect, useState } from 'react'
import {
    parsePrismicHeadingColor,
    parsePrismicHighlightColor,
    parsePrismicColorName,
    parsePrismicBackgroundColor,
} from '@orbit/prismic'
import { useWindowSize } from 'react-use'
import { BreakPoints, theme } from '@orbit/ui/themes'
import { TImageWithTextSliceWithIconBullets } from '../ImageWithText.types'
import { PrismicRichText } from '@prismicio/react'
import {
    Box,
    Container,
    Text,
    HeadingContainer,
    MotionBox,
    OrbitImage,
    Icon,
} from '@orbit/ui/core'
import Image from 'next/image'
import * as styles from './ImageWithText.css'
import { cardRevealUp } from '@orbit/utils/framer-motion'

export const WithIconBulletsImageWithText = (
    slice: TImageWithTextSliceWithIconBullets
) => {
    const { primary, items } = slice
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        if (width < BreakPoints.tabletSmall) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    const renderImage = () => {
        return (
            <Box
                width={'full'}
                boxStyles={{
                    position: 'relative',
                    height: primary.imageheightauto ? '100%' : 'unset',
                }}
            >
                <>
                    {slice.primary.overlay && (
                        <div
                            className={styles.circle({
                                color: parsePrismicColorName(
                                    slice.primary.slicecolor
                                ),
                            })}
                        ></div>
                    )}
                    {slice.primary.overlay && slice.primary.image.url ? (
                        <Box
                            extraClassName={` ${styles.SubpageLargeImage}`}
                            boxStyles={{
                                filter: slice.primary.overlay
                                    ? 'grayscale(100%) brightness(1.2)'
                                    : 'unset',
                            }}
                        >
                            <Image
                                src={slice.primary.image.url}
                                alt={slice.primary.image.alt || 'Myndskreyting'}
                                objectFit={
                                    slice.primary.overlay ? 'cover' : 'cover'
                                }
                                objectPosition={
                                    slice.primary.overlay
                                        ? 'center'
                                        : 'center left'
                                }
                                className={styles.image}
                                height="700"
                                width="900"
                                layout="fill"
                            />
                        </Box>
                    ) : (
                        primary.image && (
                            <>
                                {isMobile && (
                                    <img
                                        className={styles.image}
                                        src={primary.image.url || ''}
                                        alt={
                                            primary.image.alt || 'myndskreyting'
                                        }
                                        style={{
                                            objectFit: 'cover',
                                        }}
                                    />
                                )}

                                {!isMobile && (
                                    <OrbitImage
                                        src={primary.image.url || ''}
                                        alt={
                                            primary.image.alt || 'myndskreyting'
                                        }
                                        containerclass={styles.imageContainer}
                                        height={
                                            primary.imageheightauto
                                                ? 'auto'
                                                : 480
                                        }
                                        width={'100%'}
                                        layout="fill"
                                        objectFit="cover"
                                    />
                                )}
                            </>
                        )
                    )}
                    {slice.primary.overlay && slice.primary.image.url && (
                        <Box extraClassName={`${styles.ImageMask}`}>
                            <Image
                                src={slice.primary.image.url}
                                alt={slice.primary.image.alt || 'Myndskreyting'}
                                objectFit="cover"
                                objectPosition="center"
                                height="700"
                                width="900"
                                layout="fill"
                            />
                        </Box>
                    )}
                </>
            </Box>
        )
    }
    return (
        <MotionBox
            anchorid={slice.primary.anchor || undefined}
            justify="center"
            paddingBottom={isMobile ? 36 : 80}
            paddingTop={isMobile ? 36 : 80}
            boxStyles={{
                backgroundColor: primary.backgroundfilled
                    ? parsePrismicBackgroundColor(slice.primary.slicecolor)
                    : theme.colors.neutral[100],
            }}
            initial={isMobile ? 'visible' : 'hidden'}
            viewport={{
                once: true,
            }}
            whileInView="visible"
            transition={{
                duration: 0.6,
                delay: 0.3,
            }}
            variants={cardRevealUp}
        >
            <Container>
                <Box>
                    <Box
                        width={'full'}
                        display="flex"
                        flexDirection={
                            primary.textposition === 'right' && !isMobile
                                ? 'rowReverse'
                                : primary.textposition === 'right' && isMobile
                                ? 'columnReverse'
                                : primary.textposition !== 'right' && isMobile
                                ? 'column'
                                : 'row'
                        }
                        align="stretch"
                        gap="3rem"
                    >
                        <Box
                            flex={1}
                            flexDirection={'column'}
                            paddingBottom={isMobile ? 8 : 0}
                            align={'flexStart'}
                            width="full"
                            paddingRight={isMobile ? 0 : 48}
                            paddingLeft={isMobile ? 0 : 48}
                        >
                            <HeadingContainer
                                title={primary.title || undefined}
                                tagline={primary.tagline || undefined}
                                highlightColor={parsePrismicHighlightColor(
                                    slice.primary.slicecolor
                                )}
                                highlightStart={primary.highlightstart}
                                highlightEnd={primary.highlightend}
                                variant="left"
                                gap="1rem"
                                color={parsePrismicHeadingColor(
                                    slice.primary.slicecolor
                                )}
                                pb="2rem"
                            />
                            {primary.textposition === 'right' && isMobile && (
                                <Box width="full" paddingBottom={48}>
                                    {renderImage()}
                                </Box>
                            )}
                            <Box flexDirection="column" gap="2rem">
                                {items.map((item, index) => {
                                    return (
                                        <Box
                                            align="flexStart"
                                            key={`bullet-${index}`}
                                            gap="1rem"
                                        >
                                            <Box marginTop={4}>
                                                <Icon
                                                    icon={item.icon}
                                                    color={parsePrismicHighlightColor(
                                                        primary.slicecolor
                                                    )}
                                                    size="small"
                                                />
                                            </Box>
                                            <PrismicRichText
                                                field={item.text}
                                                components={{
                                                    strong: ({ text }) => (
                                                        <Text
                                                            size="large"
                                                            my={0}
                                                            style={{
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                            {text}
                                                        </Text>
                                                    ),
                                                    paragraph: ({ text }) => {
                                                        return (
                                                            <Text
                                                                size="large"
                                                                my={0}
                                                            >
                                                                {text}
                                                            </Text>
                                                        )
                                                    },

                                                    hyperlink: ({
                                                        text,
                                                        node,
                                                    }) => (
                                                        <a
                                                            style={{
                                                                fontWeight: 600,
                                                                textDecoration:
                                                                    'none',
                                                            }}
                                                            href={node.data.url}
                                                            target={
                                                                node.data
                                                                    .link_type ===
                                                                'Web'
                                                                    ? node.data
                                                                          .target
                                                                    : ''
                                                            }
                                                            rel="noopener noreferrer"
                                                        >
                                                            <Text
                                                                size="large"
                                                                my={0}
                                                            >
                                                                {text}
                                                            </Text>
                                                        </a>
                                                    ),
                                                }}
                                            />
                                        </Box>
                                    )
                                })}
                            </Box>
                        </Box>
                        <Box
                            flex={1}
                            width={isMobile ? 'full' : 'half'}
                            justify={
                                isMobile
                                    ? 'center'
                                    : primary.textposition === 'right'
                                    ? 'flexStart'
                                    : 'flexEnd'
                            }
                        >
                            {(primary.textposition !== 'right' ||
                                (primary.textposition === 'right' &&
                                    !isMobile)) && (
                                <Box
                                    width="full"
                                    paddingBottom={
                                        isMobile &&
                                        primary.textposition === 'right'
                                            ? 40
                                            : 48
                                    }
                                >
                                    {renderImage()}
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Container>
        </MotionBox>
    )
}

export default WithIconBulletsImageWithText
