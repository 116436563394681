import {
    parsePrismicHeadingColor,
    parsePrismicHighlightColor,
} from '@orbit/prismic'
import {
    Box,
    Text,
    Container,
    Heading,
    Button,
    OrbitImage,
} from '@orbit/ui/core'
import { useState, useEffect } from 'react'

import { THeroBannerSliceDefault } from '../HeroBanner.types'
import { CompanyLogos } from '../components'
import * as styles from '../HeroBanner.css'
import { PrismicRichText } from '@prismicio/react'
import { BreakPoints } from '@orbit/ui/themes'
import { useWindowSize } from 'react-use'
export const DefaultHeroBanner = (slice: THeroBannerSliceDefault) => {
    const { width } = useWindowSize()
    const [isInvestorsPage, setIsInvestorsPage] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const realBubbleColor = slice.primary.bubblecolor
        ? parsePrismicHeadingColor(slice.primary.bubblecolor)
        : 'none'

    useEffect(() => {
        if (window) {
            const path = window.location.pathname.slice(1).split('/')
            setIsInvestorsPage(Array(...path)[0] === 'fjarfestar')
        }
    }, [isInvestorsPage])
    return (
        <Box anchorid={slice.primary.anchor || undefined}>
            <Box width="full" boxStyles={{ overflow: 'hidden' }}>
                <Container>
                    <Box
                        extraClassName={`${
                            styles.HeroBannerWrapper
                        } ${styles.SubpageHeroBannerWrapper({
                            marginTop: isInvestorsPage ? false : undefined,
                        })}`}
                        align="center"
                    >
                        <Box
                            width={width < BreakPoints.mobile ? 'full' : 'half'}
                            flexDirection="column"
                            marginTop={0}
                        >
                            {slice.primary.logo && slice.primary.logo.url && (
                                <Box extraClassName={styles.imageBox}>
                                    <OrbitImage
                                        src={slice.primary.logo.url || ''}
                                        alt={slice.primary.logo.alt || ''}
                                        layout="fill"
                                        objectFit="contain"
                                        objectPosition="left"
                                        containerclass={styles.imageWrappers}
                                    />
                                </Box>
                            )}
                            {slice.primary.title && (
                                <Heading
                                    text={slice.primary.title}
                                    highlightStart={
                                        slice.primary.highlightstart &&
                                        slice.primary.highlightstart - 1
                                    }
                                    highlightEnd={
                                        slice.primary.highlightend &&
                                        slice.primary.highlightend - 1
                                    }
                                    highlightColor={parsePrismicHighlightColor(
                                        slice.primary.slicecolor
                                    )}
                                    type="h1"
                                    my={2}
                                    style={{
                                        overflowWrap: 'break-word',
                                        wordBreak: 'break-word',
                                    }}
                                />
                            )}

                            <PrismicRichText
                                components={{
                                    paragraph: ({ text }) => (
                                        <Text
                                            style={{ fontSize: '1.125rem' }}
                                            size="large"
                                        >
                                            {text}
                                        </Text>
                                    ),
                                }}
                                field={slice.primary.description}
                            />
                            <Box wrap="wrap">
                                {slice.primary.buttonlink?.url &&
                                    slice.primary.buttoncolor && (
                                        <Box
                                            marginRight={24}
                                            marginTop={24}
                                            marginBottom={24}
                                        >
                                            <Button
                                                variant={
                                                    slice.primary.buttonlabel
                                                        ? 'filled'
                                                        : 'icon'
                                                }
                                                size="large"
                                                color={parsePrismicHeadingColor(
                                                    slice.primary.buttoncolor
                                                )}
                                                icon={slice.primary.buttonicon}
                                                href={
                                                    slice.primary.buttonlink.url
                                                }
                                                ariaLabel={
                                                    slice.primary.buttonlabel
                                                        ? undefined
                                                        : slice.primary
                                                              .buttonicon
                                                }
                                                newTab={
                                                    slice.primary.openNewTab
                                                }
                                            >
                                                {slice.primary.buttonlabel}
                                            </Button>
                                        </Box>
                                    )}
                                {slice.primary.secondbuttonlink?.url &&
                                    slice.primary.buttoncolor && (
                                        <Box marginTop={24}>
                                            <Button
                                                variant={
                                                    slice.primary.buttonlabel
                                                        ? 'ghost'
                                                        : 'icon'
                                                }
                                                size="large"
                                                color={parsePrismicHeadingColor(
                                                    slice.primary.buttoncolor
                                                )}
                                                icon={
                                                    slice.primary
                                                        .secondbuttonicon
                                                }
                                                href={
                                                    slice.primary
                                                        .secondbuttonlink.url
                                                }
                                                newTab={
                                                    slice.primary
                                                        .openSecondNewTab
                                                }
                                            >
                                                {
                                                    slice.primary
                                                        .secondbuttonlabel
                                                }
                                            </Button>
                                        </Box>
                                    )}
                            </Box>
                            {slice.items.length !== 0 &&
                                slice.items[0].companylogo.url && (
                                    <Box boxStyles={{ height: '4.375rem' }}>
                                        <CompanyLogos
                                            items={slice.items}
                                            alt={slice.primary.title || ''}
                                        />
                                    </Box>
                                )}
                        </Box>
                        <Box
                            width={width < BreakPoints.mobile ? 'full' : 'half'}
                            justify={
                                slice.primary.bubbletextlarge ||
                                slice.primary.bubbletextsmall
                                    ? 'flexStart'
                                    : 'center'
                            }
                        >
                            <Box
                                position="relative"
                                width={80}
                                extraClassName={
                                    slice.primary.bubbletextlarge ||
                                    slice.primary.bubbletextsmall
                                        ? styles.SubpageImageWrapperWithBubbleText
                                        : styles.SubpageImageWrapper
                                }
                            >
                                {slice.primary.image &&
                                    slice.primary.image.url && (
                                        <Box
                                            extraClassName={`${styles.CircleImage}  ${styles.SubpageCircleImage}`}
                                            justify="center"
                                        >
                                            {isLoaded &&
                                                !(
                                                    slice.primary
                                                        .bubbletextlarge ||
                                                    slice.primary
                                                        .bubbletextsmall
                                                ) && (
                                                    <Box
                                                        extraClassName={`${styles.BannerImageMask(
                                                            {
                                                                color: realBubbleColor,
                                                                size: 'small',
                                                            }
                                                        )} ${
                                                            styles.ScaleMaskAnimation
                                                        }`}
                                                    />
                                                )}
                                            {isLoaded &&
                                                (slice.primary
                                                    .bubbletextlarge ||
                                                    slice.primary
                                                        .bubbletextsmall) && (
                                                    <Box
                                                        extraClassName={styles.BubbleWithText(
                                                            {
                                                                color: realBubbleColor,
                                                            }
                                                        )}
                                                        position="absolute"
                                                        flexDirection="column"
                                                        align="center"
                                                        justify="center"
                                                    />
                                                )}
                                            {isLoaded &&
                                                (slice.primary
                                                    .bubbletextlarge ||
                                                    slice.primary
                                                        .bubbletextsmall) && (
                                                    <Box
                                                        position="absolute"
                                                        flexDirection="column"
                                                        align="center"
                                                        justify="center"
                                                        extraClassName={`${styles.BubbleTextAnimation} ${styles.BubbleText} ${styles.BubbleTextBox}`}
                                                    >
                                                        {slice.primary
                                                            .bubbletextlarge && (
                                                            <Text
                                                                style={{
                                                                    fontSize:
                                                                        width <=
                                                                        BreakPoints.mobileSmall
                                                                            ? '16px'
                                                                            : '24px',
                                                                    fontWeight: 600,
                                                                    margin: 0,
                                                                }}
                                                            >
                                                                {
                                                                    slice
                                                                        .primary
                                                                        .bubbletextlarge
                                                                }
                                                            </Text>
                                                        )}
                                                        {slice.primary
                                                            .bubbletextsmall && (
                                                            <Text
                                                                style={{
                                                                    fontSize:
                                                                        width <=
                                                                        BreakPoints.mobileSmall
                                                                            ? '10px'
                                                                            : '16px',
                                                                    fontWeight: 600,
                                                                    margin: 0,
                                                                    lineHeight:
                                                                        width <=
                                                                        BreakPoints.mobileSmall
                                                                            ? '10px'
                                                                            : '24px',
                                                                }}
                                                            >
                                                                {
                                                                    slice
                                                                        .primary
                                                                        .bubbletextsmall
                                                                }
                                                            </Text>
                                                        )}
                                                    </Box>
                                                )}
                                            <OrbitImage
                                                src={
                                                    slice.primary.image.url ||
                                                    ''
                                                }
                                                alt={
                                                    slice.primary.image.alt ||
                                                    'Myndskreyting'
                                                }
                                                width={1000}
                                                height={1000}
                                                layout="fill"
                                                objectFit="cover"
                                                objectPosition="center"
                                                noSkeleton
                                                containerclass={`${
                                                    styles.SubpageCircleInnerImage
                                                } ${
                                                    isLoaded
                                                        ? styles.BannerMaskAnimation
                                                        : styles.notLoaded
                                                }`}
                                                onLoadingComplete={() => {
                                                    return setIsLoaded(true)
                                                }}
                                                style={{
                                                    backgroundColor:
                                                        parsePrismicHighlightColor(
                                                            slice.primary
                                                                .imagebackgroundcolor
                                                        ),
                                                    backgroundSize: '100% 100%',
                                                    borderRadius: '50%',
                                                    display: isLoaded
                                                        ? 'block'
                                                        : 'none',
                                                }}
                                            />
                                        </Box>
                                    )}
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </Box>
    )
}

export default DefaultHeroBanner
