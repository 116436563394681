import React from 'react'

type TVideoPlayerContext = {
    isPlaying: boolean
    isMuted: boolean
    progress: number
    volume: number
    speed: number
    renderInModal: boolean
    setIsPlaying: (isPlaying: boolean) => void
    setIsMuted: (isMuted: boolean) => void
    setProgress: (progress: number) => void
    setVolume: (volume: number) => void
    setSpeed: (speed: number) => void
    setRenderInModal: (modal: boolean) => void
}

export const VideoContext = React.createContext<TVideoPlayerContext>({
    isPlaying: false,
    isMuted: false,
    progress: 0,
    volume: 0.5,
    speed: 1,
    renderInModal: false,
    setIsPlaying: () => undefined,
    setIsMuted: () => undefined,
    setProgress: () => undefined,
    setVolume: () => undefined,
    setSpeed: () => undefined,
    setRenderInModal: () => undefined,
})

type TVideoPlayerContextProps = {
    children: React.ReactNode
}

export const VideoContextProvider = (props: TVideoPlayerContextProps) => {
    const setIsPlaying = (isPlaying: boolean) => {
        setVideoState((prev) => ({ ...prev, isPlaying }))
    }

    const setIsMuted = (isMuted: boolean) => {
        setVideoState((prev) => ({ ...prev, isMuted }))
    }

    const setProgress = (progress: number) => {
        setVideoState((prev) => ({ ...prev, progress }))
    }

    const setVolume = (volume: number) => {
        setVideoState((prev) => ({ ...prev, volume }))
    }

    const setSpeed = (speed: number) => {
        setVideoState((prev) => ({ ...prev, speed }))
    }

    const setRenderInModal = (renderInModal: boolean) => {
        setVideoState((prev) => ({ ...prev, renderInModal }))
    }

    const [videoState, setVideoState] = React.useState<TVideoPlayerContext>({
        isPlaying: false,
        isMuted: false,
        progress: 0,
        volume: 0.5,
        speed: 1,
        renderInModal: false,
        setIsPlaying: setIsPlaying,
        setIsMuted: setIsMuted,
        setProgress: setProgress,
        setVolume: setVolume,
        setSpeed: setSpeed,
        setRenderInModal: setRenderInModal,
    })

    return (
        <VideoContext.Provider value={videoState}>
            {props.children}
        </VideoContext.Provider>
    )
}
