import { theme } from '@orbit/ui/themes'
import moment from 'moment'
import { Box } from '../../Box'
import { Heading } from '../../Heading'
import { OrbitImage } from '../../Image'
import { Text } from '../../Text'
import * as styles from './NewsCard.css'

export interface NewsCardProps {
    url: string
    slicecolor: string
    imageAlt: string
    imageUrl?: string
    title: string
    summary: string
    date?: string
}

export const NewsCard: React.FC<NewsCardProps> = ({
    url,
    imageAlt,
    imageUrl,
    date,
    title,
    summary,
}) => {
    const imageHeight = 240

    return (
        <a href={`${url}`} className={styles.linkWrapper}>
            <OrbitImage
                containerclass={styles.imageWrapperStyle}
                src={imageUrl || ''}
                alt={imageAlt}
                height={imageHeight}
                layout="fill"
                width="100%"
                sizes="(max-width: 992px) 100vw, 33vw"
            />
            <Box
                flexDirection="column"
                boxStyles={{
                    paddingLeft: '1.5rem',
                    paddingRight: '1.5rem',
                }}
            >
                {date && (
                    <Text
                        style={{
                            fontSize: '0.875rem',
                            fontWeight: '600',
                            marginTop: '1.75rem',
                            color: theme.colors.primary[500],
                        }}
                    >
                        {moment(date).format('DD/MM/YYYY')}
                    </Text>
                )}
                <Heading
                    text={title}
                    type={'h3'}
                    style={{
                        marginTop: 0,
                        marginBottom: '1rem',
                    }}
                />
                <Text
                    style={{
                        marginTop: 0,
                        overflow: 'hidden',
                        display: '-webkit-box',
                        // WebkitLineClamp: 3,
                        WebkitBoxOrient: 'vertical',
                        textOverflow: 'ellipsis',
                        maxWidth: '100%',
                        visibility: 'visible',
                        wordWrap: 'break-word',
                        marginBottom: '2rem',
                    }}
                >
                    {summary}
                </Text>
            </Box>
        </a>
    )
}
