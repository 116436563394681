import { createContext, useContext, useReducer } from 'react'

const LinkStateContext = createContext({
    allPages: {},
    allSolutions: {},
    allNewss: {},
})
const LinkDispatchContext = createContext({})

function linkReducer(state, action) {
    // switch (action.type) {
    //     default: {
    //         throw new Error(`Unhandled action type: ${action.type}`)
    //     }
    // }
    return state
}

export const LinkContextProvider = ({ children, allNestedPaths }) => {
    if (allNestedPaths !== undefined) {
        const [state, dispatch] = useReducer(linkReducer, {
            allPages: allNestedPaths.allPages,
            allSolutions: allNestedPaths.allSolutions,
            allNewss: allNestedPaths.allNewss,
        })

        return (
            <LinkStateContext.Provider value={state}>
                <LinkDispatchContext.Provider value={dispatch}>
                    {children}
                </LinkDispatchContext.Provider>
            </LinkStateContext.Provider>
        )
    }
    return children
}

function UseLinkState() {
    const context = useContext(LinkStateContext)
    if (context === undefined) {
        throw new Error(
            'useLinkState must be used within a LinkContextProvider'
        )
    }
    return context
}

function useLinkDispatch() {
    const context = useContext(LinkDispatchContext)
    if (context === undefined) {
        throw new Error(
            'useLinkDispatch must be used within a LinkContextProvider'
        )
    }
    return context
}

export { UseLinkState, useLinkDispatch }
