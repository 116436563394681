import { useEffect, useState } from 'react'

import {
    parsePrismicBackgroundColor,
    parsePrismicHeadingColor,
} from '@orbit/prismic'
import {
    Box,
    Container,
    HeadingContainer,
    MotionBox,
    OrbitImage,
    Text,
} from '@orbit/ui/core'
import { BreakPoints } from '@orbit/ui/themes'
import moment from 'moment'
import { useWindowSize } from 'react-use'
import useSwr from 'swr'
import { TProductCategoriesSliceDefault } from '../ProductCategories.types'
import * as styles from '../ProductCategories.css'
import Link from 'next/link'
import { cardRevealUp } from '@orbit/utils/framer-motion'
const fetcher = (url: string) => fetch(url).then((res) => res.json())
export interface TCategory {
    color: string
    id: number
    image: string
    invisible: boolean
    level: number
    name: string
    numberOfProducts: number
    selected: boolean
}

export const DefaultProductCategories = (
    slice: TProductCategoriesSliceDefault
) => {
    moment.locale('is')
    const [isMobile, setIsMobile] = useState(false)
    const { width } = useWindowSize()

    useEffect(() => {
        if (width < BreakPoints.mobileSmall) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    const { data: categories } = useSwr(
        `/api/verslun/categorybyID?cats=${slice.items.map(
            (item) => item.categoryid
        )}`,
        fetcher
    )
    return (
        <Box
            anchorid={slice.primary.anchor || undefined}
            boxStyles={{
                backgroundColor: parsePrismicBackgroundColor(
                    slice.primary.slicecolor || 'Blue'
                ),
            }}
            paddingTop={isMobile ? 40 : 80}
            paddingBottom={isMobile ? 40 : 80}
            width="full"
        >
            <Container>
                <Box justify="center" align="center" flexDirection="column">
                    <HeadingContainer
                        variant="center"
                        tagline={slice.primary.tagline || undefined}
                        title={slice.primary.title || undefined}
                        color={parsePrismicHeadingColor(
                            slice.primary.slicecolor || 'Blue'
                        )}
                        pb="4rem"
                    />
                    <Box extraClassName={styles.cardGridWrapper}>
                        {categories &&
                            categories.cats.map(
                                (category: TCategory, i: number) => (
                                    <MotionBox
                                        flexDirection="column"
                                        justify="center"
                                        align="center"
                                        key={i}
                                        padding={isMobile ? 0 : 4}
                                        initial={
                                            isMobile ? 'visible' : 'hidden'
                                        }
                                        viewport={{
                                            once: true,
                                        }}
                                        whileInView="visible"
                                        transition={{
                                            duration: 0.6,
                                            delay: 0.15 * (i + 1),
                                        }}
                                        variants={cardRevealUp}
                                    >
                                        <Link
                                            href={slice.items[i].link.url || ''}
                                        >
                                            <a
                                                aria-label={category.name}
                                                className={
                                                    styles.LinkTextCardWrapper
                                                }
                                                href={
                                                    slice.items[i].link.url ||
                                                    ''
                                                }
                                            >
                                                <Box
                                                    extraClassName={
                                                        styles.ImageContainer
                                                    }
                                                    boxStyles={{
                                                        backgroundColor: `#${category.color}`,
                                                        margin: 'auto',
                                                        padding:
                                                            '0.75rem !important',
                                                    }}
                                                >
                                                    <OrbitImage
                                                        style={{
                                                            padding:
                                                                '0.75rem !important',
                                                        }}
                                                        objectFit="contain"
                                                        containerclass={
                                                            styles.ImageContainer
                                                        }
                                                        objectPosition={
                                                            'center'
                                                        }
                                                        width={78}
                                                        src={`https://verslun.origo.is/images/${category.image}`}
                                                        layout={'fill'}
                                                        placeholder={'blur'}
                                                        blurDataURL={`https://verslun.origo.is/images/${category.image}`.toString()}
                                                        quality={100}
                                                        alt={''}
                                                        sizes={'100vw'}
                                                        loading={'eager'}
                                                    />
                                                </Box>

                                                <Text
                                                    textClass={
                                                        styles.CategoryTitle
                                                    }
                                                >
                                                    {category.name}
                                                </Text>
                                            </a>
                                        </Link>
                                    </MotionBox>
                                )
                            )}
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

export default DefaultProductCategories
