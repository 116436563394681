import {
    parsePrismicBackgroundColor,
    parsePrismicHeadingColor,
    parseSliceSpace,
} from '@orbit/prismic'
import {
    Box,
    OrbitVideo,
    Carousel,
    Container,
    MotionBox,
    HeadingContainer,
} from '@orbit/ui/core'
import { cardRevealUp } from '@orbit/utils/framer-motion'
import { BreakPoints } from '@orbit/ui/themes'
import { useState, useEffect } from 'react'
import { useWindowSize } from 'react-use'
import { TVideoContext, TVideoSliceDefault } from '../Video.types'

export const DefaultVideo = (
    slice: TVideoSliceDefault,
    context: TVideoContext
) => {
    const { primary, items } = slice
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        if (width < BreakPoints.tablet) {
            return setIsMobile(true)
        }

        setIsMobile(false)
    }, [width])

    const sliceHeadingColor = parsePrismicHeadingColor(primary.slicecolor)

    return (
        <section
            data-anchorid={slice.primary.anchor || undefined}
            style={{
                backgroundColor: primary.backgroundfilled
                    ? parsePrismicBackgroundColor(primary.slicecolor)
                    : 'white',
                overflow: 'hidden',
                paddingBottom: parseSliceSpace(context.type, isMobile),
                paddingTop: parseSliceSpace(context.type, isMobile),
                flexDirection: 'column',
            }}
        >
            <Container>
                <HeadingContainer
                    tagline={slice.primary.tagline || undefined}
                    title={slice.primary.title || undefined}
                    color={sliceHeadingColor}
                    variant={slice.primary.tagline ? 'center' : 'left'}
                    size={slice.primary.tagline ? 'large' : 'small'}
                    pb={slice.primary.tagline ? '2.5rem' : '0rem'}
                />

                {items.length === 1 ? (
                    <MotionBox
                        width={isMobile ? 'full' : 'half'}
                        boxStyles={{ margin: '0 auto' }}
                        initial={isMobile ? 'visible' : 'hidden'}
                        viewport={{
                            once: true,
                        }}
                        whileInView="visible"
                        transition={{
                            delay: 0.2,
                            duration: 0.6,
                        }}
                        variants={cardRevealUp}
                    >
                        <OrbitVideo
                            title={`Myndband um ${slice.primary.title}`}
                            url={items[0].video.url}
                            color={primary.slicecolor}
                            videoId={items[0].videoId || undefined}
                        />
                    </MotionBox>
                ) : (
                    <Box justify={'center'} align={'center'} width={'full'}>
                        <Carousel
                            totalItems={items ? items.length : 1}
                            pageSize={{ mobile: 1, tablet: 2, desktop: 2 }}
                            color={sliceHeadingColor}
                            variant={'dots'}
                            paginationStyle={{
                                bottom: 0,
                                transform: 'translate(-50%, 100%)',
                            }}
                            margin
                        >
                            {items &&
                                items.map((item, index: number) => (
                                    <MotionBox
                                        key={'video_' + index}
                                        boxStyles={{ height: '100%' }}
                                        initial={
                                            isMobile ? 'visible' : 'hidden'
                                        }
                                        viewport={{
                                            once: true,
                                        }}
                                        whileInView="visible"
                                        transition={{
                                            delay: 0.2 * ((index % 2) + 1),
                                            duration: 0.6,
                                        }}
                                        variants={cardRevealUp}
                                    >
                                        <Box
                                            width="full"
                                            flexDirection="column"
                                            align="center"
                                            margin="auto"
                                            boxStyles={{
                                                height: '100%',
                                                padding: '0rem 1rem',
                                            }}
                                            gap="1rem"
                                            justify="spaceBetween"
                                        >
                                            <Box
                                                flexDirection="column"
                                                align="center"
                                            >
                                                {(item.title ||
                                                    item.tagline) && (
                                                    <HeadingContainer
                                                        tagline={
                                                            item.tagline ||
                                                            undefined
                                                        }
                                                        title={
                                                            item.title ||
                                                            undefined
                                                        }
                                                        color={
                                                            sliceHeadingColor
                                                        }
                                                        size={
                                                            item.tagline
                                                                ? 'large'
                                                                : 'small'
                                                        }
                                                        pb={
                                                            item.tagline
                                                                ? '2.5rem'
                                                                : '0rem'
                                                        }
                                                        subheading={{
                                                            hasParentTagline:
                                                                slice.primary
                                                                    .tagline
                                                                    ? true
                                                                    : false,
                                                            hasParentTitle:
                                                                slice.primary
                                                                    .title
                                                                    ? true
                                                                    : false,
                                                        }}
                                                    />
                                                )}
                                            </Box>
                                            {item.video && (
                                                <Box
                                                    width={'full'}
                                                    justify="center"
                                                    align="flexEnd"
                                                    flex={1}
                                                >
                                                    <OrbitVideo
                                                        title={`Myndband um ${slice.primary.title}`}
                                                        url={item.video.url}
                                                        color={
                                                            primary.slicecolor
                                                        }
                                                        videoId={
                                                            item.videoId ||
                                                            undefined
                                                        }
                                                    />
                                                </Box>
                                            )}
                                        </Box>
                                    </MotionBox>
                                ))}
                        </Carousel>
                    </Box>
                )}
            </Container>
        </section>
    )
}
export default DefaultVideo
