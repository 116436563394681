import { HeaderContext } from '@oribt/context'
import { useContext, useEffect } from 'react'
import {
    BannerStyle,
    BannerWrapper,
    CloseButton,
    ContentStyle,
} from './NotificationBanner.css'
import { useNotification } from './useNotification'

type NotificationType = 'info' | 'warning' | 'error' | 'success'

export interface NotificationBannerProps {
    id: string
    type: NotificationType
    children: React.ReactNode
}

export const NotificationBanner: React.FC<NotificationBannerProps> = ({
    id,
    type = 'info',
    children,
}) => {
    const headerContext = useContext(HeaderContext)

    const showNotification = useNotification(id)

    useEffect(() => {
        if (showNotification) {
            headerContext.setNotificationOpen(true)
        }
    }, [showNotification])

    const handleClose = () => {
        headerContext.setNotificationOpen(false)
        localStorage.setItem(`notification-${id}`, 'false')
    }
    if (
        !showNotification ||
        id === undefined ||
        !headerContext.isNotificationOpen
    )
        return null
    return (
        <div role="banner" className={BannerWrapper}>
            <div className={BannerStyle({ type: type })}>
                <div className={ContentStyle}>{children}</div>
                <button
                    onClick={() => handleClose()}
                    aria-label="Loka tilkynningu"
                    type="button"
                    className={CloseButton({ type: type })}
                />
            </div>
        </div>
    )
}
