import React from 'react'
import DefaultJobs from './variations/Default'
import { TJobsContext, TJobsSlices } from './Jobs.types'

const Jobs: React.FC<{ slice: TJobsSlices; context: TJobsContext }> = ({
    slice,
    context,
}) => {
    switch (slice.variation) {
        case 'default': {
            return DefaultJobs(slice, context)
        }
        default:
            return null
    }
}

export default Jobs
