import { useEffect, useState } from 'react'
import {
    parsePrismicHeadingColor,
    parsePrismicHighlightColor,
    parsePrismicColorName,
    parsePrismicBackgroundColor,
} from '@orbit/prismic'
import { useWindowSize } from 'react-use'
import { BreakPoints, theme } from '@orbit/ui/themes'
import { TImageWithTextSliceWithLogosSlice } from '../ImageWithText.types'
import {
    Box,
    Container,
    Button,
    HeadingContainer,
    MotionBox,
    OrbitImage,
} from '@orbit/ui/core'
import Image from 'next/image'
import * as styles from './ImageWithText.css'
import { cardRevealUp } from '@orbit/utils/framer-motion'
import { itemsLength } from '../utils'

export const WithLogosImageWithText = (
    slice: TImageWithTextSliceWithLogosSlice
) => {
    const { primary } = slice
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        if (width < BreakPoints.tabletSmall) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    const renderImage = () => {
        return (
            <Box
                width={'full'}
                boxStyles={{
                    position: 'relative',
                    height: primary.imageheightauto ? '100%' : 'unset',
                }}
            >
                {primary.imageshape !== 'Circle' && (
                    <>
                        {slice.primary.overlay && (
                            <div
                                className={styles.circle({
                                    color: parsePrismicColorName(
                                        slice.primary.slicecolor
                                    ),
                                })}
                            ></div>
                        )}
                        {slice.primary.overlay && slice.primary.image.url ? (
                            <Box
                                extraClassName={` ${styles.SubpageLargeImage}`}
                                boxStyles={{
                                    filter: slice.primary.overlay
                                        ? 'grayscale(100%) brightness(1.2)'
                                        : 'unset',
                                }}
                            >
                                <Image
                                    src={slice.primary.image.url}
                                    alt={
                                        slice.primary.image.alt ||
                                        'Myndskreyting'
                                    }
                                    objectFit={
                                        slice.primary.overlay
                                            ? 'cover'
                                            : 'cover'
                                    }
                                    objectPosition={
                                        slice.primary.overlay
                                            ? 'center'
                                            : 'center left'
                                    }
                                    className={styles.image}
                                    height="700"
                                    width="900"
                                    layout="fill"
                                />
                            </Box>
                        ) : (
                            primary.image && (
                                <>
                                    {isMobile && (
                                        <img
                                            className={styles.image}
                                            src={primary.image.url || ''}
                                            alt={
                                                primary.image.alt ||
                                                'myndskreyting'
                                            }
                                            style={{
                                                objectFit: 'cover',
                                            }}
                                        />
                                    )}

                                    {!isMobile && (
                                        <OrbitImage
                                            src={primary.image.url || ''}
                                            alt={
                                                primary.image.alt ||
                                                'myndskreyting'
                                            }
                                            containerclass={
                                                styles.imageContainer
                                            }
                                            height={
                                                primary.imageheightauto
                                                    ? 'auto'
                                                    : 480
                                            }
                                            width={'100%'}
                                            layout="fill"
                                            objectFit="cover"
                                        />
                                    )}
                                </>
                            )
                        )}
                        {slice.primary.overlay && slice.primary.image.url && (
                            <Box extraClassName={`${styles.ImageMask}`}>
                                <Image
                                    src={slice.primary.image.url}
                                    alt={
                                        slice.primary.image.alt ||
                                        'Myndskreyting'
                                    }
                                    objectFit="cover"
                                    objectPosition="center"
                                    height="700"
                                    width="900"
                                    layout="fill"
                                />
                            </Box>
                        )}
                    </>
                )}

                {primary.image && primary.imageshape === 'Circle' && (
                    <Box
                        boxStyles={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent:
                                !isMobile &&
                                primary.textposition !== 'Left side'
                                    ? 'flex-start'
                                    : 'center',
                            width: '100%',
                        }}
                    >
                        <Box
                            boxStyles={{ position: 'relative' }}
                            extraClassName={styles.circleImageWrapper({
                                color: parsePrismicHeadingColor(
                                    primary.slicecolor
                                ),
                            })}
                        >
                            <Box margin={4} width="full">
                                <OrbitImage
                                    containerclass={styles.circleImageWrappers}
                                    src={slice.primary.image.url || ' '}
                                    alt={slice.primary.image.alt || ''}
                                    layout="fill"
                                />
                            </Box>
                        </Box>
                    </Box>
                )}
            </Box>
        )
    }
    return (
        <MotionBox
            anchorid={slice.primary.anchor || undefined}
            justify="center"
            paddingBottom={isMobile ? 36 : 80}
            paddingTop={isMobile ? 36 : 80}
            boxStyles={{
                backgroundColor: primary.backgroundfilled
                    ? parsePrismicBackgroundColor(slice.primary.slicecolor)
                    : theme.colors.neutral[100],
            }}
            initial={isMobile ? 'visible' : 'hidden'}
            viewport={{
                once: true,
            }}
            whileInView="visible"
            transition={{
                duration: 0.6,
                delay: 0.3,
            }}
            variants={cardRevealUp}
        >
            <Container>
                <Box>
                    <Box
                        width={'full'}
                        display="flex"
                        flexDirection={
                            isMobile
                                ? 'columnReverse'
                                : primary.textposition === 'Right side'
                                ? 'rowReverse'
                                : 'row'
                        }
                        align="stretch"
                    >
                        <Box
                            flex={1}
                            flexDirection={'column'}
                            paddingBottom={isMobile ? 8 : 0}
                            align={'flexStart'}
                            width="full"
                            paddingRight={isMobile ? 0 : 48}
                            paddingLeft={isMobile ? 0 : 48}
                        >
                            <HeadingContainer
                                title={primary.title || undefined}
                                tagline={primary.tagline || undefined}
                                highlightColor={parsePrismicHighlightColor(
                                    slice.primary.slicecolor
                                )}
                                highlightStart={primary.highlightstart}
                                highlightEnd={primary.highlightend}
                                variant="left"
                                gap="1rem"
                                color={parsePrismicHeadingColor(
                                    slice.primary.slicecolor
                                )}
                                pb="1rem"
                            />

                            {isMobile && renderImage()}

                            {slice.items.length && (
                                <Box
                                    width={'full'}
                                    paddingTop={16}
                                    extraClassName={styles.gridWrapperWithLogos(
                                        {
                                            size: itemsLength(
                                                slice.items.length
                                            ),
                                        }
                                    )}
                                    boxStyles={{
                                        height: isMobile
                                            ? Math.ceil(
                                                  slice.items.length / 3
                                              ) * 50
                                            : Math.ceil(
                                                  slice.items.length / 3
                                              ) * 70,
                                    }}
                                >
                                    {slice.items.map((item) => (
                                        <Box
                                            boxStyles={{
                                                height: '100%',
                                                width: '100%',
                                            }}
                                        >
                                            <OrbitImage
                                                src={item.logo.url || ''}
                                                alt={
                                                    item.logo.alt ||
                                                    'myndskreyting'
                                                }
                                                containerclass={
                                                    styles.imageContainer
                                                }
                                                width="100%"
                                                layout="fill"
                                                objectFit="contain"
                                            />
                                        </Box>
                                    ))}
                                </Box>
                            )}

                            {primary.buttonlink && primary.buttonlink?.url && (
                                <Box
                                    width={'full'}
                                    paddingTop={24}
                                    justify={'flexStart'}
                                >
                                    <Button
                                        variant={
                                            primary.buttonlabel
                                                ? 'ghost'
                                                : 'icon'
                                        }
                                        href={primary.buttonlink.url}
                                        color={parsePrismicHeadingColor(
                                            slice.primary.slicecolor
                                        )}
                                        icon={primary.buttonicon}
                                        ariaLabel={
                                            primary.buttonlabel
                                                ? undefined
                                                : primary.buttonicon
                                        }
                                        newTab={primary.openNewTab}
                                    >
                                        {primary.buttonlabel}
                                    </Button>
                                </Box>
                            )}
                        </Box>
                        <Box
                            flex={1}
                            width={isMobile ? 'full' : 'half'}
                            justify={
                                isMobile
                                    ? 'center'
                                    : primary.textposition === 'Right side'
                                    ? 'flexStart'
                                    : 'flexEnd'
                            }
                        >
                            {!isMobile && renderImage()}
                        </Box>
                    </Box>
                </Box>
            </Container>
        </MotionBox>
    )
}

export default WithLogosImageWithText
