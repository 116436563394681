import { TMenuItemSliceWithBlogOrNews } from '../MenuItem.types'
import { ArrowLink, Box } from '@orbit/ui/core'
import {
    parsePrismicBackgroundColor,
    parsePrismicHighlightColorDarkest,
    parsePrismicLightBackgroundColor,
} from '@orbit/prismic'
import useSwr from 'swr'
import { ImageFieldImage } from '@prismicio/types'
import { BreakPoints } from '@orbit/ui/themes'
import { useState, useEffect } from 'react'
import { useWindowSize } from 'react-use'
import Link from 'next/link'

type IPillTag = {
    slicecolor: string
    title: string
}

type TApiResponse = {
    results: TApiResponseData[]
}

type TApiResponseData = {
    uid: string
    data: TData
    url: string
}

type TData = {
    image: ImageFieldImage
    title: string
    uid: string
}

const parseColor = (color: string) => {
    const c = color.toLocaleLowerCase()
    return c === 'blue'
        ? 'primaryBlue600'
        : c === 'green'
        ? 'primaryTeal600'
        : c === 'orange'
        ? 'primaryOrange600'
        : c === 'accent'
        ? 'secondaryCoral600'
        : 'primaryBlue600'
}

const PillTag: React.FC<IPillTag> = ({ slicecolor, title }) => {
    return (
        <Box
            boxStyles={{
                background: parsePrismicBackgroundColor(slicecolor),
                padding: '0.5rem 1rem',
                borderRadius: '2.5rem',
            }}
            align="center"
            justify="center"
        >
            <p
                style={{
                    fontSize: '0.75rem',
                    fontWeight: '600',
                    margin: 0,
                    color: parsePrismicHighlightColorDarkest(slicecolor),
                }}
            >
                {title}
            </p>
        </Box>
    )
}

type Language = 'is' | 'en-gb'

export const WithBlogOrNews = (slice: TMenuItemSliceWithBlogOrNews) => {
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)
    useEffect(() => {
        if (width < BreakPoints.tabletSmall) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    const fetcher = (url: string) => fetch(url).then((res) => res.json())

    const [lang, setLang] = useState<Language>('is')
    const { data: blog } = useSwr<TApiResponse>(
        `/api/blogg/latest?lang=${lang}`,
        fetcher
    )
    const { data: news } = useSwr<TApiResponse>(
        `/api/allNews/latest?lang=${lang}`,
        fetcher
    )

    useEffect(() => {
        if (window) {
            setLang(
                window.location.pathname.indexOf('/english') > -1
                    ? 'en-gb'
                    : 'is'
            )
        }
    }, [])

    return (
        <Box
            tabIndex={0}
            flex={1}
            flexDirection="column"
            boxStyles={{
                backgroundColor: parsePrismicLightBackgroundColor(
                    slice.primary.slicecolor
                ),
                boxShadow: isMobile
                    ? `0 100vmax 0 100vmax ${parsePrismicLightBackgroundColor(
                          slice.primary.slicecolor
                      )}`
                    : `0 0 0 100vmax ${parsePrismicLightBackgroundColor(
                          slice.primary.slicecolor
                      )}`,
                clipPath: isMobile
                    ? 'inset(0 -100vmax 0 -100vmax)'
                    : 'inset(0 -100vmax -100vmax 0)',
                height: '100%',
                paddingTop: '2.5rem',
                paddingBottom: '3rem',
                paddingLeft: isMobile ? '0rem' : '2.5rem',
                gap: '2.5rem',
            }}
        >
            {blog !== undefined && (
                <Link scroll={false} href={blog.results[0].url}>
                    <Box
                        width={'full'}
                        flexDirection="column"
                        px={4}
                        py={4}
                        boxStyles={{ cursor: 'pointer', background: 'white' }}
                    >
                        <PillTag
                            title={lang === 'is' ? 'Blogg' : 'Blog'}
                            slicecolor={slice.primary.slicecolor}
                        />
                        <p
                            style={{
                                fontWeight: '600',
                                fontSize: '1rem',
                                lineHeight: '1.25rem',
                                letterSpacing: '0.0125rem',
                            }}
                        >
                            {blog.results[0].data.title}
                        </p>
                        {blog.results[0].data.image.url && (
                            <img
                                style={{
                                    backgroundSize: 'cover',
                                    height: '120px',
                                    width: '100%',
                                    objectFit: 'cover',
                                    backgroundPosition: '50% 50%',
                                }}
                                src={blog.results[0].data.image.url}
                                alt=""
                            />
                        )}
                        {blog.results[0].url && (
                            <Box py={4}>
                                <ArrowLink
                                    link={blog.results[0].url}
                                    linkText={
                                        lang === 'is'
                                            ? 'Lesa blogg'
                                            : 'Read blog'
                                    }
                                    color={parseColor(slice.primary.slicecolor)}
                                />
                            </Box>
                        )}
                    </Box>
                </Link>
            )}
            {news !== undefined && news.results[0].url !== undefined && (
                <Link scroll={false} href={news.results[0].url}>
                    <Box
                        width={'full'}
                        flexDirection="column"
                        px={4}
                        py={4}
                        boxStyles={{ cursor: 'pointer', background: 'white' }}
                    >
                        <PillTag
                            title={lang === 'is' ? 'Fréttir' : 'News'}
                            slicecolor={slice.primary.slicecolor}
                        />
                        <p
                            style={{
                                fontWeight: '600',
                                fontSize: '1rem',
                                lineHeight: '1.25rem',
                                letterSpacing: '0.0125rem',
                            }}
                        >
                            {news.results[0].data.title}
                        </p>
                        {news.results[0].data.image.url && (
                            <img
                                style={{
                                    backgroundSize: 'cover',
                                    height: '120px',
                                    width: '100%',
                                    objectFit: 'cover',
                                    backgroundPosition: '50% 50%',
                                }}
                                src={news.results[0].data.image.url}
                                alt=""
                            />
                        )}
                        {news.results[0].url && (
                            <Box py={4}>
                                <ArrowLink
                                    link={news.results[0].url}
                                    linkText={
                                        lang === 'is'
                                            ? 'Lesa frétt'
                                            : 'Read news'
                                    }
                                    color={parseColor(slice.primary.slicecolor)}
                                />
                            </Box>
                        )}
                    </Box>
                </Link>
            )}
        </Box>
    )
}

export default WithBlogOrNews
