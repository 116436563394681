import { useEffect, useState } from 'react'
import {
    parsePrismicDarkColor,
    parsePrismicHeadingColor,
    parsePrismicHighlightColor,
    parsePrismicLightBackgroundColor,
    parsePrismicTextCardBackgroundColor,
} from '@orbit/prismic'
import { BreakPoints, theme } from '@orbit/ui/themes'
import { useWindowSize } from 'react-use'
import {
    TAdvertisingCardsItemsDefault,
    TAdvertisingCardsSliceDefault,
} from '../AdvertisingCards.types'
import {
    Box,
    Button,
    Container,
    HeadingContainer,
    AdvertisingCard,
} from '@orbit/ui/core'
import { PrismicRichText } from '@prismicio/react'
import { AnimatePresence } from 'framer-motion'

export const DefaultAdvertisingCards = (
    slice: TAdvertisingCardsSliceDefault
) => {
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        if (width < BreakPoints.medium) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    function adCards(
        item: TAdvertisingCardsItemsDefault,
        index: number,
        size: 'small' | 'medium' | 'large'
    ) {
        return (
            <AdvertisingCard
                key={item.title + '-' + index}
                image={item.cardImage.url || ''}
                imagealt={item.cardImage.alt || ''}
                index={index}
                title={item.title || undefined}
                tagline={item.tagline || undefined}
                highlightStart={item.highlightStart}
                highlightEnd={item.highlightEnd}
                highlightColor={parsePrismicHighlightColor(item.cardColor)}
                cardColor={parsePrismicHeadingColor(item.cardColor)}
                dotcolor={parsePrismicTextCardBackgroundColor(item.cardColor)}
                buttonLink={item.buttonLink?.url}
                buttonLabel={item.buttonLabel || undefined}
                openNewTab={item.openNewTab}
                cardSize={size}
                imageShape={item.photoShape}
                text={
                    <PrismicRichText
                        field={item.text}
                        components={{
                            hyperlink: ({ text, node }) => (
                                <a
                                    style={{
                                        color: parsePrismicDarkColor(
                                            item.cardColor
                                        ),
                                        fontWeight: 600,
                                        textDecoration: 'none',
                                    }}
                                    href={node.data.url}
                                    target={
                                        node.data.link_type === 'Web'
                                            ? node.data.target
                                            : ''
                                    }
                                    rel="noopener noreferrer"
                                >
                                    {text}
                                </a>
                            ),
                        }}
                    />
                }
                video={item.video ? item.video.url : undefined}
            />
        )
    }

    return (
        <Box
            paddingBottom={isMobile ? 40 : 80}
            paddingTop={isMobile ? 40 : 80}
            anchorid={slice.primary.anchor || undefined}
            boxStyles={{
                backgroundColor: theme.colors.neutral[200],
            }}
        >
            <Container>
                <>
                    <Box justify="center">
                        <HeadingContainer
                            variant="center"
                            tagline={slice.primary.tagline || undefined}
                            title={slice.primary.title || undefined}
                            highlightStart={
                                slice.primary.highlightStart || undefined
                            }
                            highlightEnd={slice.primary.highlightEnd}
                            color="gray"
                            pb="4rem"
                        />
                    </Box>
                    <Box
                        flexDirection={isMobile ? 'column' : 'row'}
                        wrap={slice.items.length > 3 ? 'wrap' : 'nowrap'}
                        display="flex"
                        gap={'2.5rem'}
                        boxStyles={{ rowGap: '2.5rem' }}
                    >
                        <AnimatePresence>
                            {slice.items?.map((item, index: number) => {
                                const cardCount = slice.items?.length
                                const leftovers = cardCount % 3
                                if (index + 1 > cardCount - leftovers) {
                                    if (leftovers === 1) {
                                        return adCards(item, index, 'large')
                                    } else if (leftovers === 2) {
                                        return adCards(item, index, 'medium')
                                    }
                                }
                                return adCards(item, index, 'small')
                            })}
                        </AnimatePresence>
                    </Box>
                    {slice.primary.buttonLink?.url && (
                        <Box justify="center" marginTop={48}>
                            <Button
                                variant={
                                    slice.primary.buttonLabel ? 'ghost' : 'icon'
                                }
                                color={parsePrismicHeadingColor(
                                    slice.primary.sliceColor
                                )}
                                href={slice.primary.buttonLink.url}
                                icon={slice.primary.buttonIcon}
                                ariaLabel={
                                    slice.primary.buttonLabel
                                        ? undefined
                                        : slice.primary.buttonIcon
                                }
                                newTab={slice.primary.openNewTab}
                            >
                                {slice.primary.buttonLabel}
                            </Button>
                        </Box>
                    )}
                </>
            </Container>
        </Box>
    )
}
export interface FieldProps {
    field: TAdvertisingCardsItemsDefault
}

export default DefaultAdvertisingCards
