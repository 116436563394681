import {
    parsePrismicBackgroundColor,
    parsePrismicDarkColor,
    parsePrismicHeadingColor,
} from '@orbit/prismic'
import {
    ArrowLink,
    Box,
    Container,
    HeadingContainer,
    Loader,
    TabBarSlider,
    Table,
} from '@orbit/ui/core'
import { useEffect, useState } from 'react'
import { TAirtableSliceWithTabs } from '../Airtable.types'
import { theme } from '@orbit/ui/themes'
import { PrismicRichText } from '@prismicio/react'
import { sliceWrapper } from '../Airtable.css'
import useSwr from 'swr'
import moment from 'moment'
import Lottie from 'lottie-react'

const fetcher = (url: string) => fetch(url).then((res) => res.json())

interface TableData {
    label: string
    children: React.ReactNode
}
type TDataDefault = [
    {
        [index: string]: Record<string, unknown>
    }
]

export const WithTabsAirtable = (slice: TAirtableSliceWithTabs) => {
    const apiString = slice.items.map((i) => `${i.base}-${i.table}`).join(',')
    const [table, setTables] = useState<TableData[]>([])
    moment.locale('is')
    const { data } = useSwr(`/api/airtable?tables=${apiString}`, fetcher)
    const isUrl = (str: string) => {
        try {
            new URL(str)
            return true
        } catch (e) {
            return false
        }
    }

    useEffect(() => {
        if (data && Array.isArray(data)) {
            const testArr: TableData[] = []
            let tableRows = []
            let tableHead: string[] = []
            data.forEach((table: TDataDefault, j: number) => {
                const heads = Object.keys(table[0])
                tableHead = heads
                const rows = table.map((rows: { [x: string]: any }) => {
                    const tmpArr = []
                    for (let i = 0; i < heads.length; i++) {
                        if (isUrl(rows[heads[i]])) {
                            tmpArr.push(
                                <ArrowLink
                                    link={rows[heads[i]]}
                                    linkText={
                                        slice.items[j].linktext || 'Skoða gögn'
                                    }
                                    color="neutral900"
                                    newTab
                                ></ArrowLink>
                            )
                        } else if (
                            typeof rows[heads[i]] !== 'string' &&
                            rows[heads[i]] !== undefined
                        ) {
                            tmpArr.push(
                                <ArrowLink
                                    link={rows[heads[i]][0].url}
                                    linkText={
                                        slice.items[j].linktext || 'Skoða gögn'
                                    }
                                    color="neutral900"
                                    newTab
                                ></ArrowLink>
                            )
                        } else if (
                            moment(rows[heads[i]], 'YYYY-MM-DD', true).isValid()
                        ) {
                            tmpArr.push(moment(rows[heads[i]]).format('L'))
                        } else {
                            tmpArr.push(rows[heads[i]])
                        }
                    }
                    return {
                        items: tmpArr,
                    }
                })
                tableRows = rows
                testArr.push({
                    label: slice.items[j].tablabel,
                    children: (
                        <Table
                            tableData={{
                                tableHead: {
                                    items: tableHead,
                                },
                                tableRows: tableRows,
                            }}
                            color={parsePrismicHeadingColor(
                                slice.primary.slicecolor
                            )}
                            backgroundFilled={slice.primary.backgroundfilled}
                            boldRow={true}
                            tableDescription={
                                <PrismicRichText
                                    field={slice.items[j].description}
                                    components={{
                                        hyperlink: ({ text, node }) => (
                                            <a
                                                style={{
                                                    color: parsePrismicDarkColor(
                                                        slice.primary.slicecolor
                                                    ),
                                                    fontWeight: 600,
                                                    textDecoration: 'none',
                                                }}
                                                href={node.data.url}
                                                target={
                                                    node.data.link_type ===
                                                    'Web'
                                                        ? node.data.target
                                                        : ''
                                                }
                                                rel="noopener noreferrer"
                                            >
                                                {text}
                                            </a>
                                        ),
                                    }}
                                />
                            }
                        />
                    ),
                })
            })
            setTables(testArr)
        }
    }, [
        data,
        slice.items,
        slice.primary.backgroundfilled,
        slice.primary.slicecolor,
    ])

    return (
        <Box
            anchorid={slice.primary.anchor || undefined}
            paddingTop={80}
            paddingBottom={80}
            boxStyles={{
                backgroundColor: slice.primary.backgroundfilled
                    ? parsePrismicBackgroundColor(slice.primary.slicecolor)
                    : theme.colors.neutral[100],
            }}
            extraClassName={sliceWrapper}
        >
            <Container>
                <HeadingContainer
                    tagline={
                        slice.primary.tagline
                            ? slice.primary.tagline
                            : undefined
                    }
                    title={slice.primary.title ? slice.primary.title : ''}
                    color={parsePrismicHeadingColor(slice.primary.slicecolor)}
                />

                {table.length > 0 ? (
                    <TabBarSlider
                        tabs={table}
                        backgroundBar={true}
                        color={parsePrismicHeadingColor(
                            slice.primary.slicecolor
                        )}
                    />
                ) : (
                    <Box width={'full'} justify="center">
                        <Lottie animationData={Loader} />
                    </Box>
                )}
            </Container>
        </Box>
    )
}

export default WithTabsAirtable
