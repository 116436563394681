import * as React from 'react'

export interface IconProps {
    width?: string
    height?: string
    color?: string
}

export const TwitterIcon: React.FC<IconProps> = ({ width, height, color }) => {
    return (
        <svg
            width={width || '27'}
            height={height || '22'}
            viewBox="0 0 27 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M26.8334 2.64475C25.8312 3.07954 24.7713 3.36696 23.6867 3.49808C24.831 2.81451 25.6884 1.73912 26.1001 0.471412C25.0248 1.1115 23.8478 1.56247 22.6201 1.80475C21.7995 0.914842 20.7067 0.322474 19.5132 0.120548C18.3196 -0.081379 17.0928 0.118557 16.0251 0.688998C14.9575 1.25944 14.1093 2.16812 13.6137 3.27253C13.1181 4.37693 13.0031 5.6146 13.2867 6.79141C11.1127 6.68145 8.986 6.11535 7.04495 5.12989C5.10391 4.14444 3.39188 2.76167 2.02008 1.07141C1.53893 1.91164 1.28611 2.86318 1.28675 3.83141C1.28504 4.73059 1.5057 5.61623 1.92909 6.4095C2.35247 7.20276 2.96544 7.87903 3.71341 8.37808C2.84405 8.35443 1.99326 8.12114 1.23341 7.69808V7.76475C1.23993 9.0246 1.68141 10.2435 2.48317 11.2154C3.28493 12.1872 4.39776 12.8523 5.63341 13.0981C5.15776 13.2428 4.66391 13.3192 4.16675 13.3247C3.8226 13.3207 3.47931 13.2895 3.14008 13.2314C3.49196 14.3152 4.17291 15.2623 5.08818 15.941C6.00345 16.6196 7.10752 16.9961 8.24675 17.0181C6.32302 18.5318 3.94793 19.3579 1.50008 19.3647C1.0544 19.3662 0.609062 19.3395 0.166748 19.2847C2.66599 20.8984 5.5785 21.755 8.55342 21.7514C10.6063 21.7727 12.6429 21.3848 14.5442 20.6102C16.4455 19.8356 18.1734 18.6899 19.627 17.2401C21.0806 15.7902 22.2307 14.0653 23.0102 12.166C23.7896 10.2666 24.1828 8.23105 24.1667 6.17808C24.1667 5.95141 24.1667 5.71141 24.1667 5.47141C25.213 4.69116 26.1154 3.73465 26.8334 2.64475Z"
                fill={color || '#0F1015'}
            />
        </svg>
    )
}
