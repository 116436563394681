import { useEffect, useState } from 'react'
import { BreakPoints, theme } from '@orbit/ui/themes'
import { useWindowSize } from 'react-use'
import {
    Box,
    Container,
    HeadingContainer,
    MotionBox,
    OrbitImage,
    Text,
} from '@orbit/ui/core'
import {
    TContactFormContext,
    TContactFormSliceWithImage,
} from '../ContactForm.types'
import {
    parsePrismicBackgroundColor,
    parsePrismicHeadingColor,
    parseSliceSpace,
} from '@orbit/prismic'
import * as styles from '../ContactForm.css'
import { cardRevealUp } from '@orbit/utils/framer-motion'

declare global {
    interface Window {
        hbspt: {
            forms: {
                create: (arg0: {
                    portalId: string
                    formId: string
                    target: string
                }) => void
            }
        }
    }
}

export const ContactFormWithImage = (
    slice: TContactFormSliceWithImage,
    context: TContactFormContext
) => {
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        if (width < BreakPoints.mobile) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    useEffect(() => {
        const script = document.createElement('script')
        script.src = 'https://js.hsforms.net/forms/shell.js'
        document.body.appendChild(script)

        script.addEventListener('load', () => {
            if (window && window.hbspt) {
                window.hbspt.forms.create({
                    portalId: '6348411',
                    formId: slice.primary.formid,
                    target: `#hbspot-contact-form-with-image-${slice.primary.formid}`,
                })
            }
        })
    }, [slice.primary.formid])
    return (
        <MotionBox
            anchorid={slice.primary.anchor || undefined}
            extraClassName={styles.test}
            boxStyles={{
                backgroundColor: slice.primary.backgroundfilled
                    ? parsePrismicBackgroundColor(slice.primary.slicecolor)
                    : theme.colors.neutral[100],
            }}
            justify="spaceAround"
            align="center"
            flexDirection={isMobile ? 'column' : 'rowReverse'}
            paddingBottom={parseSliceSpace(context.type, isMobile)}
            paddingTop={parseSliceSpace(context.type, isMobile)}
            initial={isMobile ? 'visible' : 'hidden'}
            viewport={{
                once: true,
            }}
            whileInView="visible"
            transition={{
                delay: 0.2,
                duration: 0.6,
            }}
            variants={cardRevealUp}
        >
            <Container>
                <Box flexDirection={isMobile ? 'column' : 'rowReverse'}>
                    {slice.primary.image &&
                        slice.primary.image.url &&
                        !isMobile && (
                            <Box
                                width={isMobile ? 'half' : 'full'}
                                margin={5}
                                alignSelf="center"
                                boxStyles={{ maxWidth: '520px' }}
                            >
                                <OrbitImage
                                    containerclass={styles.imageWrapper}
                                    src={slice.primary.image.url || ''}
                                    alt={slice.primary.image.alt || ''}
                                    layout="fill"
                                />
                            </Box>
                        )}

                    <Box
                        boxStyles={{
                            width:
                                slice.primary.image &&
                                slice.primary.image.url &&
                                !isMobile
                                    ? 'calc(50% + 2.5rem)'
                                    : '100%',
                        }}
                        flexDirection="column"
                        align={isMobile ? 'center' : 'flexStart'}
                    >
                        <Box
                            flexDirection="column"
                            paddingLeft={isMobile ? 0 : 36}
                            align={isMobile ? 'center' : 'flexStart'}
                        >
                            <HeadingContainer
                                title={slice.primary.title}
                                tagline={slice.primary.tagline}
                                color={parsePrismicHeadingColor(
                                    slice.primary.slicecolor
                                )}
                                pb={slice.primary.text ? '0rem' : '2.5rem'}
                                gap="1rem"
                                variant={isMobile ? 'center' : 'left'}
                                size="small"
                            />
                            {slice.primary.text && (
                                <Text
                                    textClass={styles.textStyling}
                                    textAlign={isMobile ? 'center' : 'left'}
                                >
                                    {slice.primary.text}
                                </Text>
                            )}
                        </Box>
                        {slice.primary.image &&
                            slice.primary.image.url &&
                            isMobile && (
                                <Box width={'full'} paddingBottom={8}>
                                    <OrbitImage
                                        containerclass={styles.imageWrapper}
                                        src={slice.primary.image.url || ''}
                                        alt={slice.primary.image.alt || ''}
                                        layout="fill"
                                    />
                                </Box>
                            )}

                        <Box>
                            <Box id={'contact-form-slice'}>
                                {slice.primary.formid && (
                                    <Box width={'full'}>
                                        <div
                                            id={`hbspot-contact-form-with-image-${slice.primary.formid}`}
                                            className={`hbspot-contact-form-with-image hbspot-contact-form${
                                                slice.primary.slicecolor
                                            } hbspot-contact-form-label-filled${
                                                slice.primary.backgroundfilled
                                                    ? ''
                                                    : slice.primary.slicecolor
                                            }`}
                                        ></div>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </MotionBox>
    )
}

export default ContactFormWithImage
