import { arrowLink } from './ArrowLink.css'
import { OrbitColors as colors } from '@orbit/ui/themes'
import { Icon } from '../Icons'

export type ArrowLinkProps = {
    link: any
    linkText: string
    newTab?: boolean
    color?: keyof typeof colors
    size?: 'small' | 'medium' | 'large' | 'xlarge'
}

// todo replace icon with uil icon

export const ArrowLink: React.FC<ArrowLinkProps> = ({
    link,
    linkText,
    newTab,
    color = 'primaryBlue600',
    size = 'small',
}) => {
    return (
        <a
            href={link}
            style={{
                display: 'flex',
                alignItems: 'center',
                textDecoration: 'none',
            }}
            target={newTab ? '_blank' : ''}
            rel="noopener noreferrer"
        >
            <span className={arrowLink} style={{ color: colors[color] }}>
                {linkText}
            </span>
            <Icon
                size={size}
                color={color ? colors[color] : 'primaryBlue600'}
                icon="ArrowRight"
            />
        </a>
    )
}
