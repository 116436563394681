import * as React from 'react'

export interface IconProps {
    width?: string
    height?: string
    color?: string
}

export const Facebook: React.FC<IconProps> = ({ width, height, color }) => {
    return (
        <svg
            width={width || '24'}
            height={height || '24'}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.12 5.32H17V2.14C16.0898 2.04535 15.1752 1.99862 14.26 2C11.54 2 9.68 3.66 9.68 6.7V9.32H6.61V12.88H9.68V22H13.36V12.88H16.42L16.88 9.32H13.36V7.05C13.36 6 13.64 5.32 15.12 5.32Z"
                fill={color}
            />
        </svg>
    )
}
