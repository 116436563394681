import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import AnimateHeight from 'react-animate-height'
import { Box } from '../Box'
import { Heading } from '../Heading'
import { useWindowSize } from 'react-use'
import React from 'react'
import { theme } from '@orbit/ui/themes'
import * as style from './Accordion.css'
import { OrbitImage } from '../Image'
import { ImageFieldImage } from '@prismicio/types'
import { Button } from '../Button'

export interface AccordionColorsProps {
    background: string
    iconBackground: string
    icon: string
    circle?: 'primary' | 'secondary' | 'tertiary' | 'accent'
}
export interface AccordionProps {
    colors?: AccordionColorsProps
    title?: string
    questions: string[] | string
    answers?: string[] | string
    buttonlinks?: string[] | string
    buttonlabels?: string[] | string
    image?: ImageFieldImage[]
    size?: 'full' | 'half'
    wrap?: boolean
    dangerouslySetIn?: boolean
    headingsize?: 'h2' | 'h3' | 'h4' | 'h5'
}

interface AccordionItemProps {
    question: string
    answer: string
    buttonlinks?: string
    buttonlabels?: string
    small: boolean
    wrap?: boolean
    isMobile: boolean
    colors?: AccordionColorsProps
    columnBreak?: boolean
    isActive?: boolean
    img?: ImageFieldImage
    setActiveItem: Dispatch<SetStateAction<number | undefined>>
    isImage?: boolean
    dangerouslySetIn?: boolean
    headingsize?: 'h2' | 'h3' | 'h4' | 'h5'
}

interface AccordionPanelProps {
    question: string
    setActive: any
    isActive?: boolean
    small: boolean
    colors?: AccordionColorsProps
    headingsize?: 'h2' | 'h3' | 'h4' | 'h5'
}

interface AccordionContentProps {
    question: string
    answer: string
    dangerouslySetIn?: boolean
    buttonlinks?: string
    buttonlabels?: string
    small: boolean
    isActive?: boolean
}

interface AccordionToggleProps {
    toggled: boolean | undefined
    small: boolean
    backgroundColor?: string
    color?: string
}

export const Accordion: React.FC<AccordionProps> = ({
    title,
    questions,
    answers,
    size = 'full',
    wrap,
    colors,
    image,
    dangerouslySetIn = false,
    buttonlinks,
    buttonlabels,
    headingsize = 'h3',
}) => {
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)
    const [activeItem, setActiveItem] = useState<number | undefined>()
    const [isImage, setIsImage] = useState(false)

    useEffect(() => {
        if (width < 992) {
            return setIsMobile(true)
        }

        setIsMobile(false)
    }, [width])

    const handleToggle = (index: number) => {
        setActiveItem(index === activeItem ? undefined : index)
    }

    useEffect(() => {
        if (image !== undefined) {
            return setIsImage(true)
        }
        setIsImage(false)
    }, [image])

    useEffect(() => {
        if (isImage && !activeItem) {
            return setActiveItem(0)
        }
    })

    const accordionImage = (img: ImageFieldImage) => {
        return (
            <Box
                key={img.url}
                extraClassName={style.imageWrapper({
                    color: colors?.circle,
                })}
                position="relative"
            >
                <Box extraClassName={style.AccordionImage}>
                    <OrbitImage
                        src={img.url || ''}
                        alt={img.alt || 'myndskreyting'}
                        width={520}
                        height={520}
                        objectFit="cover"
                        objectPosition="center"
                        style={{ borderRadius: '50%' }}
                        containerclass={style.CircleInnerImage}
                    />
                </Box>
            </Box>
        )
    }
    return (
        <Box
            display={isImage ? 'flex' : 'block'}
            width={'full'}
            flexDirection={isImage && isMobile ? 'columnReverse' : 'row'}
        >
            <Box extraClassName={style.AccordionContainer({ image: isImage })}>
                {title !== '' && title !== undefined && (
                    <Box>
                        <Heading type="h1" text={title} my={5} />
                    </Box>
                )}
                <ul
                    className={style.AccordionListContainer}
                    style={{
                        columnCount: size === 'half' && wrap ? 2 : 1,
                    }}
                >
                    {Array.isArray(questions) &&
                        Array.isArray(answers) &&
                        questions.map((q, i) => {
                            return (
                                <AccordionItem
                                    isImage={isImage}
                                    colors={colors}
                                    key={'accordion_item_' + i}
                                    question={questions[i]}
                                    answer={answers[i]}
                                    buttonlinks={buttonlinks && buttonlinks[i]}
                                    buttonlabels={
                                        buttonlabels && buttonlabels[i]
                                    }
                                    small={isMobile || size == 'half'}
                                    wrap={wrap}
                                    isMobile={isMobile}
                                    isActive={activeItem === i}
                                    setActiveItem={() => handleToggle(i)}
                                    columnBreak={
                                        size === 'half' &&
                                        wrap &&
                                        Math.ceil(questions.length / 2) - 1 == i
                                    }
                                    dangerouslySetIn={dangerouslySetIn}
                                    headingsize={headingsize}
                                />
                            )
                        })}
                </ul>
            </Box>
            {image && (
                <Box
                    width={isMobile ? 'full' : 'half'}
                    justify={'center'}
                    paddingLeft={isMobile ? 0 : 48}
                    paddingBottom={isMobile ? 36 : 0}
                >
                    {Array.isArray(image) &&
                        image.map((img: ImageFieldImage, index: number) => {
                            return activeItem == index && accordionImage(img)
                        })}
                    {!activeItem &&
                        activeItem !== 0 &&
                        accordionImage(image[0])}
                </Box>
            )}
        </Box>
    )
}

const AccordionItem: React.FC<AccordionItemProps> = ({
    isImage = false,
    question,
    answer,
    small,
    wrap,
    isMobile,
    colors,
    columnBreak = false,
    isActive = false,
    setActiveItem,
    dangerouslySetIn = false,
    buttonlabels,
    buttonlinks,
    headingsize,
}) => {
    return (
        <li
            className={style.AccordionItem({ small: small })}
            style={
                small && wrap && !isMobile
                    ? {
                          display: 'flex',
                          flexDirection: 'column',
                          width: 'calc(100% - 3rem)',
                          marginLeft: 0,
                          backgroundColor: colors?.background
                              ? colors.background
                              : theme.colors.primary[200],
                          breakAfter: columnBreak ? 'column' : 'unset',
                      }
                    : small && !isMobile
                    ? {
                          display: 'flex',
                          flexDirection: 'column',
                          width: isImage
                              ? 'calc(100% - 3rem) '
                              : 'calc(50% - 1.5rem)',

                          backgroundColor: colors?.background
                              ? colors.background
                              : theme.colors.primary[200],
                      }
                    : {
                          backgroundColor: colors?.background
                              ? colors.background
                              : theme.colors.primary[200],
                      }
            }
        >
            <AccordionPanel
                question={question}
                setActive={setActiveItem}
                isActive={isActive}
                small={small}
                colors={colors}
                headingsize={headingsize}
            />
            <AnimateHeight duration={300} height={isActive ? 'auto' : 0}>
                <AccordionContent
                    question={question}
                    answer={answer}
                    dangerouslySetIn={dangerouslySetIn}
                    buttonlabels={buttonlabels && buttonlabels}
                    buttonlinks={buttonlinks && buttonlinks}
                    small={small}
                    isActive={isActive}
                />
            </AnimateHeight>
        </li>
    )
}

const AccordionPanel: React.FC<AccordionPanelProps> = ({
    question,
    setActive,
    isActive,
    small,
    colors,
    headingsize = 'h3',
}) => {
    return (
        <button
            className={style.AccordionPanel({ small: small })}
            onClick={() => {
                setActive()
            }}
            aria-controls={question}
            aria-expanded={isActive}
        >
            <Heading
                style={{
                    fontSize: small ? '1rem' : '1.5rem',
                    textAlign: 'start',
                }}
                type={headingsize}
                text={question}
                my={0}
            />
            <AccordionToggle
                color={colors?.icon}
                backgroundColor={colors?.iconBackground}
                small={small}
                toggled={isActive}
            />
        </button>
    )
}

const AccordionContent: React.FC<AccordionContentProps> = ({
    question,
    answer,
    dangerouslySetIn = false,
    buttonlabels,
    buttonlinks,
    small,
    isActive,
}) => {
    const answerRef = useRef<HTMLDivElement>(null)

    // TODO: Scroll to top of answer when active
    // useEffect(() => {
    //     if (window && isActive && answerRef.current) {
    //         answerRef.current.scrollIntoView({ behavior: 'smooth' })
    //     }
    // }, [isActive, answerRef])

    return (
        <div
            id={question}
            ref={answerRef}
            className={style.AccordionContent({ small: small })}
        >
            {dangerouslySetIn ? (
                <>
                    <div
                        dangerouslySetInnerHTML={{ __html: answer }}
                        className={style.htmlJobsDetailsStyle}
                    />
                    {buttonlinks && buttonlabels && (
                        <Button
                            variant="filled"
                            fullWidth={false}
                            href={buttonlinks}
                            color={'primary'}
                        >
                            {buttonlabels}
                        </Button>
                    )}
                </>
            ) : (
                <Box flexDirection="column">{answer}</Box>
            )}
        </div>
    )
}

export const AccordionToggle: React.FC<AccordionToggleProps> = ({
    toggled,
    small,
    backgroundColor,
    color,
}) => {
    return (
        <div
            style={{
                backgroundColor: backgroundColor
                    ? backgroundColor
                    : theme.colors.primary[200],
            }}
            className={`${style.AccordionToggleContainer} ${
                toggled && 'active'
            } ${small && 'small'}`}
        >
            <div
                style={{
                    borderColor: color ? color : theme.colors.primary[500],
                }}
                className={style.AccordionToggleInnerCircle}
            >
                <div
                    style={{
                        backgroundColor: color
                            ? color
                            : theme.colors.primary[500],
                    }}
                    className={style.AccordionToggleVertical}
                ></div>
                <div
                    style={{
                        backgroundColor: color
                            ? color
                            : theme.colors.primary[500],
                    }}
                    className={style.AccordionToggleHorizontal}
                ></div>
            </div>
        </div>
    )
}
