import * as React from 'react'

export interface IconProps {
    width?: string
    height?: string
    color?: string
}

export const Envelope: React.FC<IconProps> = ({ width, height, color }) => {
    return (
        <svg
            width={width || '20'}
            height={height || '20'}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.8333 3.33333H4.16667C3.50363 3.33333 2.86774 3.59673 2.3989 4.06557C1.93006 4.53441 1.66667 5.17029 1.66667 5.83333V14.1667C1.66667 14.8297 1.93006 15.4656 2.3989 15.9344C2.86774 16.4033 3.50363 16.6667 4.16667 16.6667H15.8333C16.4964 16.6667 17.1323 16.4033 17.6011 15.9344C18.0699 15.4656 18.3333 14.8297 18.3333 14.1667V5.83333C18.3333 5.17029 18.0699 4.53441 17.6011 4.06557C17.1323 3.59673 16.4964 3.33333 15.8333 3.33333V3.33333ZM4.16667 5H15.8333C16.0543 5 16.2663 5.0878 16.4226 5.24408C16.5789 5.40036 16.6667 5.61232 16.6667 5.83333L10 9.9L3.33333 5.83333C3.33333 5.61232 3.42113 5.40036 3.57741 5.24408C3.73369 5.0878 3.94565 5 4.16667 5V5ZM16.6667 14.1667C16.6667 14.3877 16.5789 14.5996 16.4226 14.7559C16.2663 14.9122 16.0543 15 15.8333 15H4.16667C3.94565 15 3.73369 14.9122 3.57741 14.7559C3.42113 14.5996 3.33333 14.3877 3.33333 14.1667V7.73333L9.56667 11.5417C9.69335 11.6148 9.83705 11.6533 9.98333 11.6533C10.1296 11.6533 10.2733 11.6148 10.4 11.5417L16.6667 7.73333V14.1667Z"
                fill={color}
            />
        </svg>
    )
}
