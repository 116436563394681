import {
    parsePrismicLightBackgroundColor,
    parsePrismicHeadingColor,
    parsePrismicHighlightColor,
    parsePrismicBackgroundColor,
} from '@orbit/prismic'
import {
    Container,
    HeadingContainer,
    SolutionCard,
    Box,
    Carousel,
    MotionBox,
} from '@orbit/ui/core'
import { BreakPoints } from '@orbit/ui/themes'
import { cardRevealUp } from '@orbit/utils/framer-motion'
import { PrismicDocument, Query } from '@prismicio/types'
import { useState, useEffect } from 'react'
import { useWindowSize } from 'react-use'
import useSwr from 'swr'
import * as prismicH from '@prismicio/helpers'
import {
    RelatedSolutionsDefault,
    ContextType,
    TRelatedServicesSlice,
} from '../RelatedContent.types'

const fetcher = (url: string) => fetch(url).then((res) => res.json())

export const RelatedServices = (
    slice: TRelatedServicesSlice,
    context: ContextType
) => {
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)
    const [relatedContentLoaded, setrelatedContentLoaded] =
        useState<boolean>(false)
    const { data } = useSwr<
        Query<PrismicDocument<RelatedSolutionsDefault['data']>>
    >(
        context.tags
            ? `/api/contentByTag/contentByTags?tags=${context.tags}&lang=${context.lang}&id=${context.id}&type=consulting_services`
            : null,
        fetcher
    )
    useEffect(() => {
        if (width < BreakPoints.medium) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    useEffect(() => {
        setrelatedContentLoaded(true)
    }, [])
    return (
        <Box
            anchorid={slice.primary.anchor || undefined}
            justify="center"
            flexDirection="column"
            paddingTop={isMobile ? 80 : 40}
            paddingBottom={isMobile ? 80 : 40}
            boxStyles={{
                alignItems: 'center',
                backgroundColor: parsePrismicBackgroundColor(
                    slice.primary.slicecolor
                ),
            }}
        >
            <Container>
                <HeadingContainer
                    tagline={slice.primary.tagline || undefined}
                    title={slice.primary.title || undefined}
                    color={parsePrismicHeadingColor(slice.primary.slicecolor)}
                />
                <Box
                    flexDirection="row"
                    boxStyles={{
                        marginLeft: '1rem',
                        marginRight: '1rem',
                    }}
                >
                    {data?.results?.length && relatedContentLoaded && (
                        <Carousel
                            totalItems={data.results.length}
                            color={parsePrismicHeadingColor(
                                slice.primary.slicecolor
                            )}
                            variant={'numbers'}
                            pageSize={{
                                mobile: 1,
                                tablet: 2,
                                desktop: 3,
                            }}
                            itemsToSlide={{
                                mobile: 1,
                                tablet: 1,
                                desktop: 1,
                            }}
                            tabletWidth={1050}
                        >
                            {data.results !== undefined &&
                                data?.results?.map(
                                    (solution, index: number) => (
                                        <MotionBox
                                            boxStyles={{
                                                padding: '1.25rem',
                                                height: '100%',
                                            }}
                                            key={'related_solutions_' + index}
                                            initial={
                                                isMobile ? 'visible' : 'hidden'
                                            }
                                            viewport={{
                                                once: true,
                                            }}
                                            whileInView="visible"
                                            transition={{
                                                duration: 0.6,
                                                delay: 0.15 * ((index % 3) + 1),
                                            }}
                                            variants={cardRevealUp}
                                        >
                                            <SolutionCard
                                                url={
                                                    data?.results[index]?.url ||
                                                    ''
                                                }
                                                color={parsePrismicLightBackgroundColor(
                                                    slice.primary.slicecolor
                                                )}
                                                type={'default'}
                                                title={
                                                    (solution.data.title &&
                                                        prismicH.asText(
                                                            solution.data.title
                                                        )) ||
                                                    ''
                                                }
                                                tag={solution.data.card_tag}
                                                summary={solution.data.summary}
                                                buttonColor={parsePrismicHighlightColor(
                                                    slice.primary.slicecolor
                                                )}
                                                headingLevel={
                                                    slice.primary.tagline &&
                                                    slice.primary.title
                                                        ? 'h4'
                                                        : !slice.primary
                                                              .tagline &&
                                                          !slice.primary.tagline
                                                        ? 'h2'
                                                        : 'h3'
                                                }
                                            />
                                        </MotionBox>
                                    )
                                )}
                        </Carousel>
                    )}
                </Box>
            </Container>
        </Box>
    )
}

export default RelatedServices
