import {
    parsePrismicBackgroundColor,
    parsePrismicHeadingColor,
} from '@orbit/prismic'
import {
    Box,
    Container,
    HeadingContainer,
    MotionBox,
    NewsCard,
    Pagination,
} from '@orbit/ui/core'
import { BreakPoints } from '@orbit/ui/themes'
import { newsBlogCardRevealUp } from '@orbit/utils/framer-motion'
import { ImageFieldImage } from '@prismicio/types'
import { AnimatePresence } from 'framer-motion'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { useWindowSize } from 'react-use'
import useSwr from 'swr'
import { FirstItem } from '../FirstItem'
import * as styles from '../Overview.css'
import { TOverviewContext, TOverviewSlice } from '../Overview.types'

const fetcher = (url: string) => fetch(url).then((res) => res.json())

type TNewsData = {
    title: string
    image: ImageFieldImage
    summary: string
    uid: string
    date: string
}
interface TNews {
    data: TNewsData
    url: string
}

export const DefaultOverview = (
    slice: TOverviewSlice,
    context: TOverviewContext
) => {
    const [currentPage, setCurrentPage] = useState(1)
    const [totalResultsSize, setTotalResultsSize] = useState<number>(0)
    const [pageSize, setPageSize] = useState<number>(0)

    const newsSection = useRef<HTMLDivElement>(null)
    const [totalPages, setTotalPages] = useState<number>(0)
    const count = 9
    const {
        data,
        error: allNewsError,
        isValidating,
    } = useSwr(
        `/api/allNews?count=${
            currentPage === 1 ? count + 1 : count
        }&page=${currentPage}&lang=${context.lang}`,
        fetcher
    )

    const { data: firstData } = useSwr(
        `/api/allNews?count=1&lang=${context.lang}`,
        fetcher
    )

    if (allNewsError) {
        console.log(allNewsError)
    }

    const news =
        data && (currentPage === 1 ? data.results.slice(1) : data.results)
    const latestNews = firstData && firstData.results[0]
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)
    useEffect(() => {
        if (width < BreakPoints.medium) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    useEffect(() => {
        if (!isValidating && data) {
            data.total_pages !== totalPages && setTotalPages(data.total_pages)
            data.total_results_size !== totalResultsSize &&
                setTotalResultsSize(data.total_results_size)
            data.results_per_page !== pageSize &&
                setPageSize(data.results_per_page)
        }
    }, [data, isValidating, totalPages, pageSize, totalResultsSize])

    const scrollToTopOfNewsSection = () => {
        if (newsSection.current) {
            window.scrollTo({
                top: newsSection.current.offsetTop - 60,
                behavior: 'smooth',
            })
        }
    }

    return (
        <Box
            anchorid={slice.primary.anchor || undefined}
            flexDirection="column"
            justify="center"
            boxStyles={{
                height: '100%',
            }}
        >
            <Container>
                <Box marginBottom={64}>
                    {latestNews && (
                        <FirstItem
                            title={latestNews.data.title}
                            tagline={moment(latestNews.data.date).format(
                                'DD/MM/YYYY'
                            )}
                            text={latestNews.data.summary}
                            buttonLabel={
                                context.lang === 'en-gb' ? 'Read' : 'Lesa frétt'
                            }
                            buttonLink={latestNews.url}
                            sliceColor={'primary'}
                            image={latestNews.data.image}
                        />
                    )}
                </Box>
            </Container>
            <Box
                boxStyles={{
                    background: parsePrismicBackgroundColor(
                        slice.primary.slicecolor
                    ),
                    alignSelf: 'stretch',
                    width: 'auto',
                }}
                ref={newsSection}
            >
                <Container>
                    <Box
                        boxStyles={{
                            background: parsePrismicBackgroundColor(
                                slice.primary.slicecolor
                            ),
                            alignItems: 'center',
                            alignSelf: 'center',
                        }}
                        flexDirection="column"
                        marginTop={80}
                    >
                        <HeadingContainer
                            variant="center"
                            tagline={slice.primary.tagline || undefined}
                            title={slice.primary.title || undefined}
                            color={parsePrismicHeadingColor(
                                slice.primary.slicecolor
                            )}
                            pb="2rem"
                        />
                        <Box
                            boxStyles={{
                                position: 'relative',
                            }}
                            flexDirection="column"
                            marginTop={24}
                            marginBottom={64}
                            width="full"
                        >
                            <AnimatePresence>
                                <Box
                                    extraClassName={styles.NewsCardWrapper}
                                    wrap="wrap"
                                >
                                    {news &&
                                        news.map(
                                            (news: TNews, index: number) => {
                                                return (
                                                    <MotionBox
                                                        width="full"
                                                        flexDirection="column"
                                                        initial="hidden"
                                                        viewport={{
                                                            once: true,
                                                        }}
                                                        whileInView="visible"
                                                        transition={{
                                                            delay:
                                                                0.1 *
                                                                (index + 1),
                                                            duration: 0.6,
                                                        }}
                                                        variants={
                                                            newsBlogCardRevealUp
                                                        }
                                                        key={`${news.data.uid}-${index}`}
                                                    >
                                                        <NewsCard
                                                            date={
                                                                news.data.date
                                                            }
                                                            url={news.url}
                                                            slicecolor={
                                                                'primary'
                                                            }
                                                            imageAlt={
                                                                news.data.image
                                                                    .alt || ''
                                                            }
                                                            imageUrl={
                                                                news.data.image
                                                                    .url ||
                                                                undefined
                                                            }
                                                            title={
                                                                news.data.title
                                                            }
                                                            summary={
                                                                news.data
                                                                    .summary
                                                            }
                                                        />
                                                    </MotionBox>
                                                )
                                            }
                                        )}
                                </Box>
                            </AnimatePresence>
                            {totalPages > 0 && (
                                <Pagination
                                    darkBackground={false}
                                    currentPage={currentPage}
                                    variant="numbers"
                                    color={parsePrismicHeadingColor(
                                        slice.primary.slicecolor
                                    )}
                                    total={totalResultsSize}
                                    pageSize={pageSize}
                                    onPageChange={(page: number) => {
                                        setCurrentPage(page)
                                        setTimeout(
                                            () => scrollToTopOfNewsSection(),
                                            100
                                        )
                                    }}
                                />
                            )}
                        </Box>
                    </Box>
                </Container>
            </Box>
        </Box>
    )
}

export default DefaultOverview
