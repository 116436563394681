export const ArrowDownCircle = () => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.0533 20.9466C15.1801 21.068 15.3296 21.1632 15.4933 21.2266C15.8179 21.36 16.182 21.36 16.5066 21.2266C16.6703 21.1632 16.8198 21.068 16.9466 20.9466L20.9466 16.9466C21.1977 16.6956 21.3387 16.355 21.3387 16C21.3387 15.6449 21.1977 15.3044 20.9466 15.0533C20.6956 14.8022 20.355 14.6612 20 14.6612C19.6449 14.6612 19.3044 14.8022 19.0533 15.0533L17.3333 16.7866V12C17.3333 11.6463 17.1928 11.3072 16.9428 11.0572C16.6927 10.8071 16.3536 10.6666 16 10.6666C15.6463 10.6666 15.3072 10.8071 15.0572 11.0572C14.8071 11.3072 14.6666 11.6463 14.6666 12V16.7866L12.9466 15.0533C12.8227 14.9283 12.6752 14.8291 12.5127 14.7614C12.3502 14.6938 12.176 14.6589 12 14.6589C11.8239 14.6589 11.6497 14.6938 11.4872 14.7614C11.3247 14.8291 11.1772 14.9283 11.0533 15.0533C10.9283 15.1772 10.8291 15.3247 10.7614 15.4872C10.6937 15.6497 10.6589 15.824 10.6589 16C10.6589 16.176 10.6937 16.3503 10.7614 16.5127C10.8291 16.6752 10.9283 16.8227 11.0533 16.9466L15.0533 20.9466ZM16 29.3333C18.637 29.3333 21.2149 28.5513 23.4076 27.0862C25.6002 25.6211 27.3092 23.5388 28.3184 21.1024C29.3275 18.6661 29.5916 15.9852 29.0771 13.3988C28.5626 10.8123 27.2928 8.43658 25.4281 6.57188C23.5634 4.70717 21.1876 3.4373 18.6012 2.92283C16.0148 2.40836 13.3339 2.6724 10.8975 3.68157C8.46117 4.69074 6.37878 6.39971 4.9137 8.59236C3.44861 10.785 2.66663 13.3629 2.66663 16C2.66663 19.5362 4.07138 22.9276 6.57187 25.4281C7.80998 26.6662 9.27984 27.6483 10.8975 28.3184C12.5152 28.9884 14.249 29.3333 16 29.3333V29.3333ZM16 5.3333C18.1096 5.3333 20.1719 5.95889 21.926 7.13096C23.6802 8.30302 25.0473 9.96893 25.8547 11.918C26.662 13.8671 26.8732 16.0118 26.4617 18.0809C26.0501 20.1501 25.0342 22.0507 23.5424 23.5424C22.0507 25.0342 20.1501 26.0501 18.0809 26.4617C16.0118 26.8733 13.8671 26.662 11.918 25.8547C9.96892 25.0473 8.30302 23.6802 7.13095 21.926C5.95888 20.1719 5.33329 18.1096 5.33329 16C5.33329 13.171 6.4571 10.4579 8.45749 8.45749C10.4579 6.4571 13.171 5.3333 16 5.3333V5.3333Z"
                fill="#303A47"
            />
        </svg>
    )
}

export default ArrowDownCircle
