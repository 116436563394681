import { useWindowSize } from 'react-use'
import { Box, MotionBox } from '../../Box'
import { Button } from '../../Button'
import { Heading } from '../../Heading'
import * as styles from './AdvertisingCard.css'
import { useState, useEffect } from 'react'
import { BreakPoints, theme } from '@orbit/ui/themes'
import { IconType, OrbitImage, OrbitVideo } from '@orbit/ui/core'
import { Modal } from '../../Modal'
import { Container } from '../../Container'
import { VideoPlayIcon } from '../../../assets/VideoPlayIcon'
import { Text } from '../../Text'
import { cardRevealUp } from '@orbit/utils/framer-motion'

export interface SolutionCardProps {
    image: string
    imagealt: string
    tagline?: string
    title?: string
    highlightStart?: number
    highlightEnd?: number
    description?: string
    buttonLabel?: string
    buttonIcon?: IconType
    cardColor: 'primary' | 'secondary' | 'tertiary' | 'accent'
    dotcolor: string
    buttonLink?: string
    buttonText?: string
    openNewTab?: boolean
    imageWidth?: number
    imageHeight?: number
    imageShape: 'Square' | 'Window' | 'Circle'
    text: React.ReactNode
    cardSize: string
    highlightColor?: string
    video?: string
    index?: number
}

interface CardProps extends SolutionCardProps {
    width: number
}

export const AdvertisingCard: React.FC<SolutionCardProps> = (props) => {
    const { width } = useWindowSize()
    return props.cardSize === 'large' ? (
        <LargeCard {...props} width={width} />
    ) : props.cardSize === 'medium' ? (
        <MediumCard {...props} width={width} />
    ) : (
        <SmallCard {...props} width={width} />
    )
}

export const LargeCard: React.FC<CardProps> = (props) => {
    const [isMobile, setIsMobile] = useState(false)
    const { width } = useWindowSize()
    const [isOpen, setOpen] = useState(false)
    const [isVideo, setVideo] = useState(false)
    const [isImage, setImage] = useState(false)
    const [isVideoImage, setVideoImage] = useState(false)

    useEffect(() => {
        if (props.image && !props.video) {
            return setImage(true)
        }
        if (props.video && !props.image) {
            return setVideo(true)
        }
        if (props.video && props.image) {
            return setVideoImage(true)
        }
    }, [])
    useEffect(() => {
        if (width < BreakPoints.mobile) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])
    return (
        <MotionBox
            width="full"
            initial="hidden"
            viewport={{
                once: true,
            }}
            whileInView="visible"
            transition={{
                delay: 0.15 * (props.index ? props.index + 1 : 1),
                duration: 0.6,
            }}
            variants={cardRevealUp}
            key={`${props.title}-${props.index}`}
            align={'center'}
            extraClassName={`${styles.largeCardContainer} ${
                !props.video
                    ? styles.animationCards
                    : styles.animationCardsVideo
            }`}
            flexDirection={isMobile ? 'columnReverse' : 'row'}
        >
            <Box
                flexDirection="column"
                justify="center"
                flex={1}
                boxStyles={{
                    minWidth: '50%',
                    padding: isMobile ? '0 2rem 2rem 2rem' : '5rem 0 5rem 3rem',
                }}
            >
                <Box marginBottom={36} flexDirection="column">
                    {props.tagline && (
                        <Text
                            textClass={styles.TaglineStyle}
                            style={{
                                color:
                                    !props.highlightStart && !props.highlightEnd
                                        ? props.highlightColor
                                        : theme.colors.neutral[600],
                            }}
                        >
                            {props.tagline}
                        </Text>
                    )}

                    <Heading
                        type={'h4'}
                        text={props.title || ''}
                        highlightStart={
                            props.highlightStart && props.highlightStart - 1
                        }
                        highlightEnd={
                            props.highlightEnd && props.highlightEnd - 1
                        }
                        highlightColor={props.highlightColor}
                        mx={0}
                        my={1}
                        style={{
                            fontSize: '2.5rem',
                            lineHeight: '3rem',
                        }}
                    />
                    {props.text && <Box>{props.text && props.text}</Box>}
                </Box>

                {props.buttonLink && (
                    <Box>
                        <Button
                            href={props.buttonLink}
                            variant={props.buttonLabel ? 'filled' : 'icon'}
                            color={props.cardColor}
                            icon={props.buttonIcon}
                            ariaLabel={
                                props.buttonLabel === undefined ||
                                props.buttonIcon
                                    ? props.buttonIcon
                                    : props.buttonLabel
                            }
                            newTab={props.openNewTab}
                        >
                            {props.buttonLabel}
                        </Button>
                    </Box>
                )}
            </Box>
            {isImage ? (
                <Box
                    width={isMobile ? 'full' : 'half'}
                    boxStyles={{
                        maxWidth: isMobile ? '28.125rem' : '',
                        padding: isMobile ? '3rem 0 0 0' : '0 5rem 0 0',
                        transform: isMobile ? 'translateX(2rem)' : '',
                    }}
                >
                    <Box
                        justify={'center'}
                        flex={1}
                        width={isMobile ? 'full' : 'half'}
                    >
                        <OrbitImage
                            containerclass={
                                props.imageShape === 'Circle'
                                    ? styles.circleShape
                                    : styles.squareShape
                            }
                            src={props.image || ''}
                            alt={props.imagealt || 'Card image'}
                            width={'100%'}
                            layout={'fill'}
                            objectFit={
                                props.imageShape === 'Circle'
                                    ? 'fill'
                                    : 'contain'
                            }
                            // style={{ position: 'relative' }}
                        />
                    </Box>
                    {props.imageShape === 'Circle' && (
                        <Box
                            boxStyles={{
                                backgroundColor: props.dotcolor,
                                content: '',
                            }}
                            extraClassName={styles.decorativeCircleLargeCard()}
                        />
                    )}
                </Box>
            ) : isVideoImage ? (
                <Box
                    onClick={() => setOpen(true)}
                    boxStyles={{
                        width: isMobile ? '80%' : '100%',
                        justifyContent: 'center',
                    }}
                >
                    <Box width={'full'} justify="center" align="center">
                        <OrbitImage
                            containerclass={styles.squareShape}
                            src={props.image || ''}
                            alt={props.imagealt || ''}
                            height={'100%'}
                            width={'100%'}
                            layout="fill"
                            objectFit={'contain'}
                        />
                        <Box extraClassName={styles.PlayPauseButton}>
                            <VideoPlayIcon
                                className={styles.PlayPauseButtonIcon}
                            />
                        </Box>
                    </Box>
                </Box>
            ) : (
                isVideo && (
                    <Box onClick={() => setOpen(true)}>
                        <OrbitVideo
                            url={props.video || ''}
                            color={props.cardColor}
                            renderAsModal={true}
                            title={`Myndband um ${props.title}`}
                        />
                    </Box>
                )
            )}
            {props.video && (
                <Box>
                    <Modal open={isOpen} onClose={() => setOpen(false)}>
                        <Box width={80}>
                            <OrbitVideo
                                url={props.video}
                                color={props.cardColor}
                                renderAsModal={false}
                                title={`Myndband um ${props.title}`}
                            />
                        </Box>
                    </Modal>
                </Box>
            )}
        </MotionBox>
    )
}

export const MediumCard: React.FC<CardProps> = (props) => {
    const [isMobile, setIsMobile] = useState(false)
    const { width } = useWindowSize()
    const [isOpen, setOpen] = useState(false)
    const [isVideo, setVideo] = useState(false)
    const [isImage, setImage] = useState(false)
    const [isVideoImage, setVideoImage] = useState(false)

    useEffect(() => {
        if (props.image && !props.video) {
            return setImage(true)
        }
        if (props.video && !props.image) {
            return setVideo(true)
        }
        if (props.video && props.image) {
            return setVideoImage(true)
        }
    }, [])

    useEffect(() => {
        if (width < BreakPoints.mobileSmall) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])
    return (
        <MotionBox
            width="full"
            initial="hidden"
            viewport={{
                once: true,
            }}
            whileInView="visible"
            transition={{
                delay: 0.2 * (props.index ? props.index + 1 : 1),
                duration: 0.6,
            }}
            variants={cardRevealUp}
            key={`${props.title}-${props.index}`}
            flexDirection={'column'}
            align="center"
            extraClassName={`${styles.mediumCardContainer} ${
                !props.video
                    ? styles.animationCards
                    : styles.animationCardsVideo
            }`}
            boxStyles={{
                width: '100%',
            }}
            justify="center"
        >
            <Box
                flexDirection={isMobile ? 'columnReverse' : 'row'}
                boxStyles={{
                    height: '100%',
                    maxWidth: '37.5rem',
                    width: '100%',
                }}
                align={isMobile ? 'center' : 'flexStart'}
            >
                <Box
                    flexDirection="column"
                    boxStyles={{
                        height: '100%',
                        maxWidth: '60%',
                        paddingRight: isMobile ? 0 : '2rem',
                    }}
                    justify="spaceBetween"
                >
                    <Box
                        flexDirection="column"
                        width={'full'}
                        paddingBottom={isMobile ? 24 : 0}
                        justify="spaceBetween"
                        align={isMobile ? 'center' : 'flexStart'}
                        paddingTop={isMobile ? 24 : 0}
                    >
                        <Heading
                            text={props.title || ''}
                            type="h4"
                            textAlign={isMobile ? 'center' : 'left'}
                            highlightStart={
                                props.highlightStart && props.highlightStart - 1
                            }
                            highlightEnd={
                                props.highlightEnd && props.highlightEnd - 1
                            }
                            highlightColor={props.highlightColor}
                            mx={0}
                            my={0}
                        />
                        {props.text && (
                            <Box
                                marginBottom={0}
                                boxStyles={{
                                    textAlign: isMobile ? 'center' : 'left',
                                }}
                            >
                                {props.text && props.text}
                            </Box>
                        )}
                    </Box>
                    {props.buttonLink && (
                        <Box
                            marginTop={isMobile ? 0 : 16}
                            marginBottom={isMobile ? 24 : 0}
                            alignSelf={isMobile ? 'center' : 'flexStart'}
                        >
                            <Button
                                href={props.buttonLink}
                                variant={props.buttonLabel ? 'filled' : 'icon'}
                                color={props.cardColor}
                                icon={props.buttonIcon}
                                ariaLabel={
                                    props.buttonLabel
                                        ? undefined
                                        : props.buttonIcon
                                }
                                newTab={props.openNewTab}
                            >
                                {props.buttonLabel}
                            </Button>
                        </Box>
                    )}
                </Box>
                <Box
                    width={isMobile ? 'full' : 'half'}
                    justify={isMobile ? 'center' : 'flexEnd'}
                    alignSelf={'center'}
                    boxStyles={{
                        position: 'relative',
                        transform:
                            !props.video &&
                            props.imageShape === 'Circle' &&
                            !isMobile
                                ? 'translateX(0rem)'
                                : '',
                    }}
                >
                    {isImage ? (
                        <Box
                            width={'full'}
                            justify={isMobile ? 'center' : 'flexEnd'}
                            boxStyles={{
                                position: 'relative',
                            }}
                        >
                            <Box
                                width={'full'}
                                boxStyles={{
                                    justifyContent: 'center',
                                    maxWidth: isMobile ? '' : '13.438rem',
                                }}
                            >
                                <OrbitImage
                                    containerclass={
                                        props.imageShape === 'Circle'
                                            ? styles.circleShape
                                            : styles.squareShape
                                    }
                                    src={props.image || ''}
                                    alt={props.imagealt || 'Card image'}
                                    layout="fill"
                                />
                            </Box>
                            {props.imageShape !== 'Square' && (
                                <Box
                                    extraClassName={styles.decorativeCircleMediumCard()}
                                    boxStyles={{
                                        backgroundColor: props.dotcolor,
                                    }}
                                />
                            )}
                        </Box>
                    ) : isVideoImage ? (
                        <div
                            style={{
                                width: '100%',
                                justifyContent: 'center',
                            }}
                        >
                            <Box
                                width={'full'}
                                extraClassName={styles.videowithimageBox}
                                justify="center"
                                align="center"
                                boxStyles={{
                                    maxWidth: isMobile ? '' : '13.438rem',
                                }}
                            >
                                <OrbitImage
                                    containerclass={`${styles.squareShape}`}
                                    src={props.image || ''}
                                    alt={props.imagealt || ''}
                                    height={'100%'}
                                    width={'100%'}
                                    layout="fill"
                                    objectFit="contain"
                                />
                                <Box
                                    extraClassName={styles.PlayPauseButton}
                                    onClick={() => setOpen(true)}
                                >
                                    <VideoPlayIcon
                                        className={styles.PlayPauseButtonIcon}
                                    />
                                </Box>
                            </Box>
                        </div>
                    ) : (
                        isVideo && (
                            <Box
                                extraClassName={styles.modalBoxStyle}
                                onClick={() => setOpen(true)}
                            >
                                <OrbitVideo
                                    url={(props.video && props.video) || ''}
                                    color={props.cardColor}
                                    renderAsModal={true}
                                    title={`Myndband um ${props.title}`}
                                />
                            </Box>
                        )
                    )}
                </Box>
                {props.video && (
                    <Modal open={isOpen} onClose={() => setOpen(false)}>
                        <Box width={80}>
                            <OrbitVideo
                                url={props.video}
                                color={props.cardColor}
                                renderAsModal={false}
                                title={`Myndband um ${props.title}`}
                            />
                        </Box>
                    </Modal>
                )}
            </Box>
        </MotionBox>
    )
}

const SmallCard: React.FC<CardProps> = (props) => {
    const [isOpen, setOpen] = useState(false)
    const [isVideo, setVideo] = useState(false)
    const [isImage, setImage] = useState(false)
    const [isVideoImage, setVideoImage] = useState(false)

    useEffect(() => {
        if (props.image && !props.video) {
            return setImage(true)
        }
        if (props.video && !props.image) {
            return setVideo(true)
        }
        if (props.video && props.image) {
            return setVideoImage(true)
        }
    }, [])
    return (
        <MotionBox
            initial="hidden"
            viewport={{
                once: true,
            }}
            whileInView="visible"
            transition={{
                delay: 0.15 * (props.index ? props.index + 1 : 1),
                duration: 0.6,
            }}
            variants={cardRevealUp}
            key={`${props.title}-${props.index}`}
            flex={1}
            flexDirection={'column'}
            extraClassName={`${styles.smallCardContainer} ${
                !props.video
                    ? styles.animationCards
                    : styles.animationCardsVideo
            }`}
        >
            {isImage ? (
                <Box
                    width={'full'}
                    justify="center"
                    extraClassName={
                        props.imageShape === 'Square'
                            ? ''
                            : styles.imageAndDotBox
                    }
                >
                    <Box
                        width={'full'}
                        boxStyles={{
                            maxHeight: '17.75rem',
                            maxWidth: '17.75rem',
                            paddingBottom: '4rem',
                        }}
                    >
                        <OrbitImage
                            containerclass={`${
                                props.imageShape === 'Square'
                                    ? styles.squareShape
                                    : styles.circleShape
                            }`}
                            src={props.image || ''}
                            alt={props.imagealt || ''}
                            height={'100%'}
                            width={'100%'}
                            objectFit={
                                props.imageShape === 'Square'
                                    ? 'contain'
                                    : 'cover'
                            }
                            layout="fill"
                        />
                    </Box>
                    {props.imageShape !== 'Square' && (
                        <Box
                            boxStyles={{
                                backgroundColor: props.dotcolor,
                                content: '',
                            }}
                            extraClassName={styles.decorativeCircleSmallCard({
                                type: 'circle',
                            })}
                        ></Box>
                    )}
                </Box>
            ) : isVideoImage ? (
                <Box
                    onClick={() => setOpen(true)}
                    extraClassName={styles.modalBoxStyle}
                >
                    <Box
                        width={'full'}
                        extraClassName={styles.videowithimageBox}
                        justify="center"
                        align="center"
                    >
                        <OrbitImage
                            containerclass={`${styles.squareShape}`}
                            src={props.image || ''}
                            alt={props.imagealt || ''}
                            height={'100%'}
                            width={'100%'}
                            layout="fill"
                            objectFit="contain"
                        />
                        <Box extraClassName={styles.PlayPauseButton}>
                            <VideoPlayIcon
                                className={styles.PlayPauseButtonIcon}
                            />
                        </Box>
                    </Box>
                </Box>
            ) : (
                <Box
                    extraClassName={styles.modalBoxStyle}
                    onClick={() => setOpen(true)}
                >
                    <OrbitVideo
                        url={props.video || ''}
                        color={props.cardColor}
                        renderAsModal={true}
                        title={`Myndband um ${props.title}`}
                    />
                </Box>
            )}
            {isVideo ||
                (isVideoImage && (
                    <Modal open={isOpen} onClose={() => setOpen(false)}>
                        <Box width={80}>
                            <OrbitVideo
                                url={props.video || ''}
                                color={props.cardColor}
                                renderAsModal={false}
                                title={`Myndband um ${props.title}`}
                            />
                        </Box>
                    </Modal>
                ))}
            <Box
                flexDirection="column"
                align="center"
                justify={props.video ? 'flexEnd' : 'spaceBetween'}
                boxStyles={{ height: '100%' }}
            >
                <Box
                    flexDirection="column"
                    boxStyles={{ textAlign: 'center' }}
                    align="center"
                    justify="spaceBetween"
                >
                    {props.tagline && (
                        <Text
                            textClass={styles.smallCardTaglineStyle}
                            style={{
                                color:
                                    !props.highlightStart && !props.highlightEnd
                                        ? props.highlightColor
                                        : theme.colors.neutral[600],
                            }}
                        >
                            {props.tagline}
                        </Text>
                    )}

                    <Heading
                        type={'h3'}
                        text={props.title || ''}
                        highlightStart={
                            props.highlightStart && props.highlightStart - 1
                        }
                        highlightEnd={
                            props.highlightEnd && props.highlightEnd - 1
                        }
                        highlightColor={props.highlightColor}
                        mx={0}
                        my={1}
                    />
                </Box>
                {props.buttonLink && (
                    <Box marginTop={36} paddingBottom={24} justify="center">
                        <Button
                            href={props.buttonLink}
                            variant={props.buttonLabel ? 'filled' : 'icon'}
                            color={props.cardColor}
                            icon={props.buttonIcon}
                            ariaLabel={
                                props.buttonLabel ? undefined : props.buttonIcon
                            }
                            newTab={props.openNewTab}
                        >
                            {props.buttonLabel}
                        </Button>
                    </Box>
                )}
            </Box>
        </MotionBox>
    )
}
