import Image, { ImageProps } from 'next/image'
import { imageContainerStyle } from './Image.css'

type TOrbitImageProps = ImageProps & {
    containerclass?: string
    quality?: 25 | 50 | 75 | 100
    noSkeleton?: boolean
    loading?: 'lazy' | 'eager'
}

export const OrbitImage: React.FC<TOrbitImageProps> = (props) => {
    return (
        <div
            className={`${imageContainerStyle} ${
                props.containerclass ? props.containerclass : ''
            }`}
            style={{ width: props.width, height: props.height }}
        >
            <Image
                style={props.style ? props.style : {}}
                objectFit={props.objectFit || 'cover'}
                objectPosition={props.objectPosition || 'center'}
                src={props.src}
                width={props.layout != 'fill' ? props.width : undefined}
                height={props.layout != 'fill' ? props.width : undefined}
                layout={props.layout || 'intrinsic'}
                placeholder={props.noSkeleton ? undefined : 'blur'}
                blurDataURL={props.src && props.src.toString()}
                quality={props.quality || 100}
                alt={props.alt || ''}
                onLoadingComplete={
                    props.onLoadingComplete
                        ? props.onLoadingComplete
                        : undefined
                }
                sizes={props.sizes || '100vw'}
                loading={props.loading ? props.loading : 'eager'}
                onError={props.onError && props.onError}
            />
        </div>
    )
}

export default OrbitImage
