import { JSXMapSerializer } from '@prismicio/react'
import {
    Heading1,
    Heading2,
    Heading3,
    Heading4,
    Heading5,
    Heading6,
} from './Headings'
import { Paragraph, Strong } from './Text'
import { List, ListItem } from './Lists'

export const defaultRichTextComponents: JSXMapSerializer = {
    heading1: Heading1,
    heading2: Heading2,
    heading3: Heading3,
    heading4: Heading4,
    heading5: Heading5,
    heading6: Heading6,
    paragraph: Paragraph,
    strong: Strong,
    listItem: ListItem,
    list: List,
}
