import * as React from 'react'

export interface IconProps {
    width?: string
    height?: string
    color?: string
}

export const LinkedInIcon: React.FC<IconProps> = ({ width, height, color }) => {
    return (
        <svg
            width={width || '27'}
            height={height || '28'}
            viewBox="0 0 27 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M24.7932 0.578067H2.20658C1.95269 0.574541 1.70058 0.621064 1.46467 0.714979C1.22876 0.808894 1.01365 0.948361 0.831645 1.12542C0.649638 1.30247 0.504291 1.51365 0.403905 1.74688C0.303519 1.98011 0.25006 2.23084 0.246582 2.48473V25.3381C0.25006 25.592 0.303519 25.8427 0.403905 26.0759C0.504291 26.3092 0.649638 26.5203 0.831645 26.6974C1.01365 26.8744 1.22876 27.0139 1.46467 27.1078C1.70058 27.2017 1.95269 27.2483 2.20658 27.2447H24.7932C25.0471 27.2483 25.2992 27.2017 25.5352 27.1078C25.7711 27.0139 25.9862 26.8744 26.1682 26.6974C26.3502 26.5203 26.4955 26.3092 26.5959 26.0759C26.6963 25.8427 26.7498 25.592 26.7533 25.3381V2.48473C26.7498 2.23084 26.6963 1.98011 26.5959 1.74688C26.4955 1.51365 26.3502 1.30247 26.1682 1.12542C25.9862 0.948361 25.7711 0.808894 25.5352 0.714979C25.2992 0.621064 25.0471 0.574541 24.7932 0.578067V0.578067ZM8.28658 22.8981H4.28658V10.8981H8.28658V22.8981ZM6.28658 9.21807C5.73493 9.21807 5.20588 8.99892 4.8158 8.60885C4.42572 8.21877 4.20658 7.68972 4.20658 7.13807C4.20658 6.58642 4.42572 6.05736 4.8158 5.66728C5.20588 5.27721 5.73493 5.05807 6.28658 5.05807C6.57951 5.02485 6.87615 5.05387 7.15708 5.14324C7.43801 5.23262 7.6969 5.38032 7.91679 5.57668C8.13668 5.77305 8.31262 6.01364 8.43308 6.28271C8.55354 6.55178 8.61581 6.84326 8.61581 7.13807C8.61581 7.43287 8.55354 7.72435 8.43308 7.99342C8.31262 8.26249 8.13668 8.50309 7.91679 8.69945C7.6969 8.89581 7.43801 9.04352 7.15708 9.13289C6.87615 9.22226 6.57951 9.25129 6.28658 9.21807V9.21807ZM22.7132 22.8981H18.7132V16.4581C18.7132 14.8447 18.1399 13.7914 16.6866 13.7914C16.2368 13.7947 15.7988 13.9358 15.4317 14.1956C15.0646 14.4555 14.7859 14.8216 14.6333 15.2447C14.5289 15.5581 14.4837 15.8882 14.4999 16.2181V22.8847H10.4999C10.4999 22.8847 10.4999 11.9781 10.4999 10.8847H14.4999V12.5781C14.8633 11.9475 15.3918 11.4281 16.0285 11.0757C16.6652 10.7232 17.386 10.5512 18.1132 10.5781C20.7799 10.5781 22.7132 12.2981 22.7132 15.9914V22.8981Z"
                fill={color || '#0F1015'}
            />
        </svg>
    )
}
