import {
    parsePrismicHeadingColor,
    parsePrismicHighlightColor,
} from '@orbit/prismic'
import {
    Box,
    Text,
    Heading,
    Button,
    Container,
    OrbitImage,
} from '@orbit/ui/core'
import { useState, useEffect } from 'react'
import { useWindowSize } from 'react-use'
import * as styles from '../HeroBanner.css'
import { THeroBannerSliceLargeImage } from '../HeroBanner.types'
import { BreakPoints } from '@orbit/ui/themes'
import { CompanyLogos } from '../components'
import Image from 'next/image'
import { PrismicRichText } from '@prismicio/react'
export const LargeImageHeroBanner = (slice: THeroBannerSliceLargeImage) => {
    const { width } = useWindowSize()
    const [isTablet, setIsTablet] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const realBubbleColor = slice.primary.bubblecolor
        ? parsePrismicHeadingColor(slice.primary.bubblecolor)
        : 'none'
    useEffect(() => {
        if (width <= BreakPoints.tabletSmall) {
            return setIsTablet(true)
        }
        setIsTablet(false)
    }, [width])

    return (
        <Box id={slice.primary.anchor || undefined}>
            <Container>
                <Box
                    extraClassName={styles.LargeHeroBannerWrapper}
                    align="center"
                >
                    <Box
                        width="full"
                        flexDirection="column"
                        extraClassName={styles.LargeHeroBannerContentWrapper}
                    >
                        {slice.primary.logo && slice.primary.logo.url && (
                            <Box extraClassName={styles.imageBox}>
                                <OrbitImage
                                    src={slice.primary.logo.url || ''}
                                    alt={slice.primary.logo.alt || ''}
                                    layout="fill"
                                    objectFit="contain"
                                    objectPosition="left"
                                    containerclass={styles.imageWrappers}
                                />
                            </Box>
                        )}
                        {slice.primary.title && (
                            <Heading
                                text={slice.primary.title}
                                highlightStart={
                                    slice.primary.highlightstart &&
                                    slice.primary.highlightstart - 1
                                }
                                highlightEnd={
                                    slice.primary.highlightend &&
                                    slice.primary.highlightend - 1
                                }
                                highlightColor={parsePrismicHighlightColor(
                                    slice.primary.slicecolor
                                )}
                                type="h1"
                                my={2}
                                style={{
                                    marginTop: '1.5rem',
                                    overflowWrap: 'break-word',
                                    wordBreak: 'break-word',
                                }}
                            />
                        )}

                        <PrismicRichText
                            components={{
                                paragraph: ({ text }) => (
                                    <Text
                                        style={{ fontSize: '1.125rem' }}
                                        size="large"
                                    >
                                        {text}
                                    </Text>
                                ),
                            }}
                            field={slice.primary.description}
                        />
                        <Box wrap="wrap">
                            {slice.primary.buttonlink?.url &&
                                slice.primary.buttoncolor && (
                                    <Box marginRight={24} marginTop={24}>
                                        <Button
                                            variant={
                                                slice.primary.buttonlabel
                                                    ? 'filled'
                                                    : 'icon'
                                            }
                                            size="large"
                                            color={parsePrismicHeadingColor(
                                                slice.primary.buttoncolor
                                            )}
                                            icon={slice.primary.buttonicon}
                                            href={slice.primary.buttonlink.url}
                                            ariaLabel={
                                                slice.primary.buttonlabel
                                                    ? undefined
                                                    : slice.primary.buttonicon
                                            }
                                            newTab={slice.primary.openNewTab}
                                        >
                                            {slice.primary.buttonlabel}
                                        </Button>
                                    </Box>
                                )}
                            {slice.primary.secondbuttonlink?.url &&
                                slice.primary.buttoncolor && (
                                    <Box marginTop={24}>
                                        <Button
                                            variant={
                                                slice.primary.secondbuttonlabel
                                                    ? 'ghost'
                                                    : 'icon'
                                            }
                                            size="large"
                                            color={parsePrismicHeadingColor(
                                                slice.primary.buttoncolor
                                            )}
                                            icon={
                                                slice.primary.secondbuttonicon
                                            }
                                            href={
                                                slice.primary.secondbuttonlink
                                                    .url
                                            }
                                            ariaLabel={
                                                slice.primary.secondbuttonlabel
                                                    ? undefined
                                                    : slice.primary
                                                          .secondbuttonicon
                                            }
                                            newTab={
                                                slice.primary.openSecondNewTab
                                            }
                                        >
                                            {slice.primary.secondbuttonlabel}
                                        </Button>
                                    </Box>
                                )}
                        </Box>
                        {slice.items && (
                            <Box paddingTop={isTablet ? 36 : 64}>
                                <CompanyLogos
                                    items={slice.items}
                                    alt={slice.primary.title || ''}
                                />
                            </Box>
                        )}
                    </Box>
                    <Box width="full" position="relative">
                        <Box position="relative" width="full">
                            {slice.primary.image && slice.primary.image.url && (
                                <Box position="relative" width="full">
                                    <Box
                                        extraClassName={`${styles.LargeImage} ${styles.SubpageLargeImage}`}
                                        boxStyles={{
                                            filter: slice.primary
                                                .circlemaskforlargeimage
                                                ? 'grayscale(100%)'
                                                : 'unset',
                                        }}
                                    >
                                        <Image
                                            src={slice.primary.image.url}
                                            alt={
                                                slice.primary.image.alt ||
                                                'Myndskreyting'
                                            }
                                            objectFit={
                                                slice.primary
                                                    .circlemaskforlargeimage
                                                    ? 'cover'
                                                    : 'contain'
                                            }
                                            objectPosition={
                                                slice.primary
                                                    .circlemaskforlargeimage
                                                    ? 'center'
                                                    : 'center left'
                                            }
                                            height="570"
                                            width="631"
                                            layout="fill"
                                            style={{
                                                opacity: slice.primary
                                                    .circlemaskforlargeimage
                                                    ? 0.5
                                                    : 1,
                                            }}
                                        />
                                    </Box>
                                    {slice.primary.circlemaskforlargeimage && (
                                        <Box
                                            extraClassName={`${styles.LargeImageContainerMask} `}

                                            // extraClassName={`${styles.ImageMask} ${styles.SubpageImageMask}`}
                                        >
                                            {isLoaded && (
                                                <Box
                                                    extraClassName={`${styles.BannerLargeImageMaskCircle(
                                                        {
                                                            color: realBubbleColor,
                                                        }
                                                    )}
                                                        ${
                                                            styles.ScaleMaskLargeImageAnimation
                                                        }`}
                                                />
                                            )}
                                            <div
                                                className={`${
                                                    isLoaded
                                                        ? styles.BannerMaskLargeImageAnimation
                                                        : styles.notLoaded
                                                } ${
                                                    styles.LargeMaskImageContainer
                                                } `}
                                            >
                                                <Image
                                                    objectFit={'cover'}
                                                    objectPosition={'center'}
                                                    src={
                                                        slice.primary.image.url
                                                    }
                                                    width={'570px'}
                                                    height={'631px'}
                                                    layout={'fill'}
                                                    quality={100}
                                                    alt={
                                                        slice.primary.image
                                                            .alt ||
                                                        'Myndskreyting'
                                                    }
                                                    onLoadingComplete={() =>
                                                        setIsLoaded(true)
                                                    }
                                                    sizes={'100vw'}
                                                    style={{
                                                        display: isLoaded
                                                            ? 'block'
                                                            : 'none',
                                                    }}
                                                />
                                            </div>
                                        </Box>
                                    )}
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

export default LargeImageHeroBanner
