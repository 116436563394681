import { useEffect, useState } from 'react'
import {
    parsePrismicTextCardBackgroundColor,
    parseSliceSpace,
} from '@orbit/prismic'
import { BreakPoints } from '@orbit/ui/themes'
import { useWindowSize } from 'react-use'
import {
    TPrefooterContext,
    TPreFooterSocialMediaSliceDefault,
} from '../PreFooter.types'
import { Box, Container, Heading, MotionBox } from '@orbit/ui/core'
import { Facebook } from '../../OrbitFooter/icons/Facebook'
import { LinkedIn } from '../../OrbitFooter/icons/LinkedIn'
import { Youtube } from '../../OrbitFooter/icons/Youtube'
import { Instagram } from '../../OrbitFooter/icons/Instagram'
import * as styles from '../PreFooter.css'
import { Twitter } from '../../OrbitFooter/icons/Twitter'
import { cardRevealUp } from '@orbit/utils/framer-motion'

export const SocialMediaPreFooter = (
    slice: TPreFooterSocialMediaSliceDefault,
    context: TPrefooterContext
) => {
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)
    useEffect(() => {
        if (width < BreakPoints.tabletSmall) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])
    const arr = []
    if (slice.primary.facebooklink.link_type) {
        arr.push(slice.primary.facebooklink)
    }
    if (slice.primary.instagramlink.link_type === 'Web') {
        arr.push(slice.primary.instagramlink)
    }
    if (slice.primary.youtubelink.link_type === 'Web') {
        arr.push(slice.primary.youtubelink)
    }
    if (slice.primary.linkedinlink.link_type === 'Web') {
        arr.push(slice.primary.linkedinlink)
    }
    if (slice.primary.twitterlink.link_type === 'Web') {
        arr.push(slice.primary.twitterlink)
    }
    return (
        <Box
            paddingBottom={parseSliceSpace(context?.type, isMobile)}
            paddingTop={parseSliceSpace(context?.type, isMobile)}
            justify={'center'}
            anchorid={slice.primary.anchor || undefined}
        >
            <Container>
                <MotionBox
                    align="center"
                    justify="center"
                    flexDirection={'column'}
                    boxStyles={{
                        backgroundColor: parsePrismicTextCardBackgroundColor(
                            slice.primary.slicecolor
                        ),
                    }}
                    initial={isMobile ? 'visible' : 'hidden'}
                    viewport={{
                        once: true,
                    }}
                    whileInView="visible"
                    transition={{
                        duration: 0.6,
                        delay: 0.2,
                    }}
                    variants={cardRevealUp}
                >
                    {slice.primary.title && (
                        <Box align="center">
                            <Heading
                                text={slice.primary.title}
                                type={'h3'}
                                style={{
                                    marginBottom: '1rem',
                                    fontSize: isMobile ? '1.25rem' : '2rem',
                                }}
                            />
                        </Box>
                    )}
                    <Box
                        flexDirection="row"
                        paddingBottom={40}
                        wrap="wrap"
                        boxStyles={{
                            display: 'grid',
                            gridTemplateColumns: isMobile
                                ? `repeat( ${arr.length === 1 ? 1 : 2}, 1fr)`
                                : `repeat( ${arr.length}, 1fr)`,
                            gridTemplateRows: isMobile
                                ? 'repeat(2,1fr)'
                                : 'repeat(1,1fr)',
                        }}
                    >
                        {slice.primary.facebooklink.url && (
                            <Box
                                align="center"
                                paddingLeft={isMobile ? 24 : 36}
                                paddingRight={isMobile ? 24 : 36}
                            >
                                <Box padding={3}>
                                    <Facebook color="black" />
                                </Box>
                                <a
                                    className={styles.sociaMediaText}
                                    href={slice.primary.facebooklink.url}
                                >
                                    facebook
                                </a>
                            </Box>
                        )}
                        {slice.primary.linkedinlink.url && (
                            <Box
                                align="center"
                                paddingLeft={isMobile ? 24 : 36}
                                paddingRight={isMobile ? 24 : 36}
                            >
                                <Box padding={3}>
                                    <LinkedIn color="black" />
                                </Box>
                                <a
                                    className={styles.sociaMediaText}
                                    href={slice.primary.linkedinlink.url}
                                >
                                    linkedIn
                                </a>
                            </Box>
                        )}
                        {slice.primary.youtubelink.url && (
                            <Box
                                align="center"
                                paddingLeft={isMobile ? 24 : 36}
                                paddingRight={isMobile ? 24 : 36}
                            >
                                <Box padding={3}>
                                    <Youtube color="black" />
                                </Box>
                                <a
                                    className={styles.sociaMediaText}
                                    href={slice.primary.youtubelink.url}
                                >
                                    youtube
                                </a>
                            </Box>
                        )}
                        {slice.primary.instagramlink.url && (
                            <Box
                                align="center"
                                paddingLeft={isMobile ? 24 : 36}
                                paddingRight={isMobile ? 24 : 36}
                            >
                                <Box padding={3}>
                                    <Instagram color="black" />
                                </Box>
                                <a
                                    className={styles.sociaMediaText}
                                    href={slice.primary.instagramlink.url}
                                >
                                    instagram
                                </a>
                            </Box>
                        )}
                        {slice.primary.twitterlink.url && (
                            <Box
                                align="center"
                                paddingLeft={isMobile ? 24 : 36}
                                paddingRight={isMobile ? 24 : 36}
                            >
                                <Box padding={3}>
                                    <Twitter color="black" />
                                </Box>
                                <a
                                    className={styles.sociaMediaText}
                                    href={slice.primary.twitterlink.url}
                                >
                                    twitter
                                </a>
                            </Box>
                        )}
                    </Box>
                </MotionBox>
            </Container>
        </Box>
    )
}

export default SocialMediaPreFooter
