import * as prismic from '@prismicio/client' // client to query content
import { BuildQueryURLArgs } from '@prismicio/client'
import { enableAutoPreviews } from '@prismicio/next' // plugin for previews
import { PreviewData } from 'next'

export const endpoint = `https://${process.env.PRISMIC_REPO_NAME}.cdn.prismic.io/api/v2`
export const repositoryName = prismic.getRepositoryName(endpoint)

// This factory function allows smooth preview setup

interface CreateClientProps {
    req?: prismic.HttpRequestLike
    previewData?: PreviewData
    routes: NonNullable<BuildQueryURLArgs['routes']>
}

export function createClient({
    req,
    previewData,
    routes,
}: CreateClientProps): prismic.Client {
    try {
        const client = prismic.createClient(endpoint, {
            accessToken: process.env.PRISMIC_API_TOKEN,
            routes,
        })

        enableAutoPreviews({
            client,
            previewData: previewData,
            req: req,
        })

        return client
    } catch (error) {
        console.error(
            'Could not connect to prismic, check your environmental variables'
        )
        throw error
    }
}
