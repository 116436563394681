import { useEffect, useState } from 'react'
import {
    parsePrismicHeadingColor,
    parsePrismicHighlightColor,
    parsePrismicColorName,
    parsePrismicBackgroundColor,
    parsePrismicDarkColor,
} from '@orbit/prismic'
import { useWindowSize } from 'react-use'
import { BreakPoints, theme } from '@orbit/ui/themes'
import { TImageWithTextSliceWithLogosAndTextSlice } from '../ImageWithText.types'
import { PrismicRichText } from '@prismicio/react'
import {
    Box,
    Container,
    Button,
    Text,
    HeadingContainer,
    MotionBox,
    OrbitImage,
} from '@orbit/ui/core'
import Image from 'next/image'
import * as styles from './ImageWithText.css'
import { cardRevealUp } from '@orbit/utils/framer-motion'
import { itemsLength } from '../utils'

export const WithLogosAndTextImageWithText = (
    slice: TImageWithTextSliceWithLogosAndTextSlice
) => {
    const { primary } = slice
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        if (width < BreakPoints.tabletSmall) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    const renderImage = () => {
        return (
            <Box
                width={'full'}
                boxStyles={{
                    position: 'relative',
                    height: primary.imageheightauto ? '100%' : 'unset',
                    marginBottom: isMobile && slice.items.length ? '25px' : '',
                }}
            >
                <>
                    {primary.image && (
                        <>
                            {isMobile && (
                                <img
                                    className={styles.image}
                                    src={primary.image.url || ''}
                                    alt={primary.image.alt || 'myndskreyting'}
                                    style={{
                                        objectFit: 'cover',
                                    }}
                                />
                            )}

                            {!isMobile && (
                                <OrbitImage
                                    src={primary.image.url || ''}
                                    alt={primary.image.alt || 'myndskreyting'}
                                    containerclass={styles.imageContainer}
                                    height={
                                        primary.imageheightauto ? 'auto' : 480
                                    }
                                    width={'100%'}
                                    layout="fill"
                                    objectFit="cover"
                                />
                            )}
                        </>
                    )}
                    {slice.items.length && (
                        <Box
                            extraClassName={styles.gridWrapper({
                                size: itemsLength(slice.items.length),
                            })}
                        >
                            {slice.items.map((item) => (
                                <Box
                                    padding={isMobile ? 1 : 2}
                                    boxStyles={{
                                        height: '100%',
                                        width: '100%',
                                    }}
                                >
                                    <OrbitImage
                                        src={item.logo.url || ''}
                                        alt={item.logo.alt || 'myndskreyting'}
                                        containerclass={styles.imageContainer}
                                        width="100%"
                                        layout="fill"
                                        objectFit="contain"
                                    />
                                </Box>
                            ))}
                        </Box>
                    )}
                </>
            </Box>
        )
    }
    return (
        <MotionBox
            anchorid={slice.primary.anchor || undefined}
            justify="center"
            paddingBottom={isMobile ? 36 : 80}
            paddingTop={isMobile ? 36 : 80}
            boxStyles={{
                backgroundColor: primary.backgroundfilled
                    ? parsePrismicBackgroundColor(slice.primary.slicecolor)
                    : theme.colors.neutral[100],
            }}
            initial={isMobile ? 'visible' : 'hidden'}
            viewport={{
                once: true,
            }}
            whileInView="visible"
            transition={{
                duration: 0.6,
                delay: 0.3,
            }}
            variants={cardRevealUp}
        >
            <Container>
                <Box>
                    <Box
                        width={'full'}
                        display="flex"
                        flexDirection={
                            isMobile
                                ? 'columnReverse'
                                : primary.textposition === 'Right side'
                                ? 'rowReverse'
                                : 'row'
                        }
                        align="stretch"
                        gap="3rem"
                    >
                        <Box
                            flex={1}
                            flexDirection={'column'}
                            paddingBottom={isMobile ? 8 : 0}
                            align={'flexStart'}
                            width="full"
                            paddingRight={isMobile ? 0 : 48}
                            paddingLeft={isMobile ? 0 : 48}
                        >
                            <HeadingContainer
                                title={primary.title || undefined}
                                tagline={primary.tagline || undefined}
                                highlightColor={parsePrismicHighlightColor(
                                    slice.primary.slicecolor
                                )}
                                highlightStart={primary.highlightstart}
                                highlightEnd={primary.highlightend}
                                variant="left"
                                gap="1rem"
                                color={parsePrismicHeadingColor(
                                    slice.primary.slicecolor
                                )}
                                pb="1rem"
                            />

                            {isMobile && renderImage()}
                            <PrismicRichText
                                field={primary.text}
                                components={{
                                    list: ({ children }) => (
                                        <ul className={styles.bullet}>
                                            {children}
                                        </ul>
                                    ),
                                    listItem: ({ children }) => (
                                        <li
                                            className={`${styles.listItem({
                                                colors: parsePrismicColorName(
                                                    slice.primary.slicecolor
                                                ),
                                            })}`}
                                        >
                                            <Text> {children} </Text>
                                        </li>
                                    ),
                                    hyperlink: ({ text, node }) => (
                                        <a
                                            style={{
                                                color: parsePrismicDarkColor(
                                                    slice.primary.slicecolor
                                                ),
                                                fontWeight: 600,
                                                textDecoration: 'none',
                                            }}
                                            href={node.data.url}
                                            target={
                                                node.data.link_type === 'Web'
                                                    ? node.data.target
                                                    : ''
                                            }
                                            rel="noopener noreferrer"
                                        >
                                            {text}
                                        </a>
                                    ),
                                }}
                            />

                            {primary.buttonlink && primary.buttonlink?.url && (
                                <Box
                                    width={'full'}
                                    paddingTop={24}
                                    justify={'flexStart'}
                                >
                                    <Button
                                        variant={
                                            primary.buttonlabel
                                                ? 'filled'
                                                : 'icon'
                                        }
                                        href={primary.buttonlink.url}
                                        color={parsePrismicHeadingColor(
                                            slice.primary.slicecolor
                                        )}
                                        icon={primary.buttonicon}
                                        ariaLabel={
                                            primary.buttonlabel
                                                ? undefined
                                                : primary.buttonicon
                                        }
                                        newTab={primary.openNewTab}
                                    >
                                        {primary.buttonlabel}
                                    </Button>
                                </Box>
                            )}
                        </Box>
                        <Box
                            flex={1}
                            width={isMobile ? 'full' : 'half'}
                            justify={
                                isMobile
                                    ? 'center'
                                    : primary.textposition === 'Right side'
                                    ? 'flexStart'
                                    : 'flexEnd'
                            }
                        >
                            {!isMobile && renderImage()}
                        </Box>
                    </Box>
                </Box>
            </Container>
        </MotionBox>
    )
}

export default WithLogosAndTextImageWithText
