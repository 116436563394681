import { Box, Text, Heading, OrbitImage, Container } from '@orbit/ui/core'
import { useState, useEffect } from 'react'
import { useWindowSize } from 'react-use'
import * as styles from '../HeroBanner.css'
import { THeroBannerSliceSolution } from '../HeroBanner.types'
import { BreakPoints } from '@orbit/ui/themes'
import {
    parsePrismicHeadingColor,
    parsePrismicHighlightColor,
} from '@orbit/prismic'
import { PageLinks } from '../components'
import { PrismicRichText } from '@prismicio/react'

export const SolutionHeroBanner = (slice: THeroBannerSliceSolution) => {
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)
    const [isTablet, setIsTablet] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const realBubbleColor = slice.primary.bubblecolor
        ? parsePrismicHeadingColor(slice.primary.bubblecolor)
        : 'none'

    useEffect(() => {
        if (width <= BreakPoints.mobile) {
            setIsTablet(true)
            return setIsMobile(true)
        }
        setIsMobile(false)
        if (width <= BreakPoints.tabletSmall) {
            return setIsTablet(true)
        }
        setIsTablet(false)
    }, [width])

    return (
        <Box id={slice.primary.anchor || undefined}>
            <Box width="full" boxStyles={{ overflow: 'hidden' }}>
                <Container>
                    <Box
                        extraClassName={styles.HeroBannerWrapper}
                        align="center"
                    >
                        <Box
                            alignSelf="center"
                            flexDirection="column"
                            width={isMobile ? 'full' : 'half'}
                            extraClassName={
                                styles.LargeHeroBannerContentWrapper
                            }
                        >
                            {slice.primary.title && (
                                <Heading
                                    text={slice.primary.title}
                                    highlightStart={
                                        slice.primary.highlightstart &&
                                        slice.primary.highlightstart - 1
                                    }
                                    highlightEnd={
                                        slice.primary.highlightend &&
                                        slice.primary.highlightend - 1
                                    }
                                    highlightColor={parsePrismicHighlightColor(
                                        slice.primary.slicecolor
                                    )}
                                    type="h1"
                                    my={2}
                                    style={{
                                        overflowWrap: 'break-word',
                                        wordBreak: 'break-word',
                                    }}
                                />
                            )}
                            <PrismicRichText
                                components={{
                                    paragraph: ({ text }) => (
                                        <Text
                                            style={{ fontSize: '1.125rem' }}
                                            size="large"
                                        >
                                            {text}
                                        </Text>
                                    ),
                                }}
                                field={slice.primary.description}
                            />
                            {slice.items && (
                                <PageLinks
                                    items={slice.items}
                                    color={slice.primary.bubblecolor}
                                    isMobile={isMobile}
                                    isTablet={isTablet}
                                />
                            )}
                        </Box>
                        <Box width={isMobile ? 'full' : 'half'}>
                            <Box
                                position="relative"
                                width="full"
                                extraClassName={styles.SubpageImageWrapper}
                            >
                                {slice.primary.image &&
                                    slice.primary.image.url && (
                                        <Box
                                            extraClassName={styles.CircleImage}
                                        >
                                            {isLoaded && (
                                                <Box
                                                    extraClassName={`${styles.BannerImageMask(
                                                        {
                                                            color: realBubbleColor,
                                                        }
                                                    )} ${
                                                        styles.ScaleMaskAnimation
                                                    }`}
                                                />
                                            )}
                                            <OrbitImage
                                                src={slice.primary.image.url}
                                                alt={
                                                    slice.primary.image.alt ||
                                                    'Myndskreyting'
                                                }
                                                width={1000}
                                                height={1000}
                                                layout="fill"
                                                objectFit="cover"
                                                objectPosition="center"
                                                noSkeleton={true}
                                                containerclass={`${
                                                    styles.SubpageCircleInnerImage
                                                } ${
                                                    isLoaded
                                                        ? styles.BannerMaskAnimation
                                                        : styles.notLoaded
                                                }`}
                                                onLoadingComplete={() => {
                                                    return setIsLoaded(true)
                                                }}
                                                style={{
                                                    backgroundColor: isLoaded
                                                        ? parsePrismicHighlightColor(
                                                              slice.primary
                                                                  .imagebackgroundcolor
                                                          )
                                                        : undefined,
                                                    backgroundSize: '100% 100%',
                                                    borderRadius: '50%',
                                                    display: isLoaded
                                                        ? 'block'
                                                        : 'none',
                                                }}
                                            />
                                        </Box>
                                    )}
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </Box>
    )
}

export default SolutionHeroBanner
