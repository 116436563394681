import { useEffect, useRef, useState } from 'react'
import { useWindowSize } from 'react-use'
import { BreakPoints } from '@orbit/ui/themes'

import { motion } from 'framer-motion'
import {
    TLogoSliderItemsDefault,
    TLogoSliderSliceDefault,
} from '../LogoSlider.types'
import { parsePrismicDarkColor, parsePrismicHeadingColor } from '@orbit/prismic'
import {
    Box,
    Container,
    HeadingContainer,
    MotionBox,
    OrbitImage,
} from '@orbit/ui/core'
import * as styles from '../LogoSlider.css'
import { cardRevealUp } from '@orbit/utils/framer-motion'
import SwiperCore, { Autoplay, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { PrismicRichText } from '@prismicio/react'

export const DefaultLogoSlider = (slice: TLogoSliderSliceDefault) => {
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)
    const swiperRef = useRef<SwiperCore>()
    const navigationPrevRef = useRef<HTMLDivElement>(null)
    const navigationNextRef = useRef<HTMLDivElement>(null)
    SwiperCore.use([Pagination, Autoplay])

    const textAnimation = {
        visible: {
            opacity: 1,
        },
        hidden: { opacity: 0 },
    }

    useEffect(() => {
        if (width < BreakPoints.tabletSmall) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    const numberOfSlidesPerView = () => {
        if (width > BreakPoints.tabletSmall) {
            return 4
        }

        if (width > BreakPoints.mobile) {
            return 3
        }
        if (width > BreakPoints.mobileSmall) {
            return 2
        }
        return 2
    }

    return (
        <Box
            anchorid={slice.primary.anchor || undefined}
            marginBottom={64}
            marginTop={64}
        >
            <motion.div
                style={{
                    width: '100%',
                }}
                initial="hidden"
                animate="visible"
                variants={textAnimation}
                transition={{ delay: 0.4, duration: 0.7 }}
            >
                <Container>
                    <Box
                        flexDirection="column"
                        width="full"
                        justify="center"
                        align="center"
                        paddingTop={36}
                    >
                        <Box align={'center'} flexDirection="column">
                            <HeadingContainer
                                tagline={slice.primary.tagline || ''}
                                title={slice.primary.title || ''}
                                color={parsePrismicHeadingColor(
                                    slice.primary.slicecolor
                                )}
                                pb={'1rem'}
                            />
                            <PrismicRichText
                                field={slice.primary.text}
                                components={{
                                    hyperlink: ({ text, node }) => (
                                        <a
                                            style={{
                                                color: parsePrismicDarkColor(
                                                    slice.primary.slicecolor
                                                ),
                                                fontWeight: 600,
                                                textDecoration: 'none',
                                            }}
                                            href={node.data.url}
                                            target={
                                                node.data.link_type === 'Web'
                                                    ? node.data.target
                                                    : ''
                                            }
                                            rel="noopener noreferrer"
                                        >
                                            {text}
                                        </a>
                                    ),
                                }}
                            />
                        </Box>

                        {isMobile && slice.items.length ? (
                            <Box
                                display="block"
                                flexDirection="column"
                                align="center"
                                position="relative"
                                width={'full'}
                            >
                                <Swiper
                                    slidesPerView={numberOfSlidesPerView()}
                                    spaceBetween={0}
                                    navigation={{
                                        prevEl: navigationPrevRef.current,
                                        nextEl: navigationNextRef.current,
                                    }}
                                    onBeforeInit={(swiper) => {
                                        swiperRef.current = swiper
                                    }}
                                    grabCursor={true}
                                    className={styles.swiperStyle}
                                    loop={true}
                                    autoplay={{ delay: 3000 }}
                                    speed={500}
                                    lazy={false}
                                >
                                    {slice.items.map(
                                        (
                                            item: TLogoSliderItemsDefault,
                                            index: number
                                        ) => (
                                            <SwiperSlide
                                                // Þarf að vera inline
                                                style={{
                                                    width: 'fit-content',
                                                    height: 'auto',
                                                }}
                                                key={'Swiper' + index}
                                            >
                                                <Box
                                                    width={'full'}
                                                    justify="center"
                                                    key={index}
                                                    py={isMobile ? 3 : 5}
                                                >
                                                    <OrbitImage
                                                        src={
                                                            item.logo.url || ''
                                                        }
                                                        alt={
                                                            item.logo.alt || ''
                                                        }
                                                        height={80}
                                                        width={
                                                            isMobile ? 120 : 160
                                                        }
                                                        layout="fill"
                                                        objectFit="contain"
                                                        objectPosition="center"
                                                        quality={50}
                                                        loading="eager"
                                                    />
                                                </Box>
                                            </SwiperSlide>
                                        )
                                    )}
                                </Swiper>
                            </Box>
                        ) : (
                            <Box
                                extraClassName={styles.gridWrapper}
                                paddingBottom={0}
                                display={
                                    slice.items.length < 5 ? 'flex' : 'grid'
                                }
                                width="full"
                                justify="center"
                            >
                                {slice.items !== undefined &&
                                    slice.items.map(
                                        (
                                            item: TLogoSliderItemsDefault,
                                            index: number
                                        ) => (
                                            <MotionBox
                                                key={'logo_default_' + index}
                                                py={5}
                                                justify={'center'}
                                                boxStyles={{
                                                    padding: '0',
                                                }}
                                                initial={
                                                    isMobile
                                                        ? 'visible'
                                                        : 'hidden'
                                                }
                                                viewport={{
                                                    once: true,
                                                }}
                                                whileInView="visible"
                                                transition={{
                                                    duration: 0.6,
                                                    delay: 0.1 * (index + 1),
                                                }}
                                                variants={cardRevealUp}
                                            >
                                                <OrbitImage
                                                    src={item.logo.url || ''}
                                                    alt={item.logo.alt || ''}
                                                    // height={40}
                                                    width={120}
                                                    objectFit="contain"
                                                    objectPosition="center"
                                                />
                                            </MotionBox>
                                        )
                                    )}
                            </Box>
                        )}
                    </Box>
                </Container>
            </motion.div>
        </Box>
    )
}

export default DefaultLogoSlider
