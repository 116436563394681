import { ChangeEvent, SyntheticEvent, useContext, useEffect } from 'react'
import { useFullscreen, useToggle } from 'react-use'
import { FullScreenIcon } from '../../assets/FullScreenIcon'
import { VideoPlayIcon } from '../../assets/VideoPlayIcon'
import { VolumeIcon } from '../../assets/VolumeIcon'
import { PauseIcon } from '../../assets/PauseIcon'
import { Box } from '../Box'
import {
    IconButton,
    InputRangeStyle,
    PlayIconStyle,
    ProgressInputStyle,
    TimerTextStyle,
    VolumeInputStyle,
} from './Video.css'
import { VideoContext } from './VideoContext'

type TVideoControlsProps = {
    videoRef: React.RefObject<HTMLVideoElement>
    playPauseHandler: () => void
}

export const VideoControls: React.FC<TVideoControlsProps> = ({
    videoRef,
    playPauseHandler,
}) => {
    const videoState = useContext(VideoContext)
    const [show, toggle] = useToggle(false)
    const isFullscreen = useFullscreen(videoRef, show, {
        onClose: () => toggle(false),
    })
    const addZeroToTime = (time: number) => {
        return isNaN(time) ? '0' : time < 10 ? `0${time}` : time
    }

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.volume = videoState.volume
        }
    }, [videoRef])

    // toggle between mute and unmute
    const handleVolumeClick = (e: SyntheticEvent) => {
        e.stopPropagation()
        if (videoRef.current) {
            if (videoRef.current.muted) {
                videoRef.current.muted = false
                videoState.setIsMuted(false)
            } else {
                videoRef.current.muted = true
                videoState.setIsMuted(true)
            }
        }
    }

    // manual change when progress input is clicked/dragged
    const handleVideoProgress = (e: ChangeEvent<HTMLInputElement>) => {
        if (videoRef.current) {
            videoRef.current.currentTime = parseInt(e.target.value)
            videoState.setProgress(parseInt(e.target.value))
        }
    }

    // manual change when volume input is clicked/dragged
    const handleVideoVolume = (e: ChangeEvent<HTMLInputElement>) => {
        if (videoRef.current) {
            videoRef.current.volume = parseInt(e.target.value) / 100
            videoState.setVolume(Number(e.target.value) / 100)
        }
    }

    // calculate time remaining
    const calculateTimeRemaining = () => {
        if (videoRef.current) {
            const timeRemaining =
                videoRef.current.duration - videoRef.current.currentTime
            return isNaN(timeRemaining)
                ? '0:00'
                : Math.floor(timeRemaining / 60) +
                      ':' +
                      addZeroToTime(Math.floor(timeRemaining % 60))
        }
    }

    return (
        <>
            <Box
                boxStyles={{ flex: 8, paddingRight: '1rem' }}
                justify="center"
                width={'full'}
                align="center"
            >
                <button
                    aria-label={`${videoState.isPlaying ? 'Pása' : 'Spila'}`}
                    className={PlayIconStyle}
                    onClick={playPauseHandler}
                >
                    {videoState.isPlaying ? <PauseIcon /> : <VideoPlayIcon />}
                </button>
                <p
                    className={TimerTextStyle}
                    aria-label={`${Math.floor(
                        videoState.progress % 60
                    )} liðnar`}
                >
                    {Math.floor(videoState.progress / 60)}:
                    {addZeroToTime(Math.floor(videoState.progress % 60))}
                </p>
                <Box flex={1}>
                    <input
                        className={`${InputRangeStyle} ${ProgressInputStyle}`}
                        type="range"
                        min={0}
                        max={Math.floor(
                            videoRef.current ? videoRef.current.duration : 0
                        )}
                        aria-label="Seek slider"
                        aria-valuenow={Math.floor(videoState.progress)}
                        value={Math.floor(videoState.progress)}
                        style={{
                            backgroundSize: `${
                                (videoState.progress /
                                    Math.floor(
                                        videoRef.current
                                            ? videoRef.current.duration
                                            : videoState.progress
                                    )) *
                                100
                            }% 100%`,
                        }}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleVideoProgress(e)
                        }
                    />
                </Box>
                <p
                    className={TimerTextStyle}
                    aria-label={`${calculateTimeRemaining()} eftir`}
                >
                    {calculateTimeRemaining() || '0:00'}
                </p>
            </Box>
            <Box flex={2} justify="center" align="center">
                <button
                    onClick={handleVolumeClick}
                    className={IconButton}
                    aria-label={
                        videoState.isMuted
                            ? 'Kveikja á hljóði'
                            : 'Slökkva á hljóði'
                    }
                >
                    <VolumeIcon />
                </button>
                <input
                    className={`${InputRangeStyle} ${VolumeInputStyle}`}
                    type="range"
                    value={Math.floor(videoState.volume * 100)}
                    min={0}
                    max={100}
                    aria-label={
                        videoState.isMuted
                            ? 'Volume slider off'
                            : 'Volume slider on'
                    }
                    aria-valuenow={Math.floor(videoState.volume * 100)}
                    disabled={videoState.isMuted}
                    style={{
                        backgroundSize: `${Math.floor(
                            videoState.volume * 100
                        )}% 100%`,
                    }}
                    step={1}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleVideoVolume(e)
                    }
                />
            </Box>
            <button
                aria-label={
                    isFullscreen
                        ? 'Loka fullri skjástærð'
                        : 'Opna fulla skjástærð'
                }
                className={IconButton}
                onClick={() => toggle()}
            >
                <FullScreenIcon />
            </button>
        </>
    )
}
