import { Box, Heading } from '@orbit/ui/core'
import { TCategory } from '../VerslunMegaMenu'
import Image from 'next/image'
import * as styles from './Verslun.css'

export const CategoryProductItem: React.FC<TCategory & { mobile?: boolean }> = (
    props
) => {
    return (
        <a
            className={styles.categoryProductLink({ mobile: props.mobile })}
            href={'https://verslun.origo.is/SelectCat?catId=' + props.id}
        >
            <Box
                key={props.id}
                flexDirection="column"
                extraClassName={styles.categoryProduct}
            >
                <Box
                    boxStyles={{
                        height: '6rem',
                        width: '6rem',
                        borderRadius: '50%',
                        background: `#${props.color}`,
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        overflow: 'hidden',
                    }}
                >
                    <Image
                        width={78}
                        height={78}
                        alt={props.description}
                        placeholder="blur"
                        blurDataURL={`https://verslun.origo.is/images/${props.image}`}
                        src={`https://verslun.origo.is/images/${props.image}`}
                        objectFit="contain"
                    />
                </Box>

                <Heading
                    type="h5"
                    text={props.name}
                    style={{
                        fontSize: '0.75rem',
                        marginTop: '1rem',
                        marginBottom: '0rem',
                    }}
                />
            </Box>
        </a>
    )
}
