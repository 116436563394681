import { parsePrismicHeadingColor } from '@orbit/prismic'
import {
    Box,
    Text,
    Button,
    Icon,
    Modal,
    OrbitVideo,
    Container,
} from '@orbit/ui/core'
import { useState, useEffect } from 'react'
import { useWindowSize } from 'react-use'
import {
    HeroBannerTextStyle,
    LargeHeroBannerWrapper,
    ImageWrapper,
    PlayIconWrapper,
    PlayVideoButton,
    ImageMask,
    LargeImagePlayButton,
    LargeImageWrapper,
    LargeImage,
    LargeHeroBannerContentWrapper,
} from '../HeroBanner.css'
import Image from 'next/image'
import { THeroBannerSliceFrontpageLargeImage } from '../HeroBanner.types'
import { BreakPoints } from '@orbit/ui/themes'
import { AnimatedHeading, CompanyLogos } from '../components'
import { PrismicRichText } from '@prismicio/react'

export const FrontpageLargeImageHeroBanner = (
    slice: THeroBannerSliceFrontpageLargeImage
) => {
    const { width } = useWindowSize()
    const [openModal, setOpenModal] = useState(false)
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        if (width <= BreakPoints.tabletSmall) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    return (
        <Box
            id={slice.primary.anchor || undefined}
            boxStyles={{ overflow: 'clip' }}
        >
            <Container>
                <Box width="full">
                    <Box
                        extraClassName={LargeHeroBannerWrapper}
                        align="stretch"
                        width="full"
                    >
                        <Box
                            alignSelf="center"
                            flexDirection="column"
                            extraClassName={LargeHeroBannerContentWrapper}
                            width="full"
                        >
                            <AnimatedHeading
                                textBegin={slice.primary.textBegin || ''}
                                textEnd={slice.primary.textEnd || ''}
                                textHighlights={
                                    slice.primary.textHighlights || ''
                                }
                                startColor={slice.primary.slicecolor}
                                animate={slice.primary.animate}
                            />
                            <PrismicRichText
                                components={{
                                    paragraph: ({ text }) => (
                                        <Text size="large">{text}</Text>
                                    ),
                                }}
                                field={slice.primary.description}
                            />
                            <Box wrap="wrap">
                                {slice.primary.buttonlink?.url &&
                                    slice.primary.buttoncolor && (
                                        <Box marginRight={24} marginTop={24}>
                                            <Button
                                                variant={
                                                    slice.primary.buttonlabel
                                                        ? 'filled'
                                                        : 'icon'
                                                }
                                                size="large"
                                                color={parsePrismicHeadingColor(
                                                    slice.primary.buttoncolor
                                                )}
                                                icon={slice.primary.buttonicon}
                                                href={
                                                    slice.primary.buttonlink.url
                                                }
                                                ariaLabel={
                                                    slice.primary.buttonlabel
                                                        ? undefined
                                                        : slice.primary
                                                              .buttonicon
                                                }
                                                newTab={
                                                    slice.primary.openNewTab
                                                }
                                            >
                                                {slice.primary.buttonlabel}
                                            </Button>
                                        </Box>
                                    )}
                                {slice.primary.secondbuttonlink?.url &&
                                    slice.primary.buttoncolor && (
                                        <Box marginTop={24}>
                                            <Button
                                                variant={
                                                    slice.primary
                                                        .secondbuttonlabel
                                                        ? 'ghost'
                                                        : 'icon'
                                                }
                                                size="large"
                                                color={parsePrismicHeadingColor(
                                                    slice.primary.buttoncolor
                                                )}
                                                icon={
                                                    slice.primary
                                                        .secondbuttonicon
                                                }
                                                href={
                                                    slice.primary
                                                        .secondbuttonlink.url
                                                }
                                                ariaLabel={
                                                    slice.primary
                                                        .secondbuttonlabel
                                                        ? undefined
                                                        : slice.primary
                                                              .secondbuttonicon
                                                }
                                                newTab={
                                                    slice.primary
                                                        .openSecondNewTab
                                                }
                                            >
                                                {
                                                    slice.primary
                                                        .secondbuttonlabel
                                                }
                                            </Button>
                                        </Box>
                                    )}
                            </Box>
                            {slice.items.length > 0 && (
                                <Box boxStyles={{ minHeight: '70px' }}>
                                    <CompanyLogos
                                        items={slice.items}
                                        alt={slice.primary.textBegin || ''}
                                    />
                                </Box>
                            )}
                        </Box>
                        <Box
                            width="full"
                            boxStyles={{
                                maxWidth: isMobile ? 'unset' : '43rem',
                            }}
                        >
                            <Box
                                position="relative"
                                width="full"
                                extraClassName={`${ImageWrapper({
                                    color: slice.primary.circlemaskforlargeimage
                                        ? parsePrismicHeadingColor(
                                              slice.primary.bubblecolor
                                          )
                                        : 'none',
                                })} ${LargeImageWrapper}`}
                            >
                                {slice.primary.videolink &&
                                    slice.primary.videolink.url && (
                                        <Box
                                            extraClassName={`${LargeImagePlayButton} ${PlayVideoButton}`}
                                            onClick={() => setOpenModal(true)}
                                        >
                                            <Box
                                                extraClassName={PlayIconWrapper}
                                            >
                                                <Icon
                                                    icon="Play"
                                                    color="white"
                                                />
                                            </Box>
                                        </Box>
                                    )}
                                {slice.primary.image &&
                                    slice.primary.image.url && (
                                        <Box
                                            position="relative"
                                            width="full"
                                            boxStyles={{ height: '100%' }}
                                        >
                                            <Box
                                                extraClassName={LargeImage}
                                                boxStyles={{
                                                    filter: slice.primary
                                                        .circlemaskforlargeimage
                                                        ? 'grayscale(100%) brightness(1.2)'
                                                        : 'unset',
                                                }}
                                            >
                                                <Image
                                                    src={
                                                        slice.primary.image.url
                                                    }
                                                    alt={
                                                        slice.primary.image
                                                            .alt ||
                                                        'Myndskreyting'
                                                    }
                                                    objectFit={
                                                        slice.primary
                                                            .circlemaskforlargeimage
                                                            ? 'cover'
                                                            : 'contain'
                                                    }
                                                    objectPosition={'center'}
                                                    height="1400"
                                                    width="1700"
                                                    layout="fill"
                                                />
                                            </Box>
                                            {slice.primary
                                                .circlemaskforlargeimage && (
                                                <Box extraClassName={ImageMask}>
                                                    <Image
                                                        src={
                                                            slice.primary.image
                                                                .url
                                                        }
                                                        alt={
                                                            slice.primary.image
                                                                .alt ||
                                                            'Myndskreyting'
                                                        }
                                                        objectFit="cover"
                                                        objectPosition="center"
                                                        height="1400"
                                                        width="1700"
                                                        layout="fill"
                                                    />
                                                </Box>
                                            )}
                                        </Box>
                                    )}
                            </Box>
                        </Box>
                    </Box>
                </Box>
                {slice.primary.videolink && slice.primary.videolink.url && (
                    <Modal open={openModal} onClose={() => setOpenModal(false)}>
                        <Container>
                            <OrbitVideo
                                title={'Forsíðu myndband Origo'}
                                url={slice.primary.videolink.url || ''}
                                color="accent"
                            />
                        </Container>
                    </Modal>
                )}
            </Container>
        </Box>
    )
}

export default FrontpageLargeImageHeroBanner
