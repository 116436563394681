// import { HeadingProps } from '../../types'
import * as styles from './Heading.css'
import { HeadingProps } from '../../types'
/* eslint-disable-next-line */

const highlight = (
    sentance: string,
    start: number,
    end: number,
    color: string
) => {
    if (end > sentance.split(' ').length) {
        end = sentance.split(' ').length
    }
    const sentanceArr: string[] = sentance.split(' ')
    const highlightArr: string[] = sentanceArr.slice(start, end)

    const highLightWords = `<span style="color: ${color}">${highlightArr.join(
        ' '
    )}</span>`

    sentanceArr.splice(start, highlightArr.length, highLightWords)

    return sentanceArr.join(' ')
}

export const Heading: React.FC<HeadingProps> = ({
    type,
    text,
    style,
    highlightColor,
    highlightEnd,
    highlightStart,
    textAlign,
    my,
    mx,
    padding,
    paddingBottom,
    paddingTop,
    py,
    px,
    color,
    className,
}) => {
    let txt: string | JSX.Element | Document = text
    if (highlightColor && highlightEnd && highlightStart !== undefined) {
        txt = highlight(text, highlightStart, highlightEnd, highlightColor)
    }

    switch (type) {
        case 'h1': {
            return (
                <h1
                    className={`${styles.heading({
                        type: type,
                        color: color,
                        my: my,
                        mx: mx,
                        py: py,
                        px: px,
                        padding: padding,
                        paddingBottom: paddingBottom,
                        paddingTop: paddingTop,
                        textAlign: textAlign,
                    })} ${className}`}
                    style={style}
                    dangerouslySetInnerHTML={{ __html: txt }}
                ></h1>
            )
        }

        case 'h2': {
            return (
                <h2
                    className={`${styles.heading({
                        type: type,
                        color: color,
                        my: my,
                        mx: mx,
                        py: py,
                        px: px,
                        padding: padding,
                        paddingBottom: paddingBottom,
                        paddingTop: paddingTop,
                        textAlign: textAlign,
                    })} ${className}`}
                    style={style}
                    dangerouslySetInnerHTML={{ __html: txt }}
                ></h2>
            )
        }
        case 'h3': {
            return (
                <h3
                    className={`${styles.heading({
                        type: type,
                        color: color,
                        my: my,
                        mx: mx,
                        py: py,
                        px: px,
                        padding: padding,
                        paddingBottom: paddingBottom,
                        paddingTop: paddingTop,
                        textAlign: textAlign,
                    })} ${className}`}
                    style={style}
                    dangerouslySetInnerHTML={{ __html: txt }}
                ></h3>
            )
        }
        case 'h4': {
            return (
                <h4
                    className={`${styles.heading({
                        type: type,
                        color: color,
                        my: my,
                        mx: mx,
                        py: py,
                        px: px,
                        padding: padding,
                        paddingBottom: paddingBottom,
                        paddingTop: paddingTop,
                        textAlign: textAlign,
                    })} ${className}`}
                    style={style}
                    dangerouslySetInnerHTML={{ __html: txt }}
                ></h4>
            )
        }
        case 'h5': {
            return (
                <h5
                    className={`${styles.heading({
                        type: type,
                        color: color,
                        my: my,
                        mx: mx,
                        py: py,
                        px: px,
                        padding: padding,
                        paddingBottom: paddingBottom,
                        paddingTop: paddingTop,
                        textAlign: textAlign,
                    })} ${className}`}
                    style={style}
                    dangerouslySetInnerHTML={{ __html: txt }}
                ></h5>
            )
        }
        case 'h6': {
            return (
                <h6
                    className={`${styles.heading({
                        type: type,
                        color: color,
                        my: my,
                        mx: mx,
                        py: py,
                        px: px,
                        padding: padding,
                        paddingBottom: paddingBottom,
                        paddingTop: paddingTop,
                        textAlign: textAlign,
                    })} ${className}`}
                    style={style}
                    dangerouslySetInnerHTML={{ __html: txt }}
                ></h6>
            )
        }
        default:
            return (
                <h2
                    className={`${styles.heading({
                        type: type,
                        color: color,
                        my: my,
                        mx: mx,
                        py: py,
                        px: px,
                        padding: padding,
                        paddingBottom: paddingBottom,
                        paddingTop: paddingTop,
                        textAlign: textAlign,
                    })} ${className}`}
                    style={style}
                    dangerouslySetInnerHTML={{ __html: txt }}
                ></h2>
            )
    }
}

export default Heading
