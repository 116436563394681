import React from 'react'
import {
    DefaultPreFooter,
    SocialMediaPreFooter,
    PreFooterTwoCards,
} from './variations'
import { SliceComponentProps } from '@prismicio/react'
import { TPrefooterContext, TPreFooterSlices } from './PreFooter.types'

const PreFooter: React.FC<{
    slice: TPreFooterSlices
    context: TPrefooterContext
}> = ({ slice, context }) => {
    switch (slice.variation) {
        case 'default': {
            return DefaultPreFooter(slice, context)
        }
        case 'socialMedia': {
            return SocialMediaPreFooter(slice, context)
        }
        case 'preFooterTwoCards': {
            return PreFooterTwoCards(slice, context)
        }
        default:
            return null
    }
}

export default PreFooter
