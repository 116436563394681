import React from 'react'
import { TShortcutsSlice } from './Shortcuts.types'
import DefaultShortcuts from './variations/Default'

const Shortcuts: React.FC<{ slice: TShortcutsSlice }> = ({ slice }) => {
    switch (slice.variation) {
        case 'default': {
            return DefaultShortcuts(slice)
        }

        default:
            return null
    }
}

export default Shortcuts
