import { theme, BreakPoints } from '@orbit/ui/themes'
import { useState, useEffect } from 'react'
import { useWindowSize } from 'react-use'
import { Box, Container } from '@orbit/ui/core'
import React from 'react'
import * as styles from './OrbitFooter.css'
import { parsePrismicLink } from '@orbit/prismic'
import { Facebook } from './icons/Facebook'
import { Instagram } from './icons/Instagram'
import { Youtube } from './icons/Youtube'
import { LinkedIn } from './icons/LinkedIn'
import { Envelope } from './icons/Envelope'
import { OrigoFooterProps } from './OrbitFooter.types'
import { PrismicRichText, SliceZone } from '@prismicio/react'
import FooterItem from '../FooterItem'

export const OrbitFooter: React.FC<OrigoFooterProps> = ({ content, items }) => {
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        if (width < BreakPoints.mobile) {
            return setIsMobile(true)
        }

        setIsMobile(false)
    }, [width])

    const footerButton = () => {
        return (
            <Box extraClassName={styles.buttonWrapper}>
                <a
                    className={styles.footerButton}
                    href={parsePrismicLink(content.button_link)}
                >
                    {
                        <PrismicRichText
                            components={{
                                paragraph: ({ children }) => (
                                    <p style={{ margin: 0 }}>{children}</p>
                                ),
                            }}
                            field={content.button_label}
                        />
                    }
                </a>
                <Envelope color="black" />
            </Box>
        )
    }

    return (
        <Box
            flexDirection={'column'}
            width="full"
            boxStyles={{
                background: theme.colors.neutral[200],
            }}
        >
            <Box
                boxStyles={{
                    backgroundColor: theme.colors.neutral[100],
                }}
                width="full"
            >
                <Container>
                    <Box
                        extraClassName={styles.prefooterWrapper}
                        paddingBottom={80}
                        paddingTop={48}
                    >
                        <Box extraClassName={styles.logoStyle}>
                            {content.origo_logo.url && (
                                <img
                                    style={{ overflow: 'auto' }}
                                    src={content.origo_logo.url}
                                    alt={content.origo_logo.alt || 'Origo Logo'}
                                />
                            )}
                        </Box>
                        <Box
                            boxStyles={{
                                gridArea: 'image',
                                gap: isMobile ? '1.5rem' : '3.125rem',
                                maxWidth: '28rem',
                            }}
                        >
                            {content.achievement_image.url && (
                                <img
                                    style={{ overflow: 'auto' }}
                                    src={content.achievement_image.url}
                                    alt={
                                        content.achievement_image.alt ||
                                        'Myndskreyting - fyrirmyndarfyrirtæki'
                                    }
                                />
                            )}
                            {content.achievement_image1.url && (
                                <img
                                    style={{ overflow: 'auto' }}
                                    src={content.achievement_image1.url}
                                    alt={
                                        content.achievement_image1.alt ||
                                        'Myndskreyting - jafnlaunavottun'
                                    }
                                />
                            )}
                            {content.achievement_image2.url && (
                                <img
                                    style={{ overflow: 'auto' }}
                                    src={content.achievement_image2.url}
                                    alt={
                                        content.achievement_image2.alt ||
                                        'Myndskreyting - Framúrskarandi nýsköpun'
                                    }
                                />
                            )}
                            {content.achievement_image3.url && (
                                <img
                                    style={{ overflow: 'auto' }}
                                    src={content.achievement_image3.url}
                                    alt={
                                        content.achievement_image3.alt ||
                                        'Myndskreyting - ISO Logo'
                                    }
                                />
                            )}
                        </Box>

                        {footerButton()}
                    </Box>
                </Container>
            </Box>
            {items.map((footershard, index: number) => {
                return (
                    <Container key={index}>
                        <Box
                            paddingTop={64}
                            paddingBottom={24}
                            display="grid"
                            extraClassName={styles.footerWrapper}
                            width="full"
                        >
                            <SliceZone
                                slices={footershard.slices as any}
                                components={{ footer_item: FooterItem }}
                            />
                        </Box>
                    </Container>
                )
            })}
            <Box
                paddingTop={24}
                paddingBottom={24}
                width={'full'}
                boxStyles={{ backgroundColor: theme.colors.neutral[700] }}
            >
                <Container>
                    <Box
                        paddingTop={isMobile ? 8 : 0}
                        paddingBottom={isMobile ? 8 : 0}
                        justify={'spaceBetween'}
                        align={'center'}
                        flexDirection={
                            width < BreakPoints.tabletSmall
                                ? 'columnReverse'
                                : 'row'
                        }
                    >
                        <Box>
                            <PrismicRichText
                                field={content.copyright}
                                components={{
                                    paragraph: ({ children }) => (
                                        <p className={styles.whiteText}>
                                            {children}
                                        </p>
                                    ),
                                }}
                            />
                        </Box>

                        <Box extraClassName={styles.iconWrapper}>
                            <a
                                aria-label="Origo Facebook"
                                style={{ paddingRight: '1.25rem' }}
                                href={parsePrismicLink(content.facebook_link)}
                            >
                                <Facebook color={theme.colors.neutral[100]} />
                            </a>
                            <a
                                aria-label="Origo LinkedIn"
                                style={{ paddingRight: '1.25rem' }}
                                href={parsePrismicLink(content.linkedin_link)}
                            >
                                <LinkedIn color={theme.colors.neutral[100]} />
                            </a>
                            <a
                                aria-label="Origo Instagram"
                                style={{ paddingRight: '1.25rem' }}
                                href={parsePrismicLink(content.instagram_link)}
                            >
                                <Instagram color={theme.colors.neutral[100]} />
                            </a>
                            <a
                                aria-label="Origo YouTube"
                                style={{ paddingRight: '1.25rem' }}
                                href={parsePrismicLink(content.youtube_link)}
                            >
                                <Youtube color={theme.colors.neutral[100]} />
                            </a>
                        </Box>

                        <Box>
                            <a
                                href={parsePrismicLink(content.language_link)}
                                className={styles.whiteLink}
                            >
                                <p className={styles.whiteText}>
                                    {content.language_label &&
                                        content.language_label}
                                </p>
                            </a>

                            <a
                                className={styles.whiteLink}
                                href={parsePrismicLink(
                                    content.privacy_policy_link
                                )}
                            >
                                <p className={styles.whiteText}>
                                    {content.privacy_policy_label &&
                                        content.privacy_policy_label}
                                </p>
                            </a>
                            <a
                                href={parsePrismicLink(
                                    content.terms_conditions_link
                                )}
                                className={styles.whiteLink}
                            >
                                <p className={styles.whiteText}>
                                    {content.terms_conditions_label &&
                                        content.terms_conditions_label}
                                </p>
                            </a>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </Box>
    )
}

export default OrbitFooter
