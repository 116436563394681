export const itemsLength = (size: number) => {
    switch (size) {
        case 9:
            return 9
        case 8:
            return 8
        case 7:
            return 7
        case 6:
            return 6
        case 5:
            return 5
        case 4:
            return 4
        case 3:
            return 3
        case 2:
            return 2
        case 1:
            return 1
        default:
            return 4
    }
}

export const miniItemsLength = (size: number) => {
    switch (size) {
       
        case 6:
            return 6
        case 5:
            return 5
        case 4:
            return 4
        case 3:
            return 3
        case 2:
            return 2
        case 1:
            return 1
        default:
            return 5
    }
}
