/* eslint-disable */

import { Box, Text, Container, Heading } from '@orbit/ui/core'

import {
    AlgoliaSearchBar,
    AlgoliaRefinementList,
    AlgoliaHits,
    AlgoliaPagination,
} from './components'

import {
    connectHits,
    connectSearchBox,
    Configure,
    InstantSearch,
    connectPagination,
    connectRefinementList,
} from 'react-instantsearch-dom'

import {
    searchBox,
    searchResultContainer,
    divider,
    gridContainer,
    fullWidthMobile,
    searchTermContainer,
} from './SearchOverview.css'
import { AnimatePresence } from 'framer-motion'

import { useRef, useState, createRef } from 'react'

import React, { Component, useEffect } from 'react'
import { useWindowSize } from 'react-use'

export interface SearchOverviewProps {
    searchState: any
    resultsState: any
    onSearchStateChange: (nextSearchState: any) => void
    widgetsCollector?: any
    lang: string
    searchClient: any
    indexName: string
}

const solutionsSection = createRef() as any

const scrollToTopOfSolutionsSection = () => {
    if (solutionsSection.current)
        window.scrollTo({
            top: solutionsSection.current.offsetTop - 40,
            behavior: 'smooth',
        })
}
const CustomSearchBox = connectSearchBox(AlgoliaSearchBar)
const CustomRefinementList = connectRefinementList(AlgoliaRefinementList)
const CustomHits = connectHits(AlgoliaHits)
const CustomPagination = connectPagination(AlgoliaPagination)

export const SearchOverview: React.FunctionComponent<SearchOverviewProps> = ({
    searchState,
    resultsState,
    onSearchStateChange,
    lang,
    ...restProps
}) => {
    const count = 6

    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)

    return (
        <InstantSearch
            indexName={restProps.indexName}
            searchClient={restProps.searchClient}
            resultsState={resultsState}
            onSearchStateChange={onSearchStateChange}
            searchState={searchState}
            // widgetsCollector={widgetsCollector}
            // widgetsCollector={restProps.widgetsCollector}
            // createURL={this.props.createURL}
            {...restProps}
        >
            {lang === 'is' ? (
                <Configure
                    hitsPerPage={count}
                    filters={'listed:true AND lang:is'}
                />
            ) : (
                <Configure
                    hitsPerPage={count}
                    filters={'listed:true AND lang:en-gb OR entityType:Vörur'}
                />
            )}
            <Box
                paddingTop={isMobile ? 24 : 80}
                paddingBottom={isMobile ? 36 : 48}
            >
                <Container>
                    <Heading
                        type="h1"
                        text={
                            lang === 'is'
                                ? 'Leitarniðurstöður'
                                : 'Search results'
                        }
                        textAlign="center"
                    />
                    <Box justify="center">
                        <CustomSearchBox
                            wrapperClassName={searchBox}
                            placeholder={
                                lang === 'is'
                                    ? 'Skrifaðu leitarorð hér'
                                    : 'Type to search'
                            }
                        />
                    </Box>
                </Container>
            </Box>
            <Box
                extraClassName={searchResultContainer}
                paddingTop={isMobile ? 24 : 80}
                paddingBottom={isMobile ? 24 : 80}
                ref={solutionsSection}
            >
                <Container>
                    <Box display="grid" extraClassName={gridContainer}>
                        <Box
                            flexDirection="column"
                            padding={isMobile ? 0 : 6}
                            extraClassName={fullWidthMobile}
                        >
                            <Box extraClassName={searchTermContainer}>
                                <Text
                                    size="large"
                                    style={{
                                        fontWeight: '600',
                                        marginBottom: '1rem',
                                        marginTop: 0,
                                    }}
                                >
                                    {searchState?.query
                                        ? '„' + searchState?.query + '“'
                                        : ' '}
                                </Text>
                            </Box>
                            <Box extraClassName={divider} marginBottom={16} />
                            <Heading
                                type="h4"
                                text={
                                    lang === 'is' ? 'Flokka eftir' : 'Category'
                                }
                                my={0}
                            />
                            <CustomRefinementList attribute="entityType" />
                        </Box>
                        <AnimatePresence>
                            <CustomHits />
                        </AnimatePresence>
                        <Box width="full" boxStyles={{ gridColumn: '1 / 3' }}>
                            <CustomPagination
                                count={count}
                                scrollToTopOfSolutionsSection={
                                    scrollToTopOfSolutionsSection
                                }
                            />
                        </Box>
                    </Box>
                </Container>
            </Box>
        </InstantSearch>
    )
}
