import {
    Box,
    Container,
    Heading,
    SubpageHeroBanner,
    Text,
} from '@orbit/ui/core'
import { BreakPoints, theme } from '@orbit/ui/themes'
import { PrismicRichText } from '@prismicio/react'
import {
    ImageFieldImage,
    KeyTextField,
    PrismicDocumentWithUID,
    RelationField,
    RichTextField,
} from '@prismicio/types'
import moment from 'moment'
import 'moment/locale/is'
import { useEffect, useState } from 'react'
import { useWindowSize } from 'react-use'
import { AuthorProps, TBlogDataProps } from '../../types'
import * as styles from './Blog.css'

type TBlogDataDefault = {
    uid: string
    page_title: KeyTextField
    parent: RelationField
    date: KeyTextField
    content: RichTextField
    introtext: KeyTextField
    image: ImageFieldImage
    summary: KeyTextField
    title: KeyTextField
    author?: AuthorProps
    metatitle: string
    metadescription: string
    ogimageidfullpath: string
}
export interface BlogProps {
    blog: PrismicDocumentWithUID<TBlogDataDefault>
}

export const Blog: React.FC<{ blog: TBlogDataProps }> = ({ blog }) => {
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)
    useEffect(() => {
        if (width < BreakPoints.tabletSmall) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])
    moment.locale('is')

    const renderHeader = () => {
        return (
            <Box width={'full'} paddingBottom={40}>
                <SubpageHeroBanner
                    type={'Blog'}
                    imageUrl={blog.image?.url || undefined}
                    imageAlt={blog.image?.alt || undefined}
                    title={blog.title || undefined}
                    date={blog.date || undefined}
                    introtext={blog.introtext || undefined}
                    authorName={
                        blog.author?.data && blog.author.data.name
                            ? blog.author.data.name
                            : undefined
                    }
                    slicecolor={theme.colors.tertiary[400]}
                />
            </Box>
        )
    }

    return (
        <Box flexDirection="column" alignSelf="center">
            {isMobile && renderHeader()}
            <Container>
                {!isMobile && renderHeader()}
                {blog.content && (
                    <Box
                        margin={'auto'}
                        flexDirection="column"
                        boxStyles={{ maxWidth: '53.5rem' }}
                    >
                        <PrismicRichText
                            field={blog.content}
                            components={{
                                paragraph: ({ children }) => (
                                    <Text size="large">{children}</Text>
                                ),
                                heading5: ({ text }) =>
                                    text && (
                                        <Heading
                                            color={'tertiary'}
                                            type={'h5'}
                                            text={text}
                                            style={{
                                                marginTop: '0.5rem',
                                                marginBottom: '0.5rem',
                                            }}
                                        />
                                    ),
                                heading4: ({ text }) =>
                                    text && (
                                        <Heading
                                            type={'h4'}
                                            text={text}
                                            style={{
                                                marginTop: '0.5rem',
                                                marginBottom: '0.5rem',
                                            }}
                                        />
                                    ),
                                heading3: ({ text }) =>
                                    text && (
                                        <Heading
                                            type={'h3'}
                                            text={text}
                                            style={{
                                                marginTop: '0.5rem',
                                                marginBottom: '0.5rem',
                                            }}
                                        />
                                    ),
                                heading2: ({ text }) =>
                                    text && (
                                        <Heading
                                            type={'h2'}
                                            text={text}
                                            style={{
                                                marginTop: '0.5rem',
                                                marginBottom: '0.5rem',
                                            }}
                                        />
                                    ),
                                hyperlink: ({ text, node }) => (
                                    <a
                                        style={{
                                            color: theme.colors.tertiary[600],
                                            fontWeight: 600,
                                            textDecoration: 'none',
                                        }}
                                        href={node.data.url}
                                        target={
                                            node.data.link_type === 'Web'
                                                ? node.data.target
                                                : ''
                                        }
                                        rel="noopener noreferrer"
                                    >
                                        {text}
                                    </a>
                                ),
                                image: ({ node }) => (
                                    <>
                                        <img
                                            src={node.url}
                                            alt={node.alt || ''}
                                            style={{
                                                display: 'flex',
                                                alignSelf: 'center',
                                                width: '100%',
                                            }}
                                        ></img>
                                        {node.alt && (
                                            <text
                                                style={{
                                                    color: theme.colors
                                                        .tertiary[600],
                                                    fontSize: '0.75rem',
                                                    fontWeight: 600,
                                                    paddingTop: '1rem',
                                                    paddingBottom: '1rem',
                                                }}
                                            >
                                                {node.alt}
                                            </text>
                                        )}
                                    </>
                                ),
                                list: ({ children }) => (
                                    <ul className={styles.bullet}>
                                        {children}
                                    </ul>
                                ),
                                listItem: ({ children }) => (
                                    <li
                                        className={`${styles.listItem({
                                            colors: 'Green',
                                        })}`}
                                    >
                                        <Text size="large"> {children} </Text>
                                    </li>
                                ),
                            }}
                        />
                    </Box>
                )}
            </Container>
        </Box>
    )
}
