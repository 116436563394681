import {
    parsePrismicBackgroundColor,
    parsePrismicHeadingColor,
    parsePrismicHighlightColor,
    parseSliceSpace,
} from '@orbit/prismic'
import { useEffect, useState } from 'react'
import { Box, Container, MotionBox, PreFooter } from '@orbit/ui/core'
import { BreakPoints } from '@orbit/ui/themes'
import { PrismicRichText } from '@prismicio/react'
import { useWindowSize } from 'react-use'
import { TPrefooterContext, TPreFooterSliceDefault } from '../PreFooter.types'
import { cardRevealUp } from '@orbit/utils/framer-motion'

export const DefaultPreFooter = (
    slice: TPreFooterSliceDefault,
    context: TPrefooterContext
) => {
    const { width } = useWindowSize()

    const [deviceType, setDeviceType] = useState<
        'mobile' | 'tablet' | 'desktop'
    >('desktop')

    useEffect(() => {
        if (width < BreakPoints.mobile) {
            return setDeviceType('mobile')
        }
        if (width < BreakPoints.tabletSmall) {
            return setDeviceType('tablet')
        }
        return setDeviceType('desktop')
    }, [width])

    const {
        image,
        backgroundfilled,
        slicecolor,
        text,
        anchor,
        buttonlink,
        buttonlabel,
        buttonicon,
        tagline,
        title,
        imageshape,
    } = slice.primary

    return (
        <Box
            anchorid={anchor || undefined}
            justify={'center'}
            boxStyles={{
                overflow: 'hidden',
                backgroundColor: backgroundfilled
                    ? parsePrismicBackgroundColor(slicecolor)
                    : 'white',
            }}
            paddingBottom={parseSliceSpace(
                context?.type,
                deviceType === 'tablet'
            )}
            paddingTop={parseSliceSpace(context?.type, deviceType === 'tablet')}
        >
            <Container>
                <MotionBox
                    paddingLeft={deviceType === 'mobile' ? 40 : 80}
                    paddingRight={deviceType === 'mobile' ? 40 : 80} //TODO: afhverju virkar þetta ekki??!?
                    align={deviceType === 'mobile' ? 'center' : 'flexStart'}
                    justify="spaceBetween"
                    flexDirection={deviceType === 'mobile' ? 'column' : 'row'}
                    boxStyles={{
                        backgroundColor: !backgroundfilled
                            ? parsePrismicBackgroundColor(
                                  slice.primary.slicecolor
                              )
                            : 'white',
                        paddingRight:
                            deviceType === 'mobile' ? '2.5rem' : '5rem',
                    }}
                    paddingTop={40}
                    paddingBottom={40}
                    initial={deviceType === 'mobile' ? 'visible' : 'hidden'}
                    viewport={{
                        once: true,
                    }}
                    whileInView="visible"
                    transition={{
                        duration: 0.6,
                        delay: 0.2,
                    }}
                    variants={cardRevealUp}
                >
                    <PreFooter
                        backgroundFilled={backgroundfilled || false}
                        backgroundColor={parsePrismicBackgroundColor(
                            slicecolor
                        )}
                        dotColor={parsePrismicHighlightColor(slicecolor)}
                        color={parsePrismicHeadingColor(slicecolor)}
                        imageUrl={(image && image.url) || undefined}
                        imageAlt={(image && image.alt) || ''}
                        tagline={tagline || undefined}
                        title={title || undefined}
                        buttonIcon={buttonicon}
                        buttonLink={buttonlink.url}
                        buttonLabel={buttonlabel || undefined}
                        text={<PrismicRichText field={text} />}
                        openNewTab={false}
                        variant={deviceType === 'mobile' ? 'center' : 'left'}
                        imageshape={imageshape || 'Circle'}
                    />
                </MotionBox>
            </Container>
        </Box>
    )
}

export default DefaultPreFooter
