import React from 'react'

export const ChevronDown = () => {
    return (
        <svg
            width="11"
            height="8"
            viewBox="0 0 11 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.6727 1.16994C10.4775 0.983692 10.2135 0.87915 9.93833 0.87915C9.66314 0.87915 9.39913 0.983692 9.20396 1.16994L5.46437 4.70994L1.77687 1.16994C1.5817 0.983692 1.31769 0.87915 1.0425 0.87915C0.767305 0.87915 0.503293 0.983692 0.308123 1.16994C0.210489 1.26291 0.132995 1.37351 0.0801111 1.49537C0.0272271 1.61723 0 1.74793 0 1.87994C0 2.01195 0.0272271 2.14266 0.0801111 2.26452C0.132995 2.38638 0.210489 2.49698 0.308123 2.58994L4.72479 6.82994C4.82163 6.92367 4.93684 6.99806 5.06377 7.04883C5.19071 7.0996 5.32686 7.12574 5.46437 7.12574C5.60189 7.12574 5.73804 7.0996 5.86497 7.04883C5.99191 6.99806 6.10712 6.92367 6.20396 6.82994L10.6727 2.58994C10.7703 2.49698 10.8478 2.38638 10.9007 2.26452C10.9536 2.14266 10.9808 2.01195 10.9808 1.87994C10.9808 1.74793 10.9536 1.61723 10.9007 1.49537C10.8478 1.37351 10.7703 1.26291 10.6727 1.16994Z"
                fill="#0F1015"
            />
        </svg>
    )
}

export default ChevronDown
