import { Box } from '../Box'
import { Text } from '../Text'
import * as styles from './ShareTab.css'
import { OrbitColors as colors } from '@orbit/ui/themes'
import {
    TwitterShareButton,
    FacebookShareButton,
    LinkedinShareButton,
} from 'react-share'
import { FacebookIcon } from '../../assets/FacebookIcon'
import { TwitterIcon } from '../../assets/TwitterIcon'
import { LinkedInIcon } from '../../assets/LinkedInIcon'
import { useState, useEffect } from 'react'

export interface ShareTabProps {
    color: keyof typeof colors
    url: string
    title: string
    ismobile: boolean
    dataType: string
}

export const ShareTab: React.FC<ShareTabProps> = ({
    color,
    title,
    ismobile,
    dataType,
}) => {
    const [baseUrl, setBaseUrl] = useState('')
    useEffect(() => {
        if (window !== undefined) {
            setBaseUrl(window.location.href)
        }
    }, [])

    return (
        <Box
            boxStyles={{
                backgroundColor: ismobile ? 'white' : colors[color],
                width: ismobile ? '22.5rem' : '26.25rem',
                justifyContent: ismobile ? 'space-between' : '',
            }}
            align="center"
            alignSelf="center"
            alignItems="center"
            extraClassName={styles.boxStyle}
        >
            <Box paddingRight={24}>
                <Text style={{ margin: 0, fontSize: '0.875rem' }}>
                    {dataType === 'blog' ? 'Deila bloggi' : 'Deila frétt'}
                </Text>
            </Box>
            <Box flexDirection="row" alignItems="center" display="flex">
                <Box extraClassName={styles.iconStyle} align="center">
                    <FacebookShareButton url={baseUrl} title={title}>
                        <FacebookIcon />
                    </FacebookShareButton>
                </Box>
                <Box extraClassName={styles.iconStyle} alignItems="center">
                    <TwitterShareButton url={baseUrl} title={title}>
                        <TwitterIcon />
                    </TwitterShareButton>
                </Box>
                <Box extraClassName={styles.iconStyle} alignItems="center">
                    <LinkedinShareButton url={baseUrl} title={title}>
                        <LinkedInIcon />
                    </LinkedinShareButton>
                </Box>
            </Box>
        </Box>
    )
}
