import { useMemo } from 'react'

export interface UsePaginationProps {
    page: number
    pageSize: number
    total: number
    siblings?: number
    variant?: 'numbers' | 'dots'
}

export const DOTS = '...'

export const usePagination = ({
    page,
    total,
    pageSize,
    siblings = 2,
    variant = 'numbers',
}: UsePaginationProps) => {
    const range = (start: number, end: number) => {
        const length = end - start + 1
        /*
            Create an array of certain length and set the elements within it from
          start value to end value.
        */
        return Array.from({ length }, (_, idx) => idx + start)
    }

    const paginationRange = useMemo(() => {
        const totalPageCount = Math.ceil(total / pageSize)
        const totalPageNumbers = siblings + 4

        // DOT variant
        const MAX_DOTS = 7
        const dotFirstThree = [1, 2, 3]
        const dotLastThree = [
            totalPageCount - 2,
            totalPageCount - 1,
            totalPageCount,
        ]
        if (variant === 'dots') {
            if (totalPageCount <= MAX_DOTS) return [...range(1, totalPageCount)]
            if (dotFirstThree.includes(page)) return [...range(1, MAX_DOTS)]
            if (dotLastThree.includes(page))
                return [
                    ...range(totalPageCount - (MAX_DOTS - 1), totalPageCount),
                ]

            return [
                ...range(
                    Math.ceil(page - MAX_DOTS / 2),
                    Math.floor(page + MAX_DOTS / 2)
                ),
            ]
        }

        if (totalPageNumbers >= totalPageCount) {
            return range(1, totalPageCount)
        }

        const leftSiblingIndex = Math.max(page - siblings, 1)
        const rightSiblingIndex = Math.min(page + siblings, totalPageCount)

        const shouldShowLeftDots = leftSiblingIndex > 2
        const shouldShowRightDots = rightSiblingIndex < totalPageCount - 1

        const firstItems = siblings === 1 ? [1, 2, 3] : [1, 2]
        const lastItems =
            siblings === 1
                ? [totalPageCount - 2, totalPageCount - 1, totalPageCount]
                : [totalPageCount - 1, totalPageCount]

        if (firstItems.includes(page)) {
            const leftRange = siblings === 1 ? range(1, 4) : range(1, 2)

            return [...leftRange, DOTS, totalPageCount]
        }

        if (lastItems.includes(page)) {
            const rightRange =
                siblings === 1
                    ? range(totalPageCount - 3, totalPageCount)
                    : range(totalPageCount - 1, totalPageCount)

            return [1, DOTS, ...rightRange]
        }

        if (!shouldShowLeftDots && shouldShowRightDots) {
            const leftRange = range(1, rightSiblingIndex)
            const rightRange = range(totalPageCount, totalPageCount)

            return [...leftRange, DOTS, ...rightRange]
        }

        if (shouldShowLeftDots && !shouldShowRightDots) {
            const leftRange = siblings === 0 ? [1] : range(1, siblings)
            const rightRange = range(page - siblings, totalPageCount)

            return [...leftRange, DOTS, ...rightRange]
        }

        return siblings === 0
            ? [1, DOTS, page, DOTS, totalPageCount]
            : [
                  ...range(1, siblings),
                  DOTS,
                  ...range(page - siblings, page + siblings),
                  DOTS,
                  totalPageCount,
              ]
    }, [total, pageSize, siblings, page])

    return paginationRange
}
