import React from 'react'
import { TStaffSearchContext, TStaffSearchSlice } from './StaffSearch.types'
import { DefaultStaffSearch } from './variations/Default'

const StaffSearch: React.FC<{
    slice: TStaffSearchSlice
    context: TStaffSearchContext
}> = ({ slice, context }) => {
    switch (slice.variation) {
        case 'default': {
            return DefaultStaffSearch(slice, context)
        }
        default:
            return null
    }
}

export default StaffSearch
