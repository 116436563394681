import {
    Box,
    Button,
    Container,
    HeadingContainer,
    MotionBox,
} from '@orbit/ui/core'
import { BreakPoints } from '@orbit/ui/themes'
import { cardRevealUp } from '@orbit/utils/framer-motion'

import { useState, useEffect } from 'react'
import { useWindowSize } from 'react-use'

export const NoEvents = () => {
    const [isMobile, setIsMobile] = useState(false)
    const { width } = useWindowSize()

    useEffect(() => {
        if (width < BreakPoints.tabletSmall) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    const [baseUrl, setBaseUrl] = useState('')

    useEffect(() => {
        if (window !== undefined) {
            setBaseUrl(window.location.href)
        }
    }, [])

    return (
        <MotionBox
            initial="hidden"
            viewport={{
                once: true,
            }}
            whileInView="visible"
            transition={{
                delay: 0.3,
                duration: 0.6,
            }}
            variants={cardRevealUp}
            paddingBottom={isMobile ? 40 : 80}
            paddingTop={isMobile ? 40 : 80}
            width={'full'}
        >
            <Container>
                <Box
                    width={'full'}
                    flexDirection={'column'}
                    justify={'center'}
                    align={'center'}
                >
                    <HeadingContainer
                        variant="center"
                        tagline={'framundan hjá origo'}
                        title={
                            'Við erum í óða önn að skipuleggja næsta viðburð.'
                        }
                        color={'accent'}
                        pb="4rem"
                    />
                </Box>
                <Box
                    boxStyles={{ flexDirection: isMobile ? 'column' : 'row' }}
                    align={isMobile ? 'center' : 'flexStart'}
                >
                    <Box
                        width={'full'}
                        marginLeft={16}
                        marginRight={16}
                        marginBottom={isMobile ? 24 : 0}
                    >
                        <img
                            style={{
                                objectFit: 'cover',
                                maxWidth: !isMobile ? '25rem' : '100%',
                                width: '100%',
                            }}
                            height={'auto'}
                            alt={'NoEvent1'}
                            src="https://images.prismic.io/new-origo/1ddac625-c155-44e7-abf8-e80bfa83101e_noEvent1.png?auto=compress,format&q=75"
                        />
                    </Box>
                    {!isMobile && (
                        <>
                            <Box
                                width={'full'}
                                marginLeft={16}
                                marginRight={16}
                                marginBottom={isMobile ? 24 : 0}
                            >
                                <img
                                    style={{
                                        objectFit: 'cover',
                                        maxWidth: !isMobile ? '25rem' : '100%',
                                        width: '100%',
                                    }}
                                    height={'auto'}
                                    src="https://images.prismic.io/new-origo/8b6481e2-6225-4f1c-a119-e65caadece85_noEvent2.png?auto=compress,format&q=75"
                                    alt={'NoEvent2'}
                                />
                            </Box>
                            <Box
                                width={'full'}
                                marginLeft={16}
                                marginRight={16}
                                marginBottom={isMobile ? 24 : 0}
                            >
                                <img
                                    style={{
                                        objectFit: 'cover',
                                        maxWidth: !isMobile ? '25rem' : '100%',
                                        width: '100%',
                                    }}
                                    height={'auto'}
                                    src="https://images.prismic.io/new-origo/972ae59b-df08-4b4b-bd60-bf5c25961164_noEvent3.png?auto=compress,format&q=75"
                                    alt={'NoEvent3'}
                                />
                            </Box>
                        </>
                    )}
                </Box>
                <Box justify={'center'} paddingTop={isMobile ? 24 : 48}>
                    <Button
                        variant="filled"
                        color={'accent'}
                        href={baseUrl + 'vidburdir'}
                    >
                        Sjá alla viðburði
                    </Button>
                </Box>
            </Container>
        </MotionBox>
    )
}

export default NoEvents
