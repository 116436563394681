import { Box } from '../Box'
import { Icon } from '../Icons'
import * as styles from './SearchBar.css'

export interface SearchBarProps {
    value: string
    onChange: (args: any) => void
    placeholder?: string
    wrapperClassName?: string
}

export const SearchBar: React.FC<SearchBarProps> = ({
    value,
    placeholder,
    onChange,
    wrapperClassName,
}) => {
    return (
        <Box
            width="full"
            position="relative"
            extraClassName={`${styles.searchBoxWrapper} ${wrapperClassName}`}
        >
            <label style={{ width: '85%' }}>
                <span hidden={true}>Leit af lausnum</span>

                <input
                    type="search"
                    value={value}
                    placeholder={placeholder}
                    onChange={onChange}
                    className={styles.searchBox}
                />
            </label>
            <Box extraClassName={styles.iconStyling}>
                <Icon color="white" icon="Search" size="large" />
            </Box>
        </Box>
    )
}
