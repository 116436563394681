import React from 'react'

type MegamenuContext = {
    menuOpen: string
    setOpenMegamenu: (selectedMenu: string) => void
}

export const MegamenuContext = React.createContext<MegamenuContext>({
    menuOpen: '',
    setOpenMegamenu: () => undefined,
})

type MegamenuContextProps = {
    children: React.ReactNode
}

export const MegamenuContextProvider = (props: MegamenuContextProps) => {
    const setMegamenuVisible = (selectedMenu: string) => {
        setMegamenuState((prev) => ({ ...prev, menuOpen: selectedMenu }))
    }

    const [megamenuState, setMegamenuState] = React.useState<MegamenuContext>({
        menuOpen: '',
        setOpenMegamenu: setMegamenuVisible,
    })

    return (
        <MegamenuContext.Provider value={megamenuState}>
            {props.children}
        </MegamenuContext.Provider>
    )
}
