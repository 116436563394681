import * as styles from './Hamburger.css'

export interface HamburgerProps {
    open: boolean
    onClick: () => void
}

export const Hamburger: React.FC<HamburgerProps> = ({ open, onClick }) => {
    return (
        <button
            onClick={() => onClick()}
            className={`${styles.Hamburger} ${open && 'active'}`}
        >
            <span
                className={`${styles.BurgerBar} ${styles.TopBar({
                    active: open,
                })}`}
            ></span>
            <span
                className={`${styles.BurgerBar} ${styles.MiddleBar({
                    active: open,
                })}`}
            ></span>
            <span
                className={`${styles.BurgerBar} ${styles.BottomBar({
                    active: open,
                })}`}
            ></span>
        </button>
    )
}

export default Hamburger
