import { useEffect, useState } from 'react'

export const useNotification = (id: string | undefined) => {
    const [showNotification, setShowNotification] = useState(false)

    useEffect(() => {
        const notification = localStorage.getItem(`notification-${id}`)
        if (!notification) {
            setShowNotification(true)
        }
    }, [])

    return showNotification
}
