type TVideoPlayIconProps = {
    className?: string
}

export const VideoPlayIcon: React.FC<TVideoPlayIconProps> = ({
    className = '',
}) => {
    return (
        <svg
            className={className}
            width="10"
            height="12"
            viewBox="0 0 16 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.4094 8.18645L2.20086 0.48824C2.05743 0.404619 1.89473 0.360596 1.72912 0.360596C1.56351 0.360596 1.40081 0.404619 1.25739 0.48824C1.11341 0.572181 0.993952 0.693051 0.911113 0.83861C0.828273 0.984168 0.784992 1.14925 0.785652 1.31713V16.7136C0.784992 16.8814 0.828273 17.0465 0.911113 17.1921C0.993952 17.3376 1.11341 17.4585 1.25739 17.5424C1.40081 17.6261 1.56351 17.6701 1.72912 17.6701C1.89473 17.6701 2.05743 17.6261 2.20086 17.5424L15.4094 9.84423C15.5546 9.76114 15.6754 9.64063 15.7594 9.49502C15.8434 9.3494 15.8877 9.18388 15.8877 9.01534C15.8877 8.84681 15.8434 8.68129 15.7594 8.53567C15.6754 8.39005 15.5546 8.26955 15.4094 8.18645Z"
                fill="white"
            />
        </svg>
    )
}
