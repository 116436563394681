import {
    parsePrismicBackgroundColor,
    parsePrismicHeadingColor,
} from '@orbit/prismic'
import {
    Box,
    Carousel,
    Container,
    HeadingContainer,
    ItemCard,
    MotionBox,
} from '@orbit/ui/core'
import useSwr from 'swr'
import {
    ContextType,
    RelatedContentContentsData,
    TRelatedContentSlice,
} from '../RelatedContent.types'
import * as styles from '../RelatedContent.css'
import { PrismicDocument, Query } from '@prismicio/types'
import orderBy from 'lodash/orderBy'
import { cardRevealUp } from '@orbit/utils/framer-motion'
import { BreakPoints } from '@orbit/ui/themes'
import { useState, useEffect } from 'react'
import { useWindowSize } from 'react-use'

const fetcher = (url: string) => fetch(url).then((res) => res.json())

export const DefaultRelatedContent = (
    slice: TRelatedContentSlice,
    context: ContextType
) => {
    const { data, error, isValidating } = useSwr<
        Query<PrismicDocument<RelatedContentContentsData>>
    >(
        `/api/contentByTag/contentByTags?tags=${context.tags}&lang=${context.lang}`,
        fetcher
    )
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)

    const orderedData = orderBy(
        data?.results,
        [(obj) => new Date(obj.data.date)],
        ['desc']
    )

    useEffect(() => {
        if (width < BreakPoints.medium) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    if (error || isValidating) return null
    return (
        <Box
            anchorid={slice.primary.anchor || undefined}
            justify="center"
            flexDirection="column"
            extraClassName={styles.containerBox}
            boxStyles={{
                backgroundColor: parsePrismicBackgroundColor(
                    slice.primary.slicecolor
                ),
                overflow: 'clip',
            }}
        >
            <Container>
                <HeadingContainer
                    tagline={slice.primary.tagline || undefined}
                    title={slice.primary.title || undefined}
                    color={parsePrismicHeadingColor(slice.primary.slicecolor)}
                />
                <Box wrap="wrap" flexDirection="row">
                    {data && data.results && (
                        <Carousel
                            totalItems={data.results.length}
                            color={parsePrismicHeadingColor(
                                slice.primary.slicecolor
                            )}
                            variant={'numbers'}
                            pageSize={{
                                mobile: 1,
                                tablet: 2,
                                desktop: 3,
                            }}
                            itemsToSlide={{
                                mobile: 1,
                                tablet: 1,
                                desktop: 1,
                            }}
                        >
                            {orderedData.map((content, index: number) => {
                                if (content.url)
                                    return (
                                        <MotionBox
                                            extraClassName={styles.itemCardBox}
                                            key={'related_content_' + index}
                                            initial={
                                                isMobile ? 'visible' : 'hidden'
                                            }
                                            viewport={{
                                                once: true,
                                            }}
                                            whileInView="visible"
                                            transition={{
                                                duration: 0.6,
                                                delay: 0.15 * ((index % 3) + 1),
                                            }}
                                            variants={cardRevealUp}
                                        >
                                            <ItemCard
                                                datatype={
                                                    content.type === 'blog'
                                                        ? 'blog'
                                                        : 'news'
                                                }
                                                url={content.url}
                                                image={
                                                    content.data.image?.url ||
                                                    undefined
                                                }
                                                imagealt={
                                                    content.data.image?.alt ||
                                                    undefined
                                                }
                                                title={
                                                    content.data.title
                                                        ? content.data.title ||
                                                          ''
                                                        : ''
                                                }
                                                summary={content.data.summary}
                                                slicecolor={parsePrismicHeadingColor(
                                                    slice.primary.slicecolor
                                                )}
                                                headingLevel={
                                                    slice.primary.tagline &&
                                                    slice.primary.title
                                                        ? 'h4'
                                                        : !slice.primary
                                                              .tagline &&
                                                          !slice.primary.tagline
                                                        ? 'h2'
                                                        : 'h3'
                                                }
                                            />
                                        </MotionBox>
                                    )

                                return undefined
                            })}
                        </Carousel>
                    )}
                </Box>
            </Container>
        </Box>
    )
}

export default DefaultRelatedContent
