import { useState, useRef, useEffect } from 'react'
import { Tab, TabList, TabPanel, useTabStore } from '@ariakit/react'
import { Swiper, SwiperSlide } from 'swiper/react'
import * as styles from './TabBarSlider.css'
import SwiperCore from 'swiper'
import { useWindowSize } from 'react-use'
import { BreakPoints } from '@orbit/ui/themes'

export interface TabItemProps {
    label: string
    children: React.ReactNode
}

export interface TabsProps {
    tabs: TabItemProps[]
    backgroundBar?: boolean
    tabSize?: 'small' | 'medium' | 'large'
    tabVariant?: 'default' | 'filled' | 'outlined'
    tabFont?: 'regular' | 'bold'
    color?: 'primary' | 'secondary' | 'tertiary' | 'accent'
}

export const TabBarSlider: React.FC<TabsProps> = ({
    tabs,
    tabSize = 'medium',
    // tabVariant = 'default',
    backgroundBar = true,
    tabFont = 'regular',
    color,
}) => {
    const swiperRef = useRef<SwiperCore>()
    const navigationPrevRef = useRef<HTMLDivElement>(null)
    const navigationNextRef = useRef<HTMLDivElement>(null)
    const { width } = useWindowSize()
    const tabState = useTabStore()
    const selectedId = tabState.useState('selectedId')

    const tabListMobile = () => {
        return (
            <Swiper
                slidesPerView={'auto'}
                spaceBetween={0}
                navigation={{
                    prevEl: navigationPrevRef.current,
                    nextEl: navigationNextRef.current,
                }}
                onBeforeInit={(swiper) => {
                    swiperRef.current = swiper
                }}
                grabCursor={true}
                className={styles.swiperStyle({ color: color })}
            >
                <TabList
                    className={styles.tabsWrapper({
                        justify: 'flexStart',
                        bar: backgroundBar,
                        color: color,
                    })}
                    store={tabState}
                >
                    {tabs.map((tab, i) => {
                        return (
                            <SwiperSlide
                                // Þarf að vera inline
                                style={{
                                    width: 'fit-content',
                                    padding: '0',
                                    paddingLeft: '0',
                                    height: 'auto',
                                }}
                                key={'SwiperTabbar'}
                            >
                                <Tab
                                    className={styles.tabStyle({
                                        color: color,
                                        size: tabSize,
                                        font: tabFont,
                                        active:
                                            selectedId ===
                                            tab.label + i.toString(),
                                    })}
                                    {...tabState}
                                    id={tab.label + i.toString()}
                                    key={tab.label + i.toString()}
                                >
                                    {tab.label}
                                </Tab>
                            </SwiperSlide>
                        )
                    })}
                </TabList>
            </Swiper>
        )
    }

    return (
        <div style={{ width: '100%', textAlign: 'center' }}>
            <div style={{ paddingBottom: '2.5rem' }}>
                {width < BreakPoints.mobile ? (
                    tabListMobile()
                ) : (
                    <TabList
                        className={styles.tabsWrapper({
                            justify: 'flexStart',
                            bar: backgroundBar,
                            color: color,
                        })}
                        store={tabState}
                    >
                        {tabs.map((tab, i) => {
                            return (
                                <Tab
                                    className={styles.tabStyle({
                                        color: color,
                                        size: tabSize,
                                        font: tabFont,
                                        active:
                                            selectedId ===
                                            tab.label + i.toString(),
                                    })}
                                    {...tabState}
                                    id={tab.label + i.toString()}
                                    key={tab.label + i.toString()}
                                >
                                    {tab.label}
                                </Tab>
                            )
                        })}
                    </TabList>
                )}
            </div>
            {tabs.map((tab, i) => (
                <TabPanel
                    className={styles.tabPanelWrapper({ size: tabSize })}
                    key={i}
                    store={tabState}
                >
                    {tab.children}
                </TabPanel>
            ))}
        </div>
    )
}
