import { BreakPoints, theme } from '@orbit/ui/themes'
import { PrismicDocument } from '@prismicio/types'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { useWindowSize } from 'react-use'
import useSwr from 'swr'
import { Box } from '../Box'
import { Container } from '../Container'
import { Icon } from '../Icons'
import * as styles from './Breadcrumbs.css'

export type TBreacrumbsProps = {
    type:
        | 'page'
        | 'solution'
        | 'job'
        | 'blog'
        | 'news'
        | 'event'
        | 'investorspage'
        | 'investorshomepage'
        | 'consulting_services'
        | 'announcement'
    lang?: 'is' | 'en-gb'
    title?: string
}

const fetcher = (url: string) => fetch(url).then((res) => res.json())

type BreadcrumbsItem = {
    url: string
    name: string
}

export const Breadcrumbs: React.FC<TBreacrumbsProps> = ({
    type,
    lang = 'is',
    title,
}) => {
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)
    const [crumbs, setCrumbs] = useState<string[]>([])
    const [breadCrumbs, setBreadCrumbs] = useState<BreadcrumbsItem[]>([])
    const [canFetch, setCanFetch] = useState<boolean>(false)
    const router = useRouter()
    useEffect(() => {
        if (width < BreakPoints.mobile) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    // When hard reloading
    useEffect(() => {
        if (window) {
            setCrumbs(window.location.pathname.slice(1).split('/'))
        }
    }, [])

    // When using next/link
    const handler = () => {
        setCrumbs([])
        setBreadCrumbs([])
        setCanFetch(true)
    }

    useEffect(() => {
        router.events.on('routeChangeComplete', handler)
        return () => {
            router.events.off('routeChangeComplete', handler)
        }
    }, [router])

    useEffect(() => {
        if (window && canFetch) {
            setCrumbs(window.location.pathname.slice(1).split('/'))

            setCanFetch(false)
        }
    }, [canFetch])

    const { data, error } = useSwr<PrismicDocument[]>(
        crumbs.length === 0
            ? null
            : `/api/breadcrumbs?type=${type}&ids=${crumbs.join(
                  ','
              )}&lang=${lang}`,
        fetcher
    )
    useEffect(() => {
        if (type === 'job' && title) {
            setBreadCrumbs([{ name: title, url: 'javascript:;' }])
        }

        if (data) {
            setBreadCrumbs(
                data.map((doc) => {
                    return {
                        name:
                            Array.isArray(doc.data.title) &&
                            doc.data.title.length
                                ? doc.data.title[0].text
                                : doc.data.title,
                        url: doc.url,
                    } as BreadcrumbsItem
                })
            )
        }
    }, [data])

    if (error || breadCrumbs.length === 0) {
        // TODO: add skeleton here
        return (
            <Container>
                <Box align="center" paddingTop={24} paddingBottom={24}></Box>
            </Container>
        )
    }

    const renderBreadcrumbs = () => (
        <nav aria-label="Breadcrumbs" className={styles.BreadcrumbsStyle}>
            <Box>
                {isMobile ? (
                    <a
                        href="/"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginLeft: theme.layout.spacing.gap.md,
                        }}
                    >
                        <Icon
                            icon="Home"
                            size="small"
                            color={theme.colors.neutral[600]}
                        />
                    </a>
                ) : (
                    <a
                        aria-label={lang === 'is' ? 'Heim' : 'Home'}
                        className={styles.textStyle}
                        href={lang === 'is' ? '/' : '/english'}
                    >
                        {lang === 'is' ? 'Forsíða' : 'Homepage'}
                    </a>
                )}
            </Box>
            {type === 'news' && (
                <>
                    <span aria-hidden="true" className={styles.dotStyle}>
                        •
                    </span>
                    <Box>
                        <Link
                            scroll={false}
                            href={lang === 'is' ? '/frettir' : '/news'}
                        >
                            <a className={styles.textStyle}>
                                {lang === 'is' ? 'Fréttir' : 'News'}
                            </a>
                        </Link>
                    </Box>
                </>
            )}
            {type === 'blog' && (
                <>
                    <span aria-hidden="true" className={styles.dotStyle}>
                        •
                    </span>
                    <Box boxStyles={{ flexShrink: isMobile ? 0 : 1 }}>
                        <Link
                            scroll={false}
                            href={lang === 'is' ? '/blogg' : '/english/blog'}
                        >
                            <a className={styles.textStyle}>
                                {lang === 'is' ? 'Blogg' : 'Blog'}
                            </a>
                        </Link>
                    </Box>
                </>
            )}
            {type === 'event' && (
                <>
                    <span aria-hidden="true" className={styles.dotStyle}>
                        •
                    </span>
                    <Box boxStyles={{ flexShrink: isMobile ? 0 : 1 }}>
                        <Link
                            href={
                                lang === 'is' ? '/vidburdir' : '/english/events'
                            }
                        >
                            <a className={styles.textStyle}>
                                {lang === 'is' ? 'Viðburðir' : 'Events'}
                            </a>
                        </Link>
                    </Box>
                </>
            )}
            {type === 'job' && (
                <>
                    <span aria-hidden="true" className={styles.dotStyle}>
                        •
                    </span>
                    <Box boxStyles={{ flexShrink: isMobile ? 0 : 1 }}>
                        <Link
                            href={
                                lang === 'is'
                                    ? '/starfstaekifaeri'
                                    : '/english/job-opportunities'
                            }
                        >
                            <a className={styles.textStyle}>
                                {lang === 'is'
                                    ? 'Starfstækifæri'
                                    : 'Job opportunities'}
                            </a>
                        </Link>
                    </Box>
                </>
            )}
            {type === 'solution' && (
                <>
                    <span aria-hidden="true" className={styles.dotStyle}>
                        •
                    </span>
                    <Box boxStyles={{ flexShrink: isMobile ? 0 : 1 }}>
                        <Link
                            href={
                                lang === 'is'
                                    ? '/lausnir'
                                    : '/english/solutions'
                            }
                        >
                            <a className={styles.textStyle}>
                                {lang === 'is' ? 'Lausnir' : 'Solutions'}
                            </a>
                        </Link>
                    </Box>
                </>
            )}
            {type === 'investorspage' && (
                <>
                    <span aria-hidden="true" className={styles.dotStyle}>
                        •
                    </span>
                    <Box boxStyles={{ flexShrink: isMobile ? 0 : 1 }}>
                        <Link
                            href={
                                lang === 'is'
                                    ? '/fjarfestar'
                                    : '/english/investor-relations'
                            }
                        >
                            <a className={styles.textStyle}>
                                {lang === 'is'
                                    ? 'Fjárfestar'
                                    : 'Investors Relations'}
                            </a>
                        </Link>
                    </Box>
                </>
            )}

            {type === 'announcement' && (
                <>
                    <span aria-hidden="true" className={styles.dotStyle}>
                        •
                    </span>
                    <Box boxStyles={{ flexShrink: isMobile ? 0 : 1 }}>
                        <Link
                            href={
                                lang === 'is'
                                    ? '/tilkynningar'
                                    : '/english/announcements'
                            }
                        >
                            <a className={styles.textStyle}>
                                {lang === 'is'
                                    ? 'Tilkynningar'
                                    : 'Announcements'}
                            </a>
                        </Link>
                    </Box>
                </>
            )}
            {breadCrumbs.length &&
                breadCrumbs.map((c, index) => {
                    const isActivePage = index === breadCrumbs.length - 1
                    return (
                        <Box
                            key={index}
                            align="center"
                            boxStyles={{ flexShrink: isMobile ? 0 : 1 }}
                        >
                            <span
                                aria-hidden="true"
                                className={styles.dotStyle}
                            >
                                •
                            </span>
                            <Box
                                boxStyles={{
                                    backgroundColor: isActivePage
                                        ? theme.colors.neutral[300]
                                        : 'none',
                                    borderRadius: '0.5rem',
                                    padding: isActivePage ? '0.5rem' : 0,
                                    paddingTop: '0.5rem',
                                    paddingBottom: '0.5rem',

                                    marginRight:
                                        isActivePage && isMobile
                                            ? theme.layout.spacing.gap.md
                                            : 0,
                                }}
                            >
                                <Link href={`${c.url}`}>
                                    <a
                                        aria-current={
                                            isActivePage ? 'page' : undefined
                                        }
                                        className={styles.textStyle}
                                    >
                                        {c.name}
                                    </a>
                                </Link>
                            </Box>
                        </Box>
                    )
                })}
        </nav>
    )

    return isMobile ? (
        renderBreadcrumbs()
    ) : (
        <Container>{renderBreadcrumbs()}</Container>
    )
}
