import { DefaultTableOfContents } from './variations'
import { TTableOfContentsSlice } from './TableOfContents.types'

const TableOfContents: React.FC<{ slice: TTableOfContentsSlice }> = ({
    slice,
}) => {
    switch (slice.variation) {
        case 'default': {
            return DefaultTableOfContents(slice)
        }
        default:
            return null
    }
}

export default TableOfContents
