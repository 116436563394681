import {
    parsePrismicBackgroundColor,
    parsePrismicHeadingColor,
    parsePrismicHighlightColor,
    parsePrismicLightBackgroundColor,
} from '@orbit/prismic'
import { Box, Container, EventsCard, HeadingContainer } from '@orbit/ui/core'
import { BreakPoints, theme } from '@orbit/ui/themes'
import { ImageFieldImage } from '@prismicio/types'
import moment from 'moment'
import { useState, useEffect } from 'react'
import { useWindowSize } from 'react-use'
import { TEventsOverviewSliceAllEvents } from './EventsOverview.types'
import * as styles from './EventsOverview.css'

type TEvent = {
    data: TEventData
    uid: string
    id: string
}

type TEventData = {
    summary: string
    type: string
    image: ImageFieldImage
    datetime: string
    title: string
    availability: 'Full' | 'Available'
    backgroundColor: string
    textColor: string
    headingColor: string
    cardColor: string
    heading: string
    tag: string
    date: string
    link: string
    backgroundfilled: boolean
}

export const AllEventsOverview: React.FC<{
    slice: TEventsOverviewSliceAllEvents
    allEventsData: TEvent[]
}> = ({ slice, allEventsData }) => {
    moment.locale('is')
    const [isMobile, setIsMobile] = useState(false)
    const { width } = useWindowSize()
    const [highlightEventsCount, setHiglightEventsCount] = useState<number>(0)

    useEffect(() => {
        if (width < BreakPoints.tabletSmall) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    useEffect(() => {
        const cardCount = allEventsData?.length
        setHiglightEventsCount(cardCount % 3)
    }, [allEventsData, highlightEventsCount])

    const getCardProps = (event: TEvent, index: number) => {
        const shortSummary = event.data.summary?.substring(0, 160) + '...'
        if (
            allEventsData.length % 3 === 0 &&
            event.data.summary?.length > 160
        ) {
            event.data.summary = shortSummary
        } else if (index !== 0 && event.data.summary?.length > 160) {
            event.data.summary = shortSummary
        }
        if (highlightEventsCount === 2 && event.data.summary?.length > 160) {
            event.data.summary = shortSummary
        }
        const cardColor =
            event.data.type === 'Webcast'
                ? 'Green'
                : event.data.type === 'Course'
                ? 'Orange'
                : 'Blue'
        const cardProps = {
            backgroundColor: parsePrismicBackgroundColor(cardColor),
            textColor: parsePrismicHighlightColor(cardColor),
            headingColor: parsePrismicHeadingColor(cardColor),
            cardColor: parsePrismicLightBackgroundColor(cardColor),
            id: 'allevents_' + event.id + ' ' + index,
            heading: event.data.title,
            tag: event.data.type,
            date: event.data.datetime,
            image: event.data.image,
            link: event.uid,
            summary: event.data.summary,
            availability: event.data.availability,
            uid: event.uid,
        }
        return cardProps
    }

    return (
        <Box
            anchorid={slice.primary.anchor || undefined}
            paddingTop={isMobile ? 40 : 80}
            paddingBottom={isMobile ? 40 : 80}
            width={'full'}
            boxStyles={{
                backgroundColor: slice.primary.backgroundfilled
                    ? parsePrismicBackgroundColor(slice.primary.slicecolor)
                    : theme.colors.neutral[100],
            }}
            flexDirection={isMobile ? 'column' : 'row'}
        >
            <Container>
                <Box justify="center" marginBottom={64}>
                    <HeadingContainer
                        variant="center"
                        tagline={slice.primary.tagline || undefined}
                        title={slice.primary.title || undefined}
                        color={parsePrismicHeadingColor(
                            slice.primary.slicecolor
                        )}
                    />
                </Box>
                {allEventsData && highlightEventsCount === 1 && (
                    <Box
                        extraClassName={styles.eventCardWrapper({
                            size: highlightEventsCount,
                        })}
                    >
                        <EventsCard
                            {...getCardProps(allEventsData[0], 0)}
                            size="large"
                            index={0}
                        />
                    </Box>
                )}

                {allEventsData && highlightEventsCount === 2 && (
                    <Box
                        extraClassName={styles.eventCardWrapper({
                            size: highlightEventsCount,
                        })}
                    >
                        <EventsCard
                            {...getCardProps(allEventsData[0], 0)}
                            size="medium"
                            index={0}
                        />
                        <EventsCard
                            {...getCardProps(allEventsData[1], 1)}
                            size="medium"
                        />
                    </Box>
                )}

                {allEventsData && (
                    <Box
                        extraClassName={styles.eventCardWrapper({
                            size: 3,
                        })}
                    >
                        {Array.isArray(allEventsData) &&
                            allEventsData.map(
                                (event: TEvent, index: number) => {
                                    if (index >= highlightEventsCount)
                                        return (
                                            <EventsCard
                                                key={index}
                                                {...getCardProps(event, index)}
                                                size="small"
                                                index={index}
                                            />
                                        )
                                    else {
                                        return null
                                    }
                                }
                            )}
                    </Box>
                )}
            </Container>
        </Box>
    )
}

export default AllEventsOverview
