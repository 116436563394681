import React from 'react'
import { TLogoSliderSlices } from './LogoSlider.types'
import { DefaultLogoSlider, SliderLogoSlider, LogoCards } from './variations'

const LogoSlider: React.FC<{ slice: TLogoSliderSlices }> = ({ slice }) => {
    switch (slice.variation) {
        case 'default': {
            return DefaultLogoSlider(slice)
        }
        case 'slider': {
            return SliderLogoSlider(slice)
        }
        case 'logoCards': {
            return LogoCards(slice)
        }
        default:
            return null
    }
}

export default LogoSlider
