import {
    Box,
    Container,
    HeadingContainer,
    MotionBox,
    OrbitBulletList,
} from '@orbit/ui/core'

import {
    parsePrismicBackgroundColor,
    parsePrismicHeadingColor,
    parsePrismicHighlightColor,
    parsePrismicLightBackgroundColor,
} from '@orbit/prismic'

import { TBulletListSliceDefault } from '../BulletList.types'
import { BreakPoints } from '@orbit/ui/themes'
import { useState, useEffect } from 'react'
import { useWindowSize } from 'react-use'
import { cardRevealUp } from '@orbit/utils/framer-motion'
import { RichTextField, RTNode } from '@prismicio/types'
import { Bullet } from 'libs/shared/orbit-ui/core/src/lib/BulletList/BulletList'

export const DefaultBulletList = (slice: TBulletListSliceDefault) => {
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        if (width < BreakPoints.mobile) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    const RichtextToString = (bullets: RichTextField) => {
        const bullet: string[] = []
        bullets.forEach((item: RTNode) => {
            if (item.type === 'paragraph') {
                bullet.push(item.text)
            }
        })
        return bullet
    }

    return (
        <Box
            anchorid={slice.primary.anchor || undefined}
            flexDirection="column"
            paddingBottom={isMobile ? 48 : 80}
            paddingTop={isMobile ? 48 : 80}
            justify={'center'}
            align={'center'}
            boxStyles={{
                background: slice.primary.backgroundfilled
                    ? 'white'
                    : parsePrismicBackgroundColor(
                          slice.primary.slicecolor || 'Blue'
                      ),
            }}
        >
            <Container>
                <MotionBox
                    width={'full'}
                    flexDirection="column"
                    justify="center"
                    align="center"
                    boxStyles={{
                        borderRadius: '1.5rem',
                        background: slice.primary.backgroundfilled
                            ? parsePrismicLightBackgroundColor(
                                  slice.primary.slicecolor
                              )
                            : 'white',
                    }}
                    initial={isMobile ? 'visible' : 'hidden'}
                    viewport={{
                        once: true,
                    }}
                    whileInView="visible"
                    transition={{
                        duration: 0.6,
                        delay: 0.25,
                    }}
                    variants={cardRevealUp}
                >
                    <Box
                        flexDirection={'column'}
                        justify={'center'}
                        align={'center'}
                        boxStyles={{
                            paddingBottom:
                                slice.primary.tagline || slice.primary.title
                                    ? '2.5rem'
                                    : 0,
                            paddingTop:
                                slice.primary.tagline || slice.primary.title
                                    ? '2.5rem'
                                    : 0,
                            paddingLeft: isMobile ? '2rem' : '3.5rem',
                            paddingRight: isMobile ? '2rem' : '3.5rem',
                        }}
                    >
                        <HeadingContainer
                            variant="center"
                            tagline={slice.primary.tagline || undefined}
                            title={slice.primary.title || undefined}
                            color={parsePrismicHeadingColor(
                                slice.primary.slicecolor
                            )}
                            size="small"
                            pb="0rem"
                        />
                    </Box>

                    <Box
                        width={'full'}
                        marginTop={0}
                        marginBottom={64}
                        boxStyles={{
                            paddingLeft: isMobile ? '2rem' : '3.5rem',
                            paddingRight: isMobile ? '2rem' : '3.5rem',
                        }}
                    >
                        {slice.primary.bullets && (
                            <OrbitBulletList
                                variant="threecolumns"
                                color={parsePrismicHeadingColor(
                                    slice.primary.slicecolor
                                )}
                            >
                                {RichtextToString(slice.primary.bullets).map(
                                    (item, index) => (
                                        <Bullet key={index}>{item}</Bullet>
                                    )
                                )}
                            </OrbitBulletList>
                        )}
                    </Box>
                </MotionBox>
            </Container>
        </Box>
    )
}

export default DefaultBulletList
