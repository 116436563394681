import React from 'react'
import { DefaultPriceModule, MiniPriceModule } from './variations'
import { TPriceModuleSlice } from './PriceModule.types'

const PriceModule: React.FC<{ slice: TPriceModuleSlice }> = ({ slice }) => {
    switch (slice.variation) {
        case 'default': {
            return DefaultPriceModule(slice)
        }
        case 'mini': {
            return MiniPriceModule(slice)
        }
        default:
            return null
    }
}

export default PriceModule
