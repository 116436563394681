import { useEffect, useState } from 'react'
import {
    parsePrismicBackgroundColor,
    parsePrismicDarkColor,
    parsePrismicHeadingColor,
    parsePrismicLightBackgroundColor,
} from '@orbit/prismic'
import { TFaqSliceWithImage, TFaqWithImageItems } from '../Faq.types'
import { Box, Accordion, Container, HeadingContainer } from '@orbit/ui/core'
import { PrismicRichText } from '@prismicio/react'
import { Text } from '@orbit/ui/core'
import * as styles from '../Faq.css'
import { BreakPoints, theme } from '@orbit/ui/themes'
import { useWindowSize } from 'react-use'
import { ImageFieldImage, RichTextField } from '@prismicio/types'

export const WithImageFaq = (slice: TFaqSliceWithImage) => {
    const [questions, setQuestions] = useState<string[]>([])
    const [answers, setAnswers] = useState<unknown>([])
    const [image, setImage] = useState<ImageFieldImage[]>([])
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)
    useEffect(() => {
        if (width < BreakPoints.tabletSmall) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    useEffect(() => {
        slice.items.map((item: TFaqWithImageItems, i) => {
            setImage((image: ImageFieldImage[]) => [...image, item.image])
            setQuestions((question) => [
                ...question,
                item.question || 'Missing question',
            ])
            setAnswers((answers: RichTextField) => [
                ...answers,
                (
                    <PrismicRichText
                        key={i}
                        field={item.answer}
                        components={{
                            hyperlink: ({ text, node }) => (
                                <a
                                    style={{
                                        color: parsePrismicDarkColor(
                                            slice.primary.slicecolor
                                        ),
                                        fontWeight: 500,
                                        textDecoration: 'none',
                                    }}
                                    href={node.data.url}
                                    target={
                                        node.data.link_type === 'Web'
                                            ? node.data.target
                                            : ''
                                    }
                                    rel="noopener noreferrer"
                                >
                                    {text}
                                </a>
                            ),
                            list: ({ children }) => (
                                <ul className={styles.bullet}>{children}</ul>
                            ),
                            listItem: ({ children }) => (
                                <li
                                    className={`${styles.listItem({
                                        colors: parsePrismicHeadingColor(
                                            slice.primary.slicecolor
                                        ),
                                    })}`}
                                >
                                    <Text> {children} </Text>
                                </li>
                            ),
                        }}
                    />
                ) || 'Missing answer',
            ])
            return null
        })
    }, [slice.items, slice.primary.slicecolor])
    if (image.length === 0) {
        return null
    }
    return (
        <Box
            justify={'center'}
            align={'center'}
            paddingTop={isMobile ? 36 : 80}
            paddingBottom={isMobile ? 36 : 80}
            boxStyles={{
                overflow: 'hidden',
                backgroundColor: slice.primary.backgroundfilled
                    ? theme.colors.neutral[100]
                    : parsePrismicBackgroundColor(slice.primary.slicecolor),
            }}
            flexDirection="column"
        >
            <Container>
                <Box
                    flexDirection="column"
                    justify={isMobile ? 'center' : 'flexStart'}
                    align={isMobile ? 'center' : 'flexStart'}
                    width={isMobile ? 'full' : 'half'}
                >
                    <HeadingContainer
                        tagline={
                            slice.primary.tagline
                                ? slice.primary.tagline
                                : undefined
                        }
                        title={slice.primary.title ? slice.primary.title : ''}
                        color={parsePrismicHeadingColor(
                            slice.primary.slicecolor
                        )}
                        variant="center"
                        gap="2rem"
                        pb="2rem"
                    />
                </Box>

                <Box
                    flexDirection="row"
                    width={'full'}
                    boxStyles={{ height: '100%' }}
                >
                    <Box width={'full'}>
                        <Accordion
                            image={image}
                            questions={questions}
                            answers={answers as string[]}
                            size={'half'}
                            wrap={false}
                            colors={{
                                background: slice.primary.backgroundfilled
                                    ? parsePrismicLightBackgroundColor(
                                          slice.primary.slicecolor
                                      )
                                    : 'white',
                                iconBackground: parsePrismicBackgroundColor(
                                    slice.primary.slicecolor
                                ),
                                icon: parsePrismicDarkColor(
                                    slice.primary.slicecolor
                                ),
                                circle: parsePrismicHeadingColor(
                                    slice.primary.slicecolor
                                ),
                            }}
                        />
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

export default WithImageFaq
