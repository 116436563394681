/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { parseTime } from '@orbit/prismic'
import {
    Box,
    Container,
    Heading,
    Text,
    SubpageHeroBanner,
    Icon,
    IconType,
} from '@orbit/ui/core'
import { BreakPoints, theme } from '@orbit/ui/themes'
import { useState, useEffect, useRef, useMemo } from 'react'
import { useWindowSize } from 'react-use'
import moment from 'moment'
import 'moment/locale/is'
import { PrismicRichText } from '@prismicio/react'
import * as styles from './Event.css'
import {
    FilledLinkToDocumentField,
    FilledLinkToWebField,
    ImageFieldImage,
    KeyTextField,
    PrismicDocumentWithUID,
    RichTextField,
    SelectField,
    SliceZone as PrismicSlices,
} from '@prismicio/types'

import { EventSocialShare, NoEventForm } from './EventForms'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore from 'swiper'

interface TEvent {
    event: PrismicDocumentWithUID<TEventData>
}

type TEventData = {
    title: string
    description: string
    content: RichTextField
    buttonlink1: FilledLinkToDocumentField | FilledLinkToWebField
    buttonlink2: FilledLinkToDocumentField | FilledLinkToWebField
    buttonlabel1: KeyTextField
    buttonlabel2: KeyTextField
    buttonicon1: SelectField<IconType, 'filled'>
    buttonicon2: SelectField<IconType, 'filled'>
    image: ImageFieldImage
    formid: string
    formtitle: string
    formtagline: string
    datetime: string
    end_time: string
    showtime: boolean
    location: string
    price: string | null
    type: string
    summary: string
    slices: any
    publishing: 'Listed' | 'Unlisted'
    availability: 'Available' | 'Full'
    privateevent: boolean
    uid: string
}
const handleAnchorButtons = (
    e: React.MouseEvent<HTMLAnchorElement>,
    anchor: string | undefined
) => {
    e.preventDefault()
    if (window && anchor) {
        const formattedAnchor = anchor.split('https://#')[1] || anchor
        const anchorElement = window.document.querySelector(
            `[data-anchorid="${decodeURI(formattedAnchor)}"]`
        )

        if (anchorElement)
            anchorElement.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            })
    }
}

const checkIfPrismicAnchorLink = (link: string) => {
    return link.indexOf('https://#') > -1
}

export const Event: React.FC<TEvent> = ({ event }) => {
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)
    const [baseUrl, setBaseUrl] = useState('')
    const [dateExpired, setDateExpired] = useState(false)

    const swiperRef = useRef<SwiperCore>()
    const navigationPrevRef = useRef<HTMLDivElement>(null)
    const navigationNextRef = useRef<HTMLDivElement>(null)
    moment.locale('is')

    useEffect(() => {
        if (width < BreakPoints.tabletSmall) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])
    useEffect(() => {
        if (window !== undefined) {
            setBaseUrl(window.location.href)
        }
    }, [])

    useEffect(() => {
        const script = document.createElement('script')
        script.src = 'https://js.hsforms.net/forms/shell.js'
        document.body.appendChild(script)

        script.addEventListener('load', () => {
            if (window && window.hbspt) {
                window.hbspt.forms.create({
                    portalId: '6348411',
                    formId: event.data.formid,
                    target: `#hbspot-event-form-${event.data.formid}`,
                })
            }
        })
    }, [event.data.formid])

    const now = useMemo(() => new Date(), [])

    const eventDate = useMemo(
        () => new Date(event.data.datetime),
        [event.data.datetime]
    )

    useEffect(() => {
        if (eventDate < now) {
            setDateExpired(true)
        } else {
            setDateExpired(false)
        }
    }, [event.data.datetime, eventDate, now])

    const renderHeader = () => {
        return (
            <Box width={'full'} paddingBottom={64}>
                <SubpageHeroBanner
                    type={'News'}
                    imageUrl={event.data.image.url || undefined}
                    imageAlt={event.data.image.alt || undefined}
                    title={event.data.title}
                    introtext={event.data.summary && event.data.summary}
                    slicecolor={theme.colors.accents.second[400]}
                />
            </Box>
        )
    }

    const eventForm = (formid: string, title: string, tagline: string) => {
        return (
            <Box flexDirection="column" width={'full'}>
                <Box
                    width={'full'}
                    paddingTop={40}
                    flexDirection={'column'}
                    boxStyles={{ backgroundColor: theme.colors.primary[300] }}
                    align={'center'}
                >
                    <Heading
                        style={{ fontSize: '0.75rem' }}
                        type={'h6'}
                        my={0}
                        paddingBottom={2}
                        color={'primary'}
                        text={tagline}
                    />
                    <Heading
                        type={'h3'}
                        my={0}
                        text={title}
                        style={{ paddingBottom: '3rem' }}
                    />
                    <Box id={'contact-form'}>
                        {formid && (
                            <Box width={'full'} id={'contact-hsform-event'}>
                                <div
                                    id={`hbspot-event-form-${formid}`}
                                    className="hbspot-event-form"
                                />
                            </Box>
                        )}
                    </Box>
                </Box>
                <Box paddingTop={16} width={'full'}>
                    {event.data.formid &&
                        !dateExpired &&
                        !event.data.privateevent && (
                            <>{EventSocialShare(baseUrl)}</>
                        )}
                </Box>
            </Box>
        )
    }

    const contentButton = (
        buttonlink: string,
        buttonicon: IconType,
        buttonlabel?: string | null
    ) => {
        return (
            <Box extraClassName={styles.buttonWrapper}>
                <a
                    className={styles.footerButton}
                    href={buttonlink}
                    onClick={
                        checkIfPrismicAnchorLink(buttonlink)
                            ? (e) => handleAnchorButtons(e, buttonlink)
                            : undefined
                    }
                >
                    {
                        <p
                            style={{
                                margin: 0,
                                color: theme.colors.accents.second[600],
                            }}
                        >
                            {buttonlabel}
                        </p>
                    }
                </a>
                <Icon
                    color={theme.colors.accents.second[600]}
                    icon={buttonicon}
                    size="small"
                />
            </Box>
        )
    }

    return (
        <Box flexDirection="column" alignSelf="center">
            {isMobile && renderHeader()}
            <Container>
                <>
                    {!isMobile && renderHeader()}
                    {dateExpired && (
                        <Heading
                            type={'h6'}
                            text={'þessi viðburður er liðinn'}
                            my={0}
                            paddingBottom={3}
                            mx={4}
                        />
                    )}
                    <Box
                        extraClassName={styles.infoWrapper}
                        paddingBottom={isMobile ? 36 : 80}
                    >
                        <Swiper
                            slidesPerView={'auto'}
                            spaceBetween={0}
                            navigation={{
                                prevEl: navigationPrevRef.current,
                                nextEl: navigationNextRef.current,
                            }}
                            onBeforeInit={(swiper) => {
                                swiperRef.current = swiper
                            }}
                            grabCursor={true}
                            className={styles.SwiperStyle}
                        >
                            {event.uid !== 'canon-namskeid-byrjendur' && (
                                <>
                                    <SwiperSlide
                                        // Þarf að vera inline
                                        style={{
                                            width: 'fit-content',
                                            padding: isMobile
                                                ? '0.25rem'
                                                : '0.5rem',
                                            height: 'auto',
                                            flexDirection: 'row',
                                        }}
                                    >
                                        <Box
                                            extraClassName={styles.info({
                                                dateExpired: dateExpired,
                                            })}
                                        >
                                            <Icon
                                                icon={'CalendarAlt'}
                                                color={
                                                    dateExpired
                                                        ? theme.colors
                                                              .neutral[400]
                                                        : theme.colors
                                                              .tertiary[500]
                                                }
                                                size="small"
                                            />
                                            <Box
                                                extraClassName={styles.infoText(
                                                    {
                                                        dateExpired:
                                                            dateExpired,
                                                    }
                                                )}
                                            >
                                                {!event.data.end_time ||
                                                eventDate.toLocaleDateString() ===
                                                    new Date(
                                                        event.data.end_time
                                                    ).toLocaleDateString() ? (
                                                    <Box
                                                        extraClassName={styles.infoText(
                                                            {
                                                                dateExpired:
                                                                    dateExpired,
                                                            }
                                                        )}
                                                    >
                                                        {moment(
                                                            event.data.datetime
                                                        ).format('DD/MM/YY')}
                                                    </Box>
                                                ) : (
                                                    <Box
                                                        extraClassName={styles.infoText(
                                                            {
                                                                dateExpired:
                                                                    dateExpired,
                                                            }
                                                        )}
                                                    >
                                                        {moment(
                                                            event.data.datetime
                                                        ).format(
                                                            'DD/MM/YY'
                                                        )}{' '}
                                                        {' - '}
                                                        {moment(
                                                            event.data.end_time
                                                        ).format('DD/MM/YY')}
                                                    </Box>
                                                )}
                                            </Box>
                                        </Box>
                                    </SwiperSlide>
                                    {event.data.showtime && (
                                        <SwiperSlide
                                            // Þarf að vera inline
                                            style={{
                                                width: 'fit-content',
                                                padding: isMobile
                                                    ? '0.25rem'
                                                    : '0.5rem',
                                                height: 'auto',
                                                flexDirection: 'row',
                                            }}
                                        >
                                            <Box
                                                extraClassName={styles.info({
                                                    dateExpired: dateExpired,
                                                })}
                                            >
                                                <Icon
                                                    icon={'Clock'}
                                                    color={
                                                        dateExpired
                                                            ? theme.colors
                                                                  .neutral[400]
                                                            : theme.colors
                                                                  .tertiary[500]
                                                    }
                                                    size="small"
                                                />

                                                {!event.data.end_time ? (
                                                    <Box
                                                        extraClassName={styles.infoText(
                                                            {
                                                                dateExpired:
                                                                    dateExpired,
                                                            }
                                                        )}
                                                    >
                                                        {parseTime(
                                                            event.data.datetime
                                                        )}
                                                    </Box>
                                                ) : (
                                                    <Box
                                                        extraClassName={styles.infoText(
                                                            {
                                                                dateExpired:
                                                                    dateExpired,
                                                            }
                                                        )}
                                                    >
                                                        {parseTime(
                                                            event.data.datetime
                                                        )}{' '}
                                                        -{' '}
                                                        {parseTime(
                                                            event.data.end_time
                                                        )}
                                                    </Box>
                                                )}
                                            </Box>
                                        </SwiperSlide>
                                    )}
                                </>
                            )}

                            {event.data.location && (
                                <SwiperSlide
                                    // Þarf að vera inline
                                    style={{
                                        width: 'fit-content',
                                        padding: isMobile
                                            ? '0.25rem'
                                            : '0.5rem',
                                        height: 'auto',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <Box
                                        extraClassName={styles.info({
                                            dateExpired: dateExpired,
                                        })}
                                    >
                                        <Icon
                                            icon={'Location'}
                                            color={
                                                dateExpired
                                                    ? theme.colors.neutral[400]
                                                    : theme.colors.tertiary[500]
                                            }
                                            size="small"
                                        />
                                        <Box
                                            extraClassName={styles.infoText({
                                                dateExpired: dateExpired,
                                            })}
                                        >
                                            {event.data.location}
                                        </Box>
                                    </Box>
                                </SwiperSlide>
                            )}

                            {event.data.price && (
                                <SwiperSlide
                                    // Þarf að vera inline
                                    style={{
                                        width: 'fit-content',
                                        padding: isMobile
                                            ? '0.25rem'
                                            : '0.5rem',
                                        height: 'auto',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <Box
                                        extraClassName={styles.info({
                                            dateExpired: dateExpired,
                                        })}
                                    >
                                        <Icon
                                            icon={'Wallet'}
                                            color={
                                                dateExpired
                                                    ? theme.colors.neutral[400]
                                                    : theme.colors.tertiary[500]
                                            }
                                            size="small"
                                        />
                                        <Box
                                            extraClassName={styles.infoText({
                                                dateExpired: dateExpired,
                                            })}
                                        >
                                            {event.data.price}
                                        </Box>
                                    </Box>
                                </SwiperSlide>
                            )}
                        </Swiper>
                    </Box>
                    {event.data.content && (
                        <Box
                            flexDirection={isMobile ? 'column' : 'row'}
                            width={'full'}
                            justify="spaceBetween"
                            paddingBottom={isMobile ? 36 : 0}
                        >
                            <Box
                                boxStyles={{
                                    width: isMobile
                                        ? '100%'
                                        : 'calc(50% - 2.5rem)',
                                }}
                                flexDirection="column"
                                paddingBottom={isMobile ? 36 : 0}
                            >
                                <PrismicRichText
                                    field={event.data.content}
                                    components={{
                                        paragraph: ({ children }) => (
                                            <Text size="large">{children}</Text>
                                        ),
                                        heading6: ({ text }) =>
                                            text && (
                                                <Heading
                                                    color={'primary'}
                                                    type={'h6'}
                                                    text={text}
                                                    style={{
                                                        marginTop: '0.5rem',
                                                        marginBottom: '0.5rem',
                                                    }}
                                                />
                                            ),
                                        heading5: ({ text }) =>
                                            text && (
                                                <Heading
                                                    color={'primary'}
                                                    type={'h5'}
                                                    text={text}
                                                    style={{
                                                        marginTop: '0.5rem',
                                                        marginBottom: '0.5rem',
                                                    }}
                                                />
                                            ),
                                        heading4: ({ text }) =>
                                            text && (
                                                <Heading
                                                    type={'h4'}
                                                    text={text}
                                                    style={{
                                                        marginTop: '0.5rem',
                                                        marginBottom: '0.5rem',
                                                    }}
                                                />
                                            ),
                                        heading3: ({ text }) =>
                                            text && (
                                                <Heading
                                                    type={'h3'}
                                                    text={text}
                                                    style={{
                                                        marginTop: '0.5rem',
                                                        marginBottom: '0.5rem',
                                                    }}
                                                />
                                            ),
                                        heading2: ({ text }) =>
                                            text && (
                                                <Heading
                                                    type={'h2'}
                                                    text={text}
                                                    style={{
                                                        marginTop: '0.5rem',
                                                        marginBottom: '0.5rem',
                                                    }}
                                                />
                                            ),
                                        hyperlink: ({ text, node }) => (
                                            <a
                                                style={{ fontSize: 'inherit' }}
                                                className={styles.hyperlink}
                                                href={node.data.url}
                                                target={
                                                    node.data.link_type ===
                                                    'Web'
                                                        ? node.data.target
                                                        : ''
                                                }
                                                rel="noopener noreferrer"
                                            >
                                                {text}
                                            </a>
                                        ),
                                        list: ({ children }) => (
                                            <ul className={styles.bullet}>
                                                {children}
                                            </ul>
                                        ),
                                        listItem: ({ children }) => (
                                            <li
                                                className={`${styles.listItem({
                                                    colors: 'Blue',
                                                })}`}
                                            >
                                                <Text size="large">
                                                    {' '}
                                                    {children}{' '}
                                                </Text>
                                            </li>
                                        ),
                                        image: ({ node }) => (
                                            <>
                                                <img
                                                    src={node.url}
                                                    alt={node.alt || ''}
                                                    style={{
                                                        display: 'flex',
                                                        alignSelf: 'center',
                                                        width: '100%',
                                                    }}
                                                ></img>
                                                {node.alt && (
                                                    <text
                                                        style={{
                                                            color: theme.colors
                                                                .primary[600],
                                                            fontSize: '0.75rem',
                                                            fontWeight: 600,
                                                            paddingTop: '1rem',
                                                            paddingBottom:
                                                                '1rem',
                                                        }}
                                                    >
                                                        {node.alt}
                                                    </text>
                                                )}
                                            </>
                                        ),
                                    }}
                                />
                                <Box extraClassName={styles.buttonContainer}>
                                    {event.data.buttonlink1.url &&
                                        contentButton(
                                            event.data.buttonlink1.url,
                                            event.data.buttonicon1 as IconType,
                                            event.data.buttonlabel1
                                        )}
                                    {event.data.buttonlink2.url &&
                                        contentButton(
                                            event.data.buttonlink2.url,
                                            event.data.buttonicon2 as IconType,
                                            event.data.buttonlabel2
                                        )}
                                </Box>
                            </Box>
                            {event.data.formid &&
                            !dateExpired &&
                            event.data.availability === 'Available' ? (
                                <Box
                                    boxStyles={{
                                        width: isMobile
                                            ? '100%'
                                            : 'calc(50% - 2.5rem)',
                                    }}
                                >
                                    {eventForm(
                                        event.data.formid,
                                        event.data.formtitle,
                                        event.data.formtagline
                                    )}
                                </Box>
                            ) : (
                                <Box
                                    justify="center"
                                    boxStyles={{
                                        width: isMobile
                                            ? '100%'
                                            : 'calc(50% - 2.5rem)',
                                    }}
                                >
                                    {event.data.availability === 'Full' &&
                                        !dateExpired && (
                                            <>
                                                {NoEventForm(
                                                    'Það er fullt á þennan viðburð',
                                                    'Skráðu þig á póstlistann og við látum þig vita þegar eitthvað nýtt eða spennandi er að gerast hjá okkur.'
                                                )}
                                            </>
                                        )}
                                    {dateExpired && (
                                        <>
                                            {NoEventForm(
                                                'Skráðu þig á póstlistann okkar',
                                                'Við látum þig vita þegar eitthvað nýtt eða spennandi er að gerast hjá okkur.'
                                            )}
                                        </>
                                    )}
                                </Box>
                            )}
                        </Box>
                    )}
                </>
            </Container>
        </Box>
    )
}
