import { useEffect, useState } from 'react'
import { BreakPoints } from '@orbit/ui/themes'
import { useWindowSize } from 'react-use'
import { Box, Container, Heading } from '@orbit/ui/core'
import { TContactFormSliceHubspotMeeting } from '../ContactForm.types'
import {
    parsePrismicBackgroundColor,
    parsePrismicHeadingColor,
} from '@orbit/prismic'
import * as styles from '../ContactForm.css'

export const HubspotMeetingForm = (slice: TContactFormSliceHubspotMeeting) => {
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)
    useEffect(() => {
        if (width < BreakPoints.mobile) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    const meetingForm = () => {
        return (
            <Box width="full">
                {slice.primary.hubspotform.html && (
                    <div
                        style={{ width: '100%' }}
                        dangerouslySetInnerHTML={{
                            __html: slice.primary.hubspotform.html,
                        }}
                    />
                )}
            </Box>
        )
    }

    return (
        <Box
            anchorid={slice.primary.anchor || undefined}
            extraClassName={styles.test}
            boxStyles={{
                backgroundColor: slice.primary.backgroundfilled
                    ? parsePrismicBackgroundColor(slice.primary.slicecolor)
                    : 'white',
            }}
            justify="spaceAround"
            align="center"
            flexDirection={isMobile ? 'column' : 'rowReverse'}
        >
            <Container>
                <Box
                    boxStyles={{
                        width: 'calc(100% + 2.5rem)',
                    }}
                    flexDirection="column"
                    align={'center'}
                >
                    <Box
                        flexDirection="column"
                        paddingLeft={isMobile ? 0 : 36}
                        paddingTop={64}
                        align={'center'}
                    >
                        {slice.primary.tagline && (
                            <Heading
                                text={slice.primary.tagline}
                                type={'h6'}
                                color={parsePrismicHeadingColor(
                                    slice.primary.slicecolor
                                )}
                                mx={0}
                                my={0}
                            />
                        )}
                        {slice.primary.title && (
                            <Heading
                                text={slice.primary.title}
                                type={'h3'}
                                mx={0}
                                my={0}
                                paddingTop={2}
                                paddingBottom={5}
                                textAlign={isMobile ? 'center' : 'start'}
                            />
                        )}
                    </Box>

                    {meetingForm()}
                </Box>
            </Container>
        </Box>
    )
}

export default HubspotMeetingForm
