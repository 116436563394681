import * as styles from './Button.css'
import { ButtonProps } from '../../types'
import cn from 'classnames'
import { Icon } from '../Icons'
import { Box } from '../Box'
import { theme } from '@orbit/ui/themes'
import { forwardRef, useEffect, useState } from 'react'
import Link from 'next/link'

const handleAnchorButtons = (
    e: React.MouseEvent<HTMLAnchorElement>,
    anchor: string | undefined
) => {
    e.preventDefault()
    if (window && anchor) {
        const formattedAnchor = anchor.split('https://#')[1] || anchor
        const anchorElement = window.document.querySelector(
            `[data-anchorid="${decodeURI(formattedAnchor)}"]`
        )

        if (anchorElement)
            anchorElement.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            })
    }
}

const checkIfPrismicAnchorLink = (link: string) => {
    return link.indexOf('https://#') > -1
}

export const ButtonLink: React.FC<ButtonProps> = (props) => {
    if (!props.href) return null

    if (checkIfPrismicAnchorLink(props.href)) {
        return (
            <>
                {props.variant === 'ghost' || props.variant === 'filled' ? (
                    <DefaultButtonLink {...props} />
                ) : props.variant === 'text' ? (
                    <TextButtonLink {...props} />
                ) : (
                    <IconButtonLink {...props} />
                )}
            </>
        )
    }
    return (
        <Link href={props.href} passHref={true}>
            {props.variant === 'ghost' || props.variant === 'filled' ? (
                <DefaultButtonLink {...props} />
            ) : props.variant === 'text' ? (
                <TextButtonLink {...props} />
            ) : (
                <IconButtonLink {...props} />
            )}
        </Link>
    )
}

const DefaultButtonLink = forwardRef<HTMLAnchorElement, ButtonProps>(
    (
        {
            children,
            href,
            size = 'small',
            variant = 'filled',
            color = 'primary',
            icon,
            fullWidth = false,
            newTab,
        },
        ref
    ) => {
        const defaultVariant: 'filled' | 'ghost' =
            variant === 'filled' || variant == 'ghost' ? variant : 'filled'
        const [focused, setFocused] = useState(false)
        const [active, setActive] = useState(false)
        const [hovered, setHovered] = useState(false)
        if (!href) return null

        return (
            <a
                href={href}
                ref={ref}
                onClick={
                    checkIfPrismicAnchorLink(href)
                        ? (e) => handleAnchorButtons(e, href as string)
                        : undefined
                }
                className={cn(
                    styles.button({
                        size: size,
                        variant: variant,
                        fullWidth: fullWidth,
                        withIcon: !!icon,
                    }),
                    (
                        styles.buttonColors[defaultVariant] as Record<
                            string,
                            string
                        >
                    )[color]
                )}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                onDragEnd={() => setActive(false)}
                onMouseDown={() => setActive(true)}
                onMouseUp={() => setActive(false)}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                target={newTab ? '_blank' : ''}
                rel="noopener noreferrer"
            >
                <span className={styles.buttonText}>{children}</span>
                {icon && (
                    <Box
                        boxStyles={{ marginLeft: '0.75rem' }}
                        justify="center"
                        align="center"
                    >
                        <Icon
                            icon={icon}
                            size={size == 'large' ? 'medium' : 'small'}
                            color={
                                active
                                    ? theme.components.core.button.variants[
                                          color
                                      ][defaultVariant].onPressed.iconColor
                                    : hovered && variant !== 'filled'
                                    ? theme.components.core.button.variants[
                                          color
                                      ].icon.onPressed.iconColor
                                    : focused
                                    ? theme.colors.neutral[100]
                                    : theme.components.core.button.variants[
                                          color
                                      ][defaultVariant].default.textColor
                            }
                        />
                    </Box>
                )}
            </a>
        )
    }
)

const TextButtonLink = forwardRef<HTMLAnchorElement, ButtonProps>(
    (
        { href, children, variant = 'text', color = 'primary', icon, newTab },
        ref
    ) => {
        if (!href) return null

        const [focused, setFocused] = useState(false)
        const [active, setActive] = useState(false)
        const [hovered, setHovered] = useState(false)

        useEffect(() => {
            const mouseupHandler = () => {
                setHovered(false)
                setActive(false)
            }

            window.addEventListener('mouseup', mouseupHandler)

            return () => {
                window.removeEventListener('mouseup', mouseupHandler)
            }
        }, [])

        return (
            <a
                href={href}
                ref={ref}
                onClick={
                    checkIfPrismicAnchorLink(href)
                        ? (e) => handleAnchorButtons(e, href as string)
                        : undefined
                }
                className={cn(
                    (styles.buttonColors[variant] as Record<string, string>)[
                        color
                    ],
                    styles.button({
                        variant: variant,
                    })
                )}
                onDragEnd={() => setActive(false)}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                onMouseDown={() => setActive(true)}
                onMouseUp={() => setActive(false)}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                target={newTab ? '_blank' : ''}
                rel="noopener noreferrer"
            >
                <span
                    className={cn(
                        styles.buttonText,
                        (
                            styles.dotColors['dotColor'] as Record<
                                string,
                                string
                            >
                        )[color],
                        icon
                            ? styles.dotStyles
                            : styles.underlineColors({
                                  color: color,
                                  focus: focused,
                                  hover: hovered,
                                  active: active,
                              }),
                        !icon && styles.underLineStyles,
                        styles.buttonText
                    )}
                >
                    {children}
                </span>
                {icon && (
                    <Box justify="center" align="center">
                        <Icon
                            icon={icon}
                            size={'small'}
                            color={
                                active
                                    ? theme.components.core.button.variants[
                                          color
                                      ]['text'].onPressed.textColor
                                    : hovered && !focused
                                    ? theme.components.core.button.variants[
                                          color
                                      ]['text'].default.textColor
                                    : focused
                                    ? theme.colors.neutral[100]
                                    : theme.components.core.button.variants[
                                          color
                                      ]['text'].default.textColor
                            }
                        />
                    </Box>
                )}
            </a>
        )
    }
)

const IconButtonLink = forwardRef<HTMLAnchorElement, ButtonProps>(
    (
        {
            href,
            size = 'small',
            variant = 'icon',
            color = 'primary',
            icon,
            ariaLabel,
            newTab,
        },
        ref
    ) => {
        const [focused, setFocused] = useState(false)
        const [hovered, setHovered] = useState(false)
        const [active, setActive] = useState(false)

        if (!icon) return null

        useEffect(() => {
            const mouseupHandler = () => {
                setHovered(false)
                setActive(false)
            }

            window.addEventListener('mouseup', mouseupHandler)

            return () => {
                window.removeEventListener('mouseup', mouseupHandler)
            }
        }, [])

        if (!href) return null
        return (
            <a
                href={href}
                ref={ref}
                aria-label={ariaLabel}
                className={cn(
                    (styles.buttonColors['icon'] as Record<string, string>)[
                        color
                    ],
                    styles.button({
                        variant: variant,
                    }),
                    size === 'large'
                        ? styles.largeIconButton
                        : styles.smallIconButton
                )}
                onDragEnd={() => setActive(false)}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                onMouseDown={() => setActive(true)}
                onMouseUp={() => setActive(false)}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                target={newTab ? '_blank' : ''}
                rel="noopener noreferrer"
            >
                <Box justify="center" align="center" padding={1}>
                    <Icon
                        icon={icon ? icon : 'Search'}
                        size={size === 'small' ? 'medium' : 'large'}
                        color={
                            focused && !active
                                ? theme.components.core.button.states.focused
                                      .textColor
                                : theme.components.core.button.variants[color]
                                      .icon.default.iconColor
                        }
                    />
                </Box>
            </a>
        )
    }
)

export default ButtonLink
