import {
    parsePrismicBackgroundColor,
    parsePrismicHeadingColor,
} from '@orbit/prismic'

import { BreakPoints } from '@orbit/ui/themes'
import { Box, Container, HeadingContainer, Testimonials } from '@orbit/ui/core'
import { useState, useEffect } from 'react'
import { useWindowSize } from 'react-use'

import { TCustomerStorySliceWithImage } from '../CustomerStory.types'

export const CustomerStoryWithImage = (slice: TCustomerStorySliceWithImage) => {
    const { primary, items } = slice
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        if (width < BreakPoints.mobile) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    if (items.length < 1) return null
    return (
        <Container>
            <Box
                anchorid={slice.primary.anchor || undefined}
                flexDirection="column"
                width="full"
                align="center"
                paddingTop={isMobile ? 16 : 64}
                paddingBottom={isMobile ? 24 : 80}
            >
                <HeadingContainer
                    tagline={primary.tagline || undefined}
                    title={primary.title || undefined}
                    color={parsePrismicHeadingColor(primary?.sliceColor)}
                />

                <Box
                    width={'full'}
                    align={'center'}
                    flexDirection={isMobile ? 'columnReverse' : 'row'}
                    justify="spaceAround"
                    boxStyles={{
                        alignItems: 'stretch',
                        columnGap: '2rem',
                        rowGap: '2rem',
                    }}
                >
                    <Box
                        width={isMobile ? 'full' : 'half'}
                        flexDirection="column"
                        justify="spaceBetween"
                    >
                        <Box
                            flexDirection="column"
                            boxStyles={{ minHeight: '22rem' }}
                        >
                            <Testimonials
                                testimonials={{
                                    content: items[0].content,
                                    authorName: items[0].authorName,
                                    authorJobTitle: items[0].authorJobTitle,
                                }}
                                colors={{
                                    background: primary.backgroundFilled
                                        ? parsePrismicBackgroundColor(
                                              primary.sliceColor
                                          )
                                        : 'white',
                                    pagination: parsePrismicHeadingColor(
                                        primary.sliceColor
                                    ),
                                    card: {
                                        background: primary.backgroundFilled
                                            ? 'white'
                                            : parsePrismicBackgroundColor(
                                                  primary.sliceColor
                                              ),
                                        icon: 'black',
                                        divider: 'black',
                                    },
                                }}
                            />
                        </Box>
                    </Box>
                    <Box
                        width={isMobile ? 'full' : 'half'}
                        paddingTop={isMobile ? 24 : 0}
                    >
                        {!isMobile ? (
                            <div
                                style={{
                                    background:
                                        'url(' +
                                            items[0].image.url +
                                            ') no-repeat' || '',
                                    height: '100%',
                                    width: '100%',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    marginTop: '0',
                                }}
                            ></div>
                        ) : (
                            <img
                                src={items[0].image.url || ''}
                                alt={items[0].image.alt || ''}
                                width="100%"
                                height="auto"
                                style={{ objectFit: 'cover' }}
                            />
                        )}
                    </Box>
                </Box>
            </Box>
        </Container>
    )
}

export default CustomerStoryWithImage
