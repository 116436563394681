import { Box } from '../Box'
import { Text } from '../Text'
import { Heading } from '../Heading'
import * as style from './Testimonials.css'
import React from 'react'
import { Container } from '../Container'
import { Quote } from '../../assets/Quote'
import { Carousel } from '../Carousel'
import { theme } from '@orbit/ui/themes'
import { PrismicRichText } from '@prismicio/react'
import { ImageField, KeyTextField, RichTextField } from '@prismicio/types'
import { OrbitImage } from '../Image'

export interface TestimonialProps {
    authorName: KeyTextField | string
    authorJobTitle: KeyTextField | string
    content: RichTextField | string
    image?: ImageField
}

interface TestimonialColorProps {
    background: string
    pagination: 'primary' | 'secondary' | 'tertiary' | 'accent'
    card: {
        background: string
        icon: string
        divider: string
    }
}

export interface TestimonialsProps {
    testimonials: TestimonialProps[] | TestimonialProps

    title?: string
    tagline?: string
    cardSize?: 'full' | 'half' | 'third'
    colors?: TestimonialColorProps
}

export const Testimonials: React.FC<TestimonialsProps> = ({
    testimonials,
    title,
    tagline,
    colors = {
        background: 'white',
        pagination: 'secondary',
        card: {
            background: theme.colors.neutral[200],
            icon: 'black',
            divider: 'black',
        },
    },
}) => {
    const renderTestimonial = (
        testimonial: TestimonialProps,
        index?: number
    ) => {
        return (
            <div
                key={'testimonial' + index}
                className={style.testimonialItem({
                    testimonialCount:
                        index == undefined ? 'single' : 'multiple',
                })}
                style={{
                    backgroundColor: colors.card.background,
                }}
            >
                <Box
                    padding={5}
                    flexDirection="column"
                    justify="spaceBetween"
                    boxStyles={{ height: '100%' }}
                >
                    <Box flexDirection="column" my={2}>
                        <Quote color={colors.card.icon} />
                        {testimonial.content &&
                            (typeof testimonial.content == 'string' ? (
                                <Text size="medium">{testimonial.content}</Text>
                            ) : (
                                <PrismicRichText
                                    field={testimonial.content}
                                    components={{
                                        paragraph: ({ text }) => {
                                            return (
                                                text && (
                                                    <Text size="medium">
                                                        {text}
                                                    </Text>
                                                )
                                            )
                                        },
                                    }}
                                />
                            ))}
                    </Box>

                    <Box flexDirection="column" width="full">
                        <Box
                            extraClassName={style.divider}
                            my={2}
                            boxStyles={{
                                backgroundColor: colors.card.divider,
                            }}
                        />
                        <Box
                            display="flex"
                            justify="spaceBetween"
                            align="center"
                            width="full"
                        >
                            <Box
                                display="flex"
                                flexDirection="column"
                                paddingTop={16}
                                paddingBottom={8}
                                width="full"
                            >
                                <Box extraClassName={style.jobAuthor}>
                                    {testimonial?.authorName}
                                </Box>
                                <Box extraClassName={style.jobTitle}>
                                    {testimonial?.authorJobTitle}
                                </Box>
                            </Box>

                            {!!testimonial.image && testimonial.image.url && (
                                <Box paddingLeft={16}>
                                    <OrbitImage
                                        src={testimonial.image.url || ''}
                                        alt={testimonial.image.alt || ''}
                                        objectFit="contain"
                                        objectPosition="center"
                                        width={69}
                                        height={42}
                                    />
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
            </div>
        )
    }

    return (
        <Box
            width="full"
            flexDirection="column"
            boxStyles={{
                background: colors.background,
            }}
        >
            {tagline ||
                (title && (
                    <Box
                        align="center"
                        width="full"
                        flexDirection="column"
                        my={5}
                    >
                        {tagline && (
                            <Heading
                                textAlign={'center'}
                                type="h6"
                                text={tagline}
                                color="primary"
                            />
                        )}
                        {title && (
                            <Heading
                                type="h2"
                                text={title}
                                textAlign="center"
                                my={0}
                            />
                        )}
                    </Box>
                ))}

            {Array.isArray(testimonials) ? (
                <Container>
                    <Carousel
                        color={colors.pagination}
                        variant={'numbers'}
                        totalItems={
                            Array.isArray(testimonials)
                                ? testimonials.length
                                : 1
                        }
                        partialVisibilityGutter={0}
                        autoPlaySpeed={3000}
                    >
                        {testimonials.map(
                            (testimonial: TestimonialProps, index: number) => {
                                return renderTestimonial(testimonial, index)
                            }
                        )}
                    </Carousel>
                </Container>
            ) : (
                renderTestimonial(testimonials)
            )}
        </Box>
    )
}
