import {
    parsePrismicHeadingColor,
    parsePrismicDarkColor,
    parsePrismicColorName,
    parsePrismicHighlightColor,
} from '@orbit/prismic'
import { Heading, Text } from '@orbit/ui/core'
import { PrismicRichText } from '@prismicio/react'
import { RichTextField } from '@prismicio/types'
import * as styles from './PrismicRichTextSerializer.css'

export interface SerializerProps {
    slicecolor: string
    text: RichTextField
    textAlign?: 'center' | 'left'
}
export interface ItemProps {
    content: RichTextField
}

export const PrismicSerializer: React.FC<SerializerProps> = ({
    slicecolor,
    text,
    textAlign = 'left',
}) => {
    return (
        <PrismicRichText
            field={text}
            components={{
                paragraph: ({ children }) => (
                    <Text size="large" style={{ textAlign: textAlign }}>
                        {children}
                    </Text>
                ),
                heading6: ({ text }) =>
                    text && (
                        <Heading
                            color={parsePrismicHeadingColor(slicecolor)}
                            type={'h6'}
                            text={text}
                            style={{
                                marginTop: '0.5rem',
                                marginBottom: '0.5rem',
                            }}
                        />
                    ),
                heading5: ({ text }) =>
                    text && (
                        <Heading
                            color={parsePrismicHeadingColor(slicecolor)}
                            type={'h5'}
                            text={text}
                            style={{
                                marginTop: '0.5rem',
                                marginBottom: '0.5rem',
                            }}
                        />
                    ),
                heading4: ({ text }) =>
                    text && (
                        <Heading
                            type={'h4'}
                            text={text}
                            style={{
                                marginTop: '0.5rem',
                                marginBottom: '0.5rem',
                            }}
                        />
                    ),
                heading3: ({ text }) =>
                    text && (
                        <Heading
                            type={'h3'}
                            text={text}
                            style={{
                                marginTop: '0.5rem',
                                marginBottom: '0.5rem',
                            }}
                        />
                    ),
                heading2: ({ text }) =>
                    text && (
                        <Heading
                            type={'h2'}
                            text={text}
                            style={{
                                marginTop: '0.5rem',
                                marginBottom: '0.5rem',
                            }}
                        />
                    ),
                hyperlink: ({ text, node }) => (
                    <a
                        style={{
                            color: parsePrismicHighlightColor(slicecolor),
                            fontWeight: 600,
                            textDecoration: 'none',
                            overflowWrap: 'break-word',
                        }}
                        href={node.data.url}
                    >
                        {text}
                    </a>
                ),
                image: ({ node }) => (
                    <>
                        <img
                            src={node.url}
                            alt={node.alt || ''}
                            style={{
                                display: 'flex',
                                alignSelf: 'center',
                                width: '100%',
                            }}
                        ></img>
                        {node.alt && (
                            <text
                                style={{
                                    color: parsePrismicDarkColor(slicecolor),
                                    fontSize: '0.75rem',
                                    fontWeight: 600,
                                    paddingTop: '1rem',
                                }}
                            >
                                {node.alt}
                            </text>
                        )}
                    </>
                ),
                list: ({ children }) => (
                    <ul className={styles.bullet}>{children}</ul>
                ),
                listItem: ({ children }) => (
                    <li
                        className={`${styles.listItem({
                            colors: parsePrismicColorName(slicecolor),
                        })}`}
                    >
                        <Text size="large"> {children} </Text>
                    </li>
                ),
                oList: ({ children }) => (
                    <ol className={styles.number}>{children}</ol>
                ),
                oListItem: ({ children }) => (
                    <li className={styles.olistItem}>
                        <Text size="large"> {children} </Text>
                    </li>
                ),
            }}
        />
    )
}

export default PrismicSerializer
