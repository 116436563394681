import { BreakPoints } from '@orbit/ui/themes'
import { useEffect, useState } from 'react'
import { useWindowSize } from 'react-use'
import { Box } from '../Box'
import { Button } from '../Button'
import * as styles from './PriceModule.css'
import { Bullet, OrbitBulletList } from '../BulletList/BulletList'
import { Icon } from '../Icons'
import { IconType } from '../Icons/IconMap'
import { HeadingContainer } from '../HeadingContainer'
export interface PriceModuleProps {
    variation: 'Mini' | 'Default'
    backgroundFilled: boolean
    backgroundColor: string
    sliceColor: 'primary' | 'secondary' | 'tertiary' | 'accent'
    totalItems: number
    tagline: string
    price: string
    text: string
    included?: string[]
    notIncluded?: string[]
    buttonLabel?: string
    buttonIcon?: IconType
    buttonLink?: string
    openNewTab?: boolean
}

export interface PriceModuleFields {
    fields: PriceModuleProps
    variation: string
    isMobile?: boolean
}

export const OrbitPriceModule: React.FC<PriceModuleProps> = (props) => {
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        if (width < BreakPoints.mobile) {
            return setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [width])

    return props.totalItems === 1 && !isMobile ? (
        <LargeCard fields={props} variation={props.variation} />
    ) : props.totalItems > 1 ? (
        <SmallCard
            fields={props}
            variation={props.variation}
            isMobile={isMobile}
        />
    ) : (
        <SmallCard
            fields={props}
            variation={props.variation}
            isMobile={isMobile}
        />
    )
}

const LargeCard: React.FC<PriceModuleFields> = ({ fields, variation }) => {
    return (
        <Box extraClassName={styles.priceModule({ oneCard: true })}>
            <Box
                extraClassName={variation === 'Mini' ? styles.MiniSingleColumnStyle : styles.DefaultSingleColumnStyle}
                boxStyles={{
                    background: fields.backgroundColor
                }}
            >

                <Box extraClassName={variation === 'Mini' ? styles.MiniSingleColumnTitleWrapper : styles.DefaultSingleColumnTitleWrapper}>
                    <Box
                        flexDirection="column"
                        align={
                            variation === 'Default' ? 'flexStart' : 'center'
                        }
                        paddingBottom={variation === 'Default' ? 40 : 0}
                    >
                        <HeadingContainer
                            variant={variation === 'Default' ? 'left' : 'center'}
                            tagline={fields.tagline || undefined}
                            title={fields.price || undefined}
                            color={fields.sliceColor}
                            size="xlarge"
                            gap="0.5rem"
                            pb="0rem"
                        />
                        <p className={styles.priceSubStyle}>
                            {fields.text}
                        </p>
                    </Box>

                    
                    {variation === 'Mini' && (
                        <Box
                            alignSelf="center"
                        >
                            <Button
                                size='large'
                                href={fields.buttonLink}
                                color={fields.sliceColor}
                                variant={
                                    fields.buttonLabel &&
                                    fields.backgroundFilled
                                        ? 'ghost'
                                        : fields.buttonLabel &&
                                            !fields.backgroundFilled
                                        ? 'filled'
                                        : 'icon'
                                }
                                icon={fields.buttonIcon}
                                ariaLabel={
                                    fields.buttonLabel
                                        ? undefined
                                        : fields.buttonIcon
                                }
                                newTab={fields.openNewTab}
                            >
                                {fields.buttonLabel}
                            </Button>
                        </Box>
                    )}
                </Box>
                <Box
                    extraClassName={variation === 'Mini' ? styles.MiniSingleColumnItemsWrapper : styles.DefaultSingleColumnItemsWrapper}
                    boxStyles={{
                        flexFlow: 'wrap',
                        width: '100%',
                    }}
                >
                    <Box
                        boxStyles={{
                            width: '100%',
                        }}
                    >
                        {fields.included && (
                            <OrbitBulletList
                                variant={
                                    variation === 'Default'
                                        ? 'twocolumns'
                                        : 'column'
                                }
                                color={fields.sliceColor}
                                bulletPosition="top"
                            >
                                {fields.included.map((item, i) => (
                                    <Bullet key={i}>{item}</Bullet>
                                ))}
                            </OrbitBulletList>
                        )}
                    </Box>
                    {fields.notIncluded && fields.notIncluded?.length > 0 && (
                        <Box
                            boxStyles={{
                                opacity: 0.3,
                                width: '100%',
                            }}
                        >
                            <OrbitBulletList
                                variant={
                                    variation === 'Default'
                                        ? 'twocolumns'
                                        : 'column'
                                }
                                color={fields.sliceColor}
                            >
                                {fields.notIncluded.map((item, index) => {
                                    if (item !== '')
                                        return (
                                            <Bullet key={index}>{item}</Bullet>
                                        )
                                    return null
                                })}
                            </OrbitBulletList>
                        </Box>
                    )}
                </Box>
                {fields.buttonLink && fields.price && (
                    <Box
                        justify={'flexStart'}
                        alignSelf={'flexStart'}
                        display={variation == 'Default' ? 'block' : 'none'}
                        marginTop={48}
                    >
                        <Button
                            size='large'
                            href={fields.buttonLink}
                            color={fields.sliceColor}
                            variant={
                                fields.buttonLabel && fields.backgroundFilled
                                    ? 'ghost'
                                    : fields.buttonLabel &&
                                      !fields.backgroundFilled
                                    ? 'filled'
                                    : 'icon'
                            }
                            icon={fields.buttonIcon}
                            ariaLabel={
                                fields.buttonLabel
                                    ? undefined
                                    : fields.buttonIcon
                            }
                            newTab={fields.openNewTab}
                        >
                            {fields.buttonLabel}
                        </Button>
                    </Box>
                )}
            </Box>
        </Box>
    )
}

const SmallCard: React.FC<PriceModuleFields> = ({ fields, variation }) => {
    const { width } = useWindowSize()

    return (
        <Box flexDirection="row" extraClassName={styles.priceModule({ oneCard: false })}>
            <Box
                flexDirection="row"
                extraClassName={styles.smallCard}
                justify={'spaceBetween'}
                boxStyles={{
                    width: '100%',
                    background: fields.backgroundColor,
                }}
            >
                <Box
                    flexDirection='column'
                    align="stretch"
                    marginTop={width <= BreakPoints.mobile ? 0 : 24}
                >
                    <Box alignSelf={'center'} flexDirection="column" align='center'>
                        <Box
                            flexDirection="column"
                            align={'center'}
                            paddingBottom={variation === 'Mini' ? 0 : 48}
                        >
                            {!fields.price? (
                                <Box gap='0.5rem' flexDirection='column' align='center' aria-label="Hafa samband fyrir verð">
                                    <HeadingContainer
                                        variant="center"
                                        tagline={fields.tagline || undefined}
                                        title={fields.price || undefined}
                                        color={fields.sliceColor}
                                        size="xlarge"
                                        gap="0.5rem"
                                        pb="0rem"
                                    />
                                    <Icon icon={'Chatbubbles'} size="xlarge" />   
                                </Box>
                            ): 
                            <HeadingContainer
                                variant="center"
                                tagline={fields.tagline || undefined}
                                title={fields.price || undefined}
                                color={fields.sliceColor}
                                size="xlarge"
                                gap="0.5rem"
                                pb="0rem"
                            />}
                   
                            <p className={styles.priceSubStyle}>
                                {fields.text}
                            </p>
                        </Box>
                        
                        <Box
                        alignSelf={'flexStart'}
                        flexDirection={'column'}
                        boxStyles={{
                            flexFlow: 'wrap',
                            marginTop: 0,
                        }}
                        >
                        {variation === 'Default' && (
                            <Box
                                boxStyles={{
                                    flexDirection: 'column',
                                    marginBottom:
                                        fields.included || fields.notIncluded
                                            ? '3.5rem'
                                            : 0,
                                }}
                                gap="1.5rem"
                            >
                                <Box>
                                    {fields.included && (
                                        <OrbitBulletList
                                            variant={'column'}
                                            color={fields.sliceColor}
                                            bulletPosition="top"
                                        >
                                            {fields.included.map(
                                                (item, index) => (
                                                    <Bullet key={index}>
                                                        {item}
                                                    </Bullet>
                                                )
                                            )}
                                        </OrbitBulletList>
                                    )}
                                </Box>

                                {fields.notIncluded &&
                                    fields.notIncluded?.length > 0 && (
                                        <Box
                                            boxStyles={{
                                                opacity: 0.3,
                                            }}
                                        >
                                            <OrbitBulletList
                                                variant={'column'}
                                                color={fields.sliceColor}
                                                bulletPosition="top"
                                            >
                                                {fields.notIncluded.map(
                                                    (item, index) => {
                                                        if (item !== '')
                                                            return (
                                                                <Bullet
                                                                    key={index}
                                                                >
                                                                    {item}
                                                                </Bullet>
                                                            )
                                                        return null
                                                    }
                                                )}
                                            </OrbitBulletList>
                                        </Box>
                                    )}
                            </Box>
                        )}
                    </Box>
                        <Box
                            alignSelf="center"
                            marginTop={24}
                            marginBottom={!fields.price ? 24 : 0}
                            display={
                                variation == 'Mini' || !fields.price
                                    ? 'block'
                                    : 'none'
                            }
                        >
                            {!fields.price && fields.buttonLink && (
                                <Box paddingBottom={36}>
                                    <Button
                                        size='large'
                                        href={fields.buttonLink}
                                        color={fields.sliceColor}
                                        variant={
                                            fields.buttonLabel &&
                                            fields.backgroundFilled
                                                ? 'ghost'
                                                : fields.buttonLabel &&
                                                  !fields.backgroundFilled
                                                ? 'filled'
                                                : 'icon'
                                        }
                                        icon={fields.buttonIcon}
                                        ariaLabel={
                                            fields.buttonLabel
                                                ? undefined
                                                : fields.buttonIcon
                                        }
                                        newTab={fields.openNewTab}
                                    >
                                        {fields.buttonLabel}
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    </Box>

                    <Box
                        alignSelf={'flexStart'}
                        flexDirection={'column'}
                        boxStyles={{
                            flexFlow: 'wrap',
                            marginTop: 0,
                        }}
                    >
                        {variation == 'Mini' && (
                            <Box
                                boxStyles={{
                                    flexDirection: 'column',
                                    marginBottom:
                                        fields.included || fields.notIncluded
                                            ? '3.5rem'
                                            : 0,
                                }}
                                    gap="1.5rem"
                            >
                                <Box>
                                    {fields.included && (
                                        <OrbitBulletList
                                            variant={'column'}
                                            color={fields.sliceColor}
                                            bulletPosition="top"
                                        >
                                            {fields.included.map(
                                                (item, index) => (
                                                    <Bullet key={index}>
                                                        {item}
                                                    </Bullet>
                                                )
                                            )}
                                        </OrbitBulletList>
                                    )}
                                </Box>

                                {fields.notIncluded &&
                                    fields.notIncluded?.length > 0 && (
                                        <Box
                                            boxStyles={{
                                                opacity: 0.3,
                                            }}
                                        >
                                            <OrbitBulletList
                                                variant={'column'}
                                                color={fields.sliceColor}
                                                bulletPosition="top"
                                            >
                                                {fields.notIncluded.map(
                                                    (item, index) => {
                                                        if (item !== '')
                                                            return (
                                                                <Bullet
                                                                    key={index}
                                                                >
                                                                    {item}
                                                                </Bullet>
                                                            )
                                                        return null
                                                    }
                                                )}
                                            </OrbitBulletList>
                                        </Box>
                                    )}
                            </Box>
                        )}
                    </Box>
                </Box>
                {fields.buttonLink && fields.price && (
                    <Box
                        justify={'center'}
                        alignSelf={'center'}
                        // marginTop={width <= BreakPoints.mobile ? 0 : 24}
                        marginBottom={36}
                    >
                        <Button
                            size='large'
                            href={fields.buttonLink}
                            color={fields.sliceColor}
                            variant={
                                fields.buttonLabel && fields.backgroundFilled
                                    ? 'ghost'
                                    : fields.buttonLabel &&
                                      !fields.backgroundFilled
                                    ? 'filled'
                                    : 'icon'
                            }
                            icon={fields.buttonIcon}
                            ariaLabel={
                                fields.buttonLabel
                                    ? undefined
                                    : fields.buttonIcon
                            }
                            newTab={fields.openNewTab}
                        >
                            {fields.buttonLabel}
                        </Button>
                    </Box>
                )}
            </Box>
        </Box>
    )
}
