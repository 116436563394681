import {
    parsePrismicBackgroundColor,
    parsePrismicDarkColor,
    parsePrismicHeadingColor,
    parsePrismicHighlightColor,
    parsePrismicLightBackgroundColor,
    parsePrismicTextCardBackgroundColor,
    parseSliceSpace,
} from '@orbit/prismic'

import {
    Box,
    Container,
    OrbitTextCard,
    Text,
    HeadingContainer,
} from '@orbit/ui/core'
import { BreakPoints, theme } from '@orbit/ui/themes'
import { PrismicRichText } from '@prismicio/react'
import { useState, useEffect } from 'react'
import { useWindowSize } from 'react-use'
import * as styles from '../MiniTextCards.css'
import {
    TTextCardsContext,
    TTextCardsItemsDefault,
    TTextCardsSlices,
} from '../TextCards.types'
import { miniItemsLength } from '../textCardUtils'

export const MiniTextCards = (
    slice: TTextCardsSlices,
    context: TTextCardsContext
) => {
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        if (width < BreakPoints.tabletSmall) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    return (
        <Box
            anchorid={slice.primary.anchor || undefined}
            paddingTop={parseSliceSpace(context.type, isMobile)}
            paddingBottom={parseSliceSpace(context.type, isMobile)}
            flexDirection="column"
            boxStyles={{
                alignItems: 'center',
                backgroundColor: slice.primary.backgroundfilled
                    ? parsePrismicBackgroundColor(slice.primary.slicecolor)
                    : theme.colors.neutral[100],
            }}
        >
            <Container>
                <Box
                    flexDirection="column"
                    align={slice.primary.textposition ? 'center' : 'flexStart'}
                >
                    <HeadingContainer
                        variant={
                            slice.primary.textposition || isMobile
                                ? 'center'
                                : 'left'
                        }
                        tagline={slice.primary.tagline || undefined}
                        title={slice.primary.title || undefined}
                        color={parsePrismicHeadingColor(
                            slice.primary.slicecolor
                        )}
                        pb={slice.primary.text ? '1.5rem' : undefined}
                    />

                    <Box
                        boxStyles={{
                            maxWidth: '53.5rem',
                            paddingBottom: '2.5rem',
                        }}
                    >
                        {slice.primary.text && (
                            <PrismicRichText
                                field={slice.primary.text}
                                components={{
                                    paragraph: ({ children }) => (
                                        <Text
                                            textAlign={
                                                slice.primary.textposition
                                                    ? 'center'
                                                    : 'start'
                                            }
                                            my={0}
                                            size="large"
                                        >
                                            {children}
                                        </Text>
                                    ),
                                    hyperlink: ({ text, node }) => (
                                        <a
                                            style={{
                                                color: parsePrismicDarkColor(
                                                    slice.primary.slicecolor
                                                ),
                                                fontWeight: 600,
                                                textDecoration: 'none',
                                            }}
                                            href={node.data.url}
                                            target={
                                                node.data.link_type === 'Web'
                                                    ? node.data.target
                                                    : ''
                                            }
                                            rel="noopener noreferrer"
                                        >
                                            {text}
                                        </a>
                                    ),
                                }}
                            />
                        )}
                    </Box>
                </Box>
                <Box
                    extraClassName={styles.gridWrapper({
                        size: miniItemsLength(slice.items.length),
                    })}
                    boxStyles={{
                        gridAutoRows:
                            slice.primary.height === 'auto' ? 'auto' : '1fr',
                    }}
                >
                    {slice.items?.map(
                        (item: TTextCardsItemsDefault, index: number) => {
                            return (
                                <Box
                                    key={'textcards-' + index}
                                    boxStyles={{
                                        height:
                                            slice.primary.height === 'auto'
                                                ? 'auto'
                                                : '100%',
                                    }}
                                >
                                    <OrbitTextCard
                                        textCardColor={parsePrismicTextCardBackgroundColor(
                                            slice.primary.slicecolor
                                        )}
                                        isWhite={
                                            slice.primary.slicecolor === 'White'
                                                ? true
                                                : false
                                        }
                                        title={
                                            item.cardtitle ? item.cardtitle : ''
                                        }
                                        content=""
                                        color={
                                            slice.primary.backgroundfilled
                                                ? 'white'
                                                : slice.primary.slicecolor
                                        }
                                        link={item.buttonlink.url}
                                        linkText={
                                            item.buttonlabel
                                                ? item.buttonlabel
                                                : ''
                                        }
                                        linkType={slice.primary.buttonvariation}
                                        backgroundFilled={
                                            slice.primary.backgroundfilled
                                        }
                                        displayButton={
                                            slice.primary.buttonvariation !==
                                            'None'
                                                ? true
                                                : false
                                        }
                                        buttonTextColor={parsePrismicLightBackgroundColor(
                                            slice.primary.slicecolor
                                        )}
                                        buttonColor={parsePrismicHighlightColor(
                                            slice.primary.slicecolor
                                        )}
                                        icon={false}
                                        headingLevel={
                                            slice.primary.tagline &&
                                            slice.primary.title
                                                ? 'h4'
                                                : !slice.primary.title &&
                                                  !slice.primary.tagline
                                                ? 'h2'
                                                : 'h3'
                                        }
                                        newTab={item.openNewTab}
                                    />
                                </Box>
                            )
                        }
                    )}
                </Box>
            </Container>
        </Box>
    )
}

export default MiniTextCards
