import { useEffect, useMemo, useState } from 'react'
import {
    currencyFormatter,
    parsePrismicBackgroundColor,
    parsePrismicHeadingColor,
} from '@orbit/prismic'
import {
    Box,
    Button,
    Container,
    HeadingContainer,
    MotionBox,
    Pagination,
    ProductCard,
} from '@orbit/ui/core'
import { BreakPoints } from '@orbit/ui/themes'
import moment from 'moment'
import { useWindowSize } from 'react-use'
import { TProductCarouselSliceDefault } from '../ProductCarousel.types'
import { cardRevealUp } from '@orbit/utils/framer-motion'
import useSwr from 'swr'
import * as styles from '../ProductCarousel.css'
import { productUrlGenerator } from 'libs/shared/prismic/src/lib/utils'
import ReactCarousel, { ButtonGroupProps } from 'react-multi-carousel'

const fetcher = (url: string) => fetch(url).then((res) => res.json())

export interface TProduct {
    id: number
    image: string
    name: string
    priceIncTax: number
    specialPriceIncTax: number
    quantity: number
    sku: string
    custom7: string
}

export const DefaultProductCarousel = (slice: TProductCarouselSliceDefault) => {
    moment.locale('is')
    const [isMobile, setIsMobile] = useState(false)
    const { width } = useWindowSize()

    const [currentPageSize, setCurrentPageSize] = useState<number>(1)

    useEffect(() => {
        if (width < BreakPoints.mobileSmall) {
            return setCurrentPageSize(1)
        }
        if (width < BreakPoints.mobile) {
            setIsMobile(true)
            return setCurrentPageSize(2)
        }
        if (width < BreakPoints.tabletSmall) {
            return setCurrentPageSize(3)
        }
        if (width < BreakPoints.tablet) {
            return setCurrentPageSize(4)
        }
        setCurrentPageSize(5)
    }, [currentPageSize, width])

    const ButtonGroup = ({
        next,
        previous,
        goToSlide,
        total,
        ...rest
    }: ButtonGroupProps & { total: number }) => {
        const { carouselState } = rest
        const { currentSlide } = carouselState || { currentSlide: 0 }

        return (
            <div
                // style={paginationStyle && paginationStyle}
                className={styles.sliderDots}
            >
                <Pagination
                    currentPage={Math.ceil(currentSlide / currentPageSize + 1)}
                    variant={'dots'}
                    color={'primary'}
                    total={total}
                    pageSize={currentPageSize}
                    onPageChange={(page) => {
                        goToSlide && goToSlide((page - 1) * currentPageSize)
                    }}
                />
            </div>
        )
    }

    const responsive = useMemo(() => {
        return {
            mobileSmall: {
                breakpoint: { max: BreakPoints.mobileSmall, min: 0 },
                items: 1,
                slidesToSlide: currentPageSize,
                partialVisibilityGutter: 20,
            },
            mobile: {
                breakpoint: {
                    max: BreakPoints.mobile,
                    min: BreakPoints.mobileSmall,
                },
                items: 2,
                slidesToSlide: currentPageSize,
                partialVisibilityGutter: 20,
            },
            tabletSmall: {
                breakpoint: {
                    max: BreakPoints.tabletSmall,
                    min: BreakPoints.mobile,
                },
                items: 3,
                slidesToSlide: currentPageSize,
                partialVisibilityGutter: 0,
            },
            tablet: {
                breakpoint: {
                    max: BreakPoints.tablet,
                    min: BreakPoints.tabletSmall,
                },
                items: 4,
                slidesToSlide: currentPageSize,
                partialVisibilityGutter: 10,
            },

            medium: {
                breakpoint: {
                    max: BreakPoints.medium,
                    min: BreakPoints.tablet,
                },
                items: 4,
                slidesToSlide: currentPageSize,
                partialVisibilityGutter: 10,
            },
            desktop: {
                breakpoint: { max: 9999, min: BreakPoints.medium },
                items: 5,
                slidesToSlide: currentPageSize,
            },
        }
    }, [currentPageSize])

    useEffect(() => {
        if (width < BreakPoints.mobileSmall) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    const { data: products } = useSwr(
        `/api/verslun/products?prods=${slice.primary.productGroup}`,
        fetcher
    )

    return (
        <Box
            anchorid={slice.primary.anchor || undefined}
            boxStyles={{
                backgroundColor: parsePrismicBackgroundColor(
                    slice.primary.sliceColor || 'Blue'
                ),
            }}
            paddingTop={isMobile ? 40 : 80}
            paddingBottom={isMobile ? 40 : 80}
        >
            <Container>
                <Box justify="center" align="center" flexDirection="column">
                    <HeadingContainer
                        variant="center"
                        tagline={slice.primary.tagline || undefined}
                        title={slice.primary.title || undefined}
                        color={parsePrismicHeadingColor(
                            slice.primary.sliceColor || 'Blue'
                        )}
                        pb="4rem"
                    />
                </Box>
                {products && (
                    <Box extraClassName={styles.carouselBox}>
                        <Box
                            width="full"
                            position="relative"
                            paddingBottom={32}
                        >
                            <ReactCarousel
                                additionalTransfrom={0}
                                arrows={false}
                                centerMode={false}
                                draggable={true}
                                slidesToSlide={currentPageSize}
                                showDots={false}
                                renderButtonGroupOutside={true}
                                deviceType={'mobile'}
                                ssr={true}
                                partialVisible
                                infinite={false}
                                autoPlay={false}
                                responsive={responsive}
                                itemClass={styles.itemClass}
                                containerClass={styles.carouselContainer}
                                sliderClass={styles.carouselSlider({
                                    onteitem: products.length === 1,
                                })}
                                customButtonGroup={
                                    <ButtonGroup total={products.length} />
                                }
                            >
                                {products.map(
                                    (product: TProduct, i: number) => (
                                        <MotionBox
                                            extraClassName={styles.itemCardBox}
                                            key={'also_viewed__' + i}
                                            initial={
                                                isMobile ? 'visible' : 'hidden'
                                            }
                                            viewport={{
                                                once: true,
                                            }}
                                            whileInView="visible"
                                            transition={{
                                                duration: 0.6,
                                                delay: 0.15 * ((i % 5) + 1),
                                            }}
                                            variants={cardRevealUp}
                                        >
                                            <ProductCard
                                                key={'product_card_' + i}
                                                cardLink={`/testing-verslun-slices/${productUrlGenerator(
                                                    product.name,
                                                    product.id,
                                                    products?.selectedCategory &&
                                                        products
                                                            .selectedCategory
                                                            .name,
                                                    products?.selectedCategory
                                                        ?.parent?.name &&
                                                        products
                                                            ?.selectedCategory
                                                            ?.parent?.name,
                                                    products?.selectedCategory
                                                        ?.parent?.parent &&
                                                        products
                                                            .selectedCategory
                                                            .parent.parent.name
                                                )}`}
                                                onClickButton={function (): void {
                                                    throw new Error(
                                                        'Function not implemented.'
                                                    )
                                                }}
                                                onClickFave={function (): void {
                                                    throw new Error(
                                                        'Function not implemented.'
                                                    )
                                                }}
                                                productNumber={product.sku}
                                                productDescription={
                                                    product.name
                                                }
                                                productPrice={
                                                    product.specialPriceIncTax
                                                        ? currencyFormatter(
                                                              product.specialPriceIncTax
                                                          )
                                                        : currencyFormatter(
                                                              product.priceIncTax
                                                          )
                                                }
                                                oldPrice={
                                                    product.specialPriceIncTax
                                                        ? currencyFormatter(
                                                              product.priceIncTax
                                                          )
                                                        : ''
                                                }
                                                recommend={
                                                    product.custom7
                                                        ? true
                                                        : false
                                                }
                                                productImage={`https://verslun.origo.is/images/${product.image}`.toString()}
                                                discount={
                                                    product.specialPriceIncTax
                                                        ? '-' +
                                                          Math.round(
                                                              (100 *
                                                                  (product.priceIncTax -
                                                                      product.specialPriceIncTax)) /
                                                                  product.priceIncTax
                                                          ) +
                                                          '%'
                                                        : undefined
                                                }
                                                piecesLeft={
                                                    product.quantity < 6 &&
                                                    product.quantity > 0
                                                        ? product.quantity
                                                        : undefined
                                                }
                                            />
                                        </MotionBox>
                                    )
                                )}
                            </ReactCarousel>
                        </Box>
                    </Box>
                )}

                {slice.primary.buttonLink && (
                    <Box justify="center" paddingTop={isMobile ? 36 : 64}>
                        <Button
                            variant={
                                slice.primary.buttonLabel ? 'ghost' : 'icon'
                            }
                            color={parsePrismicHeadingColor(
                                slice.primary.sliceColor || 'Blue'
                            )}
                            href={slice.primary.buttonLink.url}
                            icon={slice.primary.buttonIcon}
                        >
                            {slice.primary.buttonLabel}
                        </Button>
                    </Box>
                )}
            </Container>
        </Box>
    )
}

export default DefaultProductCarousel
