import {
    parsePrismicHighlightColor,
    parsePrismicLightBackgroundColor,
} from '@orbit/prismic'
import { Box, Icon, iconMap, IconType, Text } from '@orbit/ui/core'
import Link from 'next/link'
import { SolutionLinkWrapper } from '../HeroBanner.css'
import { THeroBannerItemsSolution } from '../HeroBanner.types'

interface PageLinksProps {
    items: THeroBannerItemsSolution[]
    color: string
    isMobile: boolean
    isTablet: boolean
}

export const PageLinks: React.FC<PageLinksProps> = ({
    items,
    color,
    isMobile,
    isTablet,
}) => {
    return (
        <Box
            wrap="wrap"
            boxStyles={{
                backgroundColor: parsePrismicLightBackgroundColor(color),
            }}
            padding={2}
            marginTop={isTablet ? 24 : 48}
        >
            {items.map((item, index) => {
                return item.link.url ? (
                    <Box
                        key={`hero-item-${index}`}
                        width={isMobile ? 'full' : 'half'}
                        align="center"
                        padding={2}
                        extraClassName={SolutionLinkWrapper}
                    >
                        <Link scroll={false} href={item.link.url}>
                            <a
                                href={item.link.url}
                                style={{
                                    textDecoration: 'none',
                                    display: 'flex',
                                }}
                            >
                                {iconMap(item.logo as IconType) !==
                                    undefined && (
                                    <Box paddingRight={8}>
                                        <Icon
                                            icon={item.logo as IconType}
                                            color={parsePrismicHighlightColor(
                                                color
                                            )}
                                            size="medium"
                                        />
                                    </Box>
                                )}

                                <Text
                                    my={0}
                                    style={{
                                        fontWeight: '600',
                                    }}
                                >
                                    {item.linkTitle}
                                </Text>
                            </a>
                        </Link>
                    </Box>
                ) : (
                    <Box
                        key={`hero-item-${index}`}
                        width={isMobile ? 'full' : 'half'}
                        align="center"
                        padding={2}
                        extraClassName={SolutionLinkWrapper}
                    >
                        {iconMap(item.logo as IconType) !== undefined && (
                            <Box paddingRight={8}>
                                <Icon
                                    icon={item.logo as IconType}
                                    color={parsePrismicHighlightColor(color)}
                                    size="medium"
                                />
                            </Box>
                        )}
                        <Text my={0} style={{ fontWeight: '600' }}>
                            {item.linkTitle}
                        </Text>
                    </Box>
                )
            })}
        </Box>
    )
}
