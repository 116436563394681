// This file is generated by Slice Machine. Update with care!

import AdvertisingCards from './AdvertisingCards';
import Agenda from './Agenda';
import Airtable from './Airtable';
import AnnualReports from './AnnualReports';
import BulletList from './BulletList';
import Button from './Button';
import ContactForm from './ContactForm';
import CustomerStory from './CustomerStory';
import EventsOverview from './EventsOverview';
import Faq from './Faq';
import FooterItem from './FooterItem';
import FoxwayCalculator from './FoxwayCalculator';
import HeroBanner from './HeroBanner';
import ImageGallery from './ImageGallery';
import ImageWithText from './ImageWithText';
import Jobs from './Jobs';
import LogoCards from './LogoCards';
import MenuItem from './MenuItem';
import NewsModule from './NewsModule';
import Overview from './Overview';
import PreFooter from './PreFooter';
import PriceModule from './PriceModule';
import ProductCarousel from './ProductCarousel';
import ProductCategories from './ProductCategories';
import QuoteBlock from './QuoteBlock';
import RelatedContent from './RelatedContent';
import Shortcuts from './Shortcuts';
import SolutionsOverview from './SolutionsOverview';
import Speakers from './Speakers';
import StaffSearch from './StaffSearch';
import TableOfContents from './TableOfContents';
import TextBlock from './TextBlock';
import TextCards from './TextCards';
import Timeline from './Timeline';
import TrustSymbols from './TrustSymbols';
import Video from './Video';

export {
	AdvertisingCards,
	Agenda,
	Airtable,
	AnnualReports,
	BulletList,
	Button,
	ContactForm,
	CustomerStory,
	EventsOverview,
	Faq,
	FooterItem,
	FoxwayCalculator,
	HeroBanner,
	ImageGallery,
	ImageWithText,
	Jobs,
	LogoCards,
	MenuItem,
	NewsModule,
	Overview,
	PreFooter,
	PriceModule,
	ProductCarousel,
	ProductCategories,
	QuoteBlock,
	RelatedContent,
	Shortcuts,
	SolutionsOverview,
	Speakers,
	StaffSearch,
	TableOfContents,
	TextBlock,
	TextCards,
	Timeline,
	TrustSymbols,
	Video,
};

export const components = {
	advertising_cards: AdvertisingCards,
	agenda: Agenda,
	airtable: Airtable,
	annual_reports: AnnualReports,
	bullet_list: BulletList,
	button: Button,
	contact_form: ContactForm,
	customery_story: CustomerStory,
	events_overview: EventsOverview,
	faq: Faq,
	footer_item: FooterItem,
	foxway_calculator: FoxwayCalculator,
	hero_banner: HeroBanner,
	image_gallery: ImageGallery,
	image_with_text: ImageWithText,
	jobs: Jobs,
	logo_cards: LogoCards,
	menu_item: MenuItem,
	news_module: NewsModule,
	overview: Overview,
	mailing_list: PreFooter,
	price_module: PriceModule,
	product_carousel: ProductCarousel,
	product_categories: ProductCategories,
	quote_block: QuoteBlock,
	related_content: RelatedContent,
	shortcuts: Shortcuts,
	solutions_overview: SolutionsOverview,
	speakers: Speakers,
	staff_search: StaffSearch,
	table_of_contents: TableOfContents,
	text_block: TextBlock,
	text_card: TextCards,
	timeline: Timeline,
	logo_slider: TrustSymbols,
	video: Video,
};
