import { Pagination as PaginationComponent } from '@orbit/ui/core'

interface AlgoliaPaginationProps {
    currentRefinement: number
    nbPages: number
    refine: (...args: unknown[]) => unknown
    count: number
    scrollToTopOfSolutionsSection: () => void
    siblings: number
}

export const AlgoliaPagination = ({
    currentRefinement,
    nbPages,
    refine,
    count,
    scrollToTopOfSolutionsSection,
    siblings,
}: AlgoliaPaginationProps) => {
    return (
        <PaginationComponent
            currentPage={currentRefinement}
            variant="numbers"
            color="tertiary"
            total={nbPages * count}
            pageSize={count}
            onPageChange={(page: number) => {
                scrollToTopOfSolutionsSection()
                refine(page)
            }}
        />
    )
}
