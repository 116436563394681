import React from 'react'

type MobilemenuContext = {
    selectedMenu: string
    setSelectedMobilemenu: (selectedMenu: string) => void
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
}

export const MobilemenuContext = React.createContext<MobilemenuContext>({
    selectedMenu: '',
    setSelectedMobilemenu: () => undefined,
    isOpen: false,
    setIsOpen: () => undefined,
})

type MobilemenuContextProps = {
    children: React.ReactNode
}

export const MobilemenuContextProvider = (props: MobilemenuContextProps) => {
    const setMobilemenuOpen = (selectedMenu: string) => {
        setMobilemenuState((prev) => ({ ...prev, selectedMenu: selectedMenu }))
    }

    const setIsOpen = (isOpen: boolean) => {
        setMobilemenuState((prev) => ({ ...prev, isOpen: isOpen }))
    }

    const [MobilemenuState, setMobilemenuState] =
        React.useState<MobilemenuContext>({
            selectedMenu: '',
            setSelectedMobilemenu: setMobilemenuOpen,
            isOpen: false,
            setIsOpen: setIsOpen,
        })

    return (
        <MobilemenuContext.Provider value={MobilemenuState}>
            {props.children}
        </MobilemenuContext.Provider>
    )
}
