import { Box } from '../Box'
import Heading from '../Heading/Heading'
import { HeadingTitleStyle, TaglineStyle } from './HeadingContainer.css'

type SubHeading = {
    hasParentTagline: boolean
    hasParentTitle: boolean
}

type HeadingLevel = {
    tagline: 'h2' | 'h3' | 'h4'
    title: 'h2' | 'h3' | 'h4' | 'h5'
}

export interface ContainerProps {
    tagline?: string
    title?: string
    highlightStart?: number
    highlightEnd?: number
    color?: 'primary' | 'secondary' | 'tertiary' | 'accent' | 'gray'
    highlightColor?: string
    variant?: 'left' | 'center'
    size?: 'small' | 'large' | 'xlarge' | 'xsmall'
    gap?: '0.5rem' | '1rem' | '1.5rem' | '2rem' | '2.5rem' | '3rem'
    pb?:
        | '0rem'
        | '1rem'
        | '1.25rem'
        | '1.5rem'
        | '2rem'
        | '2.5rem'
        | '3rem'
        | '3.5rem'
        | '4rem'
    subheading?: SubHeading
}

export const HeadingContainer: React.FC<ContainerProps> = ({
    tagline,
    title,
    highlightStart,
    highlightEnd,
    color = 'primary',
    highlightColor,
    variant = 'center',
    size = 'large',
    gap,
    pb = '2.5rem',
    subheading,
}) => {
    const headingLevel: HeadingLevel =
        subheading !== undefined
            ? {
                  tagline:
                      subheading.hasParentTagline && subheading.hasParentTitle
                          ? 'h4'
                          : subheading.hasParentTagline ||
                            subheading.hasParentTitle
                          ? 'h3'
                          : 'h2',
                  title:
                      subheading.hasParentTagline && subheading && tagline
                          ? 'h5'
                          : (subheading.hasParentTagline &&
                                subheading.hasParentTitle) ||
                            ((subheading.hasParentTitle ||
                                subheading.hasParentTagline) &&
                                tagline)
                          ? 'h4'
                          : subheading.hasParentTagline ||
                            subheading.hasParentTitle ||
                            tagline
                          ? 'h3'
                          : 'h2',
              }
            : {
                  tagline: 'h2',
                  title: tagline ? 'h3' : 'h2',
              }
    return (
        <Box
            boxStyles={pb ? { paddingBottom: pb } : undefined}
            gap={gap ? gap : size === 'small' ? '1rem' : '1.5rem'}
            flexDirection="column"
            align={variant !== 'center' ? 'flexStart' : 'center'}
        >
            {tagline && (
                <Heading
                    type={headingLevel.tagline}
                    className={TaglineStyle({ size: size })}
                    text={tagline}
                    color={color}
                />
            )}
            {title && (
                <Heading
                    className={HeadingTitleStyle({
                        size: subheading !== undefined ? 'small' : size,
                    })}
                    type={headingLevel.title}
                    highlightColor={highlightColor ? highlightColor : undefined}
                    highlightStart={highlightStart && highlightStart - 1}
                    highlightEnd={highlightEnd && highlightEnd - 1}
                    text={title}
                    textAlign={variant}
                />
            )}
        </Box>
    )
}

export default HeadingContainer
