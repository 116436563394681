import { Box, Container, Heading, MotionBox } from '@orbit/ui/core'
import { TTimelineSlice } from '../Timeline.types'
import * as style from '../Timeline.css'
import cn from 'classnames'
import {
    parsePrismicColorName,
    parsePrismicHeadingColor,
    parsePrismicTextCardBackgroundColor,
} from '@orbit/prismic'
import { cardRevealRight } from '@orbit/utils/framer-motion'
import PrismicTextSerializer from '../TextSerializer'
import { BreakPoints } from '@orbit/ui/themes'
import { useState, useEffect } from 'react'
import { useWindowSize } from 'react-use'

export const DefaultTimeline = (slice: TTimelineSlice) => {
    const { items } = slice
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)
    useEffect(() => {
        if (width < BreakPoints.mobile) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    return (
        <Container>
            <Box
                anchorid={slice.primary.anchor || undefined}
                extraClassName={style.defaultTimelineWrapper}
                display="flex"
                flexDirection="column"
                justify="center"
            >
                {items?.map((item, index) => (
                    <MotionBox
                        width="full"
                        align="stretch"
                        extraClassName={cn(
                            style.defaultTimelineItem,
                            style.TimelineSliceColorBorderLeft({
                                colors: parsePrismicColorName(
                                    slice.primary.slicecolor
                                ),
                            })
                        )}
                        key={`${index}-default-timeline`}
                        initial={isMobile ? 'visible' : 'hidden'}
                        viewport={{
                            once: true,
                        }}
                        whileInView="visible"
                        transition={{
                            delay: 0.15 * ((index % 3) + 1),
                            duration: 0.6,
                        }}
                        variants={cardRevealRight}
                    >
                        <Box
                            display="flex"
                            flexDirection="column"
                            extraClassName={cn(
                                style.defaultTimelineContent,
                                style.TimelineSliceColorBorderRight({
                                    colors: parsePrismicColorName(
                                        slice.primary.slicecolor
                                    ),
                                })
                            )}
                        >
                            {item.date && (
                                <Heading
                                    type={'h4'}
                                    text={item.date.toUpperCase()}
                                    color={parsePrismicHeadingColor(
                                        slice.primary.slicecolor
                                    )}
                                    my={0}
                                    paddingBottom={2}
                                    style={{
                                        fontSize: '1rem',
                                        letterSpacing: '0.0625rem',
                                    }}
                                />
                            )}
                            <Heading
                                type={item.date ? 'h5' : 'h4'}
                                text={item.title}
                                my={0}
                                style={{
                                    fontSize: '1.5rem',
                                    letterSpacing: '0.0625rem',
                                    textTransform: 'unset',
                                }}
                            />
                            <PrismicTextSerializer
                                slicecolor={parsePrismicHeadingColor(
                                    slice.primary.slicecolor
                                )}
                                text={item.text}
                            />
                        </Box>
                        <Box
                            extraClassName={style.defaultTimelineImageWrapper}
                            boxStyles={{
                                borderColor:
                                    parsePrismicTextCardBackgroundColor(
                                        slice.primary.slicecolor
                                    ),
                            }}
                        >
                            <img
                                src={item.image.url || ''}
                                alt={item.image.alt || ''}
                                className={style.defaultTimelineImage}
                            />
                        </Box>
                    </MotionBox>
                ))}
            </Box>
        </Container>
    )
}

export default DefaultTimeline
