import { SearchBar } from '@orbit/ui/core'

interface AlgoliaSearchBarProps {
    currentRefinement: string
    refine: (...args: unknown[]) => unknown
    wrapperClassName?: string
    placeholder?: string
}

export const AlgoliaSearchBar: React.FC<AlgoliaSearchBarProps> = ({
    currentRefinement,
    refine,
    wrapperClassName,
    placeholder = 'Leitaðu í lausnunum okkar',
}) => (
    <SearchBar
        value={currentRefinement}
        placeholder={placeholder}
        onChange={(event) => refine(event.currentTarget.value)}
        wrapperClassName={wrapperClassName}
    />
)
