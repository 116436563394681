import { useEffect, useState } from 'react'
import {
    Box,
    Button,
    Container,
    EventsCard,
    HeadingContainer,
} from '@orbit/ui/core'
import { BreakPoints, theme } from '@orbit/ui/themes'
import {
    parsePrismicBackgroundColor,
    parsePrismicHeadingColor,
    parsePrismicHighlightColor,
    parsePrismicLightBackgroundColor,
} from '@orbit/prismic'
import moment from 'moment'
import useSwr from 'swr'
import { useWindowSize } from 'react-use'
import {
    TEventsOverviewContext,
    TEventsOverviewSliceDefault,
} from '../EventsOverview.types'
import NoEvents from '../NoEvents'
import * as styles from '../EventsOverview.css'
import { ImageFieldImage } from '@prismicio/types'

const fetcher = (url: string) => fetch(url).then((res) => res.json())

type TEvent = {
    data: TEventData
    uid: string
    id: string
}

type TEventData = {
    summary: string
    type: string
    image: ImageFieldImage
    datetime: string
    title: string
    availability: 'Full' | 'Available'
    backgroundColor: string
    textColor: string
    headingColor: string
    cardColor: string
    heading: string
    tag: string
    date: string
    link: string
    backgroundfilled: boolean
}

export const DefaultEventsOverview = (
    slice: TEventsOverviewSliceDefault,
    context: TEventsOverviewContext
) => {
    moment.locale('is')
    const [isMobile, setIsMobile] = useState(false)
    const { width } = useWindowSize()
    const [highlightEventsCount, setHiglightEventsCount] = useState<number>(0)

    useEffect(() => {
        if (width < BreakPoints.tablet) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    const { data: events } = useSwr(
        `/api/events/eventsHomepage?count=3&lang=${context.lang}`,
        fetcher
    )

    const allEventsData = events
    useEffect(() => {
        const cardCount = allEventsData?.length
        setHiglightEventsCount(cardCount % 3)
    }, [allEventsData, highlightEventsCount])

    const getCardProps = (event: TEvent, index: number) => {
        const shortSummary = event.data.summary.substring(0, 160) + '...'
        if (allEventsData.length % 3 === 0 && event.data.summary.length > 160) {
            event.data.summary = shortSummary
        } else if (index !== 0 && event.data.summary.length > 160) {
            event.data.summary = shortSummary
        }
        if (highlightEventsCount === 2 && event.data.summary.length > 160) {
            event.data.summary = shortSummary
        }
        const cardColor =
            event.data.type === 'Webcast'
                ? 'Green'
                : event.data.type === 'Course'
                ? 'Orange'
                : 'Blue'
        const cardProps = {
            backgroundColor: parsePrismicBackgroundColor(cardColor),
            textColor: parsePrismicHighlightColor(cardColor),
            headingColor: parsePrismicHighlightColor(cardColor),
            cardColor: parsePrismicLightBackgroundColor(cardColor),
            id: 'event_' + event.id + ' ' + index,
            heading: event.data.title,
            tag: event.data.type,
            date: event.data.datetime,
            image: event.data.image,
            link: event.uid,
            summary: event.data.summary,
            availability: event.data.availability,
            uid: event.uid,
        }

        return cardProps
    }
    if (!events || events?.length === 0) {
        return <NoEvents />
    } else {
        return (
            <Box
                anchorid={slice.primary.anchor || undefined}
                paddingTop={isMobile ? 36 : 80}
                paddingBottom={isMobile ? 36 : 80}
                boxStyles={{
                    backgroundColor: slice.primary.backgroundfilled
                        ? parsePrismicBackgroundColor(slice.primary.slicecolor)
                        : theme.colors.neutral[100],
                }}
                flexDirection={isMobile ? 'column' : 'row'}
            >
                <Container>
                    <Box justify="center">
                        <HeadingContainer
                            variant="center"
                            tagline={slice.primary.tagline || undefined}
                            title={slice.primary.title || undefined}
                            color={parsePrismicHeadingColor(
                                slice.primary.slicecolor
                            )}
                            pb="4rem"
                        />
                    </Box>

                    {allEventsData && highlightEventsCount === 1 && (
                        <Box
                            extraClassName={styles.eventCardWrapper({
                                size: highlightEventsCount,
                            })}
                        >
                            <EventsCard
                                {...getCardProps(allEventsData[0], 0)}
                                size="large"
                                index={0}
                            />
                        </Box>
                    )}

                    {allEventsData && highlightEventsCount === 2 && (
                        <Box
                            extraClassName={styles.eventCardWrapper({
                                size: highlightEventsCount,
                            })}
                        >
                            <EventsCard
                                {...getCardProps(allEventsData[0], 0)}
                                size="medium"
                                index={0}
                            />
                            <EventsCard
                                {...getCardProps(allEventsData[1], 1)}
                                size="medium"
                            />
                        </Box>
                    )}

                    {allEventsData && (
                        <Box
                            extraClassName={styles.eventCardWrapper({
                                size: 3,
                            })}
                        >
                            {Array.isArray(allEventsData) &&
                                allEventsData.map(
                                    (event: TEvent, index: number) => {
                                        if (index >= highlightEventsCount)
                                            return (
                                                <EventsCard
                                                    key={index}
                                                    {...getCardProps(
                                                        event,
                                                        index
                                                    )}
                                                    size="small"
                                                    index={index}
                                                />
                                            )
                                        else return null
                                    }
                                )}
                        </Box>
                    )}
                    {slice.primary.buttonlink && (
                        <Box justify="center" paddingTop={isMobile ? 36 : 64}>
                            <Button
                                variant={
                                    slice.primary.buttonlabel
                                        ? 'filled'
                                        : 'icon'
                                }
                                color={parsePrismicHeadingColor(
                                    slice.primary.slicecolor
                                )}
                                href={slice.primary.buttonlink.url}
                                icon={slice.primary.buttonicon}
                                ariaLabel={
                                    slice.primary.buttonlabel
                                        ? undefined
                                        : slice.primary.buttonicon
                                }
                                newTab={slice.primary.openNewTab}
                            >
                                {slice.primary.buttonlabel}
                            </Button>
                        </Box>
                    )}
                </Container>
            </Box>
        )
    }
}

export default DefaultEventsOverview
