import { useEffect, useState } from 'react'
import {
    parsePrismicHeadingColor,
    parsePrismicHighlightColor,
    parsePrismicBackgroundColor,
    parsePrismicDarkColor,
} from '@orbit/prismic'
import { useWindowSize } from 'react-use'
import { BreakPoints } from '@orbit/ui/themes'
import { TImageWithRepeatableZoneSlice } from '../ImageWithText.types'
import {
    Box,
    Container,
    Text,
    OrbitImage,
    HeadingContainer,
    MotionBox,
} from '@orbit/ui/core'
import { PrismicRichText } from '@prismicio/react'

import * as styles from './ImageWithRepeatableZone.css'
import { cardRevealUp } from '@orbit/utils/framer-motion'

export const ImageWithRepeatableZone = (
    slice: TImageWithRepeatableZoneSlice
) => {
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        if (width < BreakPoints.tabletSmall) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    return (
        <MotionBox
            anchorid={slice.primary.anchor || undefined}
            justify="center"
            paddingBottom={isMobile ? 36 : 80}
            paddingTop={isMobile ? 36 : 80}
            boxStyles={{ overflow: 'clip' }}
            initial={isMobile ? 'visible' : 'hidden'}
            viewport={{
                once: true,
            }}
            whileInView="visible"
            transition={{
                duration: 0.6,
                delay: 0.3,
            }}
            variants={cardRevealUp}
        >
            <Container>
                <Box width={'full'}>
                    <Box
                        boxStyles={{ overflow: 'hidden' }}
                        paddingBottom={isMobile ? 8 : 0}
                        align={'flexStart'}
                        width={isMobile ? 'full' : 'half'}
                        flexDirection={'column'}
                    >
                        <Box
                            width={'full'}
                            flexDirection="column"
                            justify={isMobile ? 'center' : 'flexStart'}
                            align={isMobile ? 'center' : 'flexStart'}
                        >
                            <HeadingContainer
                                title={slice.primary.title || undefined}
                                tagline={slice.primary.tagline || undefined}
                                highlightColor={parsePrismicHighlightColor(
                                    slice.primary.slicecolor
                                )}
                                highlightStart={
                                    slice.primary.highlightstart &&
                                    slice.primary.highlightstart - 1
                                }
                                highlightEnd={
                                    slice.primary.highlightend &&
                                    slice.primary.highlightend - 1
                                }
                                variant={isMobile ? 'center' : 'left'}
                                gap="1rem"
                                color={parsePrismicHeadingColor(
                                    slice.primary.slicecolor
                                )}
                                pb="1rem"
                            />
                            {slice.primary.subtitle && (
                                <Text
                                    style={{
                                        backgroundColor:
                                            parsePrismicBackgroundColor(
                                                slice.primary.slicecolor
                                            ),
                                        color: parsePrismicHighlightColor(
                                            slice.primary.slicecolor
                                        ),
                                        fontWeight: 600,
                                        letterSpacing: '0.125rem',
                                        textTransform: 'uppercase',
                                        padding: '0.5rem 1rem 0.5rem 1rem',
                                        marginTop: 0,
                                        marginBottom: '2.5rem',
                                    }}
                                >
                                    {slice.primary.subtitle}
                                </Text>
                            )}
                        </Box>
                        <Box
                            width={isMobile ? 'full' : 'half'}
                            justify={isMobile ? 'center' : 'flexEnd'}
                            marginBottom={isMobile ? 36 : 0}
                            display={isMobile ? 'flex' : 'none'}
                        >
                            <Box
                                boxStyles={{ position: 'relative' }}
                                extraClassName={styles.imageWrapper({
                                    color: parsePrismicHeadingColor(
                                        slice.primary.slicecolor
                                    ),
                                })}
                                width={80}
                            >
                                {slice.primary.image && (
                                    <Box
                                        marginTop={16}
                                        width="full"
                                        boxStyles={{
                                            width: '100%',
                                            height: '100%',
                                        }}
                                    >
                                        <OrbitImage
                                            containerclass={
                                                styles.imageWrappers
                                            }
                                            src={slice.primary.image.url || ' '}
                                            alt={slice.primary.image.alt || ''}
                                            layout="fill"
                                        />
                                    </Box>
                                )}
                            </Box>
                        </Box>
                        <Box
                            flexDirection={'column'}
                            boxStyles={{
                                display: 'grid',
                                width: '100%',
                                gridGap: '0.5rem',
                                gridTemplateRows: `repeat(${slice.items.length}, 1fr)`,
                                gridTemplateColumns: 'repeat(1, 1fr)',
                            }}
                        >
                            {slice.items.map((item, i) => (
                                <Box
                                    key={i}
                                    flexDirection={'column'}
                                    boxStyles={{
                                        backgroundColor:
                                            parsePrismicBackgroundColor(
                                                slice.primary.slicecolor
                                            ),
                                        padding: '1rem 2rem 1rem 2rem',
                                    }}
                                >
                                    <Text
                                        size={'small'}
                                        my={0}
                                        style={{
                                            lineHeight: '1.375rem',
                                        }}
                                    >
                                        {item.jobtitle}
                                    </Text>
                                    <Text
                                        my={0}
                                        style={{
                                            fontWeight: 600,
                                        }}
                                    >
                                        {item.name}
                                    </Text>
                                    <Text
                                        size={'small'}
                                        my={0}
                                        style={{
                                            lineHeight: '1.375rem',
                                        }}
                                    >
                                        {item.email}
                                    </Text>
                                </Box>
                            ))}
                        </Box>

                        <Box>
                            {slice.primary.text && (
                                <PrismicRichText
                                    field={slice.primary.text}
                                    components={{
                                        paragraph: ({ children }) => (
                                            <Text size="medium">
                                                {children}
                                            </Text>
                                        ),
                                        hyperlink: ({ text, node }) => (
                                            <a
                                                style={{
                                                    color: parsePrismicDarkColor(
                                                        slice.primary.slicecolor
                                                    ),
                                                    fontWeight: 600,
                                                    textDecoration: 'none',
                                                }}
                                                href={node.data.url}
                                                target={
                                                    node.data.link_type ===
                                                    'Web'
                                                        ? node.data.target
                                                        : ''
                                                }
                                                rel="noopener noreferrer"
                                            >
                                                {text}
                                            </a>
                                        ),
                                    }}
                                />
                            )}
                        </Box>
                    </Box>
                    <Box
                        width={isMobile ? 'full' : 'half'}
                        justify={isMobile ? 'center' : 'flexEnd'}
                        marginBottom={isMobile ? 36 : 0}
                        display={isMobile ? 'none' : 'flex'}
                    >
                        <Box
                            boxStyles={{ position: 'relative' }}
                            extraClassName={styles.imageWrapper({
                                color: parsePrismicHeadingColor(
                                    slice.primary.slicecolor
                                ),
                            })}
                            width={80}
                            justify="flexEnd"
                        >
                            {slice.primary.image && (
                                <Box
                                    marginTop={16}
                                    width="full"
                                    boxStyles={{
                                        width: '100%',
                                        height: '100%',
                                    }}
                                >
                                    <OrbitImage
                                        containerclass={styles.imageWrappers}
                                        src={slice.primary.image.url || ''}
                                        alt={slice.primary.image.alt || ''}
                                        layout="fill"
                                    />
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Container>
        </MotionBox>
    )
}

export default ImageWithRepeatableZone
