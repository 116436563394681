import { BreakPoints } from '@orbit/ui/themes'
import { useContext, useEffect, useState } from 'react'
import { useWindowSize } from 'react-use'
import { Logo } from '@orbit/assets'
import { Box, Button, Container } from '@orbit/ui/core'
import { HeaderContext } from '@oribt/context'
import { SearchInput } from './SearchInput'
import { Navigation } from '../Navigation'
import * as styles from './Header.css'
import { NavigationProps } from './Header.types'
import { parsePrismicLink } from '@orbit/prismic'
import Link from 'next/link'
import { useRouter } from 'next/router'
export interface HeaderProps {
    navigation: NavigationProps
    lang?: 'is' | 'en-gb'
}

export const Header: React.FC<HeaderProps> = ({ navigation, lang = 'is' }) => {
    const { width } = useWindowSize()
    const [renderMobile, setRenderMobile] = useState<boolean>(false)
    const [shouldAnimate, setShouldAnimate] = useState(false)
    const headerContext = useContext(HeaderContext)

    useEffect(() => {
        if (width < BreakPoints.medium) {
            if (!renderMobile) setRenderMobile(true)
            if (!headerContext.animationComplete)
                headerContext.setAnimationComplete(true)
        }
        setRenderMobile(false)
    }, [
        headerContext,
        headerContext.animationComplete,
        headerContext.setAnimationComplete,
        renderMobile,
        width,
    ])

    const router = useRouter()

    useEffect(() => {
        if (router.pathname === '/' || router.pathname === '/english') {
            setShouldAnimate(true)
        }
    }, [router])

    return (
        <header
            className={styles.HeaderStyle({
                notification: headerContext.isNotificationOpen,
            })}
            style={
                !headerContext.headerVisible
                    ? { transform: 'translateY(-100%)' }
                    : undefined
            }
        >
            <Container>
                <Box extraClassName={styles.HeaderWrapper}>
                    <Box extraClassName={styles.HeaderNavigation}>
                        <Box
                            justify="center"
                            align="center"
                            alignSelf="center"
                            extraClassName={
                                shouldAnimate ? styles.LogoAnimation : ''
                            }
                        >
                            <a className="sr-only" href="#main">
                                {lang === 'is'
                                    ? 'Hoppa yfir valmynd'
                                    : 'Skip to content'}
                            </a>
                            <Link
                                href={lang === 'is' ? '/' : '/english'}
                                passHref={true}
                            >
                                <a
                                    href={lang === 'is' ? '/' : '/english'}
                                    aria-label={
                                        lang === 'is'
                                            ? 'Origo forsíða'
                                            : 'Origo homepage'
                                    }
                                >
                                    <Logo
                                        width={renderMobile ? 75 : 132}
                                        height={renderMobile ? 25 : 40}
                                    />
                                </a>
                            </Link>
                        </Box>
                        {navigation && (
                            // TODO Animation hér skemmir position af megamenu
                            <Box
                                width="full"
                                justify="center"
                                extraClassName={`${styles.NavItemStyle} ${
                                    shouldAnimate
                                        ? styles.NavItemStyleAnimation
                                        : ''
                                }`}
                            >
                                <Navigation {...navigation} />
                            </Box>
                        )}
                    </Box>
                    <Box extraClassName={styles.HeaderSearch}>
                        <Box
                            extraClassName={
                                shouldAnimate ? styles.searchAnimation : ''
                            }
                            boxStyles={{ alignSelf: 'stretch' }}
                        >
                            <SearchInput
                                lang={lang ? lang : navigation?.lang}
                            />
                        </Box>
                        <Box gap="1rem">
                            <div
                                style={{
                                    flexGrow: 1,
                                    width: 'auto',
                                    gap: '1rem',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    display: 'flex',
                                }}
                                className={
                                    shouldAnimate ? styles.buttonAnimation : ''
                                }
                                onAnimationEnd={() => {
                                    headerContext.setAnimationComplete(true)
                                }}
                            >
                                <Button
                                    variant="icon"
                                    href={
                                        lang === 'is'
                                            ? '/english'
                                            : navigation?.lang === 'is'
                                            ? '/english'
                                            : '/'
                                    }
                                    color="secondary"
                                    size="small"
                                    icon={
                                        lang === 'is'
                                            ? 'EN'
                                            : navigation?.lang === 'is'
                                            ? 'EN'
                                            : 'IS'
                                    }
                                />
                                {navigation?.buttonLink &&
                                    navigation?.buttonLabel && (
                                        <Button
                                            href={parsePrismicLink(
                                                navigation?.buttonLink
                                            )}
                                            variant="filled"
                                            size="small"
                                            color="tertiary"
                                        >
                                            {navigation?.buttonLabel}
                                        </Button>
                                    )}
                            </div>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </header>
    )
}

export default Header
