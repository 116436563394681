import { PrismicRichText } from '@prismicio/react'
import { TFooterItemSlice } from '../FooterItem.types'
import * as styles from '../FooterItem.css'
import { theme } from '@orbit/ui/themes'
import { TFooterItemItems } from '../FooterItem.types'
import { Box, Text } from '@orbit/ui/core'
import Link from 'next/link'

export const DefaultFooterItem = (slice: TFooterItemSlice, i: number) => {
    const parseNumber = (number: number) => {
        switch (number) {
            case 0:
                return 0
            case 1:
                return 1
            case 2:
                return 2
            case 3:
                return 3
            case 4:
                return 4
            case 5:
                return 5
            case 6:
                return 6
            case 7:
                return 7
            default:
                return 7
        }
    }

    return (
        <Box
            key={slice.primary.listtitle}
            extraClassName={styles.footerItemWrapper({
                index: parseNumber(i),
            })}
        >
            <Box justify="center" flexDirection={'column'}>
                <Box flexDirection={'column'} boxStyles={{ minHeight: '3rem' }}>
                    <Text
                        my={slice.primary.subtitle ? 0 : 1}
                        style={{
                            fontSize: '1.25rem',
                            fontWeight: '600',
                            color: theme.colors.neutral[900],
                        }}
                    >
                        {slice.primary.listtitle && slice.primary.listtitle}
                    </Text>

                    <Text
                        my={0}
                        style={{
                            fontSize: '0.75rem',
                            fontWeight: '600',
                            color: theme.colors.neutral[900],
                        }}
                    >
                        {slice.primary.subtitle ? slice.primary.subtitle : ''}
                    </Text>
                </Box>

                <Box flexDirection="column">
                    {slice.items.map((field: TFooterItemItems, k: number) => {
                        return (
                            <Box key={k + '_footer_item'}>
                                {!field.footerlink.url ? (
                                    <PrismicRichText
                                        field={field.footertext}
                                        components={{
                                            hyperlink: ({ text, node }) => {
                                                return node.data.url ? (
                                                    <Link href={node.data.url}>
                                                        <a
                                                            href={node.data.url}
                                                            className={`${styles.footerLinksText} ${styles.footerlinks}`}
                                                            target={
                                                                node.data
                                                                    .link_type ===
                                                                'Web'
                                                                    ? node.data
                                                                          .target
                                                                    : ''
                                                            }
                                                            rel="noopener noreferrer"
                                                        >
                                                            {text}
                                                        </a>
                                                    </Link>
                                                ) : null
                                            },
                                        }}
                                    />
                                ) : (
                                    field.footerlink &&
                                    field.footertext && (
                                        <a
                                            className={styles.footerlinks}
                                            href={field.footerlink.url}
                                        >
                                            <PrismicRichText
                                                field={field.footertext}
                                                components={{
                                                    paragraph: ({ text }) =>
                                                        text && (
                                                            <p
                                                                className={
                                                                    styles.footerLinksText
                                                                }
                                                            >
                                                                {text}
                                                            </p>
                                                        ),
                                                }}
                                            />
                                        </a>
                                    )
                                )}
                            </Box>
                        )
                    })}
                </Box>
            </Box>
        </Box>
    )
}
export default DefaultFooterItem
