import {
    parsePrismicBackgroundColor,
    parsePrismicHeadingColor,
} from '@orbit/prismic'
import {
    Box,
    Container,
    HeadingContainer,
    MotionBox,
    Testimonials,
} from '@orbit/ui/core'
import { BreakPoints } from '@orbit/ui/themes'
import { cardRevealUp } from '@orbit/utils/framer-motion'
import { useState, useEffect } from 'react'
import { useWindowSize } from 'react-use'
import { TCustomerStorySliceDefault } from '../CustomerStory.types'

export const DefaultCustomerStory = (slice: TCustomerStorySliceDefault) => {
    const { primary, items } = slice

    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        if (width < BreakPoints.mobile) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    return (
        <Box
            anchorid={slice.primary.anchor || undefined}
            display="block"
            paddingTop={isMobile ? 36 : 48}
            paddingBottom={isMobile ? 36 : 80}
            boxStyles={{
                backgroundColor: primary.backgroundFilled
                    ? parsePrismicBackgroundColor(slice.primary.sliceColor)
                    : 'white',
            }}
        >
            <Container>
                <HeadingContainer
                    tagline={primary.tagline || undefined}
                    title={primary.title || undefined}
                    color={parsePrismicHeadingColor(primary.sliceColor)}
                />

                {items && (
                    <MotionBox
                        initial={isMobile ? 'visible' : 'hidden'}
                        viewport={{
                            once: true,
                        }}
                        whileInView="visible"
                        transition={{
                            delay: 0.15,
                            duration: 0.6,
                        }}
                        variants={cardRevealUp}
                    >
                        <Testimonials
                            testimonials={items}
                            colors={{
                                background: primary.backgroundFilled
                                    ? parsePrismicBackgroundColor(
                                          primary.sliceColor
                                      )
                                    : 'white',
                                pagination: parsePrismicHeadingColor(
                                    primary.sliceColor
                                ),
                                card: {
                                    background: primary.backgroundFilled
                                        ? 'white'
                                        : parsePrismicBackgroundColor(
                                              primary.sliceColor
                                          ),
                                    icon: 'black',
                                    divider: 'black',
                                },
                            }}
                        />
                    </MotionBox>
                )}
            </Container>
        </Box>
    )
}

export default DefaultCustomerStory
