import React from 'react'
import { THeroBannerSlices } from './HeroBanner.types'
import {
    DefaultHeroBanner,
    FrontpageHeroBanner,
    FrontpageLargeImageHeroBanner,
    LargeImageHeroBanner,
    SolutionHeroBanner,
    SolutionLargeImageHeroBanner,
} from './variations'

const HeroBanner: React.FC<{ slice: THeroBannerSlices }> = ({ slice }) => {
    switch (slice.variation) {
        case 'default': {
            return DefaultHeroBanner(slice)
        }
        case 'largeImage': {
            return LargeImageHeroBanner(slice)
        }
        case 'frontpage': {
            return FrontpageHeroBanner(slice)
        }
        case 'frontpageLargeImage': {
            return FrontpageLargeImageHeroBanner(slice)
        }
        case 'solutionLargeImage': {
            return SolutionLargeImageHeroBanner(slice)
        }
        case 'solution': {
            return SolutionHeroBanner(slice)
        }
        default:
            return null
    }
}

export default HeroBanner
