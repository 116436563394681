import React from 'react'
import { DefaultNewsModule, NewsModuleWithTag } from './variations'
import { TNewsModuleContext, TNewsModuleSlices } from './NewsModule.types'

const NewsModule: React.FC<{
    slice: TNewsModuleSlices
    context: TNewsModuleContext
}> = ({ slice, context }) => {
    switch (slice.variation) {
        case 'default': {
            return DefaultNewsModule(slice, context)
        }
        case 'newsModuleWithTag': {
            return NewsModuleWithTag(slice, context)
        }
        default:
            return null
    }
}

export default NewsModule
