import { useEffect, useState } from 'react'
import { BreakPoints } from '@orbit/ui/themes'
import { useWindowSize } from 'react-use'
import {
    Box,
    Container,
    HeadingContainer,
    MotionBox,
    Text,
} from '@orbit/ui/core'
import {
    TContactFormContext,
    TContactFormSliceDefault,
} from '../ContactForm.types'
import {
    parsePrismicBackgroundColor,
    parsePrismicHeadingColor,
    parseSliceSpace,
} from '@orbit/prismic'

import * as styles from '../ContactForm.css'
import { cardRevealUp } from '@orbit/utils/framer-motion'

export const DefaultContactForm = (
    slice: TContactFormSliceDefault,
    context: TContactFormContext
) => {
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(false)
    useEffect(() => {
        if (width < BreakPoints.mobile) {
            return setIsMobile(true)
        }
        setIsMobile(false)
    }, [width])

    useEffect(() => {
        const script = document.createElement('script')
        script.src = 'https://js.hsforms.net/forms/shell.js'
        document.body.appendChild(script)

        script.addEventListener('load', () => {
            if (window && window.hbspt) {
                window.hbspt.forms.create({
                    portalId: '6348411',
                    formId: slice.primary.formid,
                    target: `#hbspot-contact-form-${slice.primary.formid}`,
                })
            }
        })
    }, [slice.primary.formid])

    const eventForm = (formid: string) => {
        return (
            <Box id={'contact-form-slice'}>
                {formid && (
                    <Box width={'full'}>
                        <div
                            id={`hbspot-contact-form-${slice.primary.formid}`}
                            className={`hbspot-contact-form hbspot-contact-form${
                                slice.primary.slicecolor
                            } hbspot-contact-form-label-filled${
                                !slice.primary.backgroundfilled
                                    ? slice.primary.slicecolor
                                    : ''
                            } `}
                        />
                    </Box>
                )}
            </Box>
        )
    }
    return (
        <MotionBox
            anchorid={slice.primary.anchor || undefined}
            paddingTop={parseSliceSpace(context.type, isMobile)}
            paddingBottom={parseSliceSpace(context.type, isMobile)}
            boxStyles={{
                backgroundColor: slice.primary.backgroundfilled
                    ? parsePrismicBackgroundColor(slice.primary.slicecolor)
                    : 'white',
            }}
            justify="spaceAround"
            align="center"
            flexDirection={isMobile ? 'column' : 'rowReverse'}
            initial={isMobile ? 'visible' : 'hidden'}
            viewport={{
                once: true,
            }}
            whileInView="visible"
            transition={{
                delay: 0.15,
                duration: 0.6,
            }}
            variants={cardRevealUp}
        >
            <Container>
                <Box
                    extraClassName={styles.ContactFormContainer}
                    flexDirection="column"
                    align={'center'}
                >
                    <Box
                        flexDirection="column"
                        // paddingLeft={isMobile ? 0 : 36}
                        align={'center'}
                        justify={'center'}
                    >
                        <HeadingContainer
                            title={slice.primary.title}
                            tagline={slice.primary.tagline}
                            color={parsePrismicHeadingColor(
                                slice.primary.slicecolor
                            )}
                            pb={slice.primary.text ? '0rem' : '2.5rem'}
                            gap="1rem"
                            variant={'center'}
                            size="small"
                        />
                        {slice.primary.text && (
                            <Text
                                textClass={styles.textStyling}
                                textAlign={isMobile ? 'center' : 'left'}
                            >
                                {slice.primary.text}
                            </Text>
                        )}
                    </Box>

                    <Box>{eventForm(slice.primary.formid)}</Box>
                </Box>
            </Container>
        </MotionBox>
    )
}

export default DefaultContactForm
