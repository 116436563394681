import { UseLinkState } from './linkContext'

function search(nameKey: number, myArray: any) {
    for (let i = 0; i < myArray.length; i++) {
        if (myArray[i].id === nameKey) {
            return myArray[i]
        }
    }
}

export const linkResolver = (doc: any) => {
    const { allPages, allSolutions, allNewss } = UseLinkState()
    if (!doc || doc.isBroken) {
        return '/404'
    }

    if (doc.type === 'homepage') {
        return doc.lang === 'en-us' ? '/' : `/${doc.lang}`
    }

    if (doc.type === 'page') {
        const searchedData = search(doc.id, allPages)
        if (searchedData && searchedData.slug) {
            return searchedData.slug
        } else {
            return '/not-found'
        }
    }

    if (doc.type === 'news') {
        const searchedData = search(doc.id, allNewss)
        if (searchedData && searchedData.slug) {
            return searchedData.slug
        } else {
            return '/not-found'
        }
    }

    if (doc.type === 'solution') {
        const searchedData = search(doc.id, allSolutions)

        if (searchedData && searchedData.slug) {
            return searchedData.slug
        } else {
            return '/not-found'
        }
    }

    return '/'
}
