import * as styles from './Button.css'
import { ButtonProps } from '../../types'
import cn from 'classnames'
import { Icon } from '../Icons'
import { Box } from '../Box'
import { theme } from '@orbit/ui/themes'
import { useEffect, useState } from 'react'
import { ButtonLink } from './ButtonLink'

export const Button: React.FC<ButtonProps> = (props) => {
    if (props.href)
        return (
            <ButtonLink
                {...props}
                variant={props.variant ? props.variant : 'filled'}
            />
        )
    switch (props.variant) {
        case 'filled':
        case 'ghost':
            return <DefaultButton {...props} />
        case 'text':
            return <TextButton {...props} />
        case 'icon':
            return <IconButton {...props} />
        default:
            return <DefaultButton {...props} />
    }
}

const DefaultButton: React.FC<ButtonProps> = ({
    children,
    size = 'small',
    variant = 'filled',
    color = 'primary',
    icon,
    onClick,
    fullWidth = false,
    disabled = false,
}) => {
    const defaultVariant: 'filled' | 'ghost' =
        variant === 'filled' || variant == 'ghost' ? variant : 'filled'
    const [focused, setFocused] = useState(false)
    const [active, setActive] = useState(false)
    const [hovered, setHovered] = useState(false)

    useEffect(() => {
        const mouseupHandler = () => {
            setHovered(false)
            setActive(false)
        }

        window.addEventListener('mouseup', mouseupHandler)

        return () => {
            window.removeEventListener('mouseup', mouseupHandler)
        }
    }, [])

    return (
        <button
            disabled={disabled}
            onClick={onClick}
            className={cn(
                styles.button({
                    size: size,
                    variant: variant,
                    fullWidth: fullWidth,
                    withIcon: !!icon,
                }),
                (styles.buttonColors[defaultVariant] as Record<string, string>)[
                    color
                ]
            )}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            onMouseDown={() => setActive(true)}
            onMouseUp={() => setActive(false)}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <span className={styles.buttonText}>{children}</span>
            {icon && (
                <Box
                    boxStyles={{ marginLeft: '0.75rem' }}
                    justify="center"
                    align="center"
                >
                    <Icon
                        icon={icon}
                        size={size == 'large' ? 'medium' : 'small'}
                        color={
                            active
                                ? theme.components.core.button.variants[color][
                                      defaultVariant
                                  ].onPressed.iconColor
                                : hovered && variant !== 'filled'
                                ? theme.components.core.button.variants[color]
                                      .icon.onPressed.iconColor
                                : focused
                                ? theme.colors.neutral[100]
                                : theme.components.core.button.variants[color][
                                      defaultVariant
                                  ].default.textColor
                        }
                    />
                </Box>
            )}
        </button>
    )
}

const TextButton: React.FC<ButtonProps> = ({
    children,
    variant = 'text',
    color = 'primary',
    icon,
    onClick,
    disabled = false,
}) => {
    const [focused, setFocused] = useState(false)
    const [active, setActive] = useState(false)
    const [hovered, setHovered] = useState(false)

    useEffect(() => {
        const mouseupHandler = () => {
            setHovered(false)
            setActive(false)
        }

        window.addEventListener('mouseup', mouseupHandler)

        return () => {
            window.removeEventListener('mouseup', mouseupHandler)
        }
    }, [])

    return (
        <button
            disabled={disabled}
            onClick={onClick}
            className={cn(
                (styles.buttonColors[variant] as Record<string, string>)[color],
                styles.button({
                    variant: variant,
                })
            )}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            onMouseDown={() => setActive(true)}
            onMouseUp={() => setActive(false)}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <span
                className={cn(
                    styles.buttonText,
                    (styles.dotColors['dotColor'] as Record<string, string>)[
                        color
                    ],
                    icon
                        ? styles.dotStyles
                        : styles.underlineColors({
                              color: color,
                              focus: focused,
                              hover: hovered,
                              active: active,
                          }),
                    !icon && styles.underLineStyles,
                    styles.buttonText
                )}
            >
                {children}
            </span>
            {icon && (
                <Box justify="center" align="center">
                    <Icon
                        icon={icon}
                        size={'small'}
                        color={
                            active
                                ? theme.components.core.button.variants[color][
                                      'text'
                                  ].onPressed.textColor
                                : hovered && !focused
                                ? theme.components.core.button.variants[color][
                                      'text'
                                  ].default.textColor
                                : focused
                                ? theme.colors.neutral[100]
                                : theme.components.core.button.variants[color][
                                      'text'
                                  ].default.textColor
                        }
                    />
                </Box>
            )}
        </button>
    )
}

const IconButton: React.FC<ButtonProps> = ({
    size = 'small',
    variant = 'icon',
    color = 'primary',
    icon,
    onClick,
    children,
    disabled = false,
    ariaLabel,
}) => {
    const [focused, setFocused] = useState(false)
    const [hovered, setHovered] = useState(false)
    const [active, setActive] = useState(false)

    useEffect(() => {
        const mouseupHandler = () => {
            setHovered(false)
            setActive(false)
        }

        window.addEventListener('mouseup', mouseupHandler)

        return () => {
            window.removeEventListener('mouseup', mouseupHandler)
        }
    }, [])

    return (
        <button
            aria-label={ariaLabel}
            disabled={disabled}
            onClick={onClick}
            className={cn(
                (styles.buttonColors['icon'] as Record<string, string>)[color],
                styles.button({
                    variant: variant,
                }),
                size === 'large'
                    ? styles.largeIconButton
                    : styles.smallIconButton
            )}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            onMouseDown={() => setActive(true)}
            onMouseUp={() => setActive(false)}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <Box justify="center" align="center" padding={1}>
                <Icon
                    icon={icon ? icon : 'Search'}
                    size={size === 'small' ? 'medium' : 'large'}
                    color={
                        focused && !active
                            ? theme.components.core.button.states.focused
                                  .textColor
                            : theme.components.core.button.variants[color].icon
                                  .default.iconColor
                    }
                />
            </Box>
        </button>
    )
}

export default Button
